import React from "react";
import { ButtonBase } from "../ButtonBase";
import clsx from "clsx";
import { ButtonTypedProps } from "../types/ButtonTypedProps"
// import { ButtonBaseProps } from "../types";

export const ButtonTyped = ({ styling, ...baseButtonProps } : ButtonTypedProps) => {
  const { className, ...restButtonProps } = baseButtonProps;
  const typeCN = (() => {
    if(styling==="primary") return "btn-primary";
    if(styling==="default") return "btn-default";
    if(styling==="success") return "btn-success";
    if(styling==="error") return "btn-error";
    if(styling==="link") return "btn-style-link";
  })()
  return <ButtonBase
    {...restButtonProps}
    className={`${clsx(className)} ${typeCN}`}
  />
}