import { deepGet } from '../../../modules';
import { useMemo } from "react";
import { useTranslation } from 'api/translations/useTranslation';

export const useTranslationDeepValue = (scope?: string, emptyValue?: any) : any => {
  const [translation] = useTranslation();
  const value = useMemo(() => {
    if(!scope) return "";
    return deepGet(translation, scope);
  }, [scope, translation])
  return [value ? value : emptyValue]
}