// import { ls } from "../storages/ls";
// const consoleOriginal = {};

class Init {
	constructor() {
		this.replaceConsole(["log", "warn", "error", "time", "timeEnd", "count"]);
	}
	replaceConsole(key: any) {
		if(key && typeof key==="object" && Array.isArray(key)) {
			key.forEach(i=>this.replaceConsole(i));
			return;
		}
		// if(ENV_PROD) {
		// 	const allowShow = window.location.host.indexOf("testes.rede-expressos.pt")>=0 || ls.get("ShowLogs");
		// 	consoleOriginal[key] = console[key];
		// 	console[key] = (...args) => {
		// 		if(!allowShow) return;
		// 		consoleOriginal[key](...args);
		// 	};
		// }
	}
}

export const init = new Init();