import React from "react";
import "./index.scss";
import { fetchJson } from "modules/fetch";
import { uploadFile, convertFileTo64 } from "modules"

const w : any = window;
const uploadUrl = `/ms/upload_image`;
const saveHashUrl = "/ms/images/save_hash/"

const UploadButton = ({onClick}: any) => {
	return <button onClick={onClick} className="picture-edit-button">
		Upload
	</button>
}
const CancelButton = ({onClick}: any) => {
	return <button onClick={onClick} className="picture-edit-button">
		Cancel
	</button>
}
const ConfirmButton = ({onClick}: any) => {
	return <button onClick={onClick} className="picture-edit-button">
		Confirm
	</button>
}

const uploadToMS = async ({file}: any) => {
	const url = uploadUrl;
	const postIt = async ({ url, file }: any) => {
		let formData = new FormData();
		formData.append("file", file, file.name);
		const resp = await fetch(url, { method: "POST", body: formData });
		const result = await resp.json();
		return result;
	};
	const result = await postIt({ url, file });
	return result;
};

export const PictureUploader = ({scope, as, base64, file, onPreview}: any) => {
	return <div className="picture-btns-container">
		<UploadButton onClick={async () => {
			const file = await uploadFile();
			const base64 = await convertFileTo64(file);
			await onPreview({ base64, file });
		}}/>
		{base64 && <CancelButton onClick={async()=>{ await onPreview(null); }}/>}
		{base64 && <ConfirmButton onClick={async()=>{
			const hashes = await uploadToMS({file});
			const hashName = hashes && hashes.length>0 && hashes[0];
			await fetchJson(saveHashUrl, { body: { hashName, scope, value: as }});
			w.refreshAppData();
			await onPreview(null);
		}}/>}
	</div>
}