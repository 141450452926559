import { useMemo } from "react";
import {
	useReservationSearchConfirmed,
	usePassengersRequest,
	useInvoiceClient
} from 'api';
import { TicketingRegisterRequest } from "types/ticketing/TicketingRegisterRequest";
import { useTripsTicketsSelected } from "api/ticketing/useTripsTicketsSelected";

export const useReservationRegisterRequest = () : [TicketingRegisterRequest] => {
	const [passengers] = usePassengersRequest();
	const [{ outgoing_ticket, return_ticket }] = useTripsTicketsSelected();
	const [invoice] = useInvoiceClient();
	const [{
		ida,
		volta,
	}] = useReservationSearchConfirmed();

	const res = useMemo(() => {
		return {
			outgoing_schedule: outgoing_ticket && outgoing_ticket.schedule_id,
			outgoing_date: ida,
			return_schedule: (volta && return_ticket && return_ticket.schedule_id) || null,
			return_date: volta,
			passengers,
			invoice
		}
	}, [outgoing_ticket, return_ticket, ida, volta, passengers, invoice])

	if(!passengers) {
		return [null];
  }
	return [res]	
}