import React, { useRef } from "react";
import { PrimitiveFieldBase } from "./types";
import { RecaptchaInput, RecaptchaProps } from "../inputs";

export interface CaptchaPrimitiveProps extends PrimitiveFieldBase {
  recaptcha: RecaptchaProps;
}

export const CaptchaPrimitive = ({
  recaptcha,
  onChange,
  ...htmlAttributes
} : CaptchaPrimitiveProps) => {
  const ref = useRef<any>();
  //response
  return <div {...htmlAttributes}>
    <input type={"hidden"} ref={ref} onChange={onChange}/>
    <RecaptchaInput
      verifyCallback={(response) => {
        (ref.current as any).value = response;
      }}
      {...recaptcha}
    />
  </div>
}