import React from "react";
import { datetime } from "modules";
import { TableOld } from "controls";
import "./index.scss";
import { useSalesPeriods, useSalesState } from "api";

export const SalesPeriodsList = (props: any) => {
	const [periods, { success: periodsSuccess }] = useSalesPeriods();
	const [{periodID},,{save: saveState}] = useSalesState();
	if(periodsSuccess) {
		if(!periodID && periods.length>0) {
			setTimeout(() => {
				const last = periods[periods.length-1];
				saveState({periodID: last && last.ID})
			})
		}
		if(periodID) {
			const first = periods && periods.filter((c: any)=>c.ID===periodID);
			if(!periods || (first && first.length===0)) {
				setTimeout(() => {
					saveState({periodID: null})
				})
			}
		}
	}

	return <div {...props} className={"sales-periods"}>
			<div>
                <TableOld
					selectable={true}
					isDefault={true}
				>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Período</th>
                        </tr>
                    </thead>
                    <tbody>
						{periodsSuccess && periods && periods.map((item: any, index: any)=>{
							const periodBegin = item.PeriodBegin && new Date(item.PeriodBegin);
							const periodEnd = item.PeriodEnd && new Date(item.PeriodEnd);
							const coloredCN = index%2!==0 ? "colored-old" : "";
							const selectedCN = ""+item.ID === ""+periodID ? "selected" : "";
							return <tr
								key={index}
								className={`${coloredCN} ${selectedCN}`}
								data-info={JSON.stringify(item)}
								onClick={()=>{
									saveState({periodID: item.ID})
								}}
							>
								<td>{item.PeriodID}</td>
                                <td>{datetime.toDateString(periodBegin) || " - "} / {datetime.toDateString(periodEnd) || " - "}</td>
							</tr>
						})}
					</tbody>
                </TableOld>
            </div>
		</div>
}