import React, { useState } from "react";
import "./index.scss";
import { Form } from "./Form";
import { CustomersTable } from "./CustomersTable";
import { useClientInfo, useClientInfoRouting } from "api";
import { hocDialog } from "controls/dialogs";
import { EditableText } from "controls";

const SCOPE = "CLIENT_INFO";

export const SearchDialog = hocDialog({
	name: "dialogMultipleMatches",
	title: "Pesquisar Clientes",
	minWidth: 650,
	minHeight: 270
})(() => {
	const [, setClientInfo] = useClientInfoRouting();
	const [{ customerId, customer }] = useClientInfo();
	const [, setSubmitClientMessageShown] = useState<boolean>(false);
	return <>
		<Form
			initialValues={{customerId}}
			save={({customerId}) => setClientInfo({customerId})}
			setSubmitClientMessageShown={setSubmitClientMessageShown}
		/>
		<span>
			<EditableText scope={SCOPE} as="SelectClient" />
			&nbsp;
			{!customer && <EditableText scope={SCOPE} tag="span" as="ChooseClient" className='error' />}:
		</span>
		<hr />
		<CustomersTable />
	</>
})