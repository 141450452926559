import {
	mergeFetchOnceStatus,
	mergeFetchOnceMeta
} from 'modules/fetch';
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';
import { FETCH, API } from "types";
import { useFetchOrigins } from "./useFetchOrigins";
export const useOrigins = ({ offer } : API.HOOKS.UseOriginsProps) : FetchOnceResult<API.RESPONSES.OriginsResponse> => {
	const [dataNat,statusNat,metaNat] = useFetchOrigins({
    lock: offer==="international",
    national: true,
    international: false
  });
	const [dataInt,statusInt,metaInt] = useFetchOrigins({
    lock: offer==="national",
    national: false,
    international: true
  });

  const data =
    offer==="both" ?
      (
        (dataNat && dataInt) ? [...dataNat, ...dataInt] :
        (dataNat || dataInt)
      ) :
    offer==="national" ? dataNat :
		offer==="international" ? dataInt : [];
		
	const status =
		offer==="both" ? mergeFetchOnceStatus(statusNat, statusInt) :
		offer==="national" ? statusNat :
		offer==="international" ? statusInt :
		statusNat;

	const meta =
		offer==="both" ? mergeFetchOnceMeta(metaNat, metaInt) :
		offer==="national" ? metaNat :
		offer==="international" ? metaInt :
		metaNat;

	return [
		data,
		status,
		meta
	];
}