import { FETCH } from 'types';
import { fetchJson } from 'modules/fetch';
import { DeleteVoucherRequest } from "./types";
import { useStateNamed } from "hooks";
import { useVoucherRefresher } from './useVoucherRefresher';

export const useVoucherDeleteStatus = () : [FETCH.FetchOnceStatus, (status : FETCH.FetchOnceStatus) => void] => {
  const [status, setStatus] = useStateNamed<FETCH.FetchOnceStatus>("useVoucherDeleteStatus", {});
  return [status, setStatus];
}

export const useVoucherDelete = () : [FETCH.FetchOnceStatus, (body : DeleteVoucherRequest) => Promise<boolean>] => {
  const [, refreshVoucher] = useVoucherRefresher();
  // const [,, { refresh }] = useVoucherCurrent();
  const [status, setStatus] = useVoucherDeleteStatus();
  const deleteVoucher = async(body : DeleteVoucherRequest) => {
    try {
      await setStatus({ loading: true });
      await refreshVoucher();
      const response = await fetchJson<boolean>("/api/ticketing/voucher/deleteVoucher", { method: "POST", body });
      // await refreshVoucher();
      if(response===true) {
        await refreshVoucher();
      }
      else { throw new Error(); }
      await setStatus({ loading: false, success: true })
      return response;
    }
    catch(error) {
      console.error(error);
      await setStatus({loading: false, success: false, error});
      throw error;
    }
  }
  return [status, deleteVoucher]
}