export const apiValidateResponse = (response : any) => {
  if(response && response.status===401) {
  const locationT = "login";
  console.log(`Redirecting to: ${locationT}`);
  setTimeout(() => {
    window.location.pathname = locationT;
  }, 100);
}
  if(!response.ok) {
      throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
  return response;
}