export const isEqual = (time1 : Date, time2 : Date, options: any={}): any => {
	//return if time1 is equal to time2
		const { year, month, date, day, hour, minute, second, millisecond } = options;
		if(!time1 && !time2) return true;
		if(!time1 || !time2) return false;
		if(millisecond) {
			if(time1.getUTCMilliseconds()!==time2.getUTCMilliseconds()) return false;
			return isEqual(time1, time2, { second: true });
		}
		if(second) {
			if(time1.getUTCSeconds()!==time2.getUTCSeconds()) return false;
			return isEqual(time1, time2, { minute: true });
		}
		if(minute) {
			if(time1.getUTCMinutes()!==time2.getUTCMinutes()) return false;
			return isEqual(time1, time2, { hour: true });
		}
		if(hour) {
			if(time1.getUTCHours()!==time2.getUTCHours()) return false;
			return isEqual(time1, time2, { date: true });
		}
		if(date || day) {
			if(time1.getUTCDate()!==time2.getUTCDate()) return false;
			return isEqual(time1, time2, { month: true });
		}
		if(month) {
			if(time1.getUTCMonth()!==time2.getUTCMonth()) return false;
			return isEqual(time1, time2, { year: true });
		}
		if(year) {
			if(time1.getUTCFullYear()!==time2.getUTCFullYear()) return false;
			return true;
		}
		return true;
	}