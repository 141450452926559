import React from "react";
// import { SchedulesSearchTabs } from "views/search/SchedulesSearchTabs";
import clsx from "clsx";
// import { ClientInfoView } from "views/client";

import "./index.scss";
import { Form } from "./Form";
import { SchedulesSearchTabs } from "pages/Home/views/SchedulesSearchTabs";
import { ClientInfoView } from "pages/Home/views/ClientInfoView";

export const FormVoucherSearch = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
  return <div {...restAttributes} className={`voucher-search-form ${clsx(className)}`}>
    <Form initialValues={{}}/>
  </div>
}

export const VoucherSearchView = ({ ...htmlAttributes } : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
  return <div {...restAttributes} className={`voucher-search-view ${clsx(className)}`}>
    <div className="form-container">
      <SchedulesSearchTabs allow={["national"]}>
        <FormVoucherSearch />
        <ClientInfoView /> 
      </SchedulesSearchTabs>
    </div>
  </div>
}
