import { useEffect, useCallback } from "react";
import { hex_md5 } from "modules/encrypt";
import { fetchJson } from "../modules/fetchJson";
// import { FetchJsonParams } from "../modules/fetchJson";
// import { UseFetchOnceMeta } from './types/UseFetchOnceMeta';
// import { UseFetchOnceResult } from './types/UseFetchOnceResult';
// import { useData } from "hooks/useData";
// import { useNamedRefresher } from 'hooks';

import { FetchOnceProps } from "../types/FetchOnceProps";
import { FetchOnceResult } from "../types/FetchOnceResult";
import { FetchJsonParams } from "modules/fetch/types/FetchJsonParams";
import {  useRefresherNamedTimeout } from "hooks";
import { FetchOnceStatus } from "modules/fetch/types/FetchOnceStatus";
import { FetchOnceMethods } from "modules/fetch/types/FetchOnceMethods";

// const STATE_DEFAULT : UseFetchOnceStatus = { loading: false, success: false, error: false };
// const loadings : { [key: string]: boolean; } = {};
const dataCache : { [key: string]: any } = {};
const statusCache : { [key: string]: FetchOnceStatus } = {};


// export interface UseFetchOnceProps extends FetchJsonParams {
// 	lock?: boolean;
// 	refreshKey?: any;
// }

// export * from "./useFetchOnce_old"

export const useFetchOnce = <TData>(url : string, options? : FetchOnceProps) : FetchOnceResult<TData> => {
	const { body, method="POST", headers, lock=false, refreshKey } = options || {};
	const fetchJsonParams : FetchJsonParams = { body, method, headers }
	const keyInner = hex_md5(JSON.stringify({url,lock,refreshKey, fetchJsonParams}));

	const [refresh] = useRefresherNamedTimeout("useFetchOnce_"+keyInner);
	if(!statusCache[keyInner]) statusCache[keyInner] = { loading: false, success: false, error: null }

	const data = dataCache[keyInner];
	const { loading, success, error } = statusCache[keyInner];

	const setInnerData = useCallback((newData: any) => dataCache[keyInner] = newData, [keyInner]);
    const setInnerStatus = useCallback((newStatus: FetchOnceStatus) => statusCache[keyInner] = newStatus, [keyInner]);
    
	const setInner = useCallback((newData: any, newStatus: FetchOnceStatus) => {
		setInnerData(newData);
		setInnerStatus(newStatus);
	}, [setInnerData, setInnerStatus])

	const makeFetch = useCallback(async () => {
		try {
			const data = await fetchJson<TData>(url, fetchJsonParams);
			setInner(data, { loading: false, success: true, error: null });
			refresh();
		}
		catch(error) {
			setInner(null, { loading: false, success: false, error });
			refresh();
		}
    }, [url, fetchJsonParams, refresh, setInner])
    
	useEffect(() => {
		if(!lock && url && !statusCache[keyInner].loading && (!loading && !success && !error)) {
			setInnerStatus({ loading: true, success, error })
			makeFetch();
		}
	}, [makeFetch, setInnerStatus, keyInner, url, loading, success, error, lock]);

	const methods : FetchOnceMethods = {
			refresh: async () => {
				setInnerStatus({ loading: true, success, error })
				await makeFetch();
			},
			refreshSilent: async() => {
				await makeFetch();
			},
			setData: (data : any) => {
				setInnerData(data);
				refresh();
			}
	}
	return [
		data,
		{ loading, success, error },
		methods
	];
}