import { useStateNamedTimeout } from "../state";
import { useEffect } from "react";
import { lowStorage } from "../../modules/managers";

interface UseLowStorageMethods {
  get: (key : string) => string;
  set: (key : string, value : any) => any;
  remove: (key : string) => any;
}
export const useLowStorage = () : [any, UseLowStorageMethods] => {
  const [state, setState] = useStateNamedTimeout("useLowStorage");
  const refresh = () => {
    setState(lowStorage.db.getState());
  }
  useEffect(() => {
    lowStorage.subscribe(refresh);
    return () => {
      lowStorage.unsubscribe(refresh);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if(!state) {
    refresh();
  }
  return [
    state,
    {
      get: (key : string) => {
        const res = lowStorage.get(key);
        return res;
      },
      set: (key : string, value : any) => {
        const res = lowStorage.set(key, value);
        return res;
      },
      remove: (key : string) => {
        const res = lowStorage.remove(key);
        return res;
      }
    }
  ];
}