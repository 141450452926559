import React, { useContext } from "react";
import { fetchJson } from "modules/fetch";
import { useRoutingLang } from "hooks";
// import { useInitialContext } from "../../initial/context/useInitialContext";
import { SaveTranslationRequest } from "../../app/translations/types/SaveTranslationRequest";
import { TranslationsProviderProps } from "../../app/translations/types/TranslationsProviderProps";
import { InitialContext } from "api/app/InitialContext";
import { TranslationsContext } from "./TranslationsContext";

export const TranslationsProvider = ({children, saveUrl} : TranslationsProviderProps) => {
  const [lang] = useRoutingLang();
  const [initial, refresh] =  useContext(InitialContext);
  const translation = initial && initial.translations && initial.translations[lang];
  const save = async ({scope, value, as} : SaveTranslationRequest) => {
      await fetchJson(saveUrl, { body: { lang, label: as, scope, value }, method: "POST" });
      await refresh();
  }

	return <TranslationsContext.Provider value={{lang, translation, save}}>
		{children}
	</TranslationsContext.Provider>
}