import { FetchOnceMethods } from '../types/FetchOnceMethods';

export const mergeFetchOnceMeta = (meta1 : FetchOnceMethods, meta2 : FetchOnceMethods) : FetchOnceMethods => {
  const refresh = async() => {
    await meta1.refresh();
    await meta2.refresh();
  }
  const refreshSilent = async() => {
    await meta1.refreshSilent();
    await meta2.refreshSilent();
  }
  const setData = () => {
    console.error("setData is forbidden for merged metas");
  }
  return { refresh, refreshSilent, setData }
}