import { useNavigation, useCurrentRoute } from "react-navi";
import { stringify } from "query-string";
import { isFalsy } from "modules";
import { UseRouteQueryKeysResult } from "./types/UseRouteQueryKeysResult";
import { RoutingParams } from "./types/RoutingParams";

/**
 * Function to manipulate with query string
 * 
 * @returns [0] - deserialized query string parameters, [1] - action methods { addQuery, setQuery, setPathname, navigate }
 * @version 1.0.0
 */
export const useRouteQueryKeys = (): UseRouteQueryKeysResult => {
	const navigation = useNavigation();
	const { url: { query, pathname } } = useCurrentRoute();
	const { navigate } = navigation;
	const setPathname = async (pathname: string) => {
		if (Object.keys(query).length > 0) {
			await navigate(pathname + "?" + stringify(query));
		}
		else {
			await navigate(pathname);
		}
	}
	const setQuery = async (query: RoutingParams | string) => {
		const queryStr = typeof query === "string" ?
			(query.indexOf("?") === 0 ? query : `?${query}`) :
			`?${stringify(query)}`;

		const isEmptyQuery =
			(typeof query === "string" && query.length < 3) ||
			(typeof query === "object" && Object.keys(query).length === 0) ||
			(isFalsy(query));
		if (isEmptyQuery) { await navigate(pathname); }
		else { await navigate(pathname + queryStr); }
	}

	const addQuery = async (obj: RoutingParams) => {
		const newQuery = Object.assign({}, query, obj);
		Object.keys(obj).forEach(key => {
			const value = obj[key];
			if (isFalsy(value)) {
				delete newQuery[key];
			}
		})
		await setQuery(newQuery);
	}

	return [
		query,
		{
			addQuery,
			setQuery,
			setPathname,
			navigate
		}
	]
}