import React from "react";
import { LOADING_STR, PRICE_INFO_TEXT } from "consts";
import { ToolTipImage, TableSchemed, TitlePanel, ErrorEditable, EditableText } from "controls";
import "./index.scss";
import { useReservationId, useReservationSelection } from "api";
import { useTripsPrices, TripPriceItem } from "api/schedules";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";

export const ReservationPricesView = ({ className, ...rest } : React.HTMLAttributes<{}>) => {
	const [reservationId] = useReservationId();
	const [,{ loading: loadingSelection, error: errorSelection }] = useReservationSelection();
	const [priceList, {loading: loadingPrices, error: errorPrices }] = useTripsPrices();
	const [,{ loading: loadingTrips }] = useTripsConfirmed();
	const panelStatus = {
		loading: loadingSelection || loadingPrices || loadingTrips,
		error: [errorSelection, errorPrices].filter(x => !!x)[0]
	}
	const { loading, error } = panelStatus;
	return <div {...rest} className={`${className} panel-prices`}>
		<TitlePanel
			title={<div>
				<ToolTipImage
					src={"/images/icons/info.png"}
					className={!reservationId ? "" : "hidden"}
					title={PRICE_INFO_TEXT}
				/>
				Preços
			</div>}
			className="reservation-prices center-title no-overflow"
			style={{height: "calc(100% - 2px)"}}
			loading={loading && LOADING_STR}
		>
			{!error && <TableSchemed
        items={priceList}
        style={{width: "100%"}}
        body={{
          schema: (item : TripPriceItem, index) => {
            return { items: [
                {
                  children: <EditableText scope={"PASSENGERS"} as={`PASSENGER_${item.fare_type_id}`}>
                    {({value})=>{
                      return value || item.fare_type_id
                    }}
                  </EditableText>,
                  style: { display: "flex", justifyContent: "center"}
                },
                {
                  children: `€${item.price.toFixed(2)}`,
                  style: { display: "flex", justifyContent: "center"}
                }
              ]
            }
          }
        }}
      />}
      {error && <ErrorEditable code={error.errorDetails} />}
		</TitlePanel>
	</div>
};