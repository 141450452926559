import "./index.scss";
import React from "react";
import { TitlePanel, ErrorEditable } from "controls";
import { LOADING_STR } from "consts";

export const RenderConditionsData = ({title, conditions, status}: any) => {
	return <TitlePanel
		type={"dark"}
		title={title}
		className="CONDITIONS"
		loading={status.loading && LOADING_STR}
		style={{marginBottom: "5px"}}
	>
		{status && status.success && <React.Fragment>
			<div className="conditionsSubTitle">{conditions.SubTitle}</div>
			{Object.keys(conditions.Content).map((item, index) => [
				<div key={"A" + index} className="conditionItem">
				{item}
				</div>,
				conditions.Content[item].map((item: any, index1: any) => (
				<div
					key={index1}
					className="conditionsSubItem"
					dangerouslySetInnerHTML={{ __html: item }}
				/>
				))
			])}
		</React.Fragment>}
		{status.error && <ErrorEditable code={status.error.error} />}
	</TitlePanel>
}