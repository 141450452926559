import React from "react";
import "./index.scss";
import { PictureEditable } from "controls";

// import { PictureEditable } from "controls";
// import { EditableSample } from "./samples/EditableSample";
// import { GroupersSample } from "./samples/GroupersSample";
// import { TablesSample } from "./samples/TablesSample";
// import { AsyncControlSample } from "./samples/AsyncControlSample";

// import { VisibilityCheck } from "controls/ui-dumb";

// const PictureSample = ()=><PictureEditable scope="Test" as="Test"/>

// const displayedComponents = {
// 	EditableSample,
// 	GroupersSample,
// 	TablesSample,
// 	AsyncControlSample,
// 	PictureSample
// }

export const ViewControls = () => {
	return <div>
		<PictureEditable scope="TEST" as="TEST"/>
	</div>
}

// export class Controls extends React.Component {
// 	render() {
// 		return null;
// 		// return <div className="admin-styleguide-controls">
// 		// 	{Object.keys(displayedComponents).map(key => {
// 		// 		const elem = displayedComponents[key];
// 		// 		if(!elem) return null;
// 		// 		return <VisibilityCheck key={key} title={key}>
// 		// 			{React.createElement(elem)}
// 		// 		</VisibilityCheck>
// 		// 	})}
// 		// </div>
// 	}
// }