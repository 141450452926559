import React from "react";
import clsx from "clsx";
import { TableRow } from "../TableRow";
import { _TableBodyProps } from "../../types/_TableBodyProps";
import { _TableRowProps } from "../../types/_TableRowProps";

export const TableBody = ({ items, settings, ...htmlAttributes } : _TableBodyProps) => {
  const { children, className, ...restAttributes } = htmlAttributes;
  const { colored /*...testRest*/ } = settings || {};
  return <tbody {...restAttributes}>
    {items && items.map((item, index) => {
      const itemProps : _TableRowProps = (() => {
        if(Array.isArray(item)) return { selected: false, items: item }
        else return item;
      })();
      const { className } = itemProps;
      const colorCN = `${clsx(colored && "color-"+((colored==="2x" && index%2) || (colored==="3x" && index%3) || 0) )}`;
      return <TableRow {...itemProps} className={`${clsx(className)} ${clsx(colorCN)}`} key={index}/>
    })}
    {children}
  </tbody>
}