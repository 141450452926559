import React from "react";
import "./index.scss";

export interface PlaceProps {
	occupied?: boolean;
	conductor?: boolean;
	blocked?: boolean;
	reserved?: boolean;
	flex?:boolean;
	promo?:boolean;
	selectedSeat?: any;
	newSeat?: any;
	number?: any;
	onClick?: any;
}

export const Place = ({
	conductor,
	occupied,
	blocked,
	reserved,
	flex,
	promo,
	selectedSeat,
	newSeat,
	number,
	onClick
}: PlaceProps) => {
	const seatClick = () => {
		onClick();
	}

	const isActive = number === selectedSeat || number === newSeat;
	
	let info;
	if(flex)
		info=<span style={{fontSize: 6}}>VISTA</span>;
	else if(promo)
	{
		if(blocked || reserved || occupied)
			info=<span style={{color: "#ffffff",fontSize: 6}}>PROMO</span>;
		else
			info=<span style={{color: "#71BF48",fontSize: 6}}>PROMO</span>;
	}
	else
		info = <span style={{marginTop: 9}}></span>;

	if (conductor)
		return <div className={"bus-place-icon condutor"}>
			<i className="icon-Condutor" />
		</div>
	if (blocked)
		return <div className={"bus-place-icon B"}>
			{number} {info}
		</div>
	if (occupied)
		return <div className={"bus-place-icon O"}>
			{number} {info}
		</div>
	if (reserved)
		return <div className={"bus-place-icon R " + (isActive && "active")} onClick={seatClick}>
			{number} {info}
		</div> 
	return <div className={"bus-place-icon L"} onClick={seatClick}>
		{number} {info}
	</div>



}