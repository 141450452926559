import React, { useState } from "react";
import { ButtonEmoji } from "controls";
import { ErrorCodesDialog } from "./ErrorCodesDialog";
import "./index.scss";
export const ErrorCodesTranslations = () => {
	const [shown, setShown] = useState<boolean>(false);

	return <>
		<ButtonEmoji icon="📛" onClick={() => setShown(true)} />
		{shown && <ErrorCodesDialog onClose={() => setShown(false)}/>}
	</>
}