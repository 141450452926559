/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import 'react-resizable/css/styles.css';
import { PanelResizable, PanelDraggable } from "../..";
import { useLockBodyScroll, useOnClickOutside } from "hooks";
import { DialogContent } from "./DialogContent";
import { closeLocker } from "./closeLocker";

import "./hocDialog.scss";

interface HocDialogProps {
	name: string;
	fullscreen?: boolean;
	title?: string;
	minWidth?: number;
	minHeight?: number;
	width?: number;
	height?: number;
	outsideClose?: boolean;
	draggable?: boolean;
	resizable?: boolean;
}

interface HocDialogInnerProps {
	className?: string;
	open?: boolean;
	noDialog?: boolean;
	defaultStyle?: boolean;
	onClose?: () => any;
}

export const hocDialog = (props : any/*HocDialogProps*/) => (Element : any) => (props2 : any) => {
  let { name, fullscreen, title, minWidth, width, minHeight, height, outsideClose=true, draggable=true, resizable=true } = props;
  let {	className, open, noDialog = false, defaultStyle = true, onClose = () => { }, ...rest } = props2;

	if(minWidth && (!width || width<minWidth)) { width = minWidth; }
	if(minHeight && (!height || height<minHeight)) { height = minHeight; }
	if(fullscreen) {
		draggable = false;
		resizable = false;
	}
	if(noDialog && (resizable || draggable)) {
		draggable = false;
		resizable = false;
	}
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const [size, setSize] = useState<any>({ width, height });

	useEffect(() => {
		let tries = 0;
		if(!isMounted) {
			const tryMount = () => {
				const dialogWrapper = document.getElementsByClassName(name)[0];
				if(dialogWrapper) {
					const dialogWindow : any = dialogWrapper.getElementsByClassName("dialog-window")[0];
					const width = dialogWindow.offsetWidth;
					const height = dialogWindow.offsetHeight;
					if(!size.width || !size.height) {
						setSize({width, height});
					}
					setIsMounted(true);
				}
				else {
					if(tries<100) {
						setTimeout(tryMount, 100);
					}
				}
			}
			tryMount();
		}
	}, []);

	const windowRef = useRef();
	const [isOpen] = useState<any>(typeof open==="undefined" || open);
	const onCloseWrap = (e: any) => {
		if(noDialog)
			return;
		if(!closeLocker())
			return;
		const closeRes = onClose();
		if(closeRes===false)
			return;
		e && e.preventDefault && e.preventDefault();
	}

	if(outsideClose) {
		useOnClickOutside(windowRef, onCloseWrap);
	}
	if(!noDialog) {
		useLockBodyScroll();
	}
	if(!isOpen) return null;
	const rootStyle = (() => {
		let transform: any = null;
		const parentDialog = (() => {
			const current : any = windowRef && windowRef.current;
			let parent = current && current.parentNode;
			if(current && parent) {
				while(true) {
					const parentClass = parent ? (parent.className||"") : "";
					const isWrapper = parentClass.indexOf("dialog-wrapper")>=0;
					if(isWrapper && parentClass.indexOf(name)<0) {
						return parent;
					}
					parent = parent.parentNode;
					if(!parent) {
						return null;
					}
				}
			}
		})();

		if(parentDialog) {
			const parentDialogWindow = parentDialog.getElementsByClassName("dialog-window")[0];
			const rects = parentDialogWindow.getClientRects()[0];
			transform = "translate(-"+rects.left+"px, -"+rects.top+"px)";
		}
		return {
			transform
		}
	})();

	const passProps = (() => {
		return {
			propsRoot: {
				className: `dialog dialog-wrapper ${name} ${className} ${noDialog && "no-dialog"} ${defaultStyle && "default"} ${isMounted ? "mounted" : "not-mounted"} ${draggable ? "draggable" : "not-draggable"} ${fullscreen ? "fullscreen" : ""} ${noDialog ? "no-dialog" : ""}`,
				style: rootStyle
			},
			propsDraggable: {
				...size,
				name,
				draggable: draggable && isMounted
			},
			propsResizable: {
				...size,
				resizable: resizable && isMounted,
				minWidth,
        minHeight,
        onResize: () => {
          closeLocker();
        }
			},
			propsContent: {
				...size,
				name,
				windowRef,
				title,
				onCloseWrap,
				rest,
				Element
			}
		}
	})();

	return <div {...passProps.propsRoot}>
		<PanelDraggable {...passProps.propsDraggable}>
			<PanelResizable {...passProps.propsResizable}>
				<DialogContent {...passProps.propsContent}/>
			</PanelResizable>
		</PanelDraggable>
	</div>
}