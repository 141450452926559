import React from "react";
import "./index.scss";
import { ViewDynamic } from "./ViewDynamic";
import { ViewOriginal } from "./ViewOriginal";

export const PictureView = ({
	before,
	base64,
	filename,
	options: {
		widthRatio=1,
		//maxwidth scale coefficient

		size=null,
		// 100x100

		quality=80,
		// quality of image in range 0 - 100

		load=true,
		//if false - renders empty image

		original=false,
		//if true - gives NO srcSet image

	} = {},
	...rest
}: any) => {
	const View : any = (() => {
		if(!filename) return null;
		if(!load) return ()=><img alt="none" style={{display: "none"}}/>
		if(original) return ViewOriginal;
		if(filename.indexOf(".svg")>=0) return ViewOriginal;
		if(filename.indexOf(".gif")>=0) return ViewOriginal;
		return ViewDynamic;
	})();

	return <React.Fragment>
		{before}
		{base64 && <img alt="base64" src={base64}/>}
		{!base64 && filename && <View
			{...rest}
			options={{ widthRatio, size, quality }}
			filename={filename}
		/>}
	</React.Fragment>
}