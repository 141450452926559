import { LowStorage } from './../../types/STORAGE/LowStorage';
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { createHandlerList } from "./createHandlerList";

export const createLowStorage = (name : string, defaults : any) : LowStorage => {
  const adapter = new LocalStorage(name);
  const db = low(adapter);
  db.defaults(defaults).write();
  const handlerList = createHandlerList("createLowStorage");
  return {
    db,
    all: () => db.getState(),
    get: (key) => db.get(key).value(),
    set: (key, value) => {
      const res = db.set(key, value).write();
      handlerList.process();
      return res;
    },
    remove: async (key) => {
      const res = await db.unset(key).write();
      setTimeout(() => {
        handlerList.processTimeout();
      })
      return res;
    },
    subscribe: handlerList.subscribe,
    unsubscribe: handlerList.unsubscribe,
  }
}