import React, { useState } from "react";
import { getRoutes, getRouteList } from "./getRouteList"
import "./index.scss";
// import { useAdminStorage } from "hooks";

export const ViewPages = () => {
  const routeList = getRouteList();
  const [selected, setSelected] = useState<any>(null);
  const selectPage = (name : string) => {
    const routes: any = getRoutes();
    const route = routes[name];
    setSelected(route)
  }
	return <div className={"admin-page-items"}>
		<div>
      {routeList.map((name, index)=>{
        return <button className="btn-primary" style={{marginRight: 5}} onClick={() => selectPage(name)}>{name}</button>
      })}
    </div>
    {selected && React.createElement(selected)}
	</div>
}