import { useInitial } from 'api/app/useInitial';
import { UserSigned } from 'types/user/UserSigned';

// export type UseUser_Result = [
//   UserSigned,
//   { refresh: () => {} }
// ]

export const useUser = () => {// : UseUser_Result
	const [initial, refresh] = useInitial();
  return [
		initial && initial.user,
		{ refresh }
	];
}