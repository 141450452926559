import React from "react";
import { SchedulesSearchTabs } from "../SchedulesSearchTabs";
import "./index.scss";
import "./old.scss";
import clsx from "clsx";
import {FormSchedulesSoveSearch } from "./FormSchedulesSoveSearch";
import { ClientInfoView } from "../ClientInfoView";

export const SchedulesSearchSoveView = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
  return <div {...restAttributes} className={`schedules-search-sove-view ${clsx(className)}`}>
    <div className="form-container">
      <SchedulesSearchTabs>
        <FormSchedulesSoveSearch />
        <ClientInfoView />
      </SchedulesSearchTabs>
    </div>
  </div>
}
