import { createBodyElement } from "../../dom";

export const urlPrint = (url : string, mimetype: string) => {
  const frame = createBodyElement("iframe", {
		type: mimetype,
		src: url
	});
	frame.focus();
	frame.contentWindow.print();
  setTimeout(function () {
        window.URL.revokeObjectURL(url);
  }, 0);
}