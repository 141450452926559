import React from "react";
import "./index.scss";
import { TitlePanel, Flex, ButtonTyped } from "controls";
import { useClientInfo, useClientInfoRouting } from "api";
import { useReservationId } from "api";
import { useRouteQueryKey, useBootstrap } from "hooks";
import { SearchDialog } from "./SearchDialog";


const TextBlock = ({title, value, ...rest} : { title: string, value: string} & React.HTMLAttributes<{}>) => {
	return <td {...rest}>
		<span style={{whiteSpace: "nowrap", fontWeight: "bold"}}>{title}:&nbsp;</span>
		<span style={{whiteSpace: "nowrap"}}>{value}</span>
	</td>
}

const Separator = () => <div style={{borderLeft: "2px solid #1faece", marginLeft: "2px", marginRight: "2px"}}></div>

export const ClientInfoView = ({style, ...props} : React.HTMLAttributes<any>) => {
	const [clientInfo, setClientInfoOpened] = useRouteQueryKey("clientinfo");
	const [{ customer }] = useClientInfo();
	const { mobile } = useBootstrap()
	const [, setClientInfoRouting] = useClientInfoRouting();
	const [, setClientInfo] = useRouteQueryKey("clientinfo");
	const [reservationId ] = useReservationId();

	const renderCustomerInfoContent = () => {
		if(!customer || Object.keys(customer).length<1) return null;
		const { Name, Email, ProfileId, Address, RFLEXCard, CustomerNumber } = customer;
		const { PostalCodeDescription } = Address || {};
		const { CardNumber } = RFLEXCard || {};
		return <React.Fragment>
			<table>
				{mobile && <tbody>
					<tr><TextBlock title="Cliente" value={Name} /></tr>
					<tr><TextBlock title="Nº Cliente" value={CustomerNumber} /></tr>
					<tr><TextBlock title="Nº RFLEX" value={""+CardNumber} /></tr>
					<tr><TextBlock title="Localidade" value={PostalCodeDescription} /></tr>
					<tr><TextBlock title="Email" value={Email} /></tr>
					<tr><TextBlock title="Perfil" value={ProfileId} /></tr>
				</tbody>}
				{!mobile && <tbody>
					<tr>
						<TextBlock title="Cliente" value={Name} />
						<TextBlock title="Nº Cliente" value={CustomerNumber} />
					</tr>
					<tr>
						<TextBlock title="Nº RFLEX" value={""+CardNumber} />
						<TextBlock title="Localidade" value={PostalCodeDescription} />
					</tr>
					<tr>
						<TextBlock title="Email" value={Email} />
						<TextBlock title="Perfil" value={ProfileId} />
					</tr>
				</tbody>}
			</table>
			<Separator />
		</React.Fragment>
	}
	const clear = async () => {
		await setClientInfoRouting(null);
	}

	return <TitlePanel
		{...props}
		type={"simple"}
		className="client-info-panel"
		style={{...style, minHeight: "fit-content"}}
	>
		<Flex column>
			{renderCustomerInfoContent()}
			{clientInfo && <SearchDialog onClose={() => setClientInfoOpened(null)} />}
			<Flex row className="flex-between">
				<ButtonTyped
					styling="link"
					disabled={!!reservationId}
					onClick={(e) => {
						setClientInfo(true);
						e && e.preventDefault();
					}}
				>Pesquisar Clientes</ButtonTyped>
				{customer && <ButtonTyped
					styling="primary"
					disabled={!!reservationId}
					onClick={clear}
				>Limpar</ButtonTyped>}
			</Flex>
		</Flex>
	</TitlePanel>
}