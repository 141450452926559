import { fetchJson } from 'modules/fetch';
import { useUser } from "./useUser";

type UseUserStorageResult = any//[any, { set: (storage: any) => {}, append: (key: string, value : any) => {} }]

export const useUserStorage = () : UseUserStorageResult => {
  const [user, { refresh }] = useUser();
  const { storage } = user || { storage: {} };

  const set = async (newStorage : any) => {
    await fetchJson("/api/storage/user", {
      body: newStorage,
      method: "POST"
    });
    await refresh();
  }
  const append = async (key : string, value : any) => {
    await set({...storage, [key]: value});
  }

  return [
    storage || {},
    { set, append }
  ]
}