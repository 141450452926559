import React, { useState } from "react";
import { ChartRender } from "./ChartRender";
import { Loader, ErrorEditable } from "controls";
import { LOADING_STR } from "consts";
import { DialogHierarchy } from "../DialogHierarchy";
import "./index.scss";
import { useConfigurationSelection, useConfigurationState } from "api/configuration";

const btnStyle = {
	backgroundColor: "transparent",
	border: "none",
	color: "#222"
}

const ChangeChartType = () => {
	const [,,{save}] = useConfigurationSelection(); //{ chartType }
	return <div className="flex-column flex-between">
		<button onClick={()=>save({chartType: "vertical"})} style={btnStyle}>
			VERTICAL
		</button>
		<button onClick={()=>save({chartType: "horizontal"})} style={btnStyle}>
			HORIZONTAL
		</button>
	</div>
}

export const Chart = () => {
	const [{ chartType }] = useConfigurationSelection();
	const [{ chartError }] = useConfigurationState();
	const [hierarchyShown, setShowHierarchy] = useState<boolean>(false);
	if(!chartType) {
		return <Loader text={LOADING_STR} />
	}
	return <React.Fragment>
		<div className="chart-configs flex-row" style={{minHeight: "40px"}}>
			<div className="flex-column flex-center margin-right-8">
				<img
					alt="info"
					style={{cursor: "pointer"}}
					src="images/configuration/info.png"
					onClick={()=>setShowHierarchy(true)}
				/>
			</div>
			<ChangeChartType />
		</div>
		<ChartRender
			// chartType={chartType || "vertical"}
		/>
		{chartError && <>
			<ErrorEditable code={chartError} />
		</>}
		{hierarchyShown && <DialogHierarchy onClose={() => setShowHierarchy(false)} />}
	</React.Fragment>
}