import { stringReplaceAll } from '../../strings/stringReplaceAll';
import { randomHex } from '../hex';

interface IRandomGuidProps {
  uppercase?: boolean;
  noDashes?: boolean;
}
/**
 * Just gives new GUID generated by JavaScript
 * @param uppercase uppercase GUID, by default is lowercase
 * @param noDahses disable "-" to be attached
 * @default { uppercase = false, noDahses = false }
 * @returns new GUID value
 */

export const randomGuid = (
  { uppercase, noDashes }: IRandomGuidProps =
  { uppercase : false, noDashes : false },
): string => {
  const result = randomHex(8) + '-' + randomHex(4) + '-' + randomHex(4) + '-' + randomHex(4) + '-' + randomHex(12);
  if (uppercase && noDashes) { return stringReplaceAll(result.toUpperCase(), '-', ''); }
  if (uppercase) { return result.toUpperCase(); }
  if (noDashes) { return stringReplaceAll(result, '-', ''); }
  return result;
};
