import { useInitialPassengers, useReservationSearchFormValues, usePassengersRemote } from 'api';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';
import { dispatchChange } from 'forms';
import { useEffect, useState } from "react";

import { useRouteQueryKeys } from "hooks";

/*CAL, 2020-12-11 : Versão original
let isChangeBlocked = false;

export const useFormValidator = () => {
  const [trips] = useTripsConfirmed();
  const [initialPass] = useInitialPassengers();
	const [{ revalidations }] = useReservationSearchFormValues();
  
  const [query] = useRouteQueryKeys();

  useEffect(()=>{
    if(!isChangeBlocked && revalidations && trips && trips.passengers) {
      initialPass.forEach((initPass, index) => {
        const serverPass = trips && trips.passengers && trips.passengers[index];
        const reschedulePass = serverPass && serverPass.reschedule_of && serverPass.reschedule_of[0];

        const fare_type_id = (reschedulePass && reschedulePass.fare_type_id) || (serverPass && serverPass.fare_type_id) || null;

        if(initPass && fare_type_id && initPass.fare_type_id!==fare_type_id) {
          isChangeBlocked = true;
          setTimeout(() => {
            isChangeBlocked = false;
          }, 1000);
          dispatchChange("passengersForm", "passengers["+index+"].fare_type_id", fare_type_id);
        }
      })
    }


  }, [trips, initialPass, revalidations]);
  
}
*/
//CAL, 2020-12-11 => só considera o tipo de bilhete da revalidação retornado quando clica na Venda porque o operador pode alterar
export const useFormValidator = () => {
  const [trips] = useTripsConfirmed();
  const [initialPass] = useInitialPassengers();
	const [{ revalidations }] = useReservationSearchFormValues();
  const [saleClick, setSaleClick] = useState<string>("");
  const [query] = useRouteQueryKeys();
  const newSaleClick = (saleClick !== query.saleClick);  
  //console.log("useFormValidator: " + newSaleClick);
  useEffect(()=>{
    if(newSaleClick && revalidations && trips && trips.passengers) {
      initialPass.forEach((initPass, index) => {
        const serverPass = trips && trips.passengers && trips.passengers[index];
        const reschedulePass = serverPass && serverPass.reschedule_of && serverPass.reschedule_of[0];

        const fare_type_id = (reschedulePass && reschedulePass.fare_type_id) || (serverPass && serverPass.fare_type_id) || null;

        if(initPass && fare_type_id && initPass.fare_type_id!==fare_type_id) {
          setSaleClick(query.saleClick);
          dispatchChange("passengersForm", "passengers["+index+"].fare_type_id", fare_type_id);
        }
      })
    }


  }, [trips, initialPass, revalidations]);
  
}
