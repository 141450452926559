import { remoteFileGetBuffer } from "./remoteFileGetBuffer";
import { bufferDownload } from "modules";

export const remoteFileDownload = async (src: string, fileName: string) => {
	const { filename: fileNameResponse, mimetype, buffer } = await remoteFileGetBuffer(src);
	await bufferDownload(
		buffer,
		mimetype,
		fileName || fileNameResponse
	);
}