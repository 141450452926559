import React from "react";
import { PrimitiveFieldBase } from "./types";
import clsx from "clsx";
import { FieldPropsBase } from "./types";
export const CheckPrimitive = ({ ...htmlAttributes } : PrimitiveFieldBase & FieldPropsBase) => {
  const { active, form, value, className, style, onDrop, onDragStart, onChange, onBlur, onFocus, onClick, ...restAttributes } = htmlAttributes;
  return <div style={style} onDrop={onDrop} onDragStart={onDragStart} className={`${clsx(className)} check-holder`} onClick={onFocus as any}>
    <div className="check-holder-inner">
      <input
        {...restAttributes}
        type="checkbox"
        onClick={onFocus as any}
        onBlur={onBlur}
        checked={value==="true" || (typeof value==="number" && value>0) || (typeof value==="boolean" && value)}
        onChange={onChange}
      />
    </div>
  </div>
}