import { useRoutingLang } from "hooks";
import { usePassengersRequest, usePassengersRequestForConfirm } from "api/passengers";
import { useReservationId, useReservationSelection } from "api/reservation";
import { useInvoiceClient } from "api/invoice";
import { useClientInfoCustomer } from "api/user/clientInfo/useClientInfoCustomer";
import { useUserDetails } from "api/user/useUserDetails";
export const useReservationConfirmRequest = () : [any] => {
	const [reservationNumber] = useReservationId();
	const [psgList] = usePassengersRequestForConfirm();
	const [language] = useRoutingLang();

	const [customer] = useClientInfoCustomer();
	const [{ agentCompany, userAccountData }] = useUserDetails();
	const [passengersRequest] = usePassengersRequest();
	const [invoiceValues] = useInvoiceClient();
	const [{printZebra}] = useReservationSelection();
	
	const { clientObs, clientName,	clientDoc, invoiceNif, invoiceName, invoiceAddress, invoiceCity, invoicePostalCode1, invoicePostalCode2 } = invoiceValues;

	if(!passengersRequest) {
		return [null];
	}
	const nCard = (customer && customer.RFLEXCard && customer.RFLEXCard.CardNumber);
	const request : any = {//APIDTO.Payment.SalePsgInfo
		reservationNumber,
		psgList,
		sarve: agentCompany && agentCompany.ERPID,
		clientInfo: {
			clientName: customer ? customer.Name : clientName,
			clientPerfil: customer ? customer.ProfileId : "",
			nCard: nCard ? ""+nCard : "",
			nClient: (customer && customer.CustomerNumber) || "",
			nDocId: clientDoc,
			paymentType: 3,
			clientObs: clientObs,
			phone: (customer && customer.CellphoneNumber) || "",
			email: (customer && customer.Email) || ""
		},
		clientTaxInfo: {
			clientTaxCountry: "PT",
			clientTaxCountrySpecified: true,
			clientTaxID: invoiceNif,
			clientTaxName: invoiceName,
			taxAddress: {
				address1: invoiceAddress,
				locality: invoiceCity,
				postalCode1: invoicePostalCode1,
				postalCode2: invoicePostalCode2,
				country: "PT"
			}
		},
		agentInfo: {
			AgentID: null,
			AgentLocalID: null,
			AgentName: null,
			OperatorPICID: userAccountData && userAccountData.UserID,
			FixedReservation: false
		},
		language: language ? language.toUpperCase() : "PT",
		fileFormat: printZebra ? "PDF72200" : "PDF",
		sessionID: "Hello"
	}
	return [request]
}