import React, { useState/*, useEffect*/ } from "react";
import { SalesPanel } from "./SalesPanel";
import { SearchDialog } from "./SearchDialog";
import { DEFAULT_TITLE } from "consts";
import { usePage } from "hooks";
import "./index.scss";
import { useVoucherNavigation } from "api";
import { VoucherInfoDialog } from "../Voucher/VoucherInfoDialog";

const formName = "salesSearchDialogForm";

const DashboardSearchDialogHolder = ({ isSearch, onClose } : { isSearch : boolean, onClose: () => any }) => {
	if(!isSearch) return null;
 	return <SearchDialog
		form={formName}
		onClose={onClose}
	/>
}

export const DashboardInfoDialogHolder = () => {
	const [voucherId, setVoucherId] = useVoucherNavigation();
	if(!voucherId) return null;
	return <VoucherInfoDialog
		onClose={() => setVoucherId(null)}
	/>
}

export const Dashboard = () => {
	usePage({
		name: "DASHBOARD",
		options: {
			title: DEFAULT_TITLE
		}
	});
	const [isSearch, setIsSearch] = useState<any>(true);
	return <React.Fragment>
		<SalesPanel onSearch={() => setIsSearch(true)} />
		<DashboardSearchDialogHolder
			isSearch={isSearch}
			onClose={() => setIsSearch(false)}
		/>
		<DashboardInfoDialogHolder />
	</React.Fragment>
}

export default Dashboard;
