import React from "react";
import { StackPanel, Loader } from "controls";
import { EditUserButton } from "../controls";
import { LOADING_STR } from "consts";
import { FieldTextEditable, FieldCheckEditable, FieldHiddenSimple, FieldSelectEditable, FormConfirm, FormErrorsContainer } from "forms";
import { reduxForm, SubmissionError } from "redux-form";
import { useReduxFormSelector } from "forms";
import { useConfigurationAgent, useConfigurationGroup, useConfigurationSalesPeriods, useConfigurationManagers } from "api/configuration";

const connector = reduxForm({
	form: "groupForm",
	enableReinitialize: true,
	onSubmit: async(values, a, { save } : any) => {
		try {
			await save(values);
		}
		catch(error) {
			throw new SubmissionError({_error: error.error});
		}
		
	}
})
export const FormGroup = connector(({ form })=>{
	const [,, { edit: editAgent }] = useConfigurationAgent();
	const [group, { loading }] = useConfigurationGroup();
	const [{ values: { AgCpAccontManager } }] = useReduxFormSelector("groupForm");
	const [salesPeriods] = useConfigurationSalesPeriods();
	const [managers] = useConfigurationManagers();
	const groupManagersDisable = false;
	const groupManagersVisible = true;
	return <form className={`${form} block-form`}>
		{loading && <Loader text={LOADING_STR} overlay/>}
		<StackPanel>
			<FieldHiddenSimple
				name="ID"
			/>
			<FieldHiddenSimple
				name="parentId"
			/>
			<FieldTextEditable
				name="Name"
				required
			/>
			{groupManagersVisible && <FieldSelectEditable
				disabled={groupManagersDisable}
				afterLabel={<EditUserButton onClick={() => editAgent(AgCpAccontManager)} />}
				name="AgCpAccountManager"
				items={managers && managers.map((item: any)=>({...item, value: ""+item.ID, text: item.Name}))}
			/>}
			<FieldSelectEditable
				name="AgAccLiqPeriodType"
				items={salesPeriods && salesPeriods.map((item: any)=>({...item, value: ""+item.ID, text: item.Name}))}
			/>
			<FieldCheckEditable
				name="IsAvailable"
				onChange={e=>{
					const prevValue = e.target.value === "true";
					const { ID } = group;
					if (prevValue===true && ID !== "") {
						setTimeout(()=>alert("Atenção! Todos os utilizadores associados a este posto de venda serão desactivados."));
					}
				}}
			/>
			<div className="flex-end">
				<FormErrorsContainer form={form}/>
				<FormConfirm form={form}>
					Gravar
				</FormConfirm>
			</div>
		</StackPanel>
	</form>
});