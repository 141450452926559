import { API } from 'types';
import { APIDTO } from '@card4b/apidto-ts';
import { useMemo } from 'react';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';
import { useTripsTab } from 'api/ticketing/useTripsTab';
import { useTripsSelectedRoutes } from 'api/ticketing/useTripsSelectedRoutes';


export interface SelectedTripSchedule extends APIDTO.API.DateSchedule {
  price_type_id: string;
}

type UseTripSelectedResult = [
  SelectedTripSchedule,
  (schedule : (SelectedTripSchedule)) => Promise<void>
]


let newClick = 0;

export const GetNewClick =() : any =>
{
  return newClick;
}
export const SetNewClick =() : any =>
{
   newClick+=1;
}

export const RestartNewClick =() : any =>
{
   newClick=0;
}


export const useTripsSelected = (direction?: API.LISTS.RouteDirection) : UseTripSelectedResult => {
  const [tab] = useTripsTab();
  const [routesSelection, setTripSelected] = useTripsSelectedRoutes(direction || tab);
  const { schedule_id, price_type_id } = routesSelection || {};
  const [trips] = useTripsConfirmed();
  const newClick = GetNewClick();

  const selected : SelectedTripSchedule = useMemo(() => {

    if(!trips) return null;
    const { outgoing_schedules, return_schedules } = trips;
    if(tab==="ida") {
      if(!outgoing_schedules) return null;
      SetNewClick();
      let foundIdx = 0;
      if( schedule_id == undefined || newClick<4)
      {
        let currDate = new Date();
        if(outgoing_schedules[0].date=== currDate.toISOString().split('T')[0])
        {
          let min =  currDate.getMinutes()<10 ? "0"+currDate.getMinutes() :currDate.getMinutes()
          let hour =  currDate.getHours()<10 ? "0"+currDate.getHours() :currDate.getHours()
          let hoursMin = hour + ':' + min;
          for(let i=0; i<outgoing_schedules.length; i++) 
          {
            if(outgoing_schedules[i].departure_time>hoursMin)
            {
              foundIdx = i;
              setTripSelected({ schedule_id: outgoing_schedules[i].schedule_id, price_type_id: price_type_id });
              break;
            }
          }
        }
      }else
      {
        for(let i=0; i<outgoing_schedules.length; i++) {
          if(outgoing_schedules[i].schedule_id===schedule_id) {
            foundIdx = i;
            break;
          }
        }
      }

      return { ...outgoing_schedules[foundIdx], price_type_id };
    }
    if(tab==="volta") {
      if(!return_schedules) return null;
      let foundIdx = 0;
      for(let i=0; i<return_schedules.length; i++) {
        if(return_schedules[i].schedule_id===schedule_id) {
          foundIdx = i;
          break;
        }
      }
      return { ...return_schedules[foundIdx], price_type_id }
    }
  }, [price_type_id, schedule_id, tab, trips])

  return [
    selected,
    async (data : SelectedTripSchedule) => {
      await setTripSelected({ schedule_id: data.schedule_id, price_type_id: data.price_type_id });
    }
  ]
}