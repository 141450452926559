import React, { useState } from "react";
import { TableOld } from "controls";
import {  LinkPrint, LinkDownload } from "controls";
import { useFetchTicketsList } from "api/ticketing/useFetchTicketReprint";
import { useReduxFormSelector } from "forms";
import { useUserDetails } from "api/user/useUserDetails";

// export const TicketsTable = (docId: any) => {
export const TicketsTable = () => {
   
    const [{ values, syncErrors }] = useReduxFormSelector("reprintTable");
    const { docId }: any = values || {};
    const [data] = useFetchTicketsList({ docId });

    const [state, setStateInn] = useState<any>({});
    const setState = (s: any) => setStateInn({ ...state, ...s })
    const [details] = useUserDetails();
    const { userID } = details || {};

    try {
        if(data==null)
            return(<div></div>)

        console.log(data[0].origin)

        return (
         
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{'\n'}<br></br> </div>
                <div>{'\n'} <br></br></div>
            {<TableOld selectable={true} isDefault={true}>
                <thead>
                    <tr>
                        <th>Origem</th>
                        <th>Destino</th>
                        <th> Data de Partida</th>
                        <th> Bilhete </th>
                        <th> Estado </th>
                        <th style={{minWidth: 20}} />
                        <th style={{minWidth: 20}} />
                    </tr>
                </thead>
                <tbody>
                    {data &&
                    data.map((ticket: any, index: any) => {
                        return (
                            <React.Fragment key={index}>
                                <tr
                                    key={index}
                                    className={`${ index % 2 !== 0 ? "colored-old " : null }` } 
                                    onClick={() => {
                                    }}
                                >
                                    <td>{ticket.origin}</td>
                                    <td>{ticket.destination}</td>
                                    <td>{ticket.outgoingDate}</td>
                                    <td>{ticket.id}</td>
                                    <td>{ticket.state}</td>
                                   {/*  {
                                        ticket.toPrint? <> */}
                                         <td>
                                            <LinkDownload
                                            className="btn-primary text-center"
                                            href={"/api/ticketing/ticketRePrint?ticketId="+ticket.id+"&userID="+userID}
                                            onStatus={(status: any) => {
                                                setState(status)
                                            }}
                                            disabled={!ticket.toPrint}
                                            style={{ whiteSpace: "nowrap", width: "auto" }}
                                            >
                                                Descarregar
                                            </LinkDownload>
                                        </td>
                                        <td>
                                        
                                            <LinkPrint
                                            className="btn-primary text-center"
                                            href={"/api/ticketing/ticketRePrint?ticketId="+ticket.id+"&userID="+userID}
                                            disabled={!ticket.toPrint}
                                            onStatus={(status: any) => {
                                                setState(status);
                                            }}
                                            style={{ whiteSpace: "nowrap", width: "auto" }}
                                                >
                                            Imprimir
                                        
                                        </LinkPrint>
                                        </td>
                                       
                                        {/* </>: <> <td></td><td></td></>
                                }*/}
                            </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </TableOld>}
            </div>
        );
    } catch (error) {
        console.log(error)
    }
    return(<div></div>)
}