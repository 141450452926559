import { useCurrentRoute } from "react-navi";
import { convertPathnameToLang } from "../../modules/convert/convertPathnameToLang"
/**
 * Parse routing and return language, defined in pathname //domain.com/lang?/pathname
 * @returns [lang]
 */
export const useRoutingLang = () : [string] => {
  const { url: { pathname } } = useCurrentRoute();
  const lang = convertPathnameToLang(pathname);
	return [lang];
}