/* eslint-disable no-throw-literal */
import { validatePostal } from "./validatePostal";
import { validateNif } from "./validateNif";
import { dispatchstopAsyncValidation } from "forms";

export const asyncValidate = async (values: any, dispatch: any, props: any) => {
	const { invoicePostalCode1, invoicePostalCode2, invoiceNif } = values;
	try {
		const invoiceCity = await validatePostal({ invoicePostalCode1, invoicePostalCode2 });
		if(invoiceCity) {
			dispatch(props.change("invoiceCity", invoiceCity));
			dispatchstopAsyncValidation("invoiceForm", { clear: true });
		}
		else {
			dispatch(props.change("invoiceCity", ""));
			dispatchstopAsyncValidation("invoiceForm", { clear: true });
		}
	}
	catch(error) {
		console.error(error);
		throw {
			invoicePostalCode1: error
		};
	}

	try {
		await validateNif({ invoiceNif });
	}
	catch(error) {
		throw { invoiceNif: error };
	}
}