import React from "react";
import "./index.scss";
import { formName } from "./formName";
import { ButtonTyped } from "controls";
import { FieldSelectEditable, dispatchAppendObject } from "forms";
import { useReduxFormSelector } from "forms";
import { useLiquidationState, useLiquidationUsers, useLiquidationAccountStates } from "api/liquidation";
import { reduxForm, SubmissionError } from "redux-form";

const { months, years } = (() => {
	const months = [
		{ value: "", text: " - "}
	], years = [
		{ value: "", text: " - "}
	];
	const now = new Date();
	const nowMonth = now.getUTCMonth();
	const nowYear = now.getUTCFullYear();
	
	for(let i=1; i<=12; i++) {
		const item: any = {
			value: ""+(i),
			text: "MONTH_"+i
		}
		if(i===(nowMonth+1)) {
			item.className = "listItemCurrentOption";
		}
		months.push(item);
	}
	for(let i=-2; i<2; i++) {
		const year = (nowYear+i);
		const item: any = {
			value: ""+year,
			text: ""+year
		}
		if(year===nowYear) {
			item.className = "listItemCurrentOption";
		}
		years.push(item);
	}
	return { months, years }
})()

const connectForm = reduxForm({
	form: formName,
	enableReinitialize: true,
	onSubmit: async (values, a, { onConfirm }: any) => {
		try {
			onConfirm(values);
		}
		catch (error) {
			console.error(error);
			throw new SubmissionError({ _error: error.error });
		}
	}
})

export const Form = connectForm(({ submit, form }: any) => {
	const [{companyUser, year, month, accState}] = useLiquidationState();
	const [{ values: formStateValues }]: any = useReduxFormSelector(form);
	const accStateForm = formStateValues.accState;
	

	const [companyUsers] = useLiquidationUsers();

	const [accStates] = useLiquidationAccountStates();
	
	const defaultState: any = {year: null, month: null, accState: "1", companyUser: null};
	const rightValues = formStateValues || {};

	const isFormDifferentFromCurrentState = (
		rightValues.year !== year ||
		rightValues.month !== month ||
		rightValues.companyUser !== companyUser ||
		rightValues.accState !== accState
	);
	const isFormDifferentFromDefaultState = (
		rightValues.year !== defaultState.year ||
		rightValues.month !== defaultState.month ||
		rightValues.companyUser !== defaultState.companyUser ||
		rightValues.accState !== defaultState.accState
	)

	return <form className={form}>
		{years && <FieldSelectEditable
			name="year"
			required={accStateForm==="2"}
			items={years}
		/>}
		{months && <FieldSelectEditable
			name="month"
			items={months}
		/>}
		{companyUsers && <FieldSelectEditable
			name="companyUser"
			required={accStateForm==="2"}
			items={companyUsers}
			filter={true}
		/>}
		{accStates && <FieldSelectEditable
			name="accState"
			items={accStates && [
				{ value: "0", text: "Sem Vendas" },
				...(accStates.map((a: any) => ({ value: ""+a.ID, text: a.Name})))
			]}
		/>}
		<ButtonTyped
			styling="primary"
			className="margin-left-8"
			disabled={!isFormDifferentFromCurrentState}
			onClick={() => {
				setTimeout(submit);
			}}>Listar</ButtonTyped>
		<ButtonTyped
			styling="primary"
			className="margin-left-8"
			disabled={!isFormDifferentFromDefaultState}
			onClick={() => {
				dispatchAppendObject("liquidationSearchForm", defaultState);
				setTimeout(submit);
			}}
		>Limpar</ButtonTyped>
	</form>
})

export * from "./formName";