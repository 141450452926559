import { useRouteQueryKeys } from "hooks";

export const useConfigurationAccordion = () : [any, any, any]=> {
	const [query, { addQuery }] = useRouteQueryKeys();
	const { id, parentId, level } = query;
	return [
		{ id, parentId, level },
		null,
		{ save: (obj: any) => addQuery(obj) }
	]
}