import React from "react";
import "./index.scss";
import clsx from "clsx";

interface FixedCornerProps {
	className?: string;
	children: any;
	top?: boolean;
	right?: boolean;
	bottom?: boolean;
	left?: boolean;
	paddingV?: number;
	paddingH?: number;
	padding?: number;
}

/**
 * locate it's content into edge of the screen
 * @param paddingV - vertical container padding
 * @param paddingH - horizontal container padding
 * @param padding - vertical AND horizontal padding, if paddingV / paddingH not provided
 * @param top - top corner (can be mixed with left or right)
 * @param right - right corner (can be mixed with top or bottom)
 * @param bottom - bottom corner (can be mixed with left or right)
 * @param left - left corner (can be mixed with top or bottom)
 */
export const FixedCorner = ({	className, children, top, right, bottom, left, paddingV, paddingH, padding, ...rest } : FixedCornerProps) => {

	if(padding) {
		if(!paddingV) paddingV = padding;
		if(!paddingH) paddingH = padding;
	}

	const style : any = {};
	if(paddingH) {
		if(right) style.paddingRight = paddingH;
		if(left) style.paddingLeft = paddingH;
	}
	if(paddingV) {
		if(top) style.paddingTop = paddingV;
		if(bottom) style.paddingBottom = paddingV;
	}

	return <div
		style={style}
		className={`fixed-corner ${clsx(className)} ${clsx(top && "top")} ${clsx(right && "right")} ${clsx(bottom && "bottom")} ${clsx(left && "left")}`}
	>
		<div className="fixed-corner-inner">
			{children}
		</div>
	</div>
}