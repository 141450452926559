import { useReservationInfo } from "api/reservation/useReservationInfo";
import { useTripsPrices } from "api/schedules";
import { usePassengersClient } from "../usePassengersClient/usePassengersClient";
export const usePassengersPrices = () : [{ total: number; list: Array<number>; }] => {
	const [pricesInfoSchedules, { loading: pricesInfoSchedulesLoading }] = useTripsPrices();
	const [passengers] = usePassengersClient();
	const [info] = useReservationInfo();
	const { passengers: passengersInfo } = info || {};
	let list = passengers && passengers.map((pass, index) => {
		const { fare_type_id } = pass;
		let price : any = null;
		if(typeof price!=="number" && passengersInfo) {
			passengersInfo.forEach((pass: any, passIndex: number) => {
				if(passIndex === index) {
					const { outgoing_ticket, return_ticket } = pass;
					if(
						outgoing_ticket &&
						return_ticket &&
						typeof outgoing_ticket.price==="number" &&
						typeof return_ticket.price==="number"
					) price = outgoing_ticket.price+return_ticket.price;
					else if(outgoing_ticket) price = outgoing_ticket.price;
					else if(return_ticket) price = return_ticket.price;
				}
			})
		}
		if(typeof price!=="number" && pricesInfoSchedules && !pricesInfoSchedulesLoading) {
			const first = pricesInfoSchedules.filter((x: any) => x.fare_type_id === fare_type_id)[0];
			const { price: priceItem } = first || {};
			if(priceItem) {
				price = priceItem;
			}
		}
		return parseFloat(price || 0);
	})

	let total = 0;
	list && list.forEach(p => total+=p);
	return [{ total, list }]
}