import React from "react";
import { TabsButtonsStack } from "../TabsButtonsStack";
import { useRouteQueryKey } from "hooks";
import { TabsButtonsStackRoutedProps } from "../types/TabsButtonsStackRoutedProps";


export const TabsButtonsStackRouted = ({ active, buttons, disabled, query, ...rest } : TabsButtonsStackRoutedProps) => {
  const [tab, setTab] = useRouteQueryKey(query);
  const activeTabIndex = (() => {
    let result = -1;
    if(result<0) {
      buttons.forEach((button, buttonIndex) => {
        if(button.name===tab || ""+buttonIndex===""+tab) { result = buttonIndex; }
      });
      buttons.forEach((button, buttonIndex) => {
        if(button.active) { result = buttonIndex; }
      });
      if(result>=0) return result;
    }
    if(result<0) {
      if(typeof active==="string") {
        buttons.forEach((button, buttonIndex) => {
          if(button.name===active) { result = buttonIndex; }
        });
      }
      if(typeof active==="number") {
        result = active;
      }
      if(result>=0) return result;
    }
    return 0;
  })();

  return <TabsButtonsStack
    {...rest}
    active={activeTabIndex}
    disabled={disabled}
    buttons={buttons.map((button, buttonIndex)=>{
      return {
        ...button,
        active: buttonIndex===activeTabIndex,
        onClick: () => setTab(button.name || buttonIndex)
      };
    })}
  />
}