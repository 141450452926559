import { ScheduleTableItemed } from "types/ticketing/ScheduleTableItemed";
import { TransformedScheduleResult } from "types/ticketing/TransformedScheduleResult";
import { TransformedScheduleStop } from "types/ticketing/TransformedScheduleStop";

const normalizeStops = (headers : any, schedules_stop : any) : TransformedScheduleStop[][] => {
    const schedules_stop_new : TransformedScheduleStop[][] = [];
    schedules_stop.forEach((schedule: any) => {
        const schedules_row : TransformedScheduleStop[] = [];
        headers.forEach((header: any) => {
            const stopExists = schedule.filter((x: any) => x.id === header.id).map((x: any) => x);
            if (stopExists.length === 0) {
                schedules_row.push({});
            } else {
                schedules_row.push(stopExists[0]);
            }
        });
        schedules_stop_new.push(schedules_row);
    });
    return schedules_stop_new;
}

const getStopProps = (stop: any) => {
    const id = stop.stop.id;
    const name = stop.stop.name;
    const time = stop.departure === null || stop.departure.time === null ? stop.arrival.time : stop.departure.time;
    const dayOffset = stop.departure !== null && stop.departure.days_offset !== null ? stop.departure.days_offset : stop.arrival !== null && stop.arrival.days_offset !== 0 ? stop.arrival.days_offset : 0;
    return { id, name, time, dayOffset };
}

const orderStops = (schedule_stops : any, item_names : any) => {
    let orderedStops : any;
    schedule_stops.forEach((schedule : any, schIdx : any) => {    
        if (schIdx === 0) orderedStops = schedule.map((stop : any) => item_names.filter((x : any) => x.id === stop.id)[0]);
        schedule.forEach((stop : any, stpIdx : any) => {
            stop = item_names.filter((x: any) => x.id === stop.id)[0]
            let itemIdx = orderedStops.findIndex((x : any) => x.id === stop.id)
            if (itemIdx === -1) {
                let previousStops = schedule.slice(0, stpIdx);
                let nextStops = schedule.slice(stpIdx + 1, schedule.length);
                let previousCommon = previousStops.map((x : any) => orderedStops.filter((stop : any) => x.id === stop.id)).filter((x: any) => x.length > 0).reverse().shift()[0];
                let nextCommon = nextStops.map((x : any) => orderedStops.filter((stop : any) => x.id === stop.id)).filter((x: any) => x.length > 0).shift()[0];
                let previousCommonIdx = orderedStops.findIndex((x : any) => x.id === previousCommon.id);
                let nextCommonIdx = orderedStops.findIndex((x : any) => x.id === nextCommon.id);
                let leftHeaders = orderedStops.slice(0, previousCommonIdx + 1);
                let redZone = orderedStops.slice(previousCommonIdx + 1, nextCommonIdx);
                let rightHeaders = orderedStops.slice(nextCommonIdx, orderedStops.length);
                if (nextCommonIdx - previousCommonIdx > 1) {
                    let leftRedZone;
                    let rightRedZone;
                    for (let i = 0; i < redZone.length; i++) {
                        if (stop.time < redZone[i].time) {
                            leftRedZone = redZone.slice(0, i);
                            rightRedZone = redZone.slice(i, redZone.length);
                            break;
                        }
                    }
                    leftRedZone == null || rightRedZone == null ? redZone = redZone.concat(stop) : redZone = leftRedZone.concat(stop).concat(rightRedZone);
                } else {
                    redZone = redZone.concat(stop);
                }
                orderedStops = leftHeaders.concat(redZone).concat(rightHeaders);
            }
        })
    });
    return orderedStops;
}

function pad2(d : number) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

export const createScheduleTable = ({ schedules, extended } : { schedules: ScheduleTableItemed, extended : boolean }) => {
	const { items } = schedules || {};
    let item_names : TransformedScheduleStop[] = [];
    items && items.forEach(item => {
        let startTime : any;
        let start_date = item.schedule.date;
        item.stops.outgoing_itinerary.legs.forEach((leg: any, legIdx: any): any => {
            leg.stops.forEach((stop: any, stpIdx: any) => {
                let { id, name, time, dayOffset } = getStopProps(stop);
                let [year, month, day] = start_date.split("-");
                let stop_date = year + "-" + month + "-" + pad2((parseInt(day) + parseInt(dayOffset)));
                if(legIdx === 0 && stpIdx === 0 ) {
                    startTime = new Date(stop_date + "T" + time)
                }
                let currentTime = time.length > 5 ? new Date(stop_date + "T" + time.split(" | ")[0]) : new Date(stop_date + "T" + time);
                let elapsedTime = (+currentTime - startTime) / 1000 / 3600;
                const obj = {
                    id: id,
                    text: name,
                    time: elapsedTime
                }
                let itemIdx = item_names.findIndex(x => x.id === obj.id);
                if (itemIdx === -1) {
                    item_names.push(obj)
                } else {
                    if (obj.time > item_names[itemIdx].time)
                        item_names[itemIdx] = {
                            id: obj.id,
                            text: obj.text,
                            time: obj.time
                        }
                }
            });
        });
    });
    let schedules_stop : any[] = [];
    items && items.forEach(schedule => {
        let schedule_stops : any[] = [];
        schedule.stops.outgoing_itinerary.legs.forEach((leg : any): any => {
            leg.stops.forEach((x: any) : any => {
                let time = x.departure === null || x.departure.time === null ? x.arrival.time : x.departure.time;
                const obj = {
                    id: x.stop.id,
                    text: time
                }
                let itemIdx = schedule_stops.findIndex(x => x.id === obj.id);
                if (itemIdx === -1) {
                    schedule_stops.push(obj);    
                } else {
                    schedule_stops[itemIdx].text += " | " + x.departure.time;
                }
            });
        });
        schedules_stop.push(schedule_stops);
    });
    let orderedStops = orderStops(schedules_stop, item_names);
    item_names = orderedStops && orderedStops.map((x : any) => x = {
        id: x.id,
        text: item_names.filter(stop => stop.id === x.id)[0].text
    });
    let normalizedStops : TransformedScheduleStop[][] = normalizeStops(item_names, schedules_stop);
    if (!extended && item_names) {
        let partialStops = schedules_stop.map((x : any) => x.filter((stop : any) => stop.text.indexOf("|") > -1));
        partialStops = item_names.map((name : any) => partialStops.map((stop: any) => stop.filter((x: any) => x.id === name.id)[0]).filter(x => x != null)[0]).filter(x => x != null);
        partialStops = [item_names[0]].concat(partialStops).concat(item_names[item_names.length - 1]);
        partialStops = partialStops.map((x : any) => item_names.filter((stop: any) => stop.id === x.id)[0]);
        item_names = partialStops;
        normalizedStops = normalizeStops(item_names, schedules_stop);
    }
    const resultConverted : TransformedScheduleResult = {
        headers: item_names,
        schedules_stops: normalizedStops
    }
    return resultConverted;
}