import { useRefresherNamed, useRefresherNamedTimeout } from "./useRefresherNamed";
const cache : {[key: string] : any} = {};

/**
 * Returns a named stateful value, and a function to update it.
 * 
 * @param uniqueName name for this state container
 * @param initialState any initial value
 * 
 * @version 1.0.0
 */
export const useStateNamedDirect = <TState extends any>(uniqueName : string, initialState?: TState) : [TState, (data : TState) => Promise<void>] => {
  const [refresh] = useRefresherNamed(`useStateNamed.${uniqueName}`);
  if(typeof cache[uniqueName]==="undefined" && typeof initialState!=="undefined") {
    cache[uniqueName] = initialState;
  }
  return [
    (typeof cache[uniqueName] !=="undefined" ? cache[uniqueName] : initialState),
    async (value) => {
      cache[uniqueName] = value;
      await refresh();
    }
  ]
}

export const useStateNamedTimeout = <TState extends any>(uniqueName : string, initialState?: TState) : [TState, (data : TState) => Promise<void>] => {
  const [refresh] = useRefresherNamedTimeout(`useStateNamedTimeout.${uniqueName}`);
  if(typeof cache[uniqueName]==="undefined" && typeof initialState!=="undefined") {
    cache[uniqueName] = initialState;
  }
  return [
    (typeof cache[uniqueName] !=="undefined" ? cache[uniqueName] : initialState),
    async (value) => {
      cache[uniqueName] = value;
      await refresh();
    }
  ]
}

export const useStateNamed = useStateNamedDirect;