import React, { useState, useRef, ChangeEventHandler } from "react";
import { replaceMask } from "../../helpers";
import { ItemsListInput } from "../ItemsListInput";
import { SearchInput } from "../SearchInput";
import { ItemValueType } from "../../primitives/types";
import "./index.scss";
import { useOnClickOutside } from "hooks";
import { SelectInputProps } from "./SelectInputProps";
import { defaultFilterCallback } from './defaultFilterCallback';
import { useTranslationDeepValue } from "app/translations";
import { CaretArrowIcon } from "controls/ICONS/CaretArrowIcon";
import { Drop } from "controls/LAYOUT_CONTAINERS/Drop";
let locked = false;

export { SelectInputProps }

export const SelectInput = ({
	loading,
	filter=false,
	scope,
	active,
	value,
	maskItem = "{text}",
	maskOption = "{text}",
	disabled,
	placeholder,
	onChange,
	items = [],
  ...html
} : SelectInputProps) => {
	const containerRef = useRef();
	const hiddenRef : any = useRef<HTMLInputElement>();
	const [_valuesTranslations] = useTranslationDeepValue(scope, {});
	const valuesTranslations = scope ? _valuesTranslations : "";
  const { className, onFocus, onBlur, ...restHtmlAttributes }: any = html || {};
	const [__isDrop, setDropInner] = useState<boolean>(false);
	const dropOpened = active===true ? true : active===false ? false : __isDrop;
	const [filterValue, setFilterValue] = useState<any>("");
	const filterResult = filter && (!filterValue || filterValue==="") ? null : (items || []).filter(item => {
		if(!item) return false;
		if(!item.value) return false;
		if(typeof filter === "boolean") return defaultFilterCallback({item, filter: filterValue});
		if(typeof filter === "function") return filter({item, filter: filterValue});
		return false;
	});
	const itemsFiltered = filterResult && filterResult.length>0 ? filterResult : items;

	const setDrop = (value : boolean) => {
		if(value===dropOpened) return;
		if(locked) return;
		locked = true;
		setTimeout(() => { locked = false }, 50);
		setFilterValue("");
		setDropInner(value);
	}
	const selectedItems = items && items.filter(item=>{
		if(""+item.value===""+value) return true;
		if(!item.value && !value) return true;
		return false;
	}).map(item => {
		return { ...item, text: valuesTranslations[""+item.value] ? valuesTranslations[""+item.value] : item.text }
	});
	const selected = selectedItems && selectedItems[0];
	const valueText : ItemValueType = selected ?
		(maskItem ? replaceMask({ mask: maskItem, value: selected.value, text: selected.text }) : selected.text) :
		(null);

	const onInput : ChangeEventHandler<HTMLInputElement> = (e) => {
		onChange && onChange(e);
		onBlur(e as any);
	}
	useOnClickOutside(containerRef, (event : any) => {
		if(active) {
			hiddenRef.current.value = ""+value;
			hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
			// onBlur(event);
		}
	})
  return <div
		{...restHtmlAttributes}
		ref={containerRef}
		onClick={(e)=>{ setDrop(true); }}
		className={`${className} select`}
	>
		<>
			<input type={"hidden"} defaultValue={value} ref={hiddenRef} onInput={onInput}/>
			<SearchInput
				html={{
					autoFocus: dropOpened,
					placeholder,
					readOnly: !filter,
					disabled,
					value: dropOpened && filter ? filterValue : valueText,
					onClick: onFocus,
					onChange: (e) => {
						const valueFilter : string = (e.target as any).value;
						setFilterValue(valueFilter.toLowerCase());
					}
				}}
				scope={scope}
			/>
			<CaretArrowIcon direction={!dropOpened && !disabled ? "down" : "up"} onClick={onFocus}/>
		</>
		{dropOpened && !disabled && <Drop dropped={dropOpened} onLeave={(e : any)=>{
			setDrop(false);
		}}>
			<ItemsListInput
				scope={scope}
				items={itemsFiltered}
				value={value}
				onChange={e => {
					const value = e.target.value;
					const itemsFound = itemsFiltered && itemsFiltered.filter(c=>""+c.value===""+value);
					const itemFound = itemsFound && itemsFound[0];
					if(itemFound) {
						setFilterValue((""+itemFound.text).toLowerCase());
					}
					hiddenRef.current.value = ""+value;
					hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
				}}
			/>
		</Drop>}
	</div>
}