// import { onClickLabel } from './../hocs/helpers/onClickLabel';
// import { WrappedFieldProps } from 'redux-form';
// import { PrimitivePropsBase } from './../primitives/types/index';
// import { FunctionComponent } from 'react';
// import { HocFieldsMergedDefaults } from '../hocs/helpers/types/HocFieldsMergedDefaults';
import { HocFieldProps, HocFieldsMergedDefaults } from "../fields/types";
import { createGate } from 'effector-react';

export interface RootGateProps {
  defaults?: HocFieldsMergedDefaults;
  extras?: {
    // onClickLabel: (e : React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClickLabel: (form : string, field: string) => void;
  }
  props?: HocFieldProps;
  // wrapped?: WrappedFieldProps;
}

export const createRootGate = () => {
  const gate = createGate<RootGateProps>();
  return gate;
}