import { API } from "types";
import { useFetchOnce } from "hooks";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export const useFetchDestinations = ({ national, international, lock, origin } : API.HOOKS.UseFetchDestinationsProps) : FetchOnceResult<API.RESPONSES.DestinationsResponse> => {
  return useFetchOnce<any>("/api/locations/destinations", {
    method: "POST",
    lock: lock || !origin || typeof origin!=="string" || origin.length===0,
    body: {
      originId: origin,
      national,
      international
    }
  })
}