import React from "react";
import { ButtonStatusEditable } from "controls";
import { useReservationRegister } from "api";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";

export const ReservationRegiserButton = () => {
  const [{ visible, enabled }, status, { register }] = useReservationRegister();
  const [statusConverted] = useFetchStatusTimeout("ReservationRegiserButton", status, CONFIRM_ERROR_TIMEOUT_MS);
	const { loading } = statusConverted || {};
  if(!visible) return null;
  return <ButtonStatusEditable
    scope="RESERVATION"
    as="REGISTER_BUTTON"
    styling="primary"
    className="bigger"
    disabled={!enabled || loading}
    onClick={register}
    status={statusConverted}
  />
}
