import React from "react";
// import * as _clientErrors from "consts/errors";
import { ErrorCodesClient, ErrorCodesServer, ErrorCodesServiceApi, ErrorCodesServiceApiDetailed, ErrorCodesRNE } from "admin/layouts/errors";
import { useRouteQuery } from "hooks";
import "./index.scss";
const pagesList : {
  tab: string;
  text: string;
  layout: any;
}[] = [
  {
    tab: "client",
    text: "Client errors",
    layout: ErrorCodesClient
  },
  {
    tab: "server",
    text: "Server errors",
    layout: ErrorCodesServer
  },
  {
    tab: "service_api",
    text: "Service API",
    layout: ErrorCodesServiceApi
  },
  {
    tab: "service_detailed",
    text: "Service detailed",
    layout: ErrorCodesServiceApiDetailed
  },
  {
    tab: "rne",
    text: "RNE error codes",
    layout: ErrorCodesRNE
  }
]

export const LayoutErrorCodes = () => {
  const [tab, setTab] = useRouteQuery("error_codes_page");

    return <div className="errors-content">
      <div className="flex-row flex-between tabs tabs-primary">
        {pagesList && pagesList.map((page) => {
          return <div key={page.tab} className={`tabs-item ${page.tab===tab ? "active" : ""}`} style={{width: "100%"}} onClick={() => setTab(page.tab)}>
            {page.text}
          </div>
        })}
      </div>
      {pagesList && pagesList.map((page) => {
        if(page.tab!==tab) return null;
        return <div>
          <div className="error-codes-title">
            <h3>{page.text}</h3>
          </div>
          {React.createElement(page.layout)}
        </div>;
      })}
    </div>
}
