import { FETCH } from "types";
import { useClientInfoCustomer } from 'api';
import { usePassengersClient } from "api/passengers";
import { useMemo } from "react";
import { InvoiceFormValues } from "types/reservation/InvoiceFormValues";

export const useInitialInvoice = () : [InvoiceFormValues, FETCH.FetchOnceStatus] => {
	const [customer, { loading }] = useClientInfoCustomer();
	const [passengers] = usePassengersClient();
	const passenger = passengers && passengers[0];

	const initialValues = useMemo(() => {
		if(customer) {
			const { Name, DocumentNr, TaxInfo } = customer || {};
			const { NIF } = TaxInfo || {};
			return {
				clientName: Name,
				clientDoc: DocumentNr,
				invoiceNif: NIF	
			}
		}
		if(passenger) {
			return {
				clientName: passenger.name,
				clientDoc: passenger.doc
			}
		}
		return {
			clientName: "",
			clientDoc: "",
			invoiceNif: "",
			clientObs: ""
		}
	}, [customer, passenger])
	
	return [
		initialValues,
		{ loading }
	];
}