import React from "react";
import { InitialContext } from "./InitialContext";
import { InitialProviderProps } from "./types/InitialProviderProps";
import { useFetchOnce } from "modules/fetch/hooks/useFetchOnce";
import { fetchJson } from "modules/fetch/modules/fetchJson";

export const InitialProvider = ({children, url } : InitialProviderProps) => {
  
  const [data,,{ setData }] = useFetchOnce<any>(url);
  const refresh = async () => {
    const data = await fetchJson(url);
    setData(data);
  }

  return <InitialContext.Provider value={[data, refresh]}>
    {children}
	</InitialContext.Provider>
}