import React from "react";
import "./index.scss";
import "./horizontal.scss";
import "./vertical.scss";
import { DropTarget, DragSource } from 'react-dnd';
import { RemoveButton } from "./RemoveButton";
import { EditButton } from "./EditButton";
import { AddButton } from "./AddButton";
import { InactiveManagerButton } from "./InactiveManagerButton";
//@ts-ignore
import ReactTooltip from 'react-tooltip';
import {
	ACCOUNT_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
	GROUP_DEPTH_KEY
} from "consts";
import { fetchJson } from "modules/fetch";
import { useConfigurationHierarchy, useConfigurationState } from "api/configuration";
import { stringTruncTail } from "modules/strings/stringTruncTail";
import { useInitial } from "api/app/useInitial";

const nodeTruncateLimit = 13;
const AGENT_POS_UPDATE_MSG = "Trocar um agente de Posto de Venda fará com que a sua nota de liquidação atual seja fechada. \nPretende continuar?";
const POS_GROUP_UPDATE_MSG = "Trocar um Posto de Venda para outro grupo fará com que todas as notas de liquidação atuais sejam fechadas. \nPretende continuar?";

const Content = (params: any) => {
	const {Name, Manager, IsAvailable, ID, IsEditable, UserType, isInactiveManager,...rest} = params;
	const btnProps = {
		Manager,
		Name,
		IsAvailable,
		IsEditable,
		ID,
		isInactiveManager,
		UserType,
		...rest
	}
	return <React.Fragment>
		<div className="info">
			{Name && <span className="title">{stringTruncTail(Name, nodeTruncateLimit-1)}</span>}
			{Manager && <span className="manager">{stringTruncTail(Manager, nodeTruncateLimit-1)}</span>}
		</div>
		<div className="buttons">
			<div className="inactive">
				<InactiveManagerButton {...btnProps} />
			</div>
			<div className="normal">
				<AddButton {...btnProps} />
				<EditButton {...btnProps} />
				{IsAvailable && <RemoveButton {...btnProps} />}
			</div>
		</div>
	</React.Fragment>
}

const BlockSrc = ({
	isDragging,
	isOver,
	connectDragSource,
	connectDropTarget,
	className,
	children,
	...rest
}: any) => {
	let childsLength = 0;

	const [initial] = useInitial();

	const childs = React.Children.map(children, (item, index) => {
		if(item) childsLength++;
		return <div key={index} className="child">
			<div className="before"></div>
			{item}
		</div>;
	});

	const cnOver = isOver ? "over" : "";
	const cnAv = !rest.IsAvailable ? "nodeUnavailable" : "";
	const cnMan = rest.userIsManager ? "isManager" : "";
	const cnDrag = isDragging ? "drag" : "";
	const cnChilds = childsLength>0 ? "with-childs" : "";

	const isFullyHidden = (!rest.IsAvailable && !!initial.configs.ConfigurationHideDisabled);

	const tooltip = rest.Manager ? `${rest.Name} / ${rest.Manager}` : rest.Name;
	const toolTipId = "tt"+rest.level+rest.ID;
	const cn = `chart-block ${className} ${cnOver} ${cnAv} ${cnMan} ${rest.level} ${cnDrag} ${cnChilds}`;

	if(isFullyHidden) return null;
	return <div className={cn}>
		<div className={`content`}>
			<div className="before-cen"></div>
			<div className="before"></div>
			<ReactTooltip id={toolTipId} place="top" type="dark" effect="solid">
				<span>{tooltip}</span>
			</ReactTooltip>
			{connectDropTarget(connectDragSource(<div
				data-tip={true}
				data-for={toolTipId}
				id={rest.ID}
				// level={rest.level}//TODO: to check
				data-level={rest.level}
				className="content-inner"
			>
				<Content {...rest} />
			</div>))}
			<div className="after"></div>
		</div>
		{childsLength>0 && <div className="childs">{childs}</div>}
	</div>
}

export const Block = (blockProps: any) => {
	const [,, { refresh }] = useConfigurationHierarchy();
	const [,,{ setHierarchyMask, setChartError }] = useConfigurationState();
	const handleShift = async({
		originId,
		originLevel,
		destinationId,
		destinationLevel
	}: any) => {
		const posRelocate = async ({ pointOfSale, group }: any) => {
			await fetchJson("/api/configuration/pos/relocate", { method: "POST", body: { pointOfSale, group } });
			refresh();
		}
		
		const agentRelocate = async ({ agent, pointOfSale }: any) => {
			await fetchJson("/api/configuration/agent/relocate", { method: "POST", body: { agent, pointOfSale } });
			refresh();
		}
		try {
			const isAgentPosUpdate = originLevel === ACCOUNT_DEPTH_KEY && destinationLevel === POINT_OF_SALE_DEPTH_KEY;
			const isPosGroupUpdate = originLevel === POINT_OF_SALE_DEPTH_KEY && destinationLevel === GROUP_DEPTH_KEY;

			isAgentPosUpdate && window.confirm(AGENT_POS_UPDATE_MSG) && await agentRelocate({ agent: +originId, pointOfSale: +destinationId });
			isPosGroupUpdate && window.confirm(POS_GROUP_UPDATE_MSG) && await posRelocate({ pointOfSale: +originId, group: +destinationId });

			if(!isAgentPosUpdate && !isPosGroupUpdate) {
				console.error({ error: "Wrong handle shift params"})
				throw new Error("Wrong handle shift params");
			}
		}
		catch(error) {
			// alert("Não é possível efectuar a reconfiguração");
			setChartError("Não é possível efectuar a reconfiguração", 2000);
			setHierarchyMask(null);
		}
	}

	const Comp = DropTarget(
		"box",
		{
			drop(props : any) {
				return { ID: props.ID, level: props.level };
			},
		},
		(connect, monitor) => ({
			connectDropTarget: connect.dropTarget(),
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	)(
		DragSource(
			"box",
			{
				beginDrag(props : any) {
					return { ID: props.ID, level: props.level };
				},
				endDrag(props, monitor) {
					const item = monitor.getItem()
					const dropResult = monitor.getDropResult();
					if(!item || !dropResult) return;
					handleShift({
						originId: item.ID,
						destinationId: dropResult.ID,
						originLevel: item.level,
						destinationLevel: dropResult.level
					})
				},
			},
			(connect, monitor) => ({
				connectDragSource: connect.dragSource(),
				isDragging: monitor.isDragging()
			})
		)(BlockSrc)
	);

	return <Comp {...blockProps}/>
}