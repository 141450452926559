import { API } from 'types';
import { useRouteQueryKey } from "hooks";

export const useSchedulesTab = () : [API.LISTS.RouteDirection, (dir : API.LISTS.RouteDirection) => Promise<void>] => {
  const [tab, setTab] = useRouteQueryKey("schedules_tab");
  return [
    tab==="volta" ? "volta" : "ida",
    async(dir : API.LISTS.RouteDirection) => {
      await setTab(dir);
    }
  ]
}