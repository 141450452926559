import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';
import { useMemo } from "react";
import { FETCH } from 'types';
import { useReservationSearchFormValues } from "api/search";
import { useReservationOffer } from "api/reservation";
import { usePassengersRemote } from "api/passengers";
import { Passenger } from 'types/passengers/Passenger';
import { useUserDetails } from 'api/user/useUserDetails';
import { useClientInfo } from 'api/user/clientInfo/useClientInfo';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';
const { PASSENGER_YOUTH, PASSENGER_ARMY, PASSENGER_SENIOR, PASSENGER_NORMAL } = PassengerTypesEnum;

export const useInitialPassengers = () : [Passenger[], FETCH.FetchOnceStatus] => {
	const [passengers, { loading : passengersLoading }] = usePassengersRemote();
	const [{ customerId, customer }, { loading: clientLoading }] = useClientInfo();
	const [{ userEmail }] = useUserDetails();
	const [{ offer }] = useReservationOffer();
	const [{ revalidations }] = useReservationSearchFormValues();
	const loading = clientLoading || passengersLoading;

	const result = useMemo(() => {
		const { ProfileId } = customer || {};
		let fare_type_id : PassengerTypesEnum = (() => {
			switch(ProfileId) {
				case "RFJOVEM": return PASSENGER_YOUTH;
				case "RFMILITAR": return PASSENGER_ARMY;
				case "RFSENIOR": return PASSENGER_SENIOR;
				case "RFLEX": return PASSENGER_NORMAL;
				default: return PASSENGER_NORMAL;
			}
		})();

		const promocode = customer && customerId;
		const name = customer && customer.Name;
		const doc = customer && customer.DocumentNr;
		const email = (customer && customer.Email) || userEmail;

		const resultInner : Passenger[] = (() => {
			if(!passengers || passengers.length===0) {
				return [{
					email: offer==="international" ? email : "",
					fare_type_id,
					promocode,
					name,
					doc,
					phone: ""
				}];
			}
			if(revalidations) {
				return [passengers[0]];
			}
			return passengers;
		})();
		return resultInner;

	}, [loading, JSON.stringify(passengers)]);

	return [
		(loading ? [] : result),
		{ loading }
	]
}