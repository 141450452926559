import React from "react";
import clsx from "clsx";
import "./index.scss";
import { TableHeader, TableBody } from "../parts";
import { TableBaseProps } from "../types/TableBaseProps";
import { TableHeaderSettings } from "../types/TableHeaderSettings";
import { TableBodySettings } from "../types/TableBodySettings";

export const TableBase = ({ body, head, settings, ...htmlAttributes } : TableBaseProps) => {
  const { className, ...restAttributes } = htmlAttributes;
  const { colored, hoverable, primary_header/*...testRest*/ } = settings || {};
  const header_settings : TableHeaderSettings = { primary_header };
  const body_settings : TableBodySettings = { colored };
  const overridenClassName = `table-base ${clsx(colored && `colored colored-${colored}`)} ${clsx(className)} ${clsx(hoverable && "hoverable")}`;

  return <table
    {...restAttributes}
    className={overridenClassName}
  >
    {head && <TableHeader settings={header_settings} {...head} />}
    {body && <TableBody settings={body_settings} {...body} />}
  </table>
}