import React from "react";
import "./index.scss";
import clsx from "clsx";
import { arrayToArray } from "modules/array";
export const DelimiterPanel = ({className, children, ...rest} : any) => {
	const childrenNormalized = arrayToArray(children);
	const count = childrenNormalized.length;
	return <div
		{...rest}
		className={`delimiter-panel ${clsx(className)}`}
	>
		{childrenNormalized.map((child, index)=>{
			return <React.Fragment key={index}>
				{child}
				{index<count-1 && <span className="del">|</span>}
			</React.Fragment>
		})}
	</div>
}