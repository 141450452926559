import "./index.scss";
import React, { useContext } from "react";
import { View } from "./View";
import { Editable } from "./Editable";
import "./index.scss";
import { EditableTextProps } from "../types/EditableTextProps";
import { EditableTextViewProps } from "../types/EditableTextViewProps";
import { useAdminStorage } from "api/admin";
import { TranslationsContext } from "api/translations/TranslationsContext";

export const EditableText = ({
	isHtml,
	tag,
	emptyValue="",
	scope,
	as,
	children,
	ctx = TranslationsContext,
	...rest
} : EditableTextProps) => {
	const { lang, translation, save } = useContext(ctx);
	const { [as]: value } = (translation && translation[scope]) || {};
	const [{isEditable}] = useAdminStorage();

	const viewProps : EditableTextViewProps = { lang, value, isHtml, tag, emptyValue, children, isEditable, ...rest }
	if(!isEditable) {
		return <View {...viewProps}	/>
	}
	
	const onSave = async (value : string) => {
		await save({ scope, as, value })
	}
	return <Editable {...{ value, onSave, viewProps }}/>
}