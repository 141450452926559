import {
	ERROR_FORM_FIELD_INVALID,
	ERROR_FORM_FIELD_REQUIRED
} from 'consts';
import { isNumberValid } from "modules";
import { Passenger } from 'types/passengers/Passenger';
interface ValidateFunctionProps {
  passengers: any;
}
type ValidateFunctionType = (props : ValidateFunctionProps) => {}

export const validateSovePassengers = ({passengers, international} : { passengers: Passenger[], international: boolean }) => {
	if(!passengers || !Array.isArray(passengers)) return [];
	const res = passengers.map(p => {
		let locError = {};
		if(p) {
			// if(p.email && !isEmailValidRegexFull(p.email)) {
			// 	locError = { ...locError, email: ERROR_FORM_FIELD_EMAIL_INVALID }
			// }
			if(p.phone && !isNumberValid(p.phone)) {
				locError = { ...locError, phone: ERROR_FORM_FIELD_INVALID }
			}
			if(!p.email && !p.phone && international) {
				locError = { ...locError, email: ERROR_FORM_FIELD_REQUIRED, phone: ERROR_FORM_FIELD_REQUIRED }
			}
		}
		if(Object.keys(locError).length>0) {
			return locError;
		}
		return null;
	});
	return res.filter(c=>!!c);
}
