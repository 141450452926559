import React from "react";
import { route } from "navi";



export const convertRoutesToNavi = ({routes, pages}: any) => {

	const extractComponent = (component : string) => {
		let com = pages[component];
		if(typeof com==="object" && com.default) {
			return com.default;
		}
		return com;
	}

	const naviRoutes: any = {};
	Object.keys(routes).forEach(path=>{
		const component = routes[path];
		const com = extractComponent(component);
		if(com) {
			naviRoutes[path] = route({
				getData: (route)=> {
					return ({lang: "pt", ...route.params, component})
				},
				getView: (req, context) => {
					const { params } = req || {};
					return React.createElement(com, { lang: "pt", path, component, params })
				},
			});
			const langPattern = "/:lang"+(path==="/" ? "" : path);
			naviRoutes[langPattern] = route({
				getData: (route)=> ({...route.params, component}),
				getView: (req, context) => {
					const { params } = req || {};
					const { lang } = params || {};
					return React.createElement(com, { lang, path, component, params })
				}
			})
		}
	});
	return naviRoutes;
}