import { makeArray } from "./makeArray";

export const equalizeArraysLength = (a: any, b: any): any => {
	const aArr : any = Array.isArray(a);
	const bArr : any = Array.isArray(b);
	const arrsCount = aArr + bArr;
	if (arrsCount===0) return [[a], [b]];
	else if (arrsCount===2) {
		if (a.length > b.length) {
			return [a, [...b, ...makeArray(b[b.length - 1], a.length - b.length)]];
		}
		else if (a.length < b.length) return equalizeArraysLength(b, a).reverse();
		return [a, b];
	}
	else if (aArr) return [a, makeArray(b, a.length)];
	else if (bArr) return equalizeArraysLength(b, a).reverse();
}