/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useStateNamed } from "hooks";
// import { useReduxFormSelectorValues } from "forms";
import { useRouteQueryKeys } from "hooks";
import { getState } from 'app/store';

interface DashboardSearchParams {
  searchStarted?: boolean | null;
  reservationNumber?: string | null;
  ticketNumber?: string | null;
  origin?: string | null;
  destination?: string | null;
  reservationDate?: string | null;
  travelDate?: string | null;
  operat?: string | null;
}

const useDashboardRouteKeys = () : [DashboardSearchParams, (obj : DashboardSearchParams) => Promise<void>] => {
  const [query, { addQuery }] = useRouteQueryKeys();
  return [query, addQuery]
}

export const useDashboardRoute = () : [DashboardSearchParams, { save: () => Promise<void>, clear: () => Promise<void> }] => {
  const [query, addQuery] = useDashboardRouteKeys();
  const { searchStarted } = query;
  const { reservationNumber, ticketNumber, origin, destination, reservationDate, travelDate, operat } = query;
  const clear = async () => {
    await addQuery({ searchStarted: true, reservationNumber: null, ticketNumber: null,  origin: null, destination: null,  reservationDate: null, travelDate: null, operat: null})
  }
  const save = async () => {
    const { salesSearchDialogForm } = (getState().form as any);
    const state = (salesSearchDialogForm && salesSearchDialogForm.values) || {};
    const { reservationNumber, ticketNumber, origin, destination, reservationDate, travelDate, operat } = state;
    await addQuery({ searchStarted: true, reservationNumber, ticketNumber, origin, destination, reservationDate, travelDate, operat })
  }
  const [result, setResult] = useStateNamed<DashboardSearchParams>("useDashboardRoute", {});
  useEffect(() => {
    setResult({
      searchStarted: !!searchStarted,
      reservationNumber,
      ticketNumber,
      origin,
      destination,
      reservationDate,
      travelDate,
      operat
    });
  }, [searchStarted, reservationNumber, ticketNumber, origin, destination, reservationDate, travelDate, operat])

  return [
    { ...result, searchStarted: !!searchStarted },
    { clear, save }
  ]
}