import { useFetchOnce } from "hooks";
import { FETCH } from "types";
import { APIDTO } from "@card4b/apidto-ts";

export type UseFetchStopsResult = [
  APIDTO.API.ScheduleItinerary,
  FETCH.FetchOnceStatus,
  FETCH.FetchOnceMethods
]

/*export const useFetchStops = ({tripId} : { tripId : string }) : UseFetchStopsResult => {
  return useFetchOnce<any>("/api/ticketing/stops", { method: "POST", lock: !tripId, body: { tripId }});
}*/

export const useFetchStops = ({tripId, date} : { tripId : string, date : string }) : UseFetchStopsResult => {
   return useFetchOnce<any>("/api/ticketing/stopsWithDate", { method: "POST", lock: !tripId, body: { tripId,date }});
 }