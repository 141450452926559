import React from "react";
import "./index.scss";
import { ItemsListInputItemProps } from "./ItemsListInputItemProps";
import { EditableText } from "controls/EDITABLE";
export { ItemsListInputItemProps }

export const ItemsListInputItem = ({ scope, value, item, className, ...restAttributes } : ItemsListInputItemProps) => {
	if(!item) return null;
	return <div
		{...restAttributes}
		className={`items-list-input-item ${""+item.value===""+value ? "selected" : ""}`}
	>
		{!scope && <span>{item.text}</span>}
		{scope && <EditableText	tag="span" scope={scope} as={`${item.value}`} emptyValue={item.text ? ""+item.text : ""}	/>}
	</div>
}
