import React, { useState } from "react";
import { FieldSchedulesSearchOrigin } from "./FieldSchedulesSearchOrigin"
import { FieldSchedulesSearchDestination } from "./FieldSchedulesSearchDestination"
import { FieldSchedulesSearchIda } from "./FieldSchedulesSearchIda"
import { FieldSchedulesSearchVolta } from "./FieldSchedulesSearchVolta"
import { FieldsHolder } from "./FieldsHolder";
import { useReservationOffer, useReservationSearchFormValues, useOriginsOffer, useReservationId } from "api";
import { FieldCheckEditable, FieldTextEditable } from "forms";
import { reduxForm } from "redux-form";
import { Loader } from "controls";
import { RESERVATION_OFFER_MODES } from "types/API/LISTS";

import "./index.scss";
import { Confirm } from "./Confirm";

interface RevalidationsState {
	offer?: RESERVATION_OFFER_MODES;
	revalidations?: boolean;
	roundTrip?: boolean;
}
interface RevalidationFieldsCacherProps {
	data: RevalidationsState;
	onUpdate: (data: RevalidationsState) => void;
}
const useRevalidationsFieldsCacher = () => {
	const [{ offer }] = useReservationOffer();
	const [{ revalidations, roundTrip }] = useReservationSearchFormValues();
	return { offer, revalidations, roundTrip }
}

const RevalidationFieldsCacher = ({ data, onUpdate } : RevalidationFieldsCacherProps) : any => {
	const { offer, revalidations, roundTrip } = useRevalidationsFieldsCacher();
	if(JSON.stringify(data)!==JSON.stringify({ offer, revalidations, roundTrip })) {
		onUpdate({ offer, revalidations, roundTrip })
	}
	return null;
}

const RevalidationFields = ({ disabled } : { disabled : boolean }) => {
	const [{ offer, revalidations, roundTrip }, setState] = useState<RevalidationsState>({ });// offer: null, revalidations: null, roundTrip: null
	return <>
		<RevalidationFieldsCacher data={{ offer, revalidations, roundTrip }} onUpdate={(data) => setState(data)}/>
		{offer==="international" ? <div /> : <FieldCheckEditable disabled={disabled} name={"revalidations"} />}
		{offer==="national" && <div style={{display: revalidations ? "block" : "none"}}>
			<FieldTextEditable
				name="reschedule_of_ida"
				disabled={disabled}
				required={revalidations}
				value=""
			/>
		</div>}
		{offer==="national" && <div style={{display: revalidations && roundTrip ? "block" : "none"}}>
			<FieldTextEditable
				name="reschedule_of_volta"
				disabled={disabled}
				required={revalidations && roundTrip}
				value=""
			/>
		</div>}
	</>
}

export const Form = reduxForm<{},{}>({
	form: "searchForm",
	enableReinitialize: true,
	destroyOnUnmount: false
})(({form} : { form : string }) => {
	const [reservationId] = useReservationId();
	const [, { loading }] = useOriginsOffer();
	if(loading) return <Loader overlay />;
  return <form className={`form ${form}`}>
    <FieldsHolder>
			<FieldSchedulesSearchOrigin />
			<FieldSchedulesSearchDestination />
			<FieldSchedulesSearchIda />
			<FieldSchedulesSearchVolta />
			<FieldCheckEditable
				name={"roundTrip"}
				disabled={!!reservationId}
			/>
			<RevalidationFields disabled={!!reservationId}/>
			<div />
			<Confirm />
		</FieldsHolder>
  </form>
})