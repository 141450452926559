import { PassengerPayment } from 'types/passengers/PassengerPayment';
import { useTripsSelectedRoutes } from '../../ticketing/useTripsSelectedRoutes';
import { usePassengersRequest } from "./usePassengersRequest";

export const usePassengersRequestForConfirm = () : [Partial<PassengerPayment>[] | null] => {
	const [passengers] = usePassengersRequest();
	const [idaRoutes] = useTripsSelectedRoutes("ida");
	const { price_type_id } = idaRoutes || {};
	if(!passengers) return [null];
	return [passengers.map((p : any) => {
		const pTransformed : Partial<PassengerPayment> = {
			code: p.promocode,
			email: p.email,
			id: p.id,
			mobilePhone: p.phone,
			name: p.name,
			nDocument: p.doc,
			phone: p.phone,
			perfil: p ? +p.fare_type_id : undefined,
			price_type_id: p.price_type_id || price_type_id,
			reschedule_of: p.reschedule_of && p.reschedule_of.length>0 ? p.reschedule_of : null
		};
		return pTransformed;
	})]
}