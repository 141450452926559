import React from "react";
import "./index.scss";
import { EditableText } from "controls";
import { DISABLED_MESSAGES_KEY } from "consts";
import { useLowStorage } from "hooks";

export const UserMessage = ({item, style, update} : any) => {
	const [, low] : any = useLowStorage();
	if(!item) return null;
	if(!item.isActive) return null;
	return <div className="user-message-outer" style={style}>
		<div className="user-message" style={{
			backgroundColor: item.backColor,
			opacity: item.panelOpacity
		}}>
			<EditableText
				isHtml
				scope={"UserMessage"}
				as={item.ID}
				className="user-message-content"
				tag="div"
				style={{
					color: item.fontColor,
				}}
			/>
			{!item.blockClose && <button
				style={{color: item.fontColor}}
				className={"button"}
				onClick={()=>{
					low.set(DISABLED_MESSAGES_KEY, [...(low.get(DISABLED_MESSAGES_KEY)), item.ID]);
					update && update();
				}}
			><i className={"fa fa-close"} /></button>}
		</div>
	</div>;
}