import React from "react";
import "./index.scss";
import { UserSettingsDisplay } from "admin/components";
import { useUser } from "api/user";

export const ViewUser = () => {
  const [user] = useUser();
	const lsVals:any = {}
	for(let key in localStorage) {
		lsVals[key] = localStorage.getItem(key);
	}
	return <div className="admin-users-user">
		<UserSettingsDisplay user={user} />
		<h1 style={{color: "#222222"}}>LocalStorage (readonly)</h1>
		<table className={"table table-striped table-primary-header"}>
			<thead>
				<th>Name</th>
				<th>Value</th>
			</thead>
			<tbody>
				{Object.keys(lsVals).map((key)=>{
					if(typeof lsVals[key]==="object") return null;
					return <tr key={key}>
						<td>{key}</td>
						<td>{lsVals[key]}</td>
					</tr>
				})}
			</tbody>
		</table>
	</div>
}