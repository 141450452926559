import React from "react";
import { ReservationInfoPanelView } from "../ReservationInfoPanelView"
import { ReservationActionButtonsView } from "../ReservationActionButtonsView"
import { Flex } from "controls";
import { useReservationId } from "api";
import { ReservationInfoErrorsView } from "../ReservationInfoErrorsView";
import "./index.scss";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";

export const ReservationInfoView = ({ ...rest }) => {
	const [reservationId] = useReservationId();
	const [{outgoing_schedules}] = useTripsConfirmed();
	const allowShow = (!!outgoing_schedules) || reservationId;
	return <div {...rest} className="reservation-info">
		{allowShow && <Flex column className="flex flex-column flex-between" style={{height: "100%"}}>
			<ReservationInfoPanelView />
			<div className="flex-column">
				<ReservationInfoErrorsView />
				<ReservationActionButtonsView />
			</div>
		</Flex>}
	</div>
}