// const createStyleguide = require("./createStyleguide");
// const colorsProject = require("styleguide/colors");
// const styleguide = createStyleguide({
// 	colors: colorsProject
// })
// const { colors, fonts, themes } = styleguide;
// const getColor = (name) => colors[name];


const styleguide = {
	colors: require("./colors"),
	fonts: require("./fonts"),
	themes: require("./themes"),

	getColor: require("./base/getColor"),
	getFS: require("./base/getFS")
}

module.exports = styleguide;