import { API } from 'types';
import { useTripsTab } from './useTripsTab';
import { useRouteQueryKeys } from "hooks";
import { RouteTicketSelectionParams } from 'types/ticketing/RouteTicketSelectionParams';
import { UseTripsSelectedResult } from 'types/ticketing/UseTripsSelectedResult';

export const useTripsSelectedRoutes = (direction?: API.LISTS.RouteDirection) : UseTripsSelectedResult => {
  const [tab] = useTripsTab();
  const scheduleKey = `trip_${direction || tab}`;
  const priceTypeKey = `price_type_${direction || tab}`
  const [query, { addQuery }] = useRouteQueryKeys();
  const schedule_id = query[scheduleKey];
  const price_type_id = query[priceTypeKey];
  const setTrip = async (data : RouteTicketSelectionParams) => {
    if(!data) {
      await addQuery({ [scheduleKey]: null, [priceTypeKey]: null });
    }
    else {
      await addQuery({ [scheduleKey]: data.schedule_id, [priceTypeKey]: data.price_type_id });
    }
  }

  const res : RouteTicketSelectionParams = { schedule_id, price_type_id };
  return [
    (schedule_id ? res : null),
    setTrip
  ]
}