import { useFetchOnce } from "hooks";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

interface ServiceApiCodeItem {
  id: number;
  description: string;
}

type ServiceApiCodesList = ServiceApiCodeItem[];

export const useServiceApiCodes = () : FetchOnceResult<ServiceApiCodesList> => {
  const [data, status, meta] = useFetchOnce<ServiceApiCodesList>("/api/admin/codes/service_api_codes");
  return [data, status, meta];
}