import { parse, stringify } from "query-string";
import { getLang } from "modules/translations";

const escapeNullsFromObject = (value: any) => {
	const result: any = {};
	Object.keys(value).forEach(key=>{
		if(value[key]!==null) result[key] = value[key];
	});
	return result;
};

interface UrlGetPathProps {
	href?: string;
	lang?: string;
}

const urlGetPath = ({ href, lang } : UrlGetPathProps) => {
	let url = window.location.pathname;
	if(href) {
		const langCurrent = getLang();
		url = href;
		if(langCurrent==="pt") url = href;
		else if(!lang) {
			if(href==="/") url = `/${langCurrent}`;
			else url = `/${langCurrent}`+href;
		}
	}
	if(lang) {
		const preffix = !lang || lang === "pt" ? "" : "/"+lang;
		url = preffix + (url.replace("/pt/", "/").replace("/en/", "/").replace("/fr/", "/").replace("/es/", "/"));
	}
	return url;
};

interface UrlGetSearchProps {
	params?: {}
	paramsAdd?: {}
}
const urlGetSearch = ({ params, paramsAdd } : UrlGetSearchProps) => {
	if(!!params) {
		return stringify(escapeNullsFromObject(params));
	}
	if(!!paramsAdd) {
		const originalList = parse(window.location.search);
		const addingList = paramsAdd;
		return stringify(escapeNullsFromObject({...originalList, ...addingList}));
	}
	return "";
};

const urlConcatenate = (path : string, search : string) => {
	if(!search || search.length===1) return path;
	if(search.indexOf("?")===0) return path+search;
	return path+"?"+search;
};

interface UrlToProps extends UrlGetSearchProps, UrlGetPathProps { }

const urlTo = ({ href, lang, params, paramsAdd } : UrlToProps) => {
	const path = urlGetPath({ href, lang });
	const search = urlGetSearch({ params, paramsAdd });
	return urlConcatenate(path, search);
}

export { urlTo };