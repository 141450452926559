import React from "react";
import "./index.scss";
import PropTypes from 'prop-types';
import { LAST_REFRESH_STR } from "../../../consts/statuses";
import { Loader } from "../../loaders/Loader";
import clsx from "clsx";

interface TitlePanelProps {
	type?: ("dark" | "simple" | "light"),
	before?: any;
	after?: any;
	className?: string;
	title?: (string | JSX.Element);
	children?: any;
	loading?: (boolean | string);
	onRefresh?: any;
	lastRefresh?: any;
	onSearch?: any;
	style?: any;
	contentProps?: any;
	titleProps?: any;
	id?: any;
}

const TitlePanel = (props : TitlePanelProps) => {
	const {
		type,
		before,
		after,
		className,
		title,
		children,
		loading,
		onRefresh,
		lastRefresh,
		onSearch,
		style,
		contentProps={},
		titleProps={},
		// eslint-disable-next-line no-unused-vars
		// dispatch,
		...rest
	} = props;
	return <div {...rest} style={style} className={`panel panel-${type || "simple"} ${className}`}>
			{loading && <Loader text={loading} overlay/>}
			<div
				{...titleProps}
				className={`title ${clsx(titleProps.className)}`}
			>
				<div className="label">
					{typeof title==="string" ? <span>{title}</span> : title}
				</div>
				<div className="controls">
					{before}
					{lastRefresh && <label>{LAST_REFRESH_STR} {lastRefresh}</label>}
					{onRefresh && <img alt="refresh" className="refresh-button" src="images/icons/refresh.png" onClick={onRefresh} />}
					{onSearch && <img alt="search" src="images/icons/search.png" onClick={onSearch} />}
					{after}
				</div>
			</div>
			<div
				{...contentProps}
				className={`content ${clsx(contentProps.className)}`}
			>
				{children}
			</div>
		</div>
}

TitlePanel.propTypes = {
	type: PropTypes.oneOf(['simple', 'light', 'dark', 'just-one-of']),
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
}

export {
	TitlePanel
}