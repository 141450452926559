import { useRouteQueryKeys } from "hooks";
// import React from "react";

interface ClientInfoRoutingParams {
  customerId: string;
}

type ClientInfoRoutingResult = [
  ClientInfoRoutingParams,
  (data : ClientInfoRoutingParams) => Promise<void>
]

export const useClientInfoRouting = () : ClientInfoRoutingResult => {
  const [routes, { addQuery }] = useRouteQueryKeys();
  const current : ClientInfoRoutingParams = {
    customerId: routes.customerId
  }
  const setData = async (data : ClientInfoRoutingParams) => {
    if(!data) {
      await addQuery({customerId: null, reservationUid: null});  
    }
    else {
      await addQuery({...data, reservationUid: null});
    }
  }
  return [
    current,
    setData
  ]
}