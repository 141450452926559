import React from "react";
import { CHANGE_SEATS as SCOPE } from "consts";
import { EditableText } from "controls";
import { convertLegIntoBusSeats } from "./convertLegIntoBusSeats";

const labels = ["conductor", "changing", "free", "reserved", "covid", "place"]//, "flex", "promo"]
export const BusLabels = ({ leg }: any) => {
	const seatsConstruct: any = convertLegIntoBusSeats(leg);
	const { trip } = leg || {};
	const { bus_no } = trip || {};
	const renderPassengerLabels = (() => {
		// if(ENV_SITE) {
			return <ul>
				{
					seatsConstruct && Object.keys(seatsConstruct).map(key=> seatsConstruct[key]).filter(c=>c.reserved).map(c=><li key={c.nr}>
						<EditableText scope={SCOPE} as="passenger-count">{({ value }) => <span>{value}{c.passenger}</span>}</EditableText>
						-
						<EditableText scope={SCOPE} as="seat-count">{({ value }) => <span>{value}{c.nr}</span>}</EditableText>
					</li>)
				}
			</ul>
		// }
		// if(ENV_SOVE) {
		// 	return <table className="passengers-table">
		// 		<thead>
		// 			<tr>
		// 				<th><EditableText scope={SCOPE} as="passenger-count">{({ value }) => <span>{value}</span>}</EditableText></th>
		// 				<th><EditableText scope={SCOPE} as="seat-count">{({ value }) => <span>{value}</span>}</EditableText></th>
		// 			</tr>
		// 		</thead>
		// 		<tbody>
		// 			{
		// 				seatsConstruct && Object.keys(seatsConstruct).map(key=> seatsConstruct[key]).filter(c=>c.reserved).map(c=><tr key={c.nr}>
		// 					<td>{c.passenger}</td>
		// 					<td>{c.nr}</td>
		// 				</tr>)
		// 			}
		// 		</tbody>
		// 	</table>
		// }
	})

	return <div className="labels-container-div">
		<div className="first-div">
			<div className="label-car-driver">
				<EditableText scope={SCOPE} as="bus-number">{({ value }) => <span><strong>{value}{bus_no}</strong></span>}</EditableText>
			</div>
			<div className="label-car-driver">
				<div className="driver"><i className="icon-Condutor" />
					<div className="driver-label">
						<EditableText scope={SCOPE} as={labels[0]} tag="span"/>
					</div>
				</div>
			</div>
		</div>
		<div className="second-div">
			{
				labels.map((l, index) => (
					index === 0 ? null : <div key={index} className="labels-outer-div">
						<div className="labels-icons"></div>
						<div className="labels">
							<EditableText scope={SCOPE} as={l} tag={"span"} />
						</div>
					</div>
				))
			}
		</div>
		<div className="third-div">
			{renderPassengerLabels()}
		</div>
	</div>
}