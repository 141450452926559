import React from "react";
import Draggable from "react-draggable";

// interface PanelDraggableProps {
//   draggable?: boolean;
//   className?: string;
//   name?: string;
//   children?: any;
//   width: number;
//   height: number;
// }

export const PanelDraggable = ({width, height, draggable, className, children, name, ...rest }: any) => {
  if(!draggable) { return React.cloneElement(children, rest); }
  const screenX = window.innerWidth;
  const screenY = window.innerHeight;
  const defaultPosition = {
    x: (screenX-width) / 2,
    y: (screenY-height) / 2
  };
  return <Draggable
  	defaultPosition={defaultPosition}
  	handle={`.title-${name}`}
  >
    {React.cloneElement(children, {...rest, className: `${className} draggable`})}
  </Draggable>
}