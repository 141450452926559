import React from "react";
import { numbersNormalize } from "forms";

export const RenderNumber = ({value, onChange} : {value: number, onChange: (value : number) => any }) => {
  return <input
    type={"text"}
    value={value}
    onChange={(e) => {
      onChange(+numbersNormalize(e.target.value));
    }}
  />
}