import React from "react";
import { arraySplitCount, arraySumNumbers } from "modules/array";
const logError = (text: any) => {
	console.error("TableGrid: "+text);
}

const validateCountRatio = ({count, ratio}: any) => {
	if(!count && !ratio) {
		logError("count & ratio missing");
		return { count: 1, ratio: [1]}
	}
	if(count && ratio) {
		logError("count & ratio passed, required only one, ratio:array<number> OR count:number");
		return { count: ratio.length, ratio }
	}
	if(count && !ratio) {
		const newRatio : any[] = [];
		for(let i=0; i<count; i++) newRatio.push(1);
		return { count, ratio: newRatio }
	}
	if(ratio && !count) {
		return { count: ratio.length, ratio }
	}
	return { count, ratio }
}

const validateChildrens = (children: any) => {
	if(!Array.isArray(children)) {
		return [children];
	}
	return children;
}

export class TableGrid extends React.Component {
	props : any;
	render() {
		let { children, count, ratio, style, itemProps, ...rest } = this.props;
		children = validateChildrens(children);
		const val = validateCountRatio({ count, ratio });
		count = val.count;
		ratio = val.ratio;
		if(!children) return null;
		const rows = arraySplitCount(children, count);
		const totalRatio = arraySumNumbers(ratio);
		return <table {...rest} style={{...style}}>
			<tbody>
				{rows.map((row, rIndex)=><tr key={rIndex} style={{width: "100%"}}>
						{row.map((cell: any, cIndex: any) => {
							return <td key={cIndex} style={{width: `${100 * ratio[cIndex] / totalRatio}%`}}>
								{cell && React.cloneElement(cell, itemProps)}
							</td>
						})}
					</tr>)}
			</tbody>
		</table>
	}
}