import { fetchJson } from "../../fetch";
import { hex_md5 } from "../../encrypt";
import { useStateNamed } from "../../../hooks";
import { FetchActionProps } from "../types/FetchActionProps";
import { FetchActionResult } from "../types/FetchActionResult";

const STATE_DEFAULT: any = { loading: false, success: false, error: false, response: null };
const STATE_LOADING: any = { loading: true, success: false, error: false, response: null };
const STATE_SUCCESS: any = { loading: false, success: true, error: false };
const STATE_ERROR: any = { loading: false, success: false, response: null };

export const useFetchAction = (url : string, { body, method="POST", ...params } : FetchActionProps) : FetchActionResult<any> => {
	const keyInner = hex_md5(JSON.stringify({ url, body, method }));
	const [state, setState] = useStateNamed("useFetchAction:"+keyInner, STATE_DEFAULT);
	const execute = async() => {
		try {
			setState(STATE_LOADING);
			const response : any = await fetchJson(url, { body, method, ...params });
			setState({...STATE_SUCCESS, response });
			return response;
		}
		catch(error) {
			console.error(error);
			setState({...STATE_ERROR, error });
			throw error;
		}
	}
	const { response, ...status } = state;
	return [
		response,
		status,
		{
			execute
		}
	]
}