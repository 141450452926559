import React from "react";
import "./index.scss";
import { EditableText, Loader } from "controls";
import { fetchJson } from "modules/fetch";
import { useFetchOnce } from "hooks";
import { createTranslationContext } from "app";
import { useTranslationLoader } from "app/translations/hooks";
import { useServer } from "api/app/useServer";

export const Translations = () => {
	const [{langs}] = useServer()
	const [allKeys] = useFetchOnce<any>("/ms/translations_keys/");
	const ptLoader = useTranslationLoader({lang: "pt"});
	
	const loadersList = [ptLoader];
	const ptContext = createTranslationContext({
		lang: "pt",
		translation: loadersList[0][0],
		save: async ({scope, value, as}: any) => {
        await fetchJson("/ms/translations_update/", {
            body: { lang: "pt", label: as, scope, value },
            method: "POST"
        });
        setTimeout(() => {
					loadersList[0][2].refresh();
				})
    }
	});
	const contextList = [ptContext];
	if(!langs) return null;
	if(!allKeys) return <Loader />;
	const renderScope = (items: any, scope: any) => {
		return items.map((as: any, index: any) => <div className={"scope-item"} key={index}>
			<div className={"scope-item-cell"}><h4>{as}</h4></div>
			{langs.map((lang: any, idx: any) => {//lang={lang}
				return <div key={lang} className={"scope-item-cell"}><EditableText tag="p" scope={scope} as={as} ctx={contextList[idx]} /></div>
			})}
		</div>);
	}

	const colCount : number = (langs ? langs.length : 1)+1;

	return <div className={`languages-content count-${colCount}`} style={{maxWidth: "95%"}}>
		<div className={"languages-table-container"} >
			<div className="table">
				<div className="translations-header">
					<div className={"scope-item-cell"}>KEY</div>
					{langs && langs.map((l: any) => {
						return <div key={l} className={"scope-item-cell"}><span className="flag-icon flag-icon-gb">{l}</span></div>
					})}
				</div>
				<div>
					{Object.keys(allKeys).map((scopeName)=>{
						return <React.Fragment key={scopeName}>
							<div className={"scope-name"}>
								<div className={"scope-item-cell"}>
									<h2>{scopeName}</h2>
								</div>
							</div>
							{renderScope(allKeys[scopeName], scopeName)}
					</React.Fragment>})}
				</div>
			</div>
		</div>
	</div>
}