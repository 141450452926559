// tslint:disable:no-restricted-globals

import React from "react";
import "./index.scss";
import { Flex, ButtonTyped, DelimiterPanel } from "controls";
import { datetime } from "modules";
import { Form, formName } from "./Form";
import { FormErrorsContainer } from "forms";
import {
	XLSImage,
	PDFImage,
	PrintImage,
	LinkDownload,
	LinkPrint
} from "controls";
import { useReduxFormSelector } from "forms";
import { useLiquidationState, useLiquidationNotes, useLiquidationCurrent } from "api/liquidation";
import { useUserDetails } from "api/user/useUserDetails";

const w : any = window;
export const LiquidationSearch = ({ setParentState }: any) => {
	const [userDetails] = useUserDetails();
	const { sysAttribute } = userDetails;
	const isManager = sysAttribute ? sysAttribute.filter((c: any) => c.Name === "isManager" && c.Value === "1").length > 0 : false;
	const [{syncErrors, submitFailed}] = useReduxFormSelector(formName);
	const [initialValues,,{save: onConfirm}] = useLiquidationState();
	const [currentLiquidation] = useLiquidationCurrent();
	const [notes, { success: notesSuccess}] = useLiquidationNotes();
	const notesList = (notesSuccess && notes.notes) || [];
	const getFormProps = () => {
		return {
			initialValues,
			onConfirm
		}
	}
	const hasLiquidationsToAprove = isManager && notesList.filter((c: any) =>
		c.liquidation.ManagerAgCpAccount &&
		c.currentUser.ID === c.liquidation.ManagerAgCpAccount &&
		c.status.REFName === "APR"
	).length > 0;

	const { currentManager } = currentLiquidation || {};
	const currLiqInternal = (currentLiquidation && currentLiquidation.liquidation) || {};
	const { PeriodBegin, PeriodEnd, Value, ID } = currLiqInternal;
	const beginDate = PeriodBegin ? (datetime.formatDateHours(PeriodBegin)) : " - ";
	const endDate = PeriodEnd ? (datetime.formatDateHours(PeriodEnd)) : " - ";
	const period = beginDate + "/" + endDate;

	const xlsUrl = "/api/liquidation/note/xls?LiquidAccPeriod=" + ID;
	const pdfUrl = "/api/liquidation/note/pdf?LiquidAccPeriod=" + ID;

	return <>
		<Flex className="liquidation-search" style={{ padding: "8px" }}>
			<Form {...getFormProps()} />
			<div className="liquidation-search-info">
				{hasLiquidationsToAprove && <ButtonTyped
					styling="primary"
					onClick={() => {
						w.setDialogNotesApproveOpen && w.setDialogNotesApproveOpen(true);
					}}
				>Aprovar</ButtonTyped>}
				{isManager && <Flex>
					<label className="text-center">Diário Caixa</label>
					<LinkDownload href={"/api/liquidation/snapshot/pdf"} onStatus={setParentState}><PDFImage /></LinkDownload>
					<LinkPrint href={"/api/liquidation/snapshot/pdf"} onStatus={setParentState}><PrintImage /></LinkPrint>
				</Flex>}
				{currentLiquidation && <Flex className="details-text">
					<ButtonTyped
						styling="primary"
						onClick={() => {
							w.setConfirmSubmissionOpen && w.setConfirmSubmissionOpen(true);
						}}
					>Submeter</ButtonTyped>
					<DelimiterPanel>
						<label>Gestor: <label style={{ fontWeight: "normal" }}>{currentManager}</label></label>
						<label>{period}</label>
						<label>{Value && (+Value).toFixed(2)} €</label>
						{/* <label>{status.Name}</label>
						
						TODO: to check
						
						*/}
					</DelimiterPanel>
					<label className="text-center" style={{ marginLeft: "20px", marginRight: "10px" }}>Extracto</label>
					<LinkDownload id="current_liquidation_xls" href={xlsUrl} onStatus={setParentState}><XLSImage /></LinkDownload>
					<LinkDownload id="current_liquidation_pdf" href={pdfUrl} onStatus={setParentState}><PDFImage /></LinkDownload>
					<LinkPrint href={pdfUrl} onStatus={setParentState}><PrintImage /></LinkPrint>
				</Flex>}
			</div>
		</Flex>
		{syncErrors && submitFailed && <div className="form-errors-panel hide-repeating-errors">
			<FormErrorsContainer form={formName} />
		</div>}
	</>
}