import React from "react";
import { LoaderSove } from "./LoaderSove";
// import { LoaderSite } from "./LoaderSite";
// import { useEnv } from "hooks";

export const Loader = (props: any) => {
	// const [env] = useEnv();
	// if(env.ENV_SOVE) {
	// 	return <LoaderSove {...props}/>
	// }
	return <LoaderSove {...props}/>
	// return <h1>Loading...</h1>
}