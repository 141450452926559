import React, { useState } from "react";
import { TitlePanel, ErrorEditable } from "controls";
import { DEFAULT_TITLE, LOADING_STR } from "consts";
import { usePage } from "hooks";
import { useFetchOnce } from "hooks";
import "./index.scss";

// import { Button } from "@card4b-design/design-system"

export const FAQ = () => {
	usePage({
		name: "FAQ",
		options: {
			title: DEFAULT_TITLE
		}
	});

	const [faq, faqStatus] = useFetchOnce<any>("/api/faq/list");
	const [state, setStateInn] = useState<any>({});
	const setState = (s: any) => setStateInn({...state, ...s});

	return <TitlePanel
		type={"dark"}
		title={"FAQ"}
		loading={faqStatus.loading && LOADING_STR}
	>
		{/* <Button>Sample button at SOVE</Button> */}
		{faqStatus.success && Array.isArray(faq) && faq.map((item, index) => {
			const elemId = "theme" + index;
			return <React.Fragment key={index}>
				<div
					className="theme"
					onClick={() => {
						setState({ [elemId]: !state[elemId] })
					}}>
					{item.Title}
				</div>
				<dl className={`faqs ${state[elemId] ? "opened" : "closed"}`}>
					{item.Questions.map((q: any, index2: any) => {
						const elemId = "dt" + index + "." + index2;
						return <React.Fragment key={index2}>
							<dt
								onClick={() => {
									setState({ [elemId]: !state[elemId] })
								}}
								dangerouslySetInnerHTML={{ __html: q.Question }}
							/>
							<dd
								className={`${state[elemId] ? "opened" : "closed"}`}
								dangerouslySetInnerHTML={{ __html: q.Answer }}
							/>
						</React.Fragment>
					})}
				</dl>
			</React.Fragment>
		})}
		{faqStatus.error && <ErrorEditable code={faqStatus.error.error} />}
	</TitlePanel>
}

export default FAQ;