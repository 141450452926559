import React from "react";
import { hocDialog } from "controls/dialogs";
import { devConsoleListeners } from "../../devConsoleListeners";
const dialogConnect = hocDialog({name: "DevConsole", fullscreen: true, title: "DevConsole"});

export const DevDialog = dialogConnect(() => {
	const details: any = {};
	return <>
		<table>
			<thead>
				<tr>
					{Object.keys(details).map(key => <th key={key}>{key}</th>)}
				</tr>
			</thead>
			<tbody>
				<tr>
					{Object.keys(details).map(key => {
						const detail = details[key];
						return <th key={key}>{
							typeof detail==="object" ? JSON.stringify(detail) : detail
						}</th>
					})}
				</tr>
			</tbody>
		</table>
		<div className="flex-column">
			{devConsoleListeners.list}
		</div>
	</>
});