import React from "react";
import { EditableText } from "controls";
import { useServerCodes } from "api/admin/codes";

export const ErrorCodesServer = () => {
  const [codes] = useServerCodes();
  if(!codes) return null;
  return <div>
    <table>
      <thead>
        <tr>
          <th>code</th>
          <th>exception</th>
          <th>text</th>
        </tr>
      </thead>
      <tbody>
				{Object.keys(codes).map((key) => <tr key={key}>
					<td>{codes[key]}</td>
          <td style={{maxWidth: "30%"}}>{key}</td>
					<td><EditableText scope="ERRORS" as={codes[key]} /></td>
				</tr>)}
      </tbody>
    </table>
  </div>
}