import { ConfigsPublic } from "types/configs/ConfigsPublic";
import { ConfigsPrivate } from "types/configs/ConfigsPrivate";
import { ConfigsInitial } from "types/configs/ConfigsInitial";
import { useFetchOnce } from 'hooks';
import { AdminConfigs } from 'types/admin/AdminConfigs';
import { fetchJson } from "modules/fetch/modules/fetchJson";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";
const baseUrl = "/api/admin/configs/list";

const useFetchConfigs = () : FetchOnceResult<AdminConfigs> => {
  return useFetchOnce<any>(baseUrl, { method: "GET" });
}

export const useAdminConfigs = () : [
  AdminConfigs,
  {
    refresh: () => void;
    savePublic: (publicList : ConfigsPublic)=>Promise<void>;
    savePrivate: (privateList : ConfigsPrivate)=>Promise<void>;
    saveInitial: (initialList : ConfigsInitial)=>Promise<void>;
  }
] => {
  const [data,, { setData }] = useFetchConfigs();
  const refresh = async () => {
    const newData = await fetchJson(baseUrl);
    setData(newData);
  };

  const saveIt = async({name, url, configs} : { name : string, url: string, configs : any}) => {
    setData({
      ...data,
      [name]: configs
    });
    try {
      const result = await fetchJson(url, {
        method: "POST",
        body: {
          configs
        }
      })
      setData(result);
    }
    catch(error) {
      await refresh();
    }
  }

  const savePublic = async (publicList : ConfigsPublic) => {
    return await saveIt({
      name: "publicList",
      configs: publicList,
      url: "/api/admin/configs/public"
    })
  }
  const savePrivate = async (privateList : ConfigsPrivate) => {
    return await saveIt({
      name: "privateList",
      configs: privateList,
      url: "/api/admin/configs/private"
    });
  }

  const saveInitial = async (initialList : ConfigsInitial) => {
    return await saveIt({
      name: "initialList",
      configs: initialList,
      url: "/api/admin/configs/initial"
    });
  }

  return [
    data,
    {
      savePublic,
      savePrivate,
      saveInitial,
      refresh
    }
  ]
}