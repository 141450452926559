import React from "react";
import { Link } from "controls";

// export class MenuItem extends React.Component {
// 	render() {
// 		const { to, href, onClick, children, ...rest } = this.props;
// 		return <li>
// 			{to && <Link href={to} {...rest}>{children}</Link>}
// 			{href && <a href={href} {...rest}>{children}</a>}
// 			{onClick && <a onClick={onClick} {...rest}>{children}</a>}
// 		</li>
// 	}
// }

export const MenuItem = ({ to, href, onClick, children, ...rest } : any) => {
	return <li>
	{to && <Link href={to} {...rest}>{children}</Link>}
	{href && <a href={href} {...rest}>{children}</a>}
	{onClick && <a onClick={onClick} {...rest}>{children}</a>}
</li>
}