const millisecondsInDay = 86400000;

const daysToMilliseconds = (days: any) => days*millisecondsInDay;
// const millisecondsToDays = (ms) => parseInt(ms/millisecondsInDay);

const setCookie = (name: any, value: any, days: any) => {

	const expires = (() => {
		if(!days) return "";
		const date = new Date();
		date.setTime(date.getTime() + daysToMilliseconds(days));
		return "; expires=" + date.toUTCString();
	})();

	document.cookie = `${name}=${value || ""}${expires}; path=/`;
}
const getCookie = (name: any) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i<ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0) ===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
const eraseCookie = (name: any) => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
}

export const cookies = {
	get: (key: any) => getCookie(key),
	set: (key: any, value: any, validDays=365) => setCookie(key, value, validDays),
	remove: (key: any) => eraseCookie(key),
	renew: (key: any, validDays=365) => setCookie(key, getCookie(key), validDays)
}