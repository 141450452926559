

import React from "react";
import "./index.scss"
import { TitlePanel, Tabs } from "controls";
import { LOADING_STR } from 'consts';
import { useReservationId, useReservationStatus, useReservationSelection } from "api";
import { useInvoiceForm } from 'api/invoice/useInvoiceForm';
import { InvoiceForm } from "./InvoiceForm";
import { ReservationSeatsView } from "../ReservationSeatsView";
const hiddenTabStyle = { position: "absolute", top: 0, opacity: 0, visibility: "hidden" }

export const InvoiceHolder = ({style} : any) => {
	return <div style={style} className="reservation-data-container">
		<InvoiceForm />
	</div>
}

export const ReservationDataView = (props : any) => {
	const [{ syncErrors }] = useInvoiceForm();
	const [reservationId] = useReservationId();
	const [{ loading: loadingReservation }] = useReservationStatus();
	const [{ passengersTab }, { loading: loadingSelection }, { save: saveSelection }] : any = useReservationSelection();
	const loading = loadingSelection || loadingReservation;
	const selectedTab = !!reservationId ? passengersTab : 0;

	return <div {...props} className={"panel-payment-data"}>
		<TitlePanel
			title={"Passageiros"}
			id="passengers"
			className={`panel-passengers no-overflow center-title ${!!syncErrors ? " invalid-invoice" : ""}`} // ${!!syncErrors.passengers ? " invalid-passengers" : ""}
			loading={loading ? LOADING_STR : false}
		>
			<Tabs
				key={passengersTab}
				defaultTab={selectedTab}
				onChange={async (passengersTab : any) => {
					saveSelection({ passengersTab });
				}}
			>
				{[
					{ button: "Dados Reserva", layout: () : any => null },
					{ disabled: !reservationId, button: "Lugares", layout: () : any => null }
				]}
			</Tabs>
			<InvoiceHolder style={selectedTab === 0 ? {} : hiddenTabStyle} />
			<ReservationSeatsView style={selectedTab === 1 ? {} : hiddenTabStyle} />
		</TitlePanel>
	</div>
}