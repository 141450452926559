import React, { useMemo } from "react";
import { TableSchemed, ErrorEditable, Loader } from "controls";
import { useDashboardList } from "api";
import { useSaleInfoId } from "api";
import { useVoucherNavigation } from "api";
import { ItemsMergedData } from "./ItemsMergedData";
import { originDestinoFromItem } from "./originDestinoFromItem";
import { dateOfTrip } from "./dateOfTrip";
import { LOADING_STR } from "consts";
import { datetimeStringify } from "modules/datetime";

export const SalesPanelTable = () => {
	const [sales, { success, error, loading }] = useDashboardList();
  const [, setSaleInfoId] = useSaleInfoId();
	const [, setVoucherId] = useVoucherNavigation();

  const items : ItemsMergedData[] = useMemo(() => {
		if(!success) return;
		const reservations = (success && sales && sales.items) || [];
		const itemsTemp : ItemsMergedData[] = [];
		reservations.forEach(reservation => {
			reservation.Items.forEach((sub) => {
				if(!sub) {
				}
				itemsTemp.push({
					...sub,
					reservation
				});
			})
		});
		return itemsTemp;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sales]);

	if(!success) return null;
	if(loading) return <Loader text={LOADING_STR} />
  if(error) return <ErrorEditable code={error.error} />
  return <TableSchemed
		style={{ width: "100%", maxHeight: 20 + 16*(items && items.length) }}
		items={items}
		settings={{
			colored: "2x",
			primary_header: true
		}}
		head={{
			schema: () => ["Data","Cód. Reserva","Nº Venda","Nº Bilhete / Voucher","Data da Viagem","Origem/Destino","Valor(€)", "Operador"]
		}}
		body={{
			schema: (item : ItemsMergedData) => {
				const { reservation, Type, ItemID, IsDone,  Value }: any = item;
				const { TimeSale, SRPOID, TimeTktCancel, OrderID, SaleID, Account } = reservation;
				const errorCN = TimeTktCancel ? "error" : "";
				const TimeSaleDate = TimeSale && TimeSale.length>0 ? new Date(TimeSale) : null;
				return {
					className: `${errorCN}`,
					onClick: async () => {
						if(Type===0) await setSaleInfoId(SRPOID);
						if(Type===1) await setVoucherId(SRPOID);
					},
					items: [
						{ children: TimeSaleDate ? datetimeStringify(TimeSaleDate).toFieldDateTimeString() : "" },
						{ children: ""+OrderID },
						{ children: ""+SaleID },
						{ children: <>
							{IsDone ? "" : <img  alt="exclamation" src='images/icons/exclamation.png'/>}
							{ItemID}
						</> },
						{ children: dateOfTrip(item) },
						{ children: originDestinoFromItem(item) },
						{ children: Value && (+Value).toFixed(2) },
						{ children: Account ? Account.Name : " - " },
					]
				}
			}
		}}
	/>
}