import React from "react";
import { uploadFile, convertFileTo64 } from "modules";
import "./index.scss";
import { FilePickerInputProps } from './FilePickerInputProps';

export const FilePickerInput = ({value, onChange, id} : FilePickerInputProps) => {
	const onClickRoot = async () => {
		try {
			const uploadedFile = await uploadFile();
			if(!uploadedFile) {
				onChange(null);
				return;
			}
			const { name } = uploadedFile;
			const base64 = await convertFileTo64(uploadedFile);
			onChange({ name, base64 });
		}
		catch(error) {
			console.error(error);
		}
	}
	const { name } = value || {};
	return <div id={id} className="file-picker" onClick={onClickRoot} key={name}>
		<span className="file-name">{name}</span>
	</div>
}

export { FilePickerInputProps }