import { useLiquidationState } from "./useLiquidationState";
import { useLiquidationRefresher } from "../../hooks/refreshers";
import { useFetchOnce } from "hooks";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export const useLiquidationNotes = () : FetchOnceResult<any> => {
	const [{ year, month, companyUser, accState }] = useLiquidationState();
	const [liquidationRefreshKey] = useLiquidationRefresher();
	const body = {
		year,
		month,
		companyUser,
		accState
	}
	const lock = (
		!year && !accState
	)
	return useFetchOnce<any>("/api/liquidation/notes", { method: "GET", refreshKey: liquidationRefreshKey, lock, body });
}