import React from "react";
import { StackPanel } from "controls"; // Loader
import { EditUserButton } from "../controls";
import { FieldTextEditable, FieldHiddenSimple, FieldSelectEditable, FormConfirm, FormErrorsContainer } from "forms";
import { reduxForm, SubmissionError } from "redux-form";
import { useReduxFormSelector } from "forms";
import { useConfigurationHierarchy, useConfigurationManagers, useConfigurationAgent } from "api/configuration";

const connector = reduxForm({
	form: "companyForm",
	enableReinitialize: true,
	onSubmit: async(values, a, { save } : any) => {
		try {
			await save(values);
		}
		catch(error) {
			throw new SubmissionError({_error: error.error});
		}
	}
})
export const FormCompany = connector(({	form }) => {
	const [hierarchy] = useConfigurationHierarchy();
	const [{ values: { companyManager } }] = useReduxFormSelector(form);
	const [{ values: { Manager } }] = useReduxFormSelector("companyForm");
	const [managers] = useConfigurationManagers();
	const [,, { edit: editAgent }] = useConfigurationAgent();
	const companyManagersVisible = true;
	return <form className={`${form} block-form`}>
		
		<StackPanel>
			<FieldHiddenSimple
				name="ID"
			/>
			<FieldHiddenSimple
				name="parentId"
			/>
			<FieldTextEditable
				name="Name"
				required
			/>
			<FieldTextEditable
				name="Abrv"
				required
			/>
			{companyManagersVisible && <FieldSelectEditable
				name="Manager"
				afterLabel={<EditUserButton onClick={() => editAgent(Manager)} />}
				items={managers && managers.map((item : any)=>({...item, value: ""+item.ID, text: item.Name}))}
				onChange={()=>{
					const prevValue = companyManager;
					if (prevValue === hierarchy.Company.Manager) {
						alert('Atenção! Se alterar o gestor deixará de ter acesso à gestão da empresa.');
					}
				}}
			/>}
			<FormErrorsContainer form={form} />
			<div className="flex-end">
				<FormConfirm form={form} />
			</div>
		</StackPanel>
	</form>
});