import React from "react";
import { EditableText } from "controls";
import { ERROR_CLIENT_INVALID_PRICE_TYPES } from "consts";
import { useReservationPriceTypeValidation, useReservationTimeValidation, useReservationStatus } from "api/reservation";
import { useReservationSearchConfirmed } from "api/search";
import { useTripsTicketsSelected } from "api/ticketing/useTripsTicketsSelected";
export const ReservationInfoErrorsView = () => {
	let [isValid, validationError] = useReservationTimeValidation();
  let [{isPricesMixedButOK, isValidPriceTypes}] = useReservationPriceTypeValidation();
  let [{ error: errorReservation }] = useReservationStatus();
  const [{ outgoing_ticket, return_ticket }, { loading: tripsLoading }] = useTripsTicketsSelected();
  let [{ volta }] = useReservationSearchConfirmed();
  
  let preventPriceTypeError = (() => {
		if(tripsLoading) return true;
		if(outgoing_ticket && !return_ticket && volta) return true;
		return false;
  })()
  
  return <div className="reservation-errors flex-column error">
    {!isValid && <div className="error-item">
				{validationError}
		</div>}
    {isPricesMixedButOK && !tripsLoading && <EditableText
      tag="span"
      className="error-item"
      style={{fontWeight: "bold", color: "black"}}
      scope="RESERVATION"
      as={"PRICE_WILL_CHANGE"}
    />}
    {!isValidPriceTypes && !tripsLoading && !preventPriceTypeError && <EditableText
      tag="span"
      className="error-item"
      scope="ERRORS" as={ERROR_CLIENT_INVALID_PRICE_TYPES}
    />}
    {errorReservation && <EditableText
      tag="span"
      className="error-item"
      scope="ERRORS" as={errorReservation.errorDetails}
    />}
  </div>
}