import React from "react";
import * as _clientErrors from "consts/errors";
import { EditableText } from "controls";

const codes : {[key: string]: string} = _clientErrors;

export const ErrorCodesClient = () => {
  return <div>
    <table>
      <thead>
        <tr>
          <th>code</th>
          <th>text</th>
        </tr>
      </thead>
      <tbody>
				{Object.keys(codes).map((key) => <tr key={key}>
					<td>{codes[key]}</td>
					<td><EditableText scope="ERRORS" as={codes[key]} /></td>
				</tr>)}
      </tbody>
    </table>
  </div>
}