import React from "react";
import { Flex, Loader } from "controls";
import { LOADING_STR } from "consts";
import { useInvoiceClient } from 'api/invoice/useInvoiceClient';
import { usePassengersClient, useReservationSelection } from "api";
import { useOriginsOffer, useReservationSearchFormValues, useDestinationsOffer, usePassengersPrices } from "api";
import { useReservationInfo } from "api";
import { useReservationId } from "api";
import { datetimeFromDateLegacyString, datetimeStringify } from "modules";

import { useReservationSearchConfirmed } from "api";
import { useTripsTicketsSelected } from "api/ticketing/useTripsTicketsSelected";

const Row = ({title, value, blockStyle, ratio=[1,1], valueProps, children} : any) => <div className="reservation-row flex-center flex-align-center" style={{height: "100%"}}>
	<div
		className="reservation-row-label"
		style={{flex: ratio[0], whiteSpace: "nowrap", ...blockStyle}}>
		{title}
	</div>
	<div
		className="reservation-row-value"
		style={{flex: ratio[1], ...blockStyle}}
	>
		{children}
		{value && <span {...valueProps}>{value}</span>}
	</div>
</div>

const legacyDateToFieldDate = (date : Date) => {
	return datetimeStringify(datetimeFromDateLegacyString(date)).toFieldDateUtcString()
}

const useValidOrigin = () : [string]=> {
	const [{ origin: searchOrigin }] = useReservationSearchConfirmed();
	const [{ origin: formOrigin }] = useReservationSearchFormValues();
	const origin = formOrigin ? formOrigin : searchOrigin;
	const [origins] = useOriginsOffer();
	const originOK = origins && origins.filter(c=>c.id===origin).length>0 && origin;
	return [originOK];
}

export const ReservationInfoPanelView = () => {
	const [reservationId] = useReservationId();
	const [info] = useReservationInfo();
	const { outgoing_itinerary, return_itinerary } = info || {};
	const [{ clientDoc }] = useInvoiceClient();
	const [{ origin, destination, ida, volta }] = useReservationSearchConfirmed();
	const [passengers] = usePassengersClient();
	const [origins] = useOriginsOffer();
	const [validOrigin] = useValidOrigin();
	const [destinations] = useDestinationsOffer({ origin: validOrigin });
	const [{ total }] = usePassengersPrices();
	const [{ outgoing_ticket, return_ticket }, { loading: tripsLoading }] : any = useTripsTicketsSelected();
	const originName = (() => {
		if(outgoing_itinerary) {
			return outgoing_itinerary.from.name;
		}
		if(outgoing_ticket) {
			return outgoing_ticket.from.name;
		}
		if(origins) {
			const first0 = origins.filter(c=>c.id===origin);
			const first = first0 && first0[0];
			if(first) return first.name;
		}
		return "-";
	})();

	const destinationName = (() => {
		if(outgoing_itinerary) {
			return outgoing_itinerary.to.name;
		}
		if(outgoing_ticket) {
			return outgoing_ticket.to.name;
		}
		if(destinations) {
			const first0 = destinations.filter(c=>c.id===destination);
			const first = first0 && first0[0];
			if(first) return first.name;
		}
		return "-";
	})();

	const idaValue = (() => {
		
		if(outgoing_itinerary) {
			const firstLeg : any = outgoing_itinerary.legs[0];
			const firstStop : any = firstLeg && firstLeg.stops[0];
			if(firstStop) {
				return `${legacyDateToFieldDate(firstStop.departure.date)}-${firstStop.departure.time}`;
			}
		}
		if(outgoing_ticket) {
			return `${legacyDateToFieldDate(outgoing_ticket.date)}-${outgoing_ticket.departure_time}`;
		}
		return datetimeStringify(ida).toFieldDateTimeUtcString();
	})();

	const voltaValue = (() => {
		if(return_itinerary || outgoing_itinerary) {
			if(!return_itinerary) return null;
			const firstLeg : any = return_itinerary.legs[0];
			const firstStop : any = firstLeg && firstLeg.stops[0];
			if(firstStop) {
				return `${legacyDateToFieldDate(firstStop.departure.date)}-${firstStop.departure.time}`;
			}
		}
		if(outgoing_ticket || return_ticket) {
			if(!return_ticket) return null;
			return `${legacyDateToFieldDate(return_ticket.date)}-${return_ticket.departure_time}`;
		}
		if(ida || volta) {
			if(!volta) return null;
			return datetimeStringify(volta).toFieldDateTimeUtcString()
		}
		return null;
	})();

	const passengersCountValue = (()=>{
		if(passengers) {
			return passengers.length;
		}
	})();

	
	const PrintZebraCheck = () => {
		const [{printZebra},,{save}] : any = useReservationSelection();
		return <Flex gap={5}>
			<label className="flex-center flex-align-center">Escolha de impressora</label>
			<input
				checked={printZebra}
				type="checkbox"
				onChange={() => {
					save({printZebra: !printZebra});
				}}
				style={{width: "20px", height: "20px"}}
			/>
			<label className="flex-center flex-align-center">Star TSP100</label>
		</Flex>
	}
	return <div className="reservation-info-panel">
		{tripsLoading && <Loader text={LOADING_STR} overlay/>}
		<Flex className="grid full-size reservation-info-panel" style={{minHeight: 80}}>
			<Flex column className="columnGrid full-size" style={{position: "relative"}}>
				<Row title={"Cod. Reserva"} value={reservationId} valueProps={{className: "text-big", style: { fontSize: "large" }}}/>
				<Row title={"Nº Identificação:"} value={clientDoc}/>
				<Row title={"Nº Passageiros"} value={passengersCountValue}/>
				<Row title={"Total:"} value={`${total && total.toFixed(2)}€`} blockStyle={{fontSize: "16px"}} />
			</Flex>
			<Flex column className="columnGrid full-size">
				<Row title={"Origem:"} value={originName} ratio={[1,2]}/>
				<Row title={"Destino:"} value={destinationName} ratio={[1,2]} />
				<Row title={"Ida:"} value={idaValue} ratio={[1,2]} />
				<Row title={voltaValue ? "Volta:" : "-"} value={voltaValue} ratio={[1,2]} blockStyle={{fontSize: "16px"}} />
			</Flex>
		</Flex>
		<hr className="dotted double"/>
		<Flex row gap={5}>
			<PrintZebraCheck />
		</Flex>
	</div>
}