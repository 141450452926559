import React from "react";
import { TableSchemed } from "controls";
import { useClientInfo, useClientInfoRouting }from "api";
import { useRouteQueryKey } from "hooks";
import { APIDTO } from '@card4b/apidto-ts';

export const CustomersTable = () => {
	const [, setClientInfo] = useClientInfoRouting();
	const [,setDialogOpened] = useRouteQueryKey("clientinfo");
	const [{ customers, customer, customerId }] = useClientInfo();
	return <React.Fragment>
		{customers && <TableSchemed
			items={customers}
			settings={{ primary_header: true, hoverable: true }}
			head={{
				schema: () => ["Nº Cliente", "Nome", "Nº Tlf.", "Localidade", "Email", "Perfil Cliente", "Tipo"]
			}}
			body={{
				schema: (item : APIDTO.User.Customer, index : number) => {
					const { Email, Name, CustomerNumber, RFLEXCard, ProfileId, Address, CellphoneNumber } = item || {};
					const { CardNumber } = RFLEXCard || {};
					const { PostalCodeDescription } = Address || {};
					return {
						onClick: () => setClientInfo({ customerId: ""+CardNumber}),
						selected: ""+customerId===""+CardNumber,
						items: [CustomerNumber, Name, CellphoneNumber, PostalCodeDescription, Email, ProfileId, "Cliente"]
					}
				}
			}}
		/>}
		<div style={{display: "flex", justifyContent: "center"}}>
			<button
				className="btn-primary"
				onClick={async ()=>{
					setDialogOpened(false);
				}}
				disabled={!customers || !customer || customers.length===0}
			>
				Escolher Cliente
			</button>
		</div>
	</React.Fragment>
}