import React from "react";
import "./index.scss";
import { List } from "../List";
import { Routes } from "../Routes";
import { API } from "types";

interface PanelProps {
  direction: API.LISTS.RouteDirection;
}

export const Panel = ({ direction } : PanelProps) => {
  return <div className="trips-list">
    <List direction={direction}/>
    <Routes direction={direction} />
  </div>
}