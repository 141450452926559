import { useVoucherNavigation } from './useVoucherNavigation';
import { FETCH } from 'types';
import { APIDTO } from "@card4b/apidto-ts";
import { fetchJson } from 'modules/fetch';
import { CreateVoucherRequest} from "./types";
import { useStateNamed } from "hooks";
import { useVoucherRefresher } from './useVoucherRefresher';

export const useVoucherCreateStatus = () : [FETCH.FetchOnceStatus, (status : FETCH.FetchOnceStatus) => void] => {
  const [status, setStatus] = useStateNamed<FETCH.FetchOnceStatus>("useVoucherCreateStatus", {});
  return [status, setStatus];
}

export const useVoucherCreate = () : [FETCH.FetchOnceStatus, (body : CreateVoucherRequest) => Promise<APIDTO.API.Voucher>] => {
  const [, refreshVoucher] = useVoucherRefresher();
  const [, setVoucherId] : any = useVoucherNavigation();
  const [status, setStatus] = useVoucherCreateStatus();
  const createVoucher = async(body : CreateVoucherRequest) : Promise<APIDTO.API.Voucher> => {
    try {
      await setStatus({ loading: true });
      await refreshVoucher();
      const response = await fetchJson<any>("/api/ticketing/voucher/createVoucher", { method: "POST", body });
      await refreshVoucher();
      await setVoucherId(response.id);
      await setStatus({ loading: false, success: true })
      return response;
    }
    catch(error) {
      console.error(error);
      await setStatus({loading: false, success: false, error});
      throw error;
    }
  }
  return [status, createVoucher]
}