import React from "react";
import { TableOld } from "controls";
import "./index.scss";
// import { useRouteQueryKey } from "hooks";
import { useSaleInfoId, useSalesSales, useVoucherNavigation } from "api";
import { datetimeStringify } from "modules";

export const SalesTable = (props : React.HTMLAttributes<{}>) => {
	const [, setSaleInfoId] = useSaleInfoId();
	const [, setVoucherId] = useVoucherNavigation();
	const [items, { success: itemsSuccess}] = useSalesSales();
	if(!itemsSuccess) return null;
	return <div {...props} className={"sales-data"}>
		<TableOld
			isDefault={true}
			className="sales-list-table"
		>
			<thead>
				<tr>
					<th>Data/Hora</th>
					<th>Cód. Reserva</th>
					<th>Nº Venda</th>
					<th>Nº Bilhete / Voucher</th>
					<th>Valor (€)</th>
					<th>Operador</th>
				</tr>
			</thead>
			<tbody>
				{itemsSuccess && items && items.map((item, index) => {
					const cn = index%2!==0 ? "colored-old" : "";
					const cn2 = !!item.Reservation.TimeTktCancel ? "error" : "";
					const cn3 = "";
					// const cn3 = srpoId === item.Reservation.ID ? "selected" : "";
					const { Reservation, Type } = item;
					const { SRPOID } = Reservation;
					const TimeSale : string = Reservation.TimeSale as any;
					const TimeSaleDate = TimeSale && TimeSale.length>0 ? new Date(TimeSale) : null;

					return <tr key={index} className={`${cn} ${cn2} ${cn3}`} onClick={
						async () => {
							if(Type===0) await setSaleInfoId(SRPOID);
							if(Type===1) await setVoucherId(SRPOID);
						}
						}>
						<td>{TimeSaleDate!==null && datetimeStringify(TimeSaleDate).toFieldDateTimeString()}</td>
						<td>{item.Reservation.OrderID}</td>
						<td>{item.Reservation.SaleID}</td>
						<td>{item.ItemId}</td>
						<td>{!!item.Value ? (+item.Value).toFixed(2) : item.Value}</td>
						<td>{!!item.Reservation.Account ? item.Reservation.Account.Name : " - "}</td>
					</tr>
				})}
			</tbody>
		</TableOld>
	</div>
}