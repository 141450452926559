import { useStateNamed } from 'hooks';
import { randomGuid } from "modules/random";

const cache : {[key: string]: string;} = {};

/**
 * Used to create new GUID each time refresh function is called
 * @param uniqueName name for guid key
 * @returns [GUID string]
 */
export const useGuidNamed = (uniqueName : string) : [string, () => Promise<void>] => {
  const [state, setState] = useStateNamed(uniqueName, cache[uniqueName]);
  return [
    state,
    async () => {
      setState(randomGuid())
    }
  ]
}