import React from "react";
import { FormErrorsContainer } from "forms";
import { PROCESSING_STR } from "consts";
import { StackPanel } from "controls";
import { FieldPasswordEditable, FormConfirm } from "forms";
import { reduxForm } from "redux-form";

interface ChangePasswordFormValues {
	oldPwd: string;
	newPwd: string;
	confirmNewPwd: string;
}

export const ChangePasswordForm = reduxForm<ChangePasswordFormValues>({
	form: "changePasswordForm"
})(({
	form,
	submitSucceeded,
	pristine,
	valid,
	error,
	submitting
}) => {
	const submitDisabled = (!valid && !error) || pristine || submitting;

	return <form className={form}>
		<div className="margin-bottom-8">
			<label style={{fontWeight: "bold"}}>
				Alteração Password
			</label>
		</div>
		<StackPanel count={2} gap={8}>
			<FieldPasswordEditable
				name="oldPwd"
				placeholder="Password Antiga"
				required
			/>
			<div></div>
			<FieldPasswordEditable
				name="newPwd"
				placeholder="Password Nova"
				required
			/>
			<FieldPasswordEditable
				name="confirmNewPwd"
				placeholder="Confirmar Password"
				required
			/>
		</StackPanel>
		<div className="flex-between">
			<FormErrorsContainer form={form}/>
			{valid && !error && <React.Fragment>
				{!submitting && submitSucceeded && /*changePwdSubmit && */<span>Password alterada com sucesso</span>}
				{submitting && <span>{PROCESSING_STR}</span>}
			</React.Fragment>}
			&nbsp;
			<FormConfirm form={form} disabled={submitDisabled} />
		</div>
	</form>
})