import React from "react";
import "./index.scss";
import clsx from "clsx";
import { arraySumNumbers, arrayToArray } from "modules/array";
interface FlexProps extends React.HTMLAttributes<{}> {
	ratio?: any;//(boolean | number[]);
	center?: boolean;
	between?: boolean;
	column?: boolean;
	row?: boolean;
	fullWidth?: boolean;
	gap?: number;
	ref?: any;
	count?: number;
}

export const Flex : React.FunctionComponent<FlexProps> = ({
	className,
	children,
	ratio,
	center,
	column,
	between,
	fullWidth,
	gap,
	style,
	// eslint-disable-next-line no-unused-vars
	// dispatch,
	row,
	// eslint-disable-next-line no-unused-vars
	ref,
	...rest
} : FlexProps) => {
	const type = column ? "column": "row";
	const childrenNormalized = arrayToArray(children);
	const count = childrenNormalized.length;
	if(ratio===true) {
		ratio=[];
		for(let i=0; i<count; i++) { ratio.push(1); }
	}
	if(between) { className = ` ${clsx(className)} flex-between`;}
	if(center) { className = ` ${clsx(className)} flex-center`;}
	if(fullWidth) { className = ` ${clsx(className)} full-width`;}

	return <div style={style} {...rest} className={`flex flex-${type} ${clsx(className)}`}>
		{childrenNormalized.map((child, cIndex) => {
			if(!child) return null;
			let itemStyle : React.CSSProperties = {};
			if(ratio) {
				const totalRatio = arraySumNumbers(ratio);
				const ratioPerc = ratio[cIndex] * 100 / totalRatio;
				if(type==="row") {
					itemStyle.width = `${ratioPerc}%`
				}
				if(type==="column") {
					itemStyle.height = `${ratioPerc}%`
				}
			}
			if(gap) {
				const halfGap = gap / 2;
				const cellIndex = cIndex % count;
				if(type==="row") {
					if(cellIndex!==0) itemStyle.marginLeft = `${halfGap}px`;
					if(cellIndex!==count-1) itemStyle.marginRight = `${halfGap}px`;
				}
				if(type==="column") {
					if(cellIndex!==0) itemStyle.marginTop = `${halfGap}px`;
					if(cellIndex!==count-1) itemStyle.marginBottom = `${halfGap}px`;
				}
			}
			const { dispatch, ...childProps } = child.props;
			if(!child) return null;
			return React.cloneElement(child, {
				...childProps,
				key: cIndex,
				style: {
					...childProps.style,
					...itemStyle
				}
			});
		})}
	</div>
}