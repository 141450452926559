import { useReservationInfo } from "../reservation/useReservationInfo";
import { useSeatsReservationInfo } from "./useSeatsReservationInfo";
import { APIDTO } from "@card4b/apidto-ts";
import { DirectionItinerary } from "types/reservation/DirectionItinerary";

export type UseSeatsReservationItinerariesResult = {
	seatsItinerary: APIDTO.API.BookingSeatsItinerary
	reservationItinerary: APIDTO.API.BookingItinerary
};

export const useSeatsReservationItineraries = (direction : DirectionItinerary) : UseSeatsReservationItinerariesResult => {
	const [seatsInfo] = useSeatsReservationInfo();
	const [reservationInfo] = useReservationInfo();
	const seatsItinerary = seatsInfo && seatsInfo[direction];
	const reservationItinerary = reservationInfo && reservationInfo[direction];
	return { seatsItinerary, reservationItinerary }
}