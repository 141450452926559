import { FETCH } from 'types';
import { useReservationRegister, useReservationConfirm, useReservationCancel } from "api";

export const useReservationStatus = () : [FETCH.FetchOnceStatus] => {
	const [, { loading: cancelLoading, success: cancelSuccess, error: cancelError },] = useReservationCancel();
	const [, { loading: registerLoading, success: registerSuccess, error: registerError },] = useReservationRegister();
	const [, { loading: confirmLoading, success: confirmSuccess, error: confirmError },] = useReservationConfirm();

	const loading = cancelLoading || registerLoading || confirmLoading;
	const success = cancelSuccess || registerSuccess || confirmSuccess;
	const error = cancelError || registerError || confirmError;

	return [
		{ loading, success, error },
	]
}