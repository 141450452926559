import { BookingSeatsLegGrouped } from "types/reservation/BookingSeatsLegGrouped";

export interface BookingSeatsBusSelection {
	reserved?: boolean;
	nr?: number;
	passenger?: string;
	available?: boolean;
	blocked?: boolean;
	flex?: boolean;
}

type BusSelectionResult = { [index : number] : BookingSeatsBusSelection };

export const convertLegIntoBusSeats = (leg : BookingSeatsLegGrouped) : BusSelectionResult => {
	const res : BusSelectionResult = {};
	// const size = seatsInMap(leg && leg.seat_map);
	// const size = parseInt(`${leg && leg.seat_map}`.substring(1));

	// for (let i = 1; i <= size; i++) {
	// 	res[i] = { nr: i };
	// }
	leg && leg.assigned_seats && leg.assigned_seats.forEach(seat => {
		if(!res[seat.seat_no]) res[seat.seat_no] = { nr: seat.seat_no }
		res[seat.seat_no].reserved = true;
		res[seat.seat_no].passenger = seat.passenger_id;
	});
	leg && leg.available_seats && leg.available_seats.forEach(seat => {
		for (let i = seat.first; i <= seat.last; i++) {
			if(!res[i]) res[i] = { nr: i }
			if (res[i]) res[i].available = true;
		}
	});
	// for (let i = 1; i <= size; i++) {
	// 	if (res[i].available) continue;
	// 	if (res[i].reserved) continue;
	// 	if (res[i].passenger) continue;
	// 	res[i].blocked = true;
	// }
	// console.log(res);
	return res;
}