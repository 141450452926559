import React from "react";
import "./index.scss";
import clsx from "clsx";
import { TabsButtonItemProps } from "../types/TabsButtonItemProps";


export const TabsButtonItem = ({ name, active, disabled, ...htmlAttributes } : TabsButtonItemProps) => {
  const { className, onClick, ...restAttributes } = htmlAttributes;
  return <div
    {...restAttributes}
    onClick={e => {
      !disabled && onClick && onClick(e);
    }}
    className={`tabs-button-item ${clsx(className)} ${clsx(name)} ${clsx(active && "active")} ${clsx(disabled && "disabled")}`}
  />
}