import { useEffect } from "react";

/**
 * Functions calls handler when requestAnimationFrame is being trigerred
 * 
 * @param handler callback to be called when requestAnimationFrame trigerred
 * @returns void
 */
export const useAnimationFrame = (handler : () => {}) : void => {
	useEffect(() => {
		const animationFunc = () => {
			handler();
			h = window.requestAnimationFrame(animationFunc);
		}
		let h = window.requestAnimationFrame(animationFunc);
		return () => {
			window.cancelAnimationFrame(h);
		}
	})
}