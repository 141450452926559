import { useReservationMode } from "../reservation/useReservationMode";
import { FETCH } from 'types';
import { useTripsRefresher } from './../../hooks/refreshers';
import { useReservationSearchConfirmed } from '../search/useReservationSearchConfirmed';
import { APIDTO } from "@card4b/apidto-ts";
import { usePassengersTripsList } from "../passengers/convert/usePassengersTripsList"
import { useFetchTrips, UseFetchTripsProps } from './useFetchTrips';
import { useMemo } from 'react';
import { useUserDetails } from "api/user/useUserDetails";

export type UseTripsConfirmedResult = [
  APIDTO.API.ScheduleTable,
  FETCH.FetchOnceStatus,
  FETCH.FetchOnceMethods
]

let prevBody : any = null;

export const useTripsConfirmed = () : UseTripsConfirmedResult => {
  const [refreshKey] = useTripsRefresher();
  const [details] = useUserDetails();
  const { nCardRFLEX } = (details && details.site) || {};
  const rflexNr = nCardRFLEX ? +nCardRFLEX : undefined;
  const [{ origin, destination, ida, volta }, { loading: loadingSearch }] = useReservationSearchConfirmed();
  const [mode] = useReservationMode();
  const [passengerList] = usePassengersTripsList();
  const lock = loadingSearch || mode!=="trips";

  const activeElement = document.activeElement;
  // const promocodes = useMemo(() => {
  //   return passengerList && passengerList.map((pass, index)=> {
  //     const elem : any = document.getElementById(`promocode${index}`);
  //     return elem ? elem.value : null;
  //   })
  // }, [passengerList])
  const activeElements = useMemo(() => {
    return passengerList && passengerList.map((pass, index)=> {
      const elem : any = document.getElementById(`promocode${index}`);
      return activeElement===elem;
    })
  }, [passengerList, activeElement])
  const isAnyActive = activeElements ? activeElements.filter(el => !!el).length>0 : false;

  const body : UseFetchTripsProps = useMemo(() => {
    return {
      refreshKey,
      rflexNr,
      origin,
      destination,
      ida,
      volta,
      lock,
      passengerList:  !passengerList ? [] : passengerList.map((pItem, index) => {
        return pItem//: !isAnyActive && promocodes && promocodes[index] ? promocodes[index] : null
      })
    }
  }, [passengerList, destination, ida, volta, origin, rflexNr, refreshKey, lock])

  if(!isAnyActive) {
    prevBody = body;
  }

  const [data, fetchStatus, fetchMeta] = useFetchTrips(prevBody);
  const { from, to, outgoing_schedules, return_schedules, fares, passengers } = data || {};

  return [
    { from, to, outgoing_schedules, return_schedules, fares, passengers },
    fetchStatus,
    fetchMeta
  ];
}