import React from "react";
import { preffix } from "./preffix";
const resolutions : any[] = [];
for(let i=300; i<=2500; i+=100) resolutions.push(i);
const resolutionMax = resolutions[resolutions.length-1];
const resolutionOver = resolutionMax+100;

let _isSupportedWebP : any = null;
const isSupportWebP = () => {
	if(typeof(_isSupportedWebP)!=="boolean") {
		const canvas : any = typeof document === 'object' ? document.createElement('canvas') : {};
		canvas.width = canvas.height = 1;
		_isSupportedWebP = canvas.toDataURL ? canvas.toDataURL('image/webp').indexOf('image/webp') === 5 : false;
	}
	return _isSupportedWebP;
}

export const ViewDynamic = ({
	filename,
	options,
	...rest
}: any) => {
	const { widthRatio, quality, size } = options || {};
	const srcset : any[] = [], sizes : any[] = [];

	const imgParams : any[] = [];
	const allowProcess = !(filename.indexOf(".svg")>=0 || filename.indexOf(".gif")>=0);
	if(typeof quality === "number" && quality<100 && allowProcess) {
		imgParams.push(`quality=${quality}`);
	}
	if(typeof size === "string") {
		imgParams.push(`size=${size}`);
	}

	const postExt = isSupportWebP() ? ".webp" : "";
	const endFilename = `${filename}${postExt}`;
	const imgParamsStr = imgParams.join(".");
	const src = `${preffix}/${imgParamsStr}/${endFilename}`;

	resolutions.forEach((s : any) => {
		const sizeScaled = s*widthRatio;
		const maxWidthStr = !size ? `maxwidth=${sizeScaled}.` : "";
		srcset.push(`${preffix}/${maxWidthStr}${imgParamsStr}/${endFilename} ${sizeScaled}w`);
		sizes.push(`(max-width: ${s}px) ${sizeScaled}px`);
	})
	srcset.push(`${src} ${resolutionOver}w`)
	sizes.push(`(min-width: ${resolutionMax}px) ${resolutionOver}px`);

	const imgProps = {
		srcSet: srcset.join(", "),
		sizes: sizes.join(", "),
		src
	};

	return <img
		alt="view"
		{...rest}
		{...imgProps}
	/>
}