import React, { useState } from "react";
import "./index.scss";
import { DownloadingStatusMask, Loader } from "controls";
import { LOADING_STR } from "consts";
import { StackPanel, LinkPrint, LinkDownload } from "controls";
import { FieldTextEditable, FieldDateEditable, FieldSelectEditable } from "forms";
import { reduxForm } from "redux-form";
import { useReduxFormSelector } from "forms";
import { useApiSettings, useOrigins } from "api";
import { datetimeParse } from "modules";
import qs from "querystring"

const EMPTY_VALUE : {
	value: string;
	text: string;
  } = {
	value: null,
	text: ""
  }

const connectForm = reduxForm({
	form: "boardingTable",
	enableReinitialize: true,
	// onSubmit: (values) => {

	// }
});

const FormConfirms = () => {
	const [{ values, syncErrors }] = useReduxFormSelector("boardingTable");
	const { trip, date, reinforcement, stop }: any = values || {};
	const [state, setStateInn] = useState<any>({});
	const setState = (s: any) => setStateInn({ ...state, ...s })
	const dateCurr = date && datetimeParse(date).fromFieldDateUtcString();
	const dateRes = date && dateCurr ? `${dateCurr.getFullYear()}.${dateCurr.getMonth()+1}.${dateCurr.getDate()}` : undefined;

	const body = {
		trip,//string, but number
		date: dateRes,//date
		reinforcement,//number
		stop,//string
		printerType: "A4"
		// userId: userID,
		// isA4: false
	}

	const href = "/api/ticketing/getManifest?"+qs.stringify(body);

	return <div className="flex-column" style={{width: "100%"}} >
		<DownloadingStatusMask {...state} />
		<div className="flex-end">
			<LinkDownload
				className="btn-primary text-center"
				href={href}
				disabled={syncErrors != null}
				onStatus={(status: any) => {
					setState(status)
				}}
				style={{ whiteSpace: "nowrap", width: "auto" }}
			>
				Descarregar
			</LinkDownload>
			<LinkPrint
				className="btn-primary text-center"
				href={href}
				disabled={syncErrors != null}
				onStatus={(status: any) => {
					setState(status);
				}}
				style={{ whiteSpace: "nowrap", width: "auto" }}
			>
				Imprimir
			</LinkPrint>
		</div>
	</div>
	

}

export const FormBoardingNational = connectForm(({ form, onConfirm, valid }: any) => {
	const [settings] = useApiSettings();

	const days = settings ? +settings["MAX_ADVANCE_DAYS"] : 10;
	const reservationDateLimit = days;

	const [origins, { loading: loadingOrigins }] = useOrigins({ offer: "national" });
	const originsShown = origins ? [EMPTY_VALUE, ...origins.map(c=>({...c, value: c.id}))] : [EMPTY_VALUE];

	return <div>
		{loadingOrigins && <Loader text={LOADING_STR} overlay />}
		{/* <DownloadingStatusMask {...state} /> */}
		<form className={`${form}`}>
			<StackPanel style={{ maxWidth: "500px" }}>
				<FieldTextEditable
					name="trip"
					required
					normalize={value => {
						if(value && value.length>5) {
							return value.substr(0, 5);
						}
						return value;
					}}
				/>
				<FieldDateEditable
					name="date"
					id={"boarding-date"}
					maxDate={"+" + reservationDateLimit + "D"}
					required
				/>
				<FieldTextEditable
					name="reinforcement"
					className="form-value"
					required
				/>
				<FieldSelectEditable
					name="stop"
					filter={true}
					className="form-value"
					items={originsShown}
				/>
				<FormConfirms />
			</StackPanel>
		</form>
	</div>
})