import React from "react";
import { DEFAULT_TITLE } from "consts";
import { FormBoardingNational } from "./FormBoardingNational";
import { FormBoardingInternational } from "./FormBoardingInternational";
import { usePage, useRouteQueryKey } from "hooks";
import "./index.scss";
import { useBoardingSearch } from "api/boarding";
import { TabsContainer, EditableText, TitlePanel } from "controls";

export const BoardingTable = () => {

	 const [boardingOffer] = useRouteQueryKey("boardingOffer");


	usePage({
		name: "BOARDING",
		options: {
			title: DEFAULT_TITLE
		}
	});

	const [data,,{save}] = useBoardingSearch();


	const getInternationalFormProps = () => {
		return {
			initialValues: data,
			onConfirm: save
		}
	}


	return <TitlePanel
		type={"dark"}
		title={"Folha de Embarque"}
		className="boarding-table"
		contentProps={{ id: "BoardingTableContainer" }}
		// loading={loadingCodes && LOADING_STR}
	>
		<TabsContainer
			className="boarding-tabs"
			routed={{
				query: "boardingOffer",
				buttons: [
					{ name: "national", children: <EditableText scope="Search" as="TabNational"/>, active: boardingOffer!=="international" },
					{ name: "international", children: <EditableText scope="Search" as="TabInternational"/>, active: boardingOffer==="international" },
				]
			}}
		>
			{boardingOffer!=="international" && <FormBoardingNational {...getInternationalFormProps()}/>}
			{boardingOffer==="international" && <FormBoardingInternational {...getInternationalFormProps()}/>}
		</TabsContainer>
	</TitlePanel>
}

export default BoardingTable;