import { useStateNamed } from 'hooks/state/useStateNamed';
import { useEffect, useState } from 'react';
import { FetchOnceStatus } from '../types/FetchOnceStatus';
/**
 * Hook receives FetchOnceState and return FetchOnceState, but success and error will expire in <timeout> time
 * 
 * @param {FetchOnceStatus} state - current unwrapped fetch status
 * @param {number} timeoutMS - timeout to expiry fetch status
 */
export const useFetchStatusTimeout = (name : string, state : FetchOnceStatus, timeoutMS : number) : [FetchOnceStatus] => {
  const [currentState, setCurrentState] = useStateNamed<any>(name, null);
  const [counter] = useState<any>(0);
  useEffect(() => {
    const isRequireApply = state && (state.loading || state.success || state.error);
    if(isRequireApply) {
      setCurrentState(state);
      if(!state.loading) setTimeout(() => setCurrentState(null), timeoutMS);
    }
    else {
      setCurrentState(null);
    }
  }, [
    state && state.loading,
    state && state.success,
    state && state.error,
    counter,
    timeoutMS,
  ]);
  return [currentState];
}