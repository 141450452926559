import React from "react";
import { Flex, ReservationConfirmButton, ReservationRegiserButton, ReservationCancelButton } from "controls";
import { useUserDetails } from "api/user/useUserDetails";

export const ReservationActionButtonsView = () => {
	const [{ agentCompany }] = useUserDetails();
  	const isAllowSale = agentCompany && agentCompany.Status===3;
	return <Flex column gap={20} between className="flex-between">
		<Flex column className="full-size flex-end">
			<Flex className="full-width flex-between">
				{isAllowSale && <ReservationRegiserButton />}
				{isAllowSale && <ReservationConfirmButton />}
				<ReservationCancelButton />
			</Flex>
		</Flex>
	</Flex>
}