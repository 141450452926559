import React, { useEffect } from "react";//,{ useMemo }
import "./index.scss";
import { TitlePanel, ErrorEditable, TableSchemed } from "controls";
import { LOADING_STR } from "consts";
import { API } from "types";
import { useTicketsSchema } from "consts/schemas";
import { APIDTO } from "@card4b/apidto-ts";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";

type HandlerType = () => any;
class TripsStateClass {

  item?: APIDTO.API.DateSchedule;
  handlers : HandlerType[] = [];
  isValidating = false;

  validate = () => {
    if(this.isValidating) return;
    this.isValidating = true;
    this.handlers.forEach((h) => {
      setTimeout(h);
    })
    this.isValidating = false;
  }

  subscribe = (handler: HandlerType, item?: APIDTO.API.DateSchedule) => {
    this.handlers = this.handlers.filter(h => h!==handler);
    this.handlers.push(handler);
    this.item = item;

    if(!item) return;

    const minuteDepartureStr = item.departure_time.split(":")[1];
    
    const now = new Date();
    const secondsDeparture = 60 * +minuteDepartureStr;
    const secondsCurrent = 60 * now.getMinutes() + now.getSeconds();
    const secondsLeft = secondsCurrent<secondsDeparture ? (secondsDeparture - secondsCurrent) : (secondsDeparture+3600-secondsCurrent);
    // setTimeout(() => {
    // }, [secondsLeft*1000 + 60000]);
  }
  unsubscribe = (handler : HandlerType) => {
    this.handlers = this.handlers.filter(h => h!==handler);
    this.item = undefined;
  }
}

const tripsState = new TripsStateClass();

interface ListProps {
  direction: API.LISTS.RouteDirection;
}
export const List = ({ direction } : ListProps) => {
  let [trips, { loading, error }, { refreshSilent }] = useTripsConfirmed();
  const schedules = (!trips ? [] : (direction==="ida" ? trips.outgoing_schedules : direction==="volta" ? trips.return_schedules : [])) || [];
  const [{headSchema, itemsSchema}] = useTicketsSchema(schedules);
  const title = direction==="ida" ? "Horário" : "Horário volta";
  const firstSchedule = schedules && schedules[0];

  useEffect(() => {
    tripsState.subscribe(refreshSilent, firstSchedule);
    return () => {
      tripsState.unsubscribe(refreshSilent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules, firstSchedule]);

  return <div className="trips-table">
    <TitlePanel title={title} className="center-title" loading={loading && LOADING_STR}>
      <TableSchemed
        items={schedules || []}
        settings={{
          primary_header: true,
          colored: "2x"
        }}
        head={{ schema: headSchema }}
        body={{ schema: itemsSchema }}
      />
      {error && <ErrorEditable code={error.errorDetails} />}
    </TitlePanel>
  </div>
}