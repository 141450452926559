import { FormFieldNormalizerFunc } from '../../normalize/base/FormFieldNormalizerFunc';
import { FormFieldNormalizer } from '../../normalize/base/FormFieldNormalizer';

export const mixNormalize = (normalize : FormFieldNormalizer, defaultNormalize : FormFieldNormalizerFunc[]) => (value : string) => {
  if(normalize) {
    if(typeof normalize==="function") value = normalize(value);
    if(typeof normalize==="object" && Array.isArray(normalize)) {
      normalize.forEach(normalizer => {
        value = normalizer(value);
      })
    }
  }
  if(typeof defaultNormalize==="object" && Array.isArray(defaultNormalize)) {
    defaultNormalize.forEach(normalizer => {
      value = normalizer(value);
    })
  }
  return value || "";
}