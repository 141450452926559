import { RemoteStateMethods } from './../../../modules/fetch/types/RemoteStateMethods';
import { RemoteStateStatus } from './../../../modules/fetch/types/RemoteStateStatus';
import { FETCH } from "types";
import { useRemoteState } from "hooks";
import { fetchJson } from 'modules/fetch';
import { randomGuid } from "modules/random";
import { useReservationUID } from "../../reservation/helpers/useReservationUID";
import { Passenger } from "types/passengers/Passenger";
import { RemoteStateResult } from "modules/fetch/types/RemoteStateResult";
const baseUrl = "/api/reservation/passengers";

interface PassengersMSHolder {
	passengers: Passenger[]
}

const useRemoteStatePass = () : RemoteStateResult<PassengersMSHolder>=> {
	const [id] = useReservationUID();
	const [data, status, meta] = useRemoteState({
		url: `${baseUrl}/${id}`,
		lock: !id,
		empty: { passengers: [] }
	});
	return [
		data,
		status,
		{
			...meta, 
			save: async (value) => {
				await meta.save(value)
			}
		}
	]
}

export const usePassengersMS = () : [Passenger[], RemoteStateStatus, RemoteStateMethods<Passenger[]>] => {
	const [id, { setKey }] = useReservationUID();
	const [passengersData, { loading, success, error, saving }, { save: saveOrig, refresh }] = useRemoteStatePass();
	const { passengers } = passengersData || {};
	const saveNew = async (passengers : Passenger[]) => {
		const newID = randomGuid();
		await fetchJson(`${baseUrl}/update`, { method: "POST", body: { guid: newID, passengers } });
		await setKey(newID);
	}
	const saveOld = async (passengers : Passenger[]) => {
		await saveOrig({passengers});
	}
	const save = async (passengers : Passenger[]) => {
		if(!passengers || passengers.length===0) {
			return;
		}
		if(id) await saveOld(passengers);
		else await saveNew(passengers);
	}
	return [
		passengers,
		{ success, error, saving, loading: loading || saving },
		{ refresh, save }
	]
}