import React from "react";
import { useCurrentRoute } from "react-navi";
import { useEnv } from "hooks";
import { ENV_DEV } from "consts";

export const Page = ({children}: any) => {
	const route = useCurrentRoute();
	const [{ENV_SOVE}] = useEnv();
	const { url, data } = route;
	const { component } = data;
	const { query } = url;
	const mobile = query.ismobile || query.mobileapp;
	const cn = `page ${component} ${mobile ? "mobile-app" : "web-app"} ${ENV_SOVE ? "is-sove" : "is-error"} ${ENV_DEV ? "debug" : ""}`
	return <div
		className={cn}>
		{children}
	</div>
}