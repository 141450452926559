import { randomGuid } from "modules/random";

const _hostname = window.location.hostname;
export const CONFIRM_ERROR_TIMEOUT_MS = 2000;
export const SESSION_KEEP_ALIVE_TIMEOUT_MS = 300000; //5 min
export const IS_TESTES = _hostname.indexOf("testes.rede-expressos.pt")>=0;
export const CHANGE_SEATS = "CHANGE_SEATS";

export * from "./errors";
export * from "./company";
export * from "./statuses";
export * from "./strings";
export * from "./pageMode";
export * from "./submitStatus";
export * from "./keys";
export * from "./scopes";
export * from "./enviroment";
export * from "./links";

export const LOWDB_DEFAULT_GROUP_KEY = "lowdb";
export const BROWSER_TOKEN_KEY = "browser-token";
export const DISABLED_MESSAGES_KEY = "disabled-messages";
export const BROWSER_LANG = "browser-lang";
export const IMAGE_ALTS = "IMAGE_ALTS";

export const LOW_LS_DEFAULT : {
	[BROWSER_TOKEN_KEY] : string;
	[DISABLED_MESSAGES_KEY] : string[];
} = {
	[BROWSER_TOKEN_KEY]: randomGuid(),
	[DISABLED_MESSAGES_KEY]: []
}

export * from "./zIndex";