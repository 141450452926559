import React from "react";
import { LOADING_STR } from "consts";
import { TitlePanel, ErrorEditable, EditableText } from "controls";
import { FieldSelectEditable, dispatchSubmit } from "forms";
import { useApiAgentsList, useApiAgentInfo } from "api/agents";
import "./index.scss";
import { useRouteQueryKey } from "hooks";
import { hocDialog } from "controls/dialogs";
import { reduxForm } from "redux-form";

const name = "agentsDialogForm";
export const SelectForm = reduxForm<{
	agentId: string;
}>({
	form: name,
	// enableReinitialize: true,
	// destroyOnUnmount: true,
	// forceUnregisterOnUnmount: true
})(({ form,  ...rest }) => {
	const [items] = useApiAgentsList();
	return <form className={form}>
		<FieldSelectEditable
			name="agentId"
			filter={true}
			maskOption={"{text} ({value})"}
			items={items && items.map(c => ({...c, text: c.name, value: c.id}))}
			onChange={() => {
				setTimeout(() => {
					dispatchSubmit(form);
				})
			}}
		/>
	</form>
});

const composedDialog = hocDialog({
	name: "agentsDialog",
	minWidth: 350,
	minHeight: 315,
	title: "Informação de Agentes"
})

export const AgentsDialog = composedDialog(() => {
	const [agentId_inner, setAgentsId] = useRouteQueryKey("agentsid");
	const [list, {loading: agentsLoading, success: agentsSuccess, error: agentsError }] = useApiAgentsList();
	const agentIdSelected : any = (() => {
		if(agentId_inner && agentId_inner!=="true") return agentId_inner;
		if(list && list[0]) return list[0].id;
		return null;
	})()
	const [agentObjectNew, { loading: infoLoading,  success: infoSuccess, error: infoError }] = useApiAgentInfo(agentIdSelected);
	const { POS } = agentObjectNew || {};
	const loading = agentsLoading || infoLoading || undefined;
	return <div style={{ display: "flex", flexDirection: "column" }}>
		{!loading && <SelectForm
			initialValues={{ agentId: agentIdSelected }}
			onSubmit={({agentId}) => {
				setAgentsId(agentId);
			}}
		/>}
		{agentsSuccess && <TitlePanel
			title={"Agentes"}
			loading={loading && LOADING_STR}
			style={{ margin: "0" }}
		>
			{POS && POS.map((item, index) => {
				const { name, phone, address, addressBoarding } = (item) || {}
				return (
					<React.Fragment key={index}>
						<span className="text-bold">
							{name}
						</span>
						<div>
							<br />
							{phone && <span className="text-bold">Tlf: </span>}
							{phone && <span>{phone}</span>}
							<br />
							{address && <span>{address.detail}</span>}
							<br />
							{address && <span>{address.zip_code}</span>}
							{addressBoarding && (
								<div>
									<br />
									<br />
									<span className="text-bold">Embarque: </span>
									<span>{addressBoarding.detail}</span>
								</div>
							)}
						</div>
						<br />
						<br />
					</React.Fragment>
				);
			})}
			{infoSuccess && !agentObjectNew && <EditableText scope="AGENTS_DIALOG" as="AGENT_ERROR_MESSAGE" tag="span" className="error"/>}
			{infoError && <ErrorEditable code={(infoError as any).errorDetails} />}
		</TitlePanel>}
		{agentsError && <ErrorEditable code={(agentsError as any).errorDetails} />}
	</div>
})