import { fetchJson } from 'modules/fetch';
import { useAdmin } from "./useAdmin";

type UseAdminStorageResult = [any, { set: (storage: any) => {}, append: (key: string, value : any) => {} }]

export const useAdminStorage = () : UseAdminStorageResult => {
  const [admin, { refresh }] = useAdmin();
  const { userID, storage } = admin || { storage: {} };
  const set = async (newStorage : any) => {
    await fetchJson("/api/admin/admin/storage", {
      body: {
        userID,
        storage: newStorage
      },
      method: "POST"
    });
    await refresh();
  }
  const append = async (key : string, value : any) => {
    await set({...storage, [key]: value});
  }
  return [
    storage || {},
    { set, append }
  ]
}