import React from "react";
import { RootGateProps } from "../gates";
import { EditableText } from "controls";
import { Gate } from "effector-react";
import { WrappedFieldProps } from "redux-form";
import { useAdminStorage } from "api/admin";

export const FieldEditableLabel = ({ WrappedGate, gateState } : {  WrappedGate: Gate<WrappedFieldProps>, gateState: RootGateProps }) => {
  const { input, meta } = WrappedGate.state.getState();
  const [{ isEditable }] = useAdminStorage();
  const { extras, props } = gateState;
  const { required, scope } = props || {};
  const { onClickLabel } = extras || {};
  const { name } = input || {};
  const { form } = meta || {};
  const closeBracketIndex = name && name.indexOf("]");
  const fieldName = name && name.slice(+closeBracketIndex+1);
  return <div className={`field-label`} onClick={e => {
    if(isEditable) return;
    onClickLabel && onClickLabel(form, name);
  }}>
    <label>
      {required ? "*" : ""}
      <span className="label-text">
        {scope && <EditableText scope={scope} as={fieldName+"-label"}/>}
        {form && !scope && <EditableText scope={form} as={fieldName+"-label"}/>}
      </span>
    </label>
  </div>
}