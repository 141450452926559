import React from "react";
import "./index.scss";
//@ts-ignore
import { CircleMarker, Polyline, Popup } from "react-leaflet";
import { getColor } from "styleguide";

class OwnMarker extends React.Component {
	props : any;
	render() {
		const stop : any = this.props.stop;
		return (
			<CircleMarker onClick={this.props.drawConnections} center={stop.position} {...this.props}>
				{this.props.polylines ? this.props.polylines.map((item: any, index: any)=>(<Polyline key={index} weight={1} color={getColor("primary")} {...item}/>)) : null}
				<Popup><span style={{color: getColor("primary")}}>{this.props.stop.name}</span></Popup>
				{this.props.children}
			</CircleMarker>
		);
	}
}

export { CircleMarker, OwnMarker };


// {item.big && <Tooltip className='toolTip' direction='center' offset={[0, 0]} opacity={1} permanent><span>SADADS</span></Tooltip>}
//Name inside of circle marker