import React from "react";
import "./index.scss";
import { LoaderBase } from "../../LoaderBase";
import { SpinnerImg } from "./SpinnerImg";

export const LoaderSove = ({text,className,...props}: any) => {
	return <LoaderBase className={`${className} loader-sove`} {...props} RenderLoader={() => {
		return <div>
			<div>
				<div>
					<SpinnerImg />
					<span className="text-center">{text}</span>
				</div>
			</div>
		</div>
	}}/>
}