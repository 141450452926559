export enum PassengerTypesEnum {
  PASSENGER_NORMAL = "1",
  PASSENGER_CHILD = "2",
  PASSENGER_YOUTH = "4",
  PASSENGER_SENIOR = "5",
  PASSENGER_ARMY = "17",
  PASSENGER_RFLEX = "19",
  PASSENGER_ANIMAL = "42",
  PASSENGER_SPORTSBOARD = "43",
  PASSENGER_BIKE = "44",
  PASSENGER_BABE = "45"
}