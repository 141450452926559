import { BookingSeatsLegGrouped } from "types/reservation/BookingSeatsLegGrouped";
import { DirectionItinerary } from "types/reservation/DirectionItinerary";
import { UseSeatsReservationItinerariesResult } from "./useSeatsReservationItineraries";

export const useSeatsLegsGrouper = (type: DirectionItinerary, { seatsItinerary, reservationItinerary } : UseSeatsReservationItinerariesResult) : BookingSeatsLegGrouped[] => {
	const { legs : seatsLegs } = seatsItinerary || {};
	const { legs : reservationLegs } = reservationItinerary || {};
	let legs : BookingSeatsLegGrouped[] = [];
	if(seatsLegs && reservationLegs) {
		legs = seatsLegs.map((seatLeg, legIndex) => {
			const reservationLeg = reservationLegs[legIndex];
			const { stops } = reservationLeg;
			const legGrouped : BookingSeatsLegGrouped = {
				...seatLeg,
				...reservationLeg,
				type,
				legIndex: (legIndex+1),
				origin: stops[0].stop.name,
				destination: stops[stops.length - 1].stop.name,
				departure: new Date(stops[0].departure.date+"T"+stops[0].departure.time+":00.0000Z")
			}
			return legGrouped;
		})
	}
	return legs;
}