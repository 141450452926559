import { useRouteQueryKeys } from "hooks";
import { datetimeParse } from "modules/datetime";


export type UseReservationSearchConfirmedResult = [
  {
    origin: string;
    destination: string;
    ida: Date;
    volta: Date;
    reschedule_of_ida: string;
    reschedule_of_volta: string;
  },
  {
    loading: boolean;
  }
]

export const useReservationSearchConfirmed = () : UseReservationSearchConfirmedResult => {
  const [query] = useRouteQueryKeys();
 
  const { origin, destination, ida, volta, reschedule_of_ida, reschedule_of_volta } = query;
  // const [{ isRecovery, recoveryData, loading: recoveryLoading }] = useReservationRecovery();//TODO load it

  return [
    {
      origin,
      destination,
      ida: (ida && datetimeParse(ida).fromSearchDateUtcString()) || null,
      volta: (volta && datetimeParse(volta).fromSearchDateUtcString()) || null,
      reschedule_of_ida,
      reschedule_of_volta
    },
    {
      loading: false
    }
  ];
}