import React from "react";
import { EditableTextViewProps } from "../../types/EditableTextViewProps";

const ViewHTML = ({ value, isHtml, tag, lang, emptyValue, children, isEditable, ...rest } : EditableTextViewProps) => {
  const htmlValue = !tag ? (value || "") : React.createElement(tag, {
    dangerouslySetInnerHTML: {__html: value|| emptyValue },
    lang,
    ...rest
  });
  return !children ? htmlValue : children({
    value: htmlValue,
    isEditable,
    lang,
    ...rest
  });
}

const ViewNormal = ({
  value,
  isHtml,
  tag,
  lang,
  emptyValue,
  children,
  isEditable,
  ...rest
} : EditableTextViewProps) => {
  const valueFound = (value || emptyValue || "");
  if(children) {
    if(typeof children!=="function") return children;
    return children({
      ...rest,
      value: valueFound,
      isEditable,
      lang
    })
  }
  if(tag) {
    return React.createElement(tag, { lang: lang, ...rest}, valueFound)
  }
  return valueFound;
}

export const View = (props : EditableTextViewProps) => {
  if(props.isHtml) {
    return <ViewHTML {...props}/>
  }
  return <ViewNormal {...props}/>
}