import Home from "./Home";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Boarding from "./Boarding";
import RePrint from "./RePrint";
import Conditions from "./Conditions/Conditions";
import Configuration from "./Configuration";
import FAQ from "./FAQ";
import InternationalConditions from "./Conditions/IntConditions";
import PwdReset from "./PwdReset";
import SalesLiquidation from "./SalesLiquidation";
import Voucher from "./Voucher";
import InitialSetup from "./InitialSetup";

export const pages = {
  InitialSetup,
	Admin: require("admin/AdminPage/index.async"),
	Home,
	Login,
	Dashboard,
	Boarding,
	Conditions,
	Configuration,
	FAQ,
	InternationalConditions,
	PwdReset,
	SalesLiquidation,
	Voucher,
	RePrint
};