import React from "react";

type SpecificControlsProps = {
  value: any;
  onChange: (value : any) => {};
}

type SpecificControlsList = {
  [index : string] : (props : SpecificControlsProps) => JSX.Element;
}

const specificControls : SpecificControlsList = {
  "ProjectType": ({value, onChange}) => <div>
    <button className={value==="LOCAL" ? "btn-primary" : "btn-secondary"} onClick={() => onChange("LOCAL")}>LOCAL</button>
    <button className={value==="STAGING" ? "btn-primary" : "btn-secondary"} onClick={() => onChange("STAGING")}>STAGING</button>
    <button className={value==="PRODUCTION" ? "btn-primary" : "btn-secondary"} onClick={() => onChange("PRODUCTION")}>PRODUCTION</button>
  </div>,
  "ChannelID": ({value, onChange}) => <div>
    <button className={value===1 ? "btn-primary" : "btn-secondary"} onClick={() => onChange(1)}>1 - SITE</button>
    <button className={value===3 ? "btn-primary" : "btn-secondary"} onClick={() => onChange(3)}>3 - SOVE</button>
  </div>
}

export const isSpecificValue = (name : string) => {
  return !!specificControls[name];
}

export const RenderSpecific = (props : { name : string } & SpecificControlsProps) => {
  return React.createElement(specificControls[props.name], props);
}