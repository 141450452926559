import React, { useState } from "react";
import { VisibilityCheck } from "controls/primitive";
import { useAdminPermissions } from "api/admin";

export const AdminPermissions = () => {
  const [loadingKey, setLoadingKey] = useState<string | null>(null);
  const [permissions, { append: appendPermissions }] = useAdminPermissions();
  if(!permissions) return null;
  const permissionKeys = Object.keys(permissions);
  const selectedPermissions = permissionKeys.map(key=>!!permissions[key]).filter(value => !!value);

  const onPermissionClick = async (key : string) => {
    setLoadingKey(key);
    await appendPermissions(key, !permissions[key]);
    setLoadingKey(null);
  }

  return <VisibilityCheck title={`Permissions (${selectedPermissions.length} / ${permissionKeys.length})`} className="arrow-right justify-between">
    <table>
        <tbody>
          {permissionKeys.map(key => {
            const value = permissions[key];
            return <tr key={key} style={{fontWeight: value ? "bold" : "normal"}}>
              <td>{key}</td>
              <td> 
                {loadingKey!==key && <input type="checkbox" checked={value} onClick={() => {
                  onPermissionClick(key);
                }} />}
                {loadingKey===key && <i className="fa fa-spin fa-spinner"/>}
              </td>
            </tr>
          })}
        </tbody>
      </table>
  </VisibilityCheck>
}