import React from "react";
import { FieldTextEditable, FormErrorsContainer, dispatchSubmit } from "forms";
import { ButtonTyped, EditableText } from "controls";
import { useAdminStorage } from "api/admin";
import { reduxForm } from "redux-form";

const formName = "recoverPwdForm";

export const Form = reduxForm<{
	userName: string;
},{}>({
	form: formName,
	// onSubmit: async ({userName},a,{resetPassword}) => {
	// 	try {
	// 		await resetPassword({userName});
	// 	}
	// 	catch(error) {
	// 		console.error(error);
	// 		throw new SubmissionError({_error: error.error});
	// 	}
	// }
})(({
	form,
	pristine,
	valid,
	error,
	submitting,
	submitSucceeded
} ) => {
	const submitDisabled = (!valid && !error) || pristine || submitting;
	const [{isEditable}] = useAdminStorage();
	return <form className={form}>
		<FieldTextEditable
			name="userName"
			placeholder="Utilizador"
			required
			// validate={[requiredValidate]}
		/>
		<div className="flex-end">
			<ButtonTyped styling="primary" disabled={submitDisabled} onClick={()=>dispatchSubmit(form)}>Recuperar</ButtonTyped>
		</div>
		<div className="form-submit-row">
			<FormErrorsContainer form={form}/>
			{(submitSucceeded || isEditable) && <EditableText scope={formName} as="SENT" tag={"span"} />}
			{/* TODO: Pedido de recuperação enviado */}
		</div>
	</form>
})