import { deepCopy, deepEqual } from 'modules';
import { InvoiceFormValues } from 'types/reservation/InvoiceFormValues';
import { IInvoiceActionsList } from '../../types/models/interfaces/invoice/IInvoiceActionsList';
import { useInvoiceClient } from './useInvoiceClient';
import { useInvoiceServer } from './useInvoiceServer';



export const useInvoiceActions = () : [IInvoiceActionsList] => {
	const [clientInvoice] = useInvoiceClient();
	const [,, {
		save: saveServer,
		refresh: refreshServer
	}] = useInvoiceServer();
	const save = async(invoice : InvoiceFormValues) => {
		if(!invoice) invoice = deepCopy(clientInvoice);
		// const allowUpdate = !deepEqual(clientInvoice, newInvoice);
		const allowUpdate = !deepEqual(clientInvoice, invoice);
		if(allowUpdate) {
			await saveServer(invoice);
		}
	}
		
	const refresh = async() => {
		await refreshServer();
	}

	const clear = async () => {
		await save({});
	}

	return [{ save, refresh, clear }]
}

