import React from "react";

export const Paths = ({ paths }: any) => {
	if(!paths) return null;
	return <div className="admin-server-paths">
		<h1>Server paths</h1>
		<table className="table table-striped table-primary-header">
			<thead>
				<th>Name</th>
				<th>Location</th>
			</thead>
			<tbody>
				{Object.keys(paths).map(p=>(<tr key={p}>
					<td>{p}</td>
					<td>{paths[p]}</td>
				</tr>))}
			</tbody>
		</table>
	</div>
}