import React from "react";
import { TableBase } from "../TableBase";
import { TableSchemedProps } from "../types/TableSchemedProps";

export const TableSchemed = ({ body, head, items, settings, ...htmlAttributes } : TableSchemedProps) => {
  const { schema: headSchema, ...restHead } : any = head || {};
  const { schema: rowsSchema, ...restBody } : any = body || {};
  return <TableBase
    {...htmlAttributes}
    settings={settings}
    head={head && {
      ...restHead,
      items: headSchema()
    }}
    body={body && {
      ...restBody,
      items: items.map(rowsSchema)
    }}
  />
}