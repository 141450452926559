import React, { useRef } from "react";
import { EditableText } from "controls";
import { randomGuid } from "modules/random";
import { useRNECodes } from "api/admin/codes";
export const ErrorCodesRNE = () => {
  const [[codes], { setCode, removeCode }] = useRNECodes();
  const addingGuidRef = useRef<any>();
  const addingIdRef = useRef<any>();
  const addingDetailedRef = useRef<any>();

  const onAdd = async () => {
    const guid = addingGuidRef.current.value;
    const id = addingIdRef.current.value;
    const detailed = addingDetailedRef.current.value;
    await setCode({ guid, id, detailed });
  }
  const onRemove = async(id: string) => {
    const yes = window.confirm(`Sure you want to delete code ${id}?`);
    if(yes) {
      await removeCode(id);
    }
  }

  if(!codes) return null;
  return <div>
    <table>
      <thead>
        <tr>
          <th>key</th>
          <th>id</th>
          <th>code</th>
          <th>text</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        {codes && Object.keys(codes).map(guid => {
          const code = codes[guid];
          return <tr key={code.id}>
            <td>{guid}</td>
            <td>{code.id}</td>
            <td>{code.detailed}</td>
            <td><EditableText scope="ERRORS" as={code.detailed} /></td>
            <th>
              <button onClick={() => onRemove(guid)}>Remove</button>
            </th>
          </tr>
        })}
      </tbody>
    </table>
    <div className="flex-row flex-between">
        <div className="flex-row">
          <div>
            <label htmlFor="adding-id">key:</label>
            <input defaultValue={randomGuid()} id="adding-guid" ref={addingGuidRef} />
          </div>
          <div>
            <label htmlFor="adding-id">id:</label>
            <input id="adding-id" ref={addingIdRef} />
          </div>
          <div>
            <label htmlFor="adding-detailed">detailed:</label>
            <input id="adding-detailed" ref={addingDetailedRef} />
          </div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={onAdd}>Add</button>
        </div>
    </div>
    
  </div>
}