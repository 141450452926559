import React from "react";
import { ButtonStatusEditable } from "controls";
import { useReservationCancel } from "api";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";
export const ReservationCancelButton = () => {
  const [{ visible, enabled }, status, { cancel }] = useReservationCancel();
  const [statusConverted] = useFetchStatusTimeout("ReservationCancelButton", status, CONFIRM_ERROR_TIMEOUT_MS);
  const { loading } = statusConverted || {};
  if(!visible) return null;
  return <ButtonStatusEditable
    scope="RESERVATION"
    as="CANCEL_BUTTON"
    className="bigger"
    styling="default"
    disabled={!enabled || loading}
    onClick={cancel}
    status={statusConverted}
  />
}