import React from "react";
import "./index.scss";
import clsx from "clsx";
import { SchedulesView } from "../SchedulesView"
import { TripsPanelView } from "../TripsPanelView"
import { useUserDetails } from "api/user/useUserDetails";
import { useReservationMode } from "api/reservation/useReservationMode";
import { useReservationStep } from "api/reservation/useReservationStep";
export const ReservationSchedulesView = ({className, ...props} : React.HTMLAttributes<{}>) => {
  const [step] = useReservationStep();
  const [mode] = useReservationMode();
  const [{ agentCompany }] = useUserDetails();
  const isAllowSale = agentCompany && agentCompany.Status===3;
    
  if(step==="NONE") return null;
  return <div {...props} className={`reservation-schedules-view ${clsx(className)}`}>
    {mode==="schedules" && <SchedulesView />}
    {mode==="trips" && isAllowSale && <TripsPanelView />}
  </div>
}