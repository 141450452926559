import React from "react";
import "./index.scss";
import clsx from "clsx";

interface StackPanelProps extends React.HTMLAttributes<{}> {
	count? : number;
	gap? : number;
}

export const StackPanel = ({count, gap=0, style, children, ...htmlAttributes} : StackPanelProps) => {
	if(!count) count = 1;
	const halfGap = gap / 2;
	const totalGap = (count-1) * gap;
	const itemReduce = totalGap / count;
	const itemWidth = `calc(${100 / count}% - ${itemReduce}px)`;
	const { className, ...restAttributes } = htmlAttributes;
	return <div
		{...restAttributes}
		style={style}
		className={`stack-panel count-${count} gap-${gap} ${clsx(className)}`}
	>
		{React.Children.map(children, (child : any, cIndex) => {
			if(!child) return null;
			const { style, className, ...cp } = child.props;
			const cellIndex = count ? cIndex % count : 0;
			let cellStyle : React.CSSProperties = {};// marginLeft: null, marginRight : null 
			if(gap) {
				if(cellIndex!==0) cellStyle.marginLeft = `${halfGap}px`;
				if(typeof count==="number" && cellIndex!==count-1) cellStyle.marginRight = `${halfGap}px`;
			}
			return React.cloneElement(child, {
				...cp,
				className: `stack-panel-item ${clsx(className)}`,
				style: {
					width: itemWidth,
					...cp.style,
					...cellStyle
				}
			})
		})}
	</div>
}