import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

export class Overlay extends React.Component {
	el : any;
	props : any;
	constructor(props: any) {
		super(props);
		if(this.portal) {
			this.el = document.createElement('div');
		}
	}
	get portal() {
		return !!this.props.at;
	}
	get container() : any {
		if(this.portal) {
			return document.getElementById(this.props.at);
		}
		return null;
	}
	componentDidMount() {
		if(this.portal)
			this.container.appendChild(this.el);
	}
	componentWillUnmount() {
		if(this.portal)
			this.container.removeChild(this.el);
	}
	render() {
		const { opacity, onClick } = this.props;
		const styleFixed : any = { position: "fixed", top: 0, left: 0, right: 0, bottom: 0 };
		const style : any = {
			...styleFixed
		}
		if(typeof opacity==="number") {
			style.opacity = opacity;
		}
		const content = <div className="react-overlay" style={style} onClick={onClick}></div>
		if(this.portal) {
			return ReactDOM.createPortal(content, this.el);
		}
		return content;
	}
}