import React from "react";
import { usePage } from "hooks";
import { DEFAULT_TITLE } from "consts";
import { Flex } from "controls";
import { SalesPanel } from "./SalesPanel";
import { LiquidationPanel } from "./LiquidationPanel";
import "./index.scss"
import { DashboardInfoDialogHolder } from "pages/Dashboard";
export const SalesLiquidation = () => {
	usePage({
		name: "SALES",
		options: {
			title: DEFAULT_TITLE
		}
	});
	return <Flex column ratio={[45, 55]} gap={5} className="full-size">
		<SalesPanel/>
		<LiquidationPanel />
		<DashboardInfoDialogHolder />
	</Flex>
}

export default SalesLiquidation;