import React, { useRef } from "react";
import { RadioInputProps } from "./RadioInputProps";
import clsx from "clsx";
import "./index.scss";
import { RadioInputItem } from "forms/input/items/RadioInputItem";

export const RadioInput = (props : RadioInputProps) => {
  const hiddenRef : any = useRef<HTMLInputElement>();
  const { scope, items, ...htmlAttributes } = props;
  const { className, name, value, onBlur, onFocus, onChange, ...restAttributes } = htmlAttributes;
  return <div
    {...restAttributes}
    onFocus={onFocus}
    className={`radio-input ${clsx(className)}`}
  >
    <input type={"hidden"} defaultValue={value} ref={hiddenRef} onInput={onChange}/>
    {items && items.map(item => {
      return <RadioInputItem
        value={value}
        // onChange={onChange}
        item={item}
        name={name}
        scope={scope}
        onClick={(e) => {
          hiddenRef.current.value = ""+item.value;
          hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
          e.preventDefault();
        }}
      />
    })}
  </div>
}
export { RadioInputProps }