import { ReactCalendarPicker, ReactCalendarPickerProps } from "controls/CALENDAR/ReactCalendarPicker";
import React, { FunctionComponent } from "react";
import { PrimitiveFieldBase } from "./types";

export interface DatePrimitiveProps extends ReactCalendarPickerProps, PrimitiveFieldBase {

}

export const DatePrimitive : FunctionComponent<DatePrimitiveProps> = ({...htmlAttributes} : DatePrimitiveProps) => {
  return <ReactCalendarPicker
    {...htmlAttributes}
  />
}