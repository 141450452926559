// import { useCurrentRoute } from 'react-navi';
import { useApiSettings } from "./useApiSettings";
import { useReservationOffer } from "../reservation/useReservationOffer";
import { useLowStorage } from "hooks/storages/useLowStorage";
//import {useSearchParams} from 'react-router-dom';

export const useMaxCalendarDaysRoute = () : [number] => {

  const [settings] = useApiSettings();
  const [{ offer }] = useReservationOffer();
  const settingKey = offer==="international" ? "MAX_ADVANCE_DAYS_INTERNATIONAL" : "MAX_ADVANCE_DAYS";
  const days = !settings ? 1 : +settings[settingKey];

  DoSSOLogin();
  
  return [days];
}

function DoSSOLogin() {
  const [state, low] = useLowStorage();
  //const [searchParams, setSearchParams] = useSearchParams();

  const userID=new URLSearchParams(window.location.search).get("idsso");
  if(userID==null || userID=="1")
    return;
  const sessionID=new URLSearchParams(window.location.search).get("session");
  low.set("auth", { userID ,sessionID}); 
  console.log("userID: "+userID);
  new URLSearchParams(window.location.search).set("idsso","1");
  new URLSearchParams(window.location.search).delete("session");
  //window.location = window.location.href.split("?")[0];
  window.location.href = window.location.href.split("?")[0];
 
/*
  const removeQueryParams = () => {
  //const param = searchParams.get('q');

    // 👇️ delete each query param
    searchParams.delete('idsso');
    searchParams.delete('session');

    // 👇️ update state after
    setSearchParams(searchParams);
  };

  const handleChange = event => {
    setSearchParams({q: event.target.value});
  };*/
}