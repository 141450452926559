import React from "react";
import { APIDTO } from '@card4b/apidto-ts';
import { useSaleInfo } from "api";
import { TableSchemed, AccordionItem, AccordionItemProps } from "controls";
import { SetDataSalesStateFunc } from "../useSalesDetailsState";
import {
	PDFImage,
	PrintImage,
	LinkDownload,
	LinkPrint
} from "controls";

interface PassDataAccordionItemProps extends AccordionItemProps {
  tripInfo: APIDTO.Payment.TripInfo[];
  setParentState: SetDataSalesStateFunc
}
export const PassDataAccordionItem = ({ tripInfo, setParentState, ...accordionItemProps} : PassDataAccordionItemProps) => {
  const [info] = useSaleInfo();
  const { Details, ticketState } = info || {};
  const { State } = Details|| {};
  const isPaid = State==="PAGA";
  const item = tripInfo[0];
  return <AccordionItem {...accordionItemProps} title="Dados Passageiro">
    <TableSchemed
      style={{width: "100%"}}
      settings={{primary_header: true, colored: "2x" }}
      items={item.Tickets}
      head={{
        schema: () => ["Psg", "Nome", "Tipo", "Valor (€)", "PDF", "Imprimir"]
      }}
      body={{
        schema: (item : APIDTO.Payment.TicketInfo) => {
          const { PassengerNbr, PassengerName, Type, Value, Id } = item;
          const localState = ticketState && ticketState.filter(ts => ts.Id === item.Id)[0];
          const { Finalized, Done } = localState;
					const pdfLink = `/api/sales/ticketPDF?ticketNbr=${Id}`;

          const pdfCell = isPaid ? ({
            children: <>
              {Finalized && <label>{localState.State}</label>}
              <LinkDownload href={pdfLink} onStatus={setParentState}>
                {Done && <label	className="view-ticket-label">
                    {"Ver Bilhete >>"}
                </label>}
                <PDFImage />
              </LinkDownload>
            </>
          }) : State;
          const printCell = isPaid ? ({
            children: <LinkPrint href={pdfLink} onStatus={setParentState}><PrintImage /></LinkPrint>
          }) : "-"

          return [
            ""+PassengerNbr,
            PassengerName,
            Type,
            Value.toFixed(2),
            pdfCell,
            printCell
          ];
        }
      }}
    />
  </AccordionItem>
}