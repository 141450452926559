import { useEffect } from "react";
import { devConsoleListeners } from "../../devConsoleListeners";
import { ENV_DEV } from "consts";
import { useAdmin } from "api/admin";

export const DevDialogChild = ({children}: any): any => {
	const [{ IsAdmin }] = useAdmin();
	const isAllow = ENV_DEV || IsAdmin;

	useEffect(() => {
		if(isAllow)devConsoleListeners.subscribe(children);
		return () => {
			if(isAllow)	devConsoleListeners.unsubscribe(children);
		}
	}, [children, isAllow])
	return null;
}