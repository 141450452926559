/*eslint-disable */

export function isNumberValid(value: any) {
	if(!value) return false;
	const firstOK = value[0] === "9";
	const secondOK = value[1] === "1" || value[1] === "2" || value[1] === "3" || value[1] === "6";
	const lengthOK = value.length===9;
	if(!firstOK || !secondOK || !lengthOK) {
		return false;
	}
    return true;
}