import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';
import { UsePassengerTypesResult } from 'types/passengers/UsePassengerTypesResult';

const passengerKeys = Object.keys(PassengerTypesEnum);
const passengerTypes : PassengerTypesEnum[] = passengerKeys.map(key => (PassengerTypesEnum as any)[key]);

export const usePassengersTypes = () : UsePassengerTypesResult => {
  return { passengerKeys, passengerTypes };
}

///