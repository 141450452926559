import React from "react";
import { TABLE } from "types";
import { APIDTO } from '@card4b/apidto-ts';
import { useRouteQueryKey } from "hooks";
import { useSchedulesTransformer } from "./useSchedulesTransformer";
import { useSchedulesSelected } from "./useSchedulesSelected";
import { OperatorImage } from "controls";
import { useSchedulesWithStopsConfirmed } from "api/ticketing/useSchedulesWithStopsConfirmed";

type SchedulesSchemaResult = [
  {
    headSchema: () => TABLE.TableHeadProps[];
    itemsSchema: (item : APIDTO.API.DateSchedule, index : number) => TABLE.TableRowProps;
  }
];

export const useSchedulesSchema = () : SchedulesSchemaResult => {
  const [schedules] = useSchedulesWithStopsConfirmed();
  const [schedulesTransformed] = useSchedulesTransformer(schedules);
  const [, setAgentsId] = useRouteQueryKey("agentsid");
  const [selected, setSelected] = useSchedulesSelected();

  const { headers, schedules_stops } = schedulesTransformed || {};
  const headSchema = () => [
    { children: "Serviço", onClick: () => setAgentsId(true) },
    ...(headers || []).map(h => ({ children: h.text, onClick: () => setAgentsId(h.id) }))
  ]
  const itemsSchema = (item : APIDTO.API.DateSchedule, index : number) => {
    const code = (item.departure_carrier.id) || 60;
    const stops = schedules_stops[index];
    const onClick = () => setSelected(item);
    const res : TABLE.TableRowProps = {
      selected: selected && item && item.schedule_id === selected.schedule_id,
      items: !stops ? [] : [
        {
          children: <OperatorImage onClick={() => {}} code={code}/>,
          onClick
        },
        ...stops.map(stop => ({ children: stop.text, onClick }))
      ]
    }
    return res;
  }

  return [{
    headSchema,
    itemsSchema
  }];
}