import { useEffect } from "react";
import { useStateNamed } from "../../hooks";
const results: any = {};
let isExecuting = false;
let lastSteps: any = {};

type TUseStepProceedProps = {
  name : string;
  enabled?: boolean;
  loading?: boolean;
  steps : ((params?: any) => Promise<(any | void)>)[]
}

type UseStepsProceedResult = [
	() => Promise<void>,
	{
		loading: boolean;
	}
]

/**
 * Calls multiple steps of execution in their order and renders component again each call of steps
 * 
 * @param name unique name of this executor
 * @param enabled requires true to be executed
 * @param loading prevents data of being loaded if loading = true
 * @param steps array of steps to be executed
 * 
 * @returns [execute, { loading }]
 */
export const useStepsProceed = (props : TUseStepProceedProps) : UseStepsProceedResult => {
  const {
    name,
    enabled,
    loading,
    steps
  } = props;
	const [step, setStepInner] = useStateNamed(`useStepsProceed.${name}`, lastSteps[name]);
	const method : any = typeof step==="number" && steps[step];

	const setStep = async (newStep: any) => {
		if(newStep===null) {
			isExecuting = false;
		}
		lastSteps[name] = newStep;
		await setStepInner(newStep);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const currentExecute = async () => {
		if(isExecuting)	return;
		isExecuting = true;
		let requireStop = false;
		if(step && step>0 && !results[name]) {
			requireStop = true;
		}
		if(!method) {
			requireStop = true;
		}
		if(requireStop) {
			results[name] = null;
			setStep(null);
			return;
		}
		try {
			results[name] = await method(results[name]);
			isExecuting = false;
			if(!results[name]) {
				await setStep(null);
			}
			else {
				await setStep(step+1);
			}
		}
		catch(error) {
			await setStep(null);
		}
	}

	useEffect(() => {
		if(typeof step==="number") {
			currentExecute();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);

	const doIt = async () => {
		if(typeof step==="number") return;
		if(!enabled) return;
		if(loading) return;
		await	setStep(0);
	}
	return [doIt, { loading: typeof step==="number" }];
}
