import React from "react";
import { Accordion } from "controls";
import { API } from "types";
import { APIDTO } from '@card4b/apidto-ts';
import clsx from "clsx";
import "./index.scss";
import { SetDataSalesStateFunc } from "../useSalesDetailsState";
import { useSaleInfo } from "api";
import { PassDataAccordionItem } from "./PassDataAccordionItem";
import { RouteAccordionItem } from "./RouteAccordionItem";

interface TripInfoAccordionProps extends React.HTMLAttributes<{}> {
  direction: API.LISTS.RouteDirection;
  active?: (number | boolean);
  onActivate: (idx: number) => void;
  setParentState: SetDataSalesStateFunc;
}

export const TripInfoAccordion = ({ direction, active, setParentState, onActivate, ...htmlAttributes } : TripInfoAccordionProps) => {
  const { className, ...restAttributes } = htmlAttributes;
  const [info] = useSaleInfo();
  const { TripInfo, RoundTripInfo } = info || {};
  const tripInfo : APIDTO.Payment.TripInfo[] = direction==="ida" ? TripInfo : direction==="volta" ? RoundTripInfo : [];
  if(!tripInfo) return null;
  return <div className={`${clsx(className)} trip-info-accordion`} {...restAttributes}>
    <label className="text-bold">{direction==="ida" ? "Ida" : direction==="volta" ? "Volta" : ""}</label>
    <Accordion
      duration={400}
      active={active}
      onActivate={onActivate}
    >
      <PassDataAccordionItem tripInfo={tripInfo} setParentState={setParentState} />
      {tripInfo.map((infoItem, index) => {
        return <RouteAccordionItem infoItem={infoItem}/>
      })}
    </Accordion>
  </div>
}