import { useSaleInfo } from "./useSaleInfo";
import { useFetchAction, useSalesRefresher } from "hooks";
import { FetchOnceStatus } from "modules/fetch/types/FetchOnceStatus";

interface UseSaleCancelMethods {
  cancel: () => Promise<boolean>
}

type UseSaleCancelResult = [FetchOnceStatus, UseSaleCancelMethods];

export const useSaleCancel = () : UseSaleCancelResult => {
  const [info,, { refresh }] = useSaleInfo();
	const [, salesRefresh] = useSalesRefresher();
	const { Details } = info || {};
	const { ReservationID } = Details || {};
  const [, cancelStatus, { execute: cancelExecute }] = useFetchAction("/api/sales/cancel", {
		method: "POST",
		body: { id: ReservationID }
  });
  const cancel = async () => {
		try {
			const reallyExit = window.confirm("Tem a certeza que pretende continuar?");
			if (reallyExit) {
				const responseData : { response: boolean; } = await cancelExecute();
				const { response } = responseData;
				if(response) {
					await salesRefresh();
					await refresh();
				}
				return response;
			}
			return reallyExit;
		}
		catch(error) {
			console.error(error);
			throw error;
		}
	}
  return [cancelStatus, { cancel }];
}