import React from "react";
import { ConfigItem } from "./ConfigItem";

export const ConfigList = ({list, defaults, save} : { list : any, defaults : any, save: (data : any) => Promise<any> }) => {
  if(!list) return null;

  const onChange = async (key : string, value : any) => {
    await save({...list, [key]: value });
  }
  return <div>
    <table style={{width: "100%"}}>
      <tbody>
        {Object.keys(list).map(key => {
          return <ConfigItem key={JSON.stringify({ key, v: list[key]})} defaultValue={defaults && defaults[key]} name={key} value={list[key]} onChange={(value) => onChange(key, value)} />
        })}
      </tbody>
    </table>
  </div>
}