import React from 'react';
import { hocDialog } from 'controls/dialogs';

const elements = [
	{img: "nivel1.png", txt: "Empresa"},
	{img: "nivel1yellow.png", txt: "Empresa gerida pelo utilizador actual"},
	{img: "nivel2darkblue.png", txt: "Grupo"},
	{img: "nivel2yellow.png", txt: "Grupo gerido pelo utilizador actual"},
	{img: "nivel3darkblue.png", txt: "Posto de Venda"},
	{img: "nivel3yellow.png", txt: "Posto de venda gerido pelo utilizador actual"},
	{img: "nivel4.png", txt: "Operador"},
	{img: "nivel4yellow.png", txt: "Operador actual"},
	{img: "nivel4flag.png", txt: "Operador com permissão de gestão sem estar atribuído"},
	{img: "nivelGrey.png", txt: "Inactivo"},
]

const dialogConnect = hocDialog({
	name: "hierarchyDescription",
	title: "Descrição Hierarquia",
	width: 400,
	height: 260
})

export const DialogHierarchy = dialogConnect(() => {
	return <table>
		<tbody>
			{elements.map((item, index) => {
				return <tr key={index}>
					<td>
						<img alt="element" src={"images/configuration/"+item.img} />
					</td>
					<td>{item.txt}</td>
				</tr>
			})}
		</tbody>
	</table>
});