import { useMemo } from "react";
import { useReservationSearchConfirmed } from '../search/useReservationSearchConfirmed';
import { APIDTO } from "@card4b/apidto-ts";
import { RouteTicketSelectionParams } from "types/ticketing/RouteTicketSelectionParams";
import { useTripsSelectedRoutes } from './useTripsSelectedRoutes';
import { useTripsConfirmed } from "./useTripsConfirmed";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export interface UseTripsSelectedValues {
  outgoing_ticket?: APIDTO.API.DateSchedule;
  return_ticket?: APIDTO.API.DateSchedule;

  outgoing_route?: RouteTicketSelectionParams;
  return_route?: RouteTicketSelectionParams;
}

export const useTripsTicketsSelected = () : FetchOnceResult<UseTripsSelectedValues> => {
  const [data, status, meta] = useTripsConfirmed();
  const [outgoing_route] : any = useTripsSelectedRoutes("ida");
  const [return_route] : any = useTripsSelectedRoutes("volta");
  const [{ ida, volta }] = useReservationSearchConfirmed();
  const { outgoing_schedules, return_schedules } = data || {};
  const outgoing_filtered = (ida && outgoing_route && outgoing_schedules && outgoing_schedules.filter(sch => sch.schedule_id===outgoing_route.schedule_id));
  const return_filtered = (ida && volta && return_route && return_schedules && return_schedules.filter(sch => sch.schedule_id===return_route.schedule_id));
  const outgoing_ticket = useMemo(() =>  (outgoing_filtered && outgoing_filtered[0]) || undefined, [outgoing_filtered]);
  const return_ticket = useMemo(() => (return_filtered && return_filtered[0]) || undefined, [return_filtered])

  return [
    {
      outgoing_ticket,
      return_ticket,
      outgoing_route,
      return_route
    },
    status,
    meta
  ];
}