import React from "react";
import './index.scss';
import { DOWNLOADING_FILE_STR, DOWNLOADING_FILE_ERROR_STR } from "../../../consts";
import { Loader } from "controls";
// import { LinkDownload, LinkPrint } from "controls"

export const PDFImage = ({icon="pdf"}={}) => <img alt="pdf" src={`images/icons/${icon}.png`} className="download-file" />
export const XLSImage = () => <PDFImage icon="Xls_icon" />
export const PrintImage = () =>  <PDFImage icon="print" />

export const DownloadingStatusMask = ({success, loading, error}: any) => {
	return <React.Fragment>
		{loading && <Loader text={DOWNLOADING_FILE_STR} overlay/>}
		{error && <Loader text={DOWNLOADING_FILE_ERROR_STR} overlay/>}
	</React.Fragment>
}