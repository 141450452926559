import React from "react";
import { ButtonStatusProps } from "../types/ButtonStatusProps";
import { ButtonTyped } from "../ButtonTyped";
// import { ButtonStylingTypes } from "../types/ButtonStylingTypes";

export const ButtonStatus = ({ status, children, styling, ...baseProps } : ButtonStatusProps) => {
  const { loading, success, error } = status || {};
  const stylingOverride : /*ButtonStylingTypes*/any = (() : /*ButtonStylingTypes*/any => {
    if(loading) return styling;
    if(error) return "error";
    if(success) return "success";
    return styling;
  })();

  return <ButtonTyped
    {...baseProps}
    styling={stylingOverride}
  >
    {children}
    {loading && <i className="fa fa-spin fa-spinner"/>}
    {success && <i className="fa fa-check"/>}
    {error && <i className="fa fa-close"/>}
  </ButtonTyped>
}