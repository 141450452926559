import React from "react";
import { DEFAULT_TITLE } from "consts";
import { RenderConditionsData } from "./Panels";
import { usePage } from "hooks";
import "./index.scss";
import { useFetchOnce } from "hooks";

export const InternationalConditions = () => {
	usePage({
		name: "INT_CONDITIONS",
		options: {
			title: DEFAULT_TITLE
		}
	});
	const [conditions, status] = useFetchOnce<any>("/api/conditions/international");

	return <RenderConditionsData
		title="Condições de Utilização - Serviço Internacional"
		conditions={conditions}
		status={status}
	/>
}

export default InternationalConditions;