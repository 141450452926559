import { createBodyElement } from '../../dom';
export const urlDownload = (url : string, filename: string) => {
  const a = createBodyElement("a", {
    href: url,
    download: filename
  });
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}