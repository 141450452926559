import { FormFieldNormalizer } from "./FormFieldNormalizer";
export const combineNormalize = (normalizers : FormFieldNormalizer) => {
	return (value : string) => {
		let result = value;
		if(typeof normalizers==="function") {
			result = normalizers(result);
		}
		if(typeof normalizers==="object" && Array.isArray(normalizers)) {
			normalizers.forEach(norm => {
				if(typeof norm==="function") {
					result = norm(result);
				}
				if(typeof norm==="object" && Array.isArray(norm)) {
					result = combineNormalize(norm)(result);
				}
			});
		}
		return result;
	}
}