// let totalHandlers = 0;

export class HandlerList {

  name : string;
  list: any = [];

  constructor(name : string) {
    this.name = name;
  }
  subscribe = (handler: any) => {
    this.list = this.list.filter((h: any) => h!==handler);
    this.list.push(handler);
  }
  unsubscribe = (handler: any) => {
    this.list = this.list.filter((h: any) => h!==handler);
  }
  process = () => {
    this.list.forEach((handler: any) => {
      handler();
    })
  }
  processTimeout = () => {
    this.list.forEach((handler: any) => {
      setTimeout(handler);
    })
  }
}