import { fetchJson } from 'modules/fetch';
import { useAdmin } from "./useAdmin";

type TempPermissionsCache = {
  // [index : string] : AdminPermissions | null;
  [index : string] : any;
};
const tempPermissionsCache : TempPermissionsCache = {};

export const useAdminPermissions = () : [any, { append: (key : string, value : any) => {}, set: (permissions : any) => {}}] => {
    const [{userID, permissions}, { refresh }] = useAdmin() || [null, { refresh: () => {} }];

    const set = async (permissions : any) => {
      tempPermissionsCache[userID] = permissions;
      await fetchJson("/api/admin/admin/permissions", {
        body: {
          permissions,
          userID
        },
        method: "POST"
      });
      tempPermissionsCache[userID] = null;
      await refresh();
    }
    const append = async (key : string, value : any) => {
      await set({...permissions, [key]: value});
    }

    const result : any = (permissions || tempPermissionsCache[userID] || {});
    return [
      result,
      { append, set }
    ];
}