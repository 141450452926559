import { Link } from "react-navi";
import { CONFIRM_ERROR_TIMEOUT_MS } from "../../consts";
import React from "react";
import { remoteFileDownload, remoteFilePrint } from "modules/files";


const LinkFile = ({children, fileName, processor, href, onStatus,...props} : any) => {
	return <a
		href={href}
		target="_blank"
		rel="noopener noreferrer"
		onClick={async (e) => {
			e.preventDefault();
			try {
				onStatus && onStatus({loading: true, error: null, success: null})
				await processor(href, fileName);
				onStatus && onStatus({loading: false, error: null, success: true})
			}
			catch(error) {
				if(onStatus) {
					onStatus({loading: false, error, success: false});
					setTimeout(() => {
						onStatus({loading: false, error: null, success: null})
					}, CONFIRM_ERROR_TIMEOUT_MS)
				}
			}
		}}
		{...props}
	>
		{children}
	</a>
}

export { Link }

export const LinkDownload = ({...props}) => {
	return <LinkFile
		processor={remoteFileDownload}
		{...props}
	/>
}

export const LinkPrint = ({...props}) => {
	return <LinkFile
		processor={remoteFilePrint}
		{...props}
	/>
}