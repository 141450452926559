const w : any = window;

class LocalStorage {
  items: any;
	constructor() {
		w.ls = this;
		this.refresh();
	}
	refresh() {
		this.items = {};
		for(let key in localStorage) {
			this.items[key] = localStorage.getItem(key);
		}
	}
	set(name: any, value: any) {
		if(!value) localStorage.removeItem(name);
		else localStorage.setItem(name, value);
		this.refresh();
	}
	get(name: any) { return this.items[name]||null; }
	remove(name: any) { this.set(name, null); }
	all() { return this.items; }
}

const ls = new LocalStorage();
w.ls = ls;

export { ls }