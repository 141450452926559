import React from "react";
import { ButtonBase } from "../ButtonBase";
import { ButtonBaseProps } from "../types/ButtonBaseProps";
import clsx from "clsx";
import "./index.scss";

export const ButtonClose = ({ children, className, ...restProps } : ButtonBaseProps) => {
  return <ButtonBase
    {...restProps}
    className={`button-close ${clsx(className)}`}
  >
    {children}
    <span>&nbsp;</span>
  </ButtonBase>
}