import React from "react";
import "./index.scss";
import * as _dialogs from "controls/dialogs";
import "./index.scss";

const dialogs:any = _dialogs;

export const ViewDialogs = () => {
	if(!dialogs) return <span></span>;
	return <div className="admin-content-dialogs">
		{Object.keys(dialogs).map(name => {
			const dialog = dialogs[name];
			return <div key={name} className="admin-content-dialog">
				<h1 className="admin-content-dialog-title">{name}</h1>
				<div className="admin-content-dialog-body">
					{dialog && React.createElement(dialog, { noDialog: true, adminView: true })}
				</div>
			</div>
		})}
	</div>
}