import React from "react";
import { ButtonStatusEditable } from "controls";
import { useReservationConfirm } from "api";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";
export const ReservationConfirmButton = () => {
  const [{ visible, enabled }, status, { confirm }] = useReservationConfirm();
  const [statusConverted] = useFetchStatusTimeout("ReservationConfirmButton", status, CONFIRM_ERROR_TIMEOUT_MS);
	const { loading } = statusConverted || {};
  if(!visible) return null;
  return <ButtonStatusEditable
    scope="RESERVATION"
    as="CONFIRM_BUTTON"
    className="bigger"
    styling="success"
    disabled={!enabled || loading}
    onClick={confirm}
    status={statusConverted}
  />
}