import React from "react";
import "./index.scss";
import { Flex, TitlePanel, ErrorEditable } from "controls";
import { TripsView } from "../TripsView";
import { ReservationPricesView } from "../ReservationPricesView";
import { ReservationDataView } from "../ReservationDataView";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";
import { useCurrentRoute } from "react-navi";

export const TripsPanelView = () => {
  let [, { error }] = useTripsConfirmed();
  const route = useCurrentRoute();
  const { pathname } = route.url;


  if(error && error.errorDetails=="ERROR_API_DETAILS_24")
  {
    if(pathname.indexOf("login") < 0) {
      window.location.pathname = "/login";
    }
  }

  return <TitlePanel
    type={"dark"}
    title={"Reservar"}
    className="panel-reservations"
    // loading={loading && LOADING_STR}
    contentProps={{className: "flex flex-column"}}
  >
    {!error && <Flex
      className="panel-trips"
      gap={5}
      ratio={[4,1,4]}
    >
      <div className="panel-trips-list">
        <TripsView />
      </div>
      <ReservationPricesView />
      <ReservationDataView />
    </Flex>}
    {error && <div>
      <ErrorEditable code={error.errorDetails} />
    </div>}
  </TitlePanel>
}
//	Sem login