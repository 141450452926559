import { useUserDetails } from "api/user/useUserDetails";
import { useRouteQueryKeys } from "hooks";
import { API } from "types";
import { UseReservationOfferResult } from "types/reservation/UseReservationOfferResult";

export const useReservationOffer = () : UseReservationOfferResult => {
	const [query, { addQuery }] = useRouteQueryKeys();
	const [{ sysAttribute }] = useUserDetails();
	const defaultOffer : API.LISTS.RESERVATION_OFFER_MODES = (() => {
		const OfferPreference : any = sysAttribute && sysAttribute.filter(c=>c.Name==="OfferPreference");
		const offerPreferenceVal : API.LISTS.RESERVATION_OFFER_MODES = OfferPreference && OfferPreference[0] && OfferPreference[0].Value;
		return offerPreferenceVal || "national";
	})()
	const offer : API.LISTS.RESERVATION_OFFER_MODES = query.offer==="international" ? "international" : query.offer==="national" ? "national" : defaultOffer;
	const setOffer = async (offer : API.LISTS.RESERVATION_OFFER_MODES) => {
		await addQuery({ offer })
	}
	return [
		{ offer, defaultOffer },
		setOffer
	]
}