import React, { useState } from "react";
import "./index.scss";
import { Flex, Loader, ButtonTyped, ContentBoxTitle, DownloadingStatusMask, EditableText } from "controls";
import { Details as DetailsList } from "./Details";
import { TripInfoAccordion } from "./TripInfoAccordion";
import { useSaleInfo, useSaleCancel } from "api/sales";
import { hocDialog } from "controls/dialogs";
import { useSalesDetailsState } from "./useSalesDetailsState";

// let allowCloseThisDialog : any;
const connectDialog = hocDialog({
	name: "salesDetailsDialog",
	title: "Detalhes Venda",
	minWidth: 600,
	minHeight: 500,
	height: 700,
	// onClose: () => {
	// 	return allowCloseThisDialog && allowCloseThisDialog();
	// }
})

export const SalesDetailsDialog = connectDialog(() => {
	const [info, { loading, error }, { refresh }] = useSaleInfo();
	const [{ loading: cancelLoading, error: cancelError }, { cancel }] = useSaleCancel();
	const [state, appendState] : any = useSalesDetailsState();
	const {	Details, RoundTripInfo, HasInvoice } = info || {};///, HasSRPOError,	HasSGCAError
	const [manualTicketError] = useState<boolean>(false);
	// const onManualError = () => {
	// 	if(!manualTicketError)
	// 		setManualTicketError(true);
	// }
	const {	IsRoundTrip, State } = Details || {};//ReservationID,
	let isPaidState = State==="PAGA";

	// allowCloseThisDialog = () : boolean => {
	// 	if (HasSRPOError) {
	// 		return window.confirm(`Atenção! A reserva foi registada mas não é possível emitir o bilhete. Ao fechar os detalhes da venda deixará de conseguir aceder aos mesmos. Conserve o nº da reserva (${ReservationID}). Tem a certeza que quer fechar?`);
	// 	}
	// 	if (HasSGCAError) {
	// 		return window.confirm(`Atenção! Ocorreu um problema a registar a reserva. Ao fechar os detalhes da venda deixará de conseguir aceder aos mesmos. Conserve o nº da reserva (${ReservationID}). Tem a certeza que quer fechar?`);
	// 	}
	// 	return true;
	// };

	return <div className="sales-details-root" style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
		{loading && <Loader text={"A processar bilhetes..."} overlay />}
		{cancelLoading && <Loader text={"A cancelar bilhetes..."} overlay />}
		{Details && <div id="salesDetailsPanel">
			<DownloadingStatusMask loading={state.loading} error={state.error} success={state.success}/>
			<DetailsList
				setParentState={appendState}
				isPaidState={isPaidState}
			/>
			{manualTicketError && <span className="error">De momento não é possível efectuar a operação</span>}
			{error && <span className="error">De momento não é possível obter os dados pretendidos</span>}
			{loading && <span className="error">A atualizar...</span>}
			{isPaidState && <Flex className="full-width flex-end" gap={5}>
				{!HasInvoice && <ButtonTyped styling="primary" onClick={()=>{refresh(); }}>Atualizar</ButtonTyped>}
				<ButtonTyped styling="primary"	disabled={cancelLoading} onClick={async ()=>{ await cancel();	}}>Cancelar Reserva</ButtonTyped>
			</Flex>}
		</div>}
		<div style={{float: "left", width: "100%"}}>
			<br/>
			<ContentBoxTitle>Detalhes Viagem</ContentBoxTitle>
			<TripInfoAccordion
				direction="ida"
				active={state.activeIda}
				setParentState={appendState}
				onActivate={(idx)=>{
					appendState({ activeIda: state.activeIda === idx ? null : idx })
				}}
			/>
			<br/>
			{IsRoundTrip && RoundTripInfo && <TripInfoAccordion
				direction="volta"
				active={state.activeVolta}
				setParentState={appendState}
				onActivate={(idx)=>{
					appendState({ activeVolta: state.activeVolta === idx ? null : idx })
				}}
			/>}
		</div>
		{error && <EditableText scope="ERRORS" as={(error as any).error} className="error" style={{color: "red"}} tag="p"/>}
		{cancelError && <EditableText scope="ERRORS" as={(cancelError as any).errorDetails} className="error" style={{color: "red"}} tag="p"/>}
	</div>
})