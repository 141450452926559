import { APIDTO } from "@card4b/apidto-ts";
import { useFetchOnce } from 'hooks';
import { useVoucherNavigation } from './useVoucherNavigation';
import { useVoucherRefresher } from './useVoucherRefresher';
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

export const useVoucherCurrent = () : FetchOnceResult<APIDTO.User.ClientVoucher> => {
  const [voucherId] = useVoucherNavigation();
  const [voucherGuid] = useVoucherRefresher();
  const [data, status, meta] = useFetchOnce<any>("/api/ticketing/voucher/getVoucher", { method: "GET", lock: !voucherId, refreshKey: voucherGuid, body: { id: voucherId }});
  return [data, status, meta];
}