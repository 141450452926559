import React, { useState } from "react";
import { ButtonEmoji } from "controls";
import { StyleGuideDialog } from "./StyleGuideDialog";
import "./index.scss";
export const StyleGuide = () => {
	const [shown, setShown] = useState<boolean>(false);

	return <>
		<ButtonEmoji icon="🎨" onClick={() => setShown(true)}/>
		{shown && <StyleGuideDialog onClose={() => setShown(false)}/>}
	</>
}
export * from './StyleGuideContent'