import React from "react";
import { AdminContext } from "./AdminContext";
import { useInitial } from "api/app/useInitial";
import { AdminAdmin } from "types/admin/AdminAdmin";

export const AdminProvider = ({admin, onRefresh, children} : any = {}) => {
  const [initial, refreshInitial] = useInitial();
  const adminSelected : AdminAdmin = admin || (initial && initial.admin) || {};
  const refresh = async() => {
    if(admin) { return await onRefresh(); }
    else { return await refreshInitial(); }
  }
  return <AdminContext.Provider value={[adminSelected, refresh]}>
    {children}
	</AdminContext.Provider>
}