/* eslint-disable no-throw-literal */
import { fetchJson } from 'modules/fetch';

export const validateNif = async ({ invoiceNif }: any) => {
	// const stringifiedNif = !invoiceNif ? "" : ""+invoiceNif;
	if(invoiceNif) {
		if(invoiceNif.length===9) {
			await fetchJson("/api/payments/validateInvoice/userValidate", {
				method: "POST",
				body: { taxNr: invoiceNif }
			});
		}
		else if(invoiceNif.length>0 && invoiceNif.length!==9) {
			throw {
				error: "ERROR_API_7",
				errorDetails: "ERROR_API_DETAILS_19"
			}
		}
	}
}