import React from "react";
import { AccordionItem, AccordionItemProps, OperatorImage, TableSchemed, TableBase } from "controls";
import { APIDTO } from '@card4b/apidto-ts';
import { datetime } from "modules";

interface RouteAccordionItemProps extends AccordionItemProps {
  infoItem: APIDTO.Payment.TripInfo;
}
export const RouteAccordionItem = ({ infoItem, ...accordionItemProps} : RouteAccordionItemProps) => {
  const { Origin, Destination, Departure, Arrival, BusNbr, Operator, Tickets } = infoItem;
  const tripDeparture = datetime.formatDateHours(Departure);
	const tripArrival = datetime.formatDateHours(Arrival);
  return <AccordionItem {...accordionItemProps} title={`${Origin} - ${Destination}`}>
    <OperatorImage style={{float: "left"}} code={Operator ? Operator : "60"} />
    <TableBase
      style={{width: "100%"}}
      body={{
        items: [
          [{ children: "Partida/Chegada:", className: "text-bold"}, { children: `${tripDeparture} / ${tripArrival}`}],
          [{ children: "Nº Viatura:", className: "text-bold" }, { children: ""+BusNbr, style: { textAlign: "left" } }]
        ]
      }}
    />
    {Tickets && <TableSchemed
      style={{width: "100%"}}
      settings={{colored: "2x", primary_header: true}}
      items={Tickets}
      head={{
        schema: () => ["Psg", "Nome", "Email", "Lugar", "Bilhete"]
      }}
      body={{
        schema: (item : APIDTO.Payment.TicketInfo) => {
          const { PassengerNbr, PassengerName, PassengerEmail, SeatNbr, Id } = item;
          return [""+PassengerNbr, PassengerName, PassengerEmail, ""+(SeatNbr || " - "), Id];
        }
      }}
    />}
  </AccordionItem>
}