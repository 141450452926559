import { useUserLogout } from './useUserLogout';
import { SESSION_KEEP_ALIVE_TIMEOUT_MS } from 'consts';
import { fetchJson } from 'modules/fetch';
import { useUser } from './useUser';

class KeepAliveClass {
  started = false;
  logout : () => Promise<void> = async () => {};
  execute = () => {
    if(!this.started) return;
    setTimeout(() => {
      fetchJson("/api/app/keepalive").then((response: any) => {
        let { ok } = response;
        if(ok) {
          this.execute()
        }
        else {
          this.stop();
        }
      }).catch((error) => {
          console.error(error)
      }) 
    }, SESSION_KEEP_ALIVE_TIMEOUT_MS)
  }
  start = (logout : () => Promise<void>) => {
    this.logout = logout;
    this.started = true;
    this.execute()
  }
  stop = () => {
    this.started = false;
    this.logout();
    this.logout = async () => {};
  }
}

const keepAlive = new KeepAliveClass();

export const useKeepAlive = () => {
  const [user] = useUser();
  const [logout] = useUserLogout();
  if(user && !keepAlive.started) {
    keepAlive.start(async () => {
      await logout();
    });
  }
  if(!user && keepAlive.started) {
    keepAlive.stop();
  }
}