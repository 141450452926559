import React from 'react';
import "./Users.scss";
import { LOADING_STR } from "consts"; // ACCOUNT_DEPTH_KEY
import { TableOld, TitlePanel, ErrorEditable } from "controls";
import { useBootstrap } from "hooks";
import { useConfigurationState, useConfigurationSelection, useConfigurationAgent, useConfigurationUsers } from 'api/configuration';
import { useUserDetails } from 'api/user/useUserDetails';

export const Users = () => {
	
	const [details] = useUserDetails();
	const { userAccountData, userType } = details || {};
	const { UserID } = userAccountData || {};
	const [,,{ setChartError }] = useConfigurationState();
	const [{showAllUsers},,{ save: saveState }] = useConfigurationSelection();
	const [,,{ edit: editAgent, enable: enableAgent, disable: disableAgent }] = useConfigurationAgent();
	const isLocked = userType==="OPERATOR";
	const [users, { loading: usersLoading, success: usersSuccess, error: usersError }] = useConfigurationUsers({ lock: isLocked });

	const bs = useBootstrap();

	const { xs, sm, md } = bs;

	const isWrapName = xs || sm || md;

	const agentChangeUserStatus = async (isActive: any, ID: any, UserId: any) => {
		const checkIfTryingToDeleteCurrentUser = (UserId: any) => {
			if (UserId === UserID) {
				alert('Não é possível desactivar a própria conta.');
				return true;
			}
			return false;
		}
		const enable = (!isActive);
		if (!enable && checkIfTryingToDeleteCurrentUser(UserId)) {
			return false;
		}
		const reallyExit = window.confirm(`Tem a certeza que pretende ${enable ? "activar" : "desactivar"} o utilizador?`);
		if (reallyExit && ID) {
			if(enable) {
				try {
					await enableAgent(ID);
				}
				catch(error) {
					console.error(error);
					setChartError('Ocorreu um erro a activar o elemento', 2000);
				}
			}
			else {
				try {
					await disableAgent(ID);
				}
				catch(error) {
					console.error(error);
					setChartError('Ocorreu um erro a desactivar o elemento', 2000);
				}
			}
		}
		return reallyExit;
	}

	if(isLocked) return null;
	return <TitlePanel
		title={"Lista de utilizadores"}
		className="configuration-users-panel"
		after={<div className="see-all">
			<input
				type="checkbox"
				checked={showAllUsers}
				onChange={() => {
					saveState({showAllUsers: !showAllUsers});
				}}
			/>
			Ver Todos
		</div>}
		loading={usersLoading && LOADING_STR}
	>
		{usersSuccess && <TableOld selectable={true} isDefault={true}>
			<thead>
				<tr>
					{isWrapName ? <>
						<th>Nome / Email</th>
					</> : <>
						<th>Nome</th>
						<th>Email</th>
					</>}
					<th style={{minWidth: 20}} />
					<th style={{minWidth: 20}} />
				</tr>
			</thead>
			<tbody>
				{users && usersSuccess &&
				(
					!showAllUsers ? users.filter((user: any) => user.IsActive) : users
				).map((user: any, index: any) => {
					return (
						<React.Fragment key={index}>
							<tr
								key={index}
								className={`${ index % 2 !== 0 ? "colored-old " : null }` } 
								onClick={() => {
								}}
							>
								{isWrapName ? <>
									<td>{user.Name}<br/>{user.Email}</td>
								</> : <>
									<td>{user.Name}</td>
									<td>{user.Email}</td>
								</>}
								<td>
									<img
										alt="edit"
										src="images/icons/edit.png"
										style={{ cursor: "pointer" }}
										onClick={async () => {
											await editAgent(user.ID);
										}}
									/>
								</td>
								<td>
									<img
										alt="active"
										src={`images/icons/${user.IsActive ? "delete" : "active"}.png`}
										style={{ cursor: "pointer" }}
										onClick={() => {
											agentChangeUserStatus(
												user.IsActive,
												user.ID,
												user.UserId
											)
										}}
									/>
								</td>
							</tr>
						</React.Fragment>
					);
				})}
			</tbody>
		</TableOld>}
		{usersError && <ErrorEditable code={usersError.error} />}
	</TitlePanel>
}