module.exports = {
	"gray-88": "#888888",
	"gray-9a": "#9a9a9a",
	"gray-d3": "#d3d3d3",
	"gray-d7": "#d7d7d7",
	"gray-e8": "#e8e8e8",
	"gray-f3": "#f3f3f3",
	"gray-bf": "#bfbfbf",


	"light-blue-1": "#00b3ef",
	"light-blue-2": "#1abbf1",
	"light-blue-3": "#66d1f5",
	"light-blue-4": "#99e1f9",
	"light-blue-5": "#ccf0fc",
	"light-blue-6": "#e6f7fd",

	"deep-red-1": "#ec2848",
	"deep-red-2": "#ee3d5a",
	"deep-red-3": "#f47e91",
	"deep-red-4": "#f7a9b6",
	"deep-red-5": "#fbd4da",
	"deep-red-6": "#fdeaed",

	"dark-blue-1": "#0067a0",
	"dark-blue-2": "#1a76aa",
	"dark-blue-3": "#66a4c6",
	"dark-blue-4": "#99c2d9",
	"dark-blue-5": "#cce1ec",
	"dark-blue-6": "#e6f0f6",

	"black-1": "#000000",
	"black-2": "#1a1a1a",
	"black-3": "#666666",
	"black-4": "#999999",
	"black-5": "#cccccc",
	"black-6": "#e6e6e6",

	"level-1-back": "#178ca5", // sove chart
	"level-2-back": "#b5d8e2",
	"level-3-back": "#eff7f9",
	"level-4-back": "#ffffff",

	"level-1-border": "#075768", // sove chart
	"level-2-border": "#075768",
	"level-3-border": "#075768",
	"level-4-border": "#178ca5",

	"level-1-fore": "#ffffff", // sove chart
	"level-2-fore": "#075e70",
	"level-3-fore": "#075e70",
	"level-4-fore": "#178ca5",

	"dev-1": "#663399",

	"panel-dark": "#032730",
	"panel-dark-2": "#0e5163",

	"black": "#000000",
	"white": "#ffffff",
	"red": "#ff0000",
	"error-text": "#ff0000",

	"dark-text": "#222222",
	"option-hover": "#f3f3f3",

	"field-disabled-background": "#f3f3f3",
	"field-disabled-foreground": "#888888",

	"border": "#333333",

	// >> SITE ONLY
	"light-red": "#e05656",
	"pale-rose": "#e0bcdd",
	"ice-blue": "#eefbff",
	"buy-ticket-back": "#f3f8fa",
	"buy-ticket-directions": "#e7f1f5",
	"grid-background-hover": "#dff0f6",
	"section-background": "#f3f3f3",
	"section-background-dark": "#d3d3d3",
	"scroll-scroller": "#414344",
	"scroll-back": "#d7d7d7",
	"state-success-background": "#00a363",
	"state-error-background": "#e05656",
	"ticket-background": "#ffffff",
	"ticket-info-background": "#f3f8fa",
	"ticket-active-background":  "#f3f8fa",
	"ticket-active-info-background": "#e7f1f5",
	"reservation-timer-text": "#ec2848",
	"reservation-step-previous": "#f7a9b6",
	"reservation-step-previous-text": "#000000",
	"reservation-step-next": "#f3f3f3",
	"tab-hover": "#eefbff",
	// << SITE ONLY

	// >> SOVE ONLY
	"link-default-color": "#2e6e9e",
	"border-default-color": "#032730",
	"link-active-color": "#199bb7",
	"border-active-color": "#a6c9e2",
	"link-hover": "#1d5987",
	"header-2": "#0e5163",
	"header-2-to": "#03252e",
	"big-text": "#0c0342",
	"reservation-stated": "#fde214",
	"reservation-stated-to": "#c59c06",
	"reservation-registered": "#ef0534",
	"reservation-registered-to": "#9c001f",
	"panel-light": "#2a7c91",
	"is-manager-user": "#ffdc77",
	// << SOVE ONLY


	// "border": "#333333",
	"primary": "#1a9eba",
	"primary-hover": "#127286",
	"primary-text": "#147da1",

	"bus-border": "#4b8a9c",
	"bus-conductor": "#000000",
	"bus-active-seat": "#ffd200",
	"bus-reserved-seat": "#ce1d05",
	//"bus-free-seat": "#c4dee6",
	"bus-free-seat": "#ffffff",
	"bus-free-flex-seat": "#37bbb2",
	"bus-free-promo-seat": "#b7cfff",
	"bus-covid-seat": "#9a9a9a",
	"bus-current-seat": "#349705",
	//"bus-current-seat": "#00b3ef",
}