import { normalizeFetchRequest } from "./normalizeFetchRequest";
import { FetchDataParams } from "../types/FetchDataParams";
//@ts-ignore
import { fetch } from "whatwg-fetch";

export const fetchData = (url : string, params : FetchDataParams={}) : Promise<any> => {
	return new Promise(async (resolve, reject)=>{
		try {
			const [ urlN, paramsN ] = normalizeFetchRequest(url, params);
			const result = await fetch(urlN, paramsN);
			if(!result.ok) {
				return reject(result);
			}
			resolve(result);
		}
		catch(e) {
			console.error(e);
			reject(e);
		}
	});
};