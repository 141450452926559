import { useServer } from "api/app/useServer";
import React from "react";
import "./index.scss";

export const LoginLayout = ({children}: any) => {
	const [{version}] = useServer();
	return <div className="login-layout">
		<div className="login-holder">
			{children}
			<div className="login-version">
				Versão {version}
			</div>
		</div>
	</div>
}