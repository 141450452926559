import React from "react";
import { Loader, DownloadingStatusMask } from "controls";
import { LOADING_STR } from "consts";
import { useStateAppend } from "hooks";
import { ENV_DEV } from "consts";

import {
	XLSImage,
	PDFImage,
	// PrintImage,
	LinkDownload,
	// LinkPrint
} from "controls";
import { hocDialog } from "controls/dialogs";

const dialogConnect = hocDialog({
	name: "DialogReportSubmission",
	title: "Exportar",
	minWidth: 500,
	minHeight: 100
})

export const DialogReportSubmission = dialogConnect(({reportSubmissionID, loading, close}: any) => {
	const [state, setState] = useStateAppend({});
	const xlsUrl = "/api/liquidation/note/xls?LiquidAccPeriod="+reportSubmissionID;
	const pdfUrl = "/api/liquidation/note/pdf?LiquidAccPeriod="+reportSubmissionID;

	return <div style={{display: "flex", flexDirection: "column"}}>
		{ENV_DEV && <p>
			reportSubmissionID: {reportSubmissionID}
		</p>}
		{loading && <Loader text={LOADING_STR} overlay />}
		<p>Escolha o formato a exportar os dados da nota de liquidação submetida</p>
		<div className="dialog-buttons">
			<DownloadingStatusMask {...state} />
			<div style={{position: "absolute", opacity: "0"}}>
				
				<LinkDownload id="approved_liquidation_xls" href={xlsUrl} onStatus={setState}><XLSImage /></LinkDownload>
				<LinkDownload id="approved_liquidation_pdf" href={pdfUrl} onStatus={setState}><PDFImage /></LinkDownload>
			</div>
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={()=>{
					const link = document.getElementById("approved_liquidation_pdf");
					link && link.click();
					setTimeout(close);
				}}>
				PDF
			</button>
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={()=>{
					const link = document.getElementById("approved_liquidation_xls");
					link && link.click();
					setTimeout(close);
				}}>
				Excel
			</button>
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={()=>{
					close();
				}}>
				Cancelar
			</button>
		</div>
	</div>
})