import React, { useState, useMemo } from "react";
import { useTripsSelected } from "./useTripsSelected";
import clsx from "clsx";
import { useReservationId } from "api";
import { useContentCompanies } from "api";
import { TABLE } from "types";
import { APIDTO } from '@card4b/apidto-ts';
import { useServer } from "api/app/useServer";

type TripsSchemaResult = [
  {
    headSchema: () => TABLE.TableHeadProps[];
    itemsSchema: (item : APIDTO.API.DateSchedule, index : number) => TABLE.TableRowProps;
  }
];
// let lockChange = false;
// let preventHighlight = false;

const hasAvailableSeats = (list : APIDTO.API.DateSchedule[]) => {
	return list && list.filter(item => typeof(item.available_seats)==="number" && item.available_seats!==0).length>0;
}
export const useTicketsSchema = (schedules : APIDTO.API.DateSchedule[]) : TripsSchemaResult => {
  const [{projectType}] = useServer();
  const [/*priceHighlight*/, setPriceHighlight] = useState<number>(0);
  const [selected, setSelected] = useTripsSelected();
  const [reservationId] = useReservationId();
	const [companies] = useContentCompanies();
  const types = useMemo(() => {
    const typesFound : string[] = [];
    if(!schedules) return;
    schedules.forEach(schedule => {
      const { min_price_per_type } = schedule;
      min_price_per_type && min_price_per_type.forEach(({price_type_id}) => {
        if(typesFound.indexOf(price_type_id)<0)typesFound.push(price_type_id);
      });
    });
    const typesFoundSorted : string[] = typesFound.sort((a, b) => {
      if(a>b) return 1;
      if(a<b) return -1;
      return 0;
    });
    return typesFoundSorted;
  }, [schedules]);

  const showSeats = hasAvailableSeats(schedules);
  const priceMapper = (p : string) => {
		if(projectType==="PRODUCTION") { return <span>Preço</span> }
		return <span>Preço <span style={{color: "red"}}>{p}</span></span>
  }
  const isOnlyPrice = types.length===1;
  const lockChange = !!reservationId;
  const cursorStyle = lockChange ? {} : { cursor: "pointer"};

  const priceCellMapper = (p : string, item : APIDTO.API.DateSchedule, index : number) => {
    const { min_price_per_type } = item;
    const priceTypes = min_price_per_type && min_price_per_type.filter(c=>c.price_type_id === p);
    const { price_type_id, price }  = (priceTypes && priceTypes[0]) || {};
    const priceInfo = (() => {
      if(price_type_id === "B") return `<img class='priceTypeInfoTooltipImg' title='Não Reembolsável' src='images/icons/info.png'/>`
      if(price_type_id === "C") return `<img class='priceTypeInfoTooltipImg' title='Não Revalidável' src='images/icons/info.png'/>`
      if(price_type_id === "D") return `<img class='priceTypeInfoTooltipImg' title='Não Reembolsável e não revalidável' src='images/icons/info.png'/>`
      return "";
    })()
    if(!price_type_id) {
      return { children: "", className: "price" };
    }


    const isSelected = selected && selected.schedule_id===item.schedule_id && selected.price_type_id === price_type_id;
    const className = `price ${clsx(isSelected && "selected")}`;// ${clsx(priceHighlight===index && "error")}
    const children = <>
      €{price && price.toFixed(2)}<span dangerouslySetInnerHTML={{__html: priceInfo}}></span>
    </>;
    const onClick = async () => {
      if(lockChange) return;
      if(lockChange) return;
      // preventHighlight = true;
      // setTimeout(() => {
      //   // preventHighlight = false;
      // }, 100);
      if(!lockChange) {
        await setSelected({ ...item, price_type_id: price_type_id });
      }
    };
    return {
      className,
      children,
      style: { ...cursorStyle },
      onClick: isOnlyPrice ? null : onClick,
      disabled: lockChange
    }
  }

  const companyIDToName = (id : any, service : any) => {
		if(!companies) return "";
		if(companies.length===0) return "";
    let serObs = (isNaN(service)) ?  "" : " ("+service+")" ;
		for(let i=0; i<companies.length; i++) {
			if(""+companies[i].Code===""+id) {
				return companies[i].Abbr + serObs;
			}
		}
		for(let i=0; i<companies.length; i++) {
			if(""+companies[i].Code==="60") {
				return companies[i].Abbr+ serObs;
			}
		}
		return "";
	}

  const itemsSchema = (item : APIDTO.API.DateSchedule, index : number) : TABLE.TableRowProps => {
    // return {
    //   items: []
    // }

    const nonPriceClick = () => {
      if(lockChange) return;
      if(!isOnlyPrice) {
        setPriceHighlight(index);
        setTimeout(() => setPriceHighlight(0), 2000);
      }
    }
    const items : TABLE.TableCellProps[] = (() => {
      return [
        { children: item.departure_time, onClick: nonPriceClick, disabled: lockChange },
        { children: item.arrival_time, onClick: nonPriceClick, disabled: lockChange },
        ...types.map((price) => priceCellMapper(price, item, index)),
        { children: companyIDToName(item.departure_carrier && item.departure_carrier.id,item.departure_trip_id ), onClick: nonPriceClick, disabled: lockChange }
      ]
    })();
    const availableSeatsStr = !item.available_seats ? "0" : ""+(item.available_seats);
    return {
      style: isOnlyPrice ? { ...cursorStyle } : { },
      selected: selected && selected.schedule_id===item.schedule_id,
      onClick: async () => {
        if(lockChange) return;
        if(isOnlyPrice) {
          const { min_price_per_type } = item;
          await setSelected({...item, price_type_id: min_price_per_type[0].price_type_id })
        }
      },
      items: !showSeats ?
      items :
        [
          ...items,
          { children: availableSeatsStr, onClick: nonPriceClick }
        ]
    };
  }

  // const headersList : string[] = [];

  const headersList = useMemo(() => {
    return showSeats ?
  ["Partida", "Chegada", ...types.map(priceMapper), "Serviço", "Nº Lugares"] :
  ["Partida", "Chegada", ...types.map(priceMapper), "Serviço"]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSeats, types]);


  return [{
    headSchema: () => headersList,
    itemsSchema
  }]
}