import React from "react";
import { ButtonClose } from "../../BUTTONS/ButtonClose";

interface PanelTitleCloseProps {
  title?: string;
  className?: string;
  onClose?: () => any;
}

export const PanelTitleClose = ({ title, className, onClose } : PanelTitleCloseProps) => {
  return <div className={`panel-title-close ${className}`}>
    <span className="title-text">{title}</span>
    <ButtonClose onClick={onClose}/>
  </div>
}