import React from "react";
import { FilePickerInput, FilePickerInputProps } from "../inputs";
import { PrimitiveFieldBase } from "./types";

export interface FileB64PrimitiveProps extends PrimitiveFieldBase {
  picker : FilePickerInputProps;
  html?: React.HTMLAttributes<{}>;
}

export const FileB64Primitive = ({ picker, html } : FileB64PrimitiveProps) => {
  return <div {...(html || {})}>
    <FilePickerInput
      // value={{ name: ""+value }}
      {...picker}
      // {...htmlAttributes}
    />
  </div>
}