import { API } from "types";
import { datetimeStringify } from "modules/datetime";
import { useUserDetails } from "api/user/useUserDetails";
import { useReservationSearchConfirmed } from "./useReservationSearchConfirmed";
import { useReservationOffer } from "api/reservation/useReservationOffer";
import { useOriginsOffer } from "api/locations/useOriginsOffer";
import { useDestinationsOffer } from "api/locations/useDestinationsOffer";

const firstEntrance = (locations: any, id: any) => {
	const filtered = locations && locations.filter((c: any)=>c.id===id);
	return (filtered && filtered[0]) || null;
}

export const useInitialSearch = () : API.HOOKS.UseInitialSearchResult => {
	const [{ sysAttribute }] = useUserDetails();
	const [{
		origin: savedOrigin,
		destination: savedDestination,
		ida: savedIda,
		volta: savedVolta,
		reschedule_of_ida,
		reschedule_of_volta,
	}] = useReservationSearchConfirmed();
	const [{ offer }] = useReservationOffer();
	const [origins] = useOriginsOffer();

	const DepartureCode = sysAttribute && sysAttribute.filter(c=>c.Name==="DepartureCode");
	const departureParsed = (DepartureCode && DepartureCode[0] && DepartureCode[0].Value) || "";
	const departureSplit = departureParsed.split(";");
	
	const origin = (() => {
		const defaultId = departureSplit && departureSplit.length>0 && (offer==="international" && departureSplit.length>1 ? departureSplit[1] : departureSplit[0]);
		if(savedOrigin && firstEntrance(origins, savedOrigin)) return savedOrigin;
		if(defaultId && firstEntrance(origins, defaultId)) return defaultId;
		return undefined;
	})();
	const [destinations] = useDestinationsOffer({	origin });

	const destination = (() => {
		if(savedDestination && firstEntrance(destinations, savedDestination)) return savedDestination;
		return undefined;
	})();

	const ida = (() => {
		if(!savedIda) {
			return datetimeStringify(new Date()).toFieldDateUtcString()
		}
		return datetimeStringify(savedIda).toFieldDateUtcString();
	})();

	const volta = (() => {
		return savedVolta && datetimeStringify(savedVolta).toFieldDateUtcString();
	})();

	return [
		{
			origin,
			destination,
			ida,
			volta,
			roundTrip: !!volta,
			revalidations: !!reschedule_of_ida || !!reschedule_of_volta,
			reschedule_of_ida,
			reschedule_of_volta
		}
	]
}