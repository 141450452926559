import { useFetchOnce } from "hooks";
import { useSalesRefresher } from "../../hooks/refreshers";
import { useDashboardRoute } from "./useDashboardRoute";
import { APIDTO } from "@card4b/apidto-ts";
import { useVoucherRefresher } from "../voucher/useVoucherRefresher";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export const useDashboardList = () : FetchOnceResult<{
	time: string;
	items: APIDTO.Agents.AgCompanyBasketDTO[]
}> => {
	const [{searchStarted, ...restState}] = useDashboardRoute();
	const [voucherRefreshKey] = useVoucherRefresher()
	const [salesRefreshKey] = useSalesRefresher();
	const refreshKey = `${salesRefreshKey}-${voucherRefreshKey}`;
	const lock = !searchStarted;

	const [data2, status2, meta2] = useFetchOnce<any>("api/dashboard/sales", {
		lock,
		refreshKey,
		method: "POST",
		body: {
			...restState,
		}
	});
  return [data2, status2, meta2];
}