import { useReservationSearchConfirmed } from 'api';
import { useTripsTicketsSelected } from 'api/ticketing/useTripsTicketsSelected';

export const useReservationPriceTypeValidation = () => {
	const [{ volta }] = useReservationSearchConfirmed();
	const [{ outgoing_route, return_route }] = useTripsTicketsSelected();
	const isPricesMixedButOK = (() => {
		if(!!volta) {
			if(!outgoing_route) return false;
			if(!return_route) return false;
			if(outgoing_route.price_type_id!==return_route.price_type_id) {
				if(["A", "X"].indexOf(outgoing_route.price_type_id)>=0 && ["A", "X"].indexOf(return_route.price_type_id)>=0)
					return true;
			}
		}
		return false;
	})();

	const isValidPriceTypes = (()=>{
		if(!!volta) {
			if(!outgoing_route) return false;
			if(!return_route) return false;
			if(outgoing_route.price_type_id!==return_route.price_type_id && !isPricesMixedButOK) return false;
		}
		return true;
	})();

	return [{
		isValidPriceTypes,
		isPricesMixedButOK
	}];
}