import React from "react";
import "./index.scss";
// import { Flex, Loader, ContentBoxTitle, DownloadingStatusMask, EditableText, PDFImage } from "controls";
// import { useSaleInfo, useSaleCancel, useSaleInfoId, useVoucherCurrent } from "api";
import { hocDialog } from "controls/dialogs";
// import { base64Download } from "modules";
import { VoucherInfoView } from "../VoucherInfoView";

const connectDialog = hocDialog({
	name: "voucherInfoDialog",
	title: "Voucher",
	minWidth: 600,
	minHeight: 500,
	height: 700,
	onClose: () => {
		return true;
	}
})

export const VoucherInfoDialog = connectDialog(() => {
	return <div className="voucher-info-dialog" style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
		<VoucherInfoView />
	</div>
})
