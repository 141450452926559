import { useClientInfoRouting } from './useClientInfoRouting';
import { useClientInfoCustomers } from "./useClientInfoCustomers";
import { APIDTO } from "@card4b/apidto-ts";
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

export const useClientInfoCustomer = () : FetchOnceResult<APIDTO.User.Customer> => {
  const [{customerId}] = useClientInfoRouting();
  const [customers, state, meta] = useClientInfoCustomers();
  const current : APIDTO.User.Customer = (() => {
    if(!customerId) return null;
    if(!customers) return null;
    let selection : any = null;
    customers && customers.forEach((customer: any) => {
      if(""+customer.RFLEXCard.CardNumber===""+customerId) {
        selection = customer;
      }
    })
    return selection;
  })();
	return [
    current,
    state,
    meta
  ]
}