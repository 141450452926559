import React, { useEffect } from "react";
import { PwdResetForm } from "./PwdResetForm"
import { usePage } from "hooks";
import { useNavigation } from "react-navi";
import { LoginLayout } from "../_partials/LoginLayout";

export const PwdReset = () => {
	usePage({
		name: "PWD_RESET",
		options: {
			title: "Recuperação Senha Consola Sistema Online de Vendas para Empresas"
		}
	});
	const navigation = useNavigation();
	useEffect(() => {
		if(window.location.pathname.indexOf("PwdReset.aspx")>=0) {
			navigation.navigate("pwdreset"+window.location.search);
		}
	}, [navigation])

	return <LoginLayout>
		<PwdResetForm />
	</LoginLayout>
}

export default PwdReset;