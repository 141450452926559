
import { WrappedFieldProps, Field } from 'redux-form';
import { createRootGate } from './../gates/createRootGate';
import { HocFieldProps, HocFieldsMergedDefaults } from "./types";
import React, { useMemo } from "react";
import { onClickLabel, mixValidate, mixNormalize } from "./helpers";
import { FieldEditableElement, FieldEditableLabel, FieldContainer } from '../parts';
import { createGate } from 'effector-react';
import { PrimitiveFieldBase } from 'forms/input/primitives/types/PrimitiveFieldBase';
import { FieldPropsBase } from 'forms/input/primitives/types/FieldPropsBase';

type HocFieldPropsT<TProps extends PrimitiveFieldBase> = (HocFieldProps & TProps);
type HocWrapper<TProps extends PrimitiveFieldBase> = (props : HocFieldPropsT<TProps>) => JSX.Element;

type HocMergeFieldsResult<TProps extends PrimitiveFieldBase> = [
  HocWrapper<TProps>,
  HocWrapper<TProps>,
  HocWrapper<TProps>
]

const GateWrapped = <TProps extends PrimitiveFieldBase & FieldPropsBase>({
  Primitive,
  defaults,
  ...props
} : {
  Primitive: (props : PrimitiveFieldBase) => JSX.Element,
  defaults: HocFieldsMergedDefaults } & HocFieldPropsT<TProps>
) => {
  const { name, validate, normalize, required, onChange, ...restProps } : any = props;
  const { defaultValidate, defaultNormalize } : any = defaults;
  const Gate = createRootGate();
  const WrappedGate = createGate<WrappedFieldProps>("wrapped");
  const normalizersResult = useMemo(() => mixNormalize(normalize, defaultNormalize), [normalize, defaultNormalize])
  const validatorsResult = useMemo(() => mixValidate(required, validate, defaultValidate, { name }), [required, validate, defaultValidate, name]);
  return <>
    <Gate
      defaults={defaults}
      extras={{ onClickLabel }}
      props={{ ...restProps, onChange, required, name }}
    />
    <Field
      name={name}
      onChange={onChange}
      component={({input, meta} : WrappedFieldProps) => {
        const gateState = Gate.state.getState();
        return <>
          <WrappedGate input={input} meta={meta} />
          <FieldContainer gateState={gateState} WrappedGate={WrappedGate}>
            <FieldEditableLabel gateState={gateState} WrappedGate={WrappedGate} />
            <FieldEditableElement gateState={gateState} WrappedGate={WrappedGate} Primitive={Primitive} />
          </FieldContainer>
        </>
      }}
      normalize={normalizersResult}
      validate={validatorsResult}
    />
  </>
}

export const hocMergeFields = <TProps extends PrimitiveFieldBase>(
  Primitive : (props : /*PrimitiveFieldBase*/any) => any,//JSX.Element,
  defaults: HocFieldsMergedDefaults
) : HocMergeFieldsResult<TProps> => {
  return [
    (props : HocFieldPropsT<TProps>) => <span></span>,
    (props : HocFieldPropsT<TProps>) => <span></span>,
    (props : HocFieldPropsT<TProps>) => <GateWrapped {...props} defaults={defaults} Primitive={Primitive} />
  ];
}