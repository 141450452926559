import React from "react";
import { PrimitiveFieldBase } from "./types";
import { SelectInput, SelectInputProps } from "../inputs";

export interface SelectPrimitiveProps extends SelectInputProps, PrimitiveFieldBase {
  afterLabel?: any;
}

export const SelectPrimitive = (props : SelectPrimitiveProps) => {
  const { name, form, scope } = props;
  const scopePrimitive = scope ? scope : form ? (form+"_"+name) : null;
  if(!scopePrimitive) return null;
  return <SelectInput
    {...props}
    scope={scopePrimitive}
  />
}