import React from "react";
import { RadioInputItemProps } from "./RadioInputItemProps";
import clsx from "clsx";
import "./index.scss";
import { EditableText } from "controls";

export const RadioInputItem = (props : RadioInputItemProps) => {
  const { item, scope, ...htmlAttributes } = props;
  const { value, className, onClick, name, disabled, onBlur, onFocus, onChange } = htmlAttributes;

  const isChecked = ""+item.value===""+value;

  const lockedClick = (e: any) => {
    e.preventDefault();
  }

  return <div onClick={onClick} className={`radio-input-item ${clsx(className)} ${isChecked ? "selected" : ""}`}>
    <input
      // onClick={lockedClick}
      checked={isChecked}
      name={name}
      disabled={disabled}
      value={item.value}
      type={"radio"}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
    />
    {!scope && <span onClick={lockedClick}>{item.text}</span>}
		{scope && <EditableText onClick={lockedClick} tag="span" scope={scope} as={`${item.value}`} emptyValue={item.text ? ""+item.text : ""}	/>}
  </div>
}