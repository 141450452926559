import React, { useState } from "react";
import { fetchJson } from "modules/fetch";
import { getColor } from "styleguide";
import { UserMessage } from 'controls/partials/UserMessages';
import { deepCopy } from "modules";
import { randomGuid } from "modules/random";

const dateFormatValue = (item: any): any => {
	if(typeof(item) === "string" && item.length>2) return dateFormatValue(new Date(item));
	if(!!item) {
		const res = item.getFullYear()+"-0"+(item.getMonth()+1)+"-"+(item.getDate());
		return res;
	}
	return "";
}

const RenderItem = ({item, index, forceUpdate}: any) => {
	return <tr key={"s"+index}>
		<td colSpan={13} style={{padding: "0"}}><UserMessage
			update={()=>forceUpdate()}
			item={item}
		/></td>
	</tr>
}

const RenderTable = ({state, setState, removeItem, forceUpdate, setStateItem}: any) => {
	const { data } = state;
	if(!data) return null;

	const userMessagesArr = Object.keys(data).map(key => data[key]);

	return <table className={"table table-striped"}>
			<thead>
				<tr>
					<td>ID</td>
					<td>Start</td>
					<td>End</td>
					<td>Day Start</td>
					<td>Day End</td>
					<td>Font Color</td>
					<td>Back Color</td>
					<td>Opacity</td>
					<td>Position</td>
					<td>Active</td>
					<td>Logged</td>
					<td>Disable Closing</td>
					<td>Actions</td>
				</tr>
			</thead>
			<tbody>
				{userMessagesArr.map((item, index) => {
					return <>
						<tr key={index}>
							<td>{item.ID}</td>
							<td style={{whiteSpace: "nowrap"}}>{dateFormatValue(item.activeFrom)}</td>
							<td style={{whiteSpace: "nowrap"}}>{dateFormatValue(item.activeTo)}</td>
							<td style={{whiteSpace: "nowrap"}}>{item.activeDailyFrom}</td>
							<td style={{whiteSpace: "nowrap"}}>{item.activeDailyTo}</td>
							<td style={{backgroundColor: item.fontColor ? item.fontColor : "transparent"}}>{item.fontColor}</td>
							<td style={{backgroundColor: item.backColor ? item.backColor : "transparent"}}>{item.backColor}</td>
							<td>{item.panelOpacity}</td>
							<td>{item.pagePosition}</td>
							<td>{item.isActive ? "Yes" : "No"}</td>
							<td>{item.onlyLogged ? "Yes" : "No"}</td>
							<td>{item.blockClose ? "Yes" : "No"}</td>
							<td>
								<div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
									<button onClick={()=>setState({item, adding: true, addingIndex: index})}>Edit</button>
									<button onClick={()=>removeItem(item)}>Remove</button>
								</div>
							</td>
						</tr>
						<RenderItem key={index+"ITEM"} item={item} index={index} forceUpdate={forceUpdate}/>
						{state.adding && state.addingIndex===index && <tr key={"s"+item.ID}>
							<td colSpan={13} style={{padding: "0"}}>
								<RenderAdditions state={state} setStateItem={setStateItem} setState={setState}/>
							</td>
						</tr>}
					</>
				})}
			</tbody>
		</table>;
}

const RenderAdditions = ({state, setStateItem, setState}: any) => {
	const { item } = state;
	if(!item) return null;
	return <div style={{border: `2px solid ${getColor("primary")}`, width: "100%"}} key={item.ID}>
		<div>
			<table className={"table"}>
				<thead>
					<tr>
						<td>ID</td>
						<td>Start</td>
						<td>End</td>
						<td>Day Start</td>
						<td>Day End</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><input value={item.ID} readOnly={true} onChange={e => setStateItem({ID: e.target.value})} type={"text"} /></td>
						<td><input value={dateFormatValue(item.activeFrom)} onChange={e => setStateItem({activeFrom: e.target.value})} type={"date"} /></td>
						<td><input value={dateFormatValue(item.activeTo)} onChange={e => setStateItem({activeTo: e.target.value})} type={"date"} /></td>
						<td><input value={item.activeDailyFrom} onChange={e => setStateItem({activeDailyFrom: e.target.value})} type={"time"} /></td>
						<td><input value={item.activeDailyTo} onChange={e => setStateItem({activeDailyTo: e.target.value})} type={"time"} /></td>
					</tr>
				</tbody>
			</table>
			<table className={"table"}>
				<thead>
					<tr>
						<td>Font</td>
						<td>Back</td>
						<td>Opacity</td>
						<td>Position</td>
						<td>Block Close</td>
						<td>When Logged</td>
						<td>Active</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><input value={item.fontColor} onChange={e => setStateItem({fontColor: e.target.value})} type={"color"} /></td>
						<td><input value={item.backColor} onChange={e => setStateItem({backColor: e.target.value})} type={"color"} /></td>
						<td><input value={item.panelOpacity*100} onChange={e => setStateItem({panelOpacity: (+e.target.value / 100.0)})} step={"1"} min={"0"} max={"100"} type={"range"} /></td>
						<td>
							<div>
								<label>Page absolute</label>
								<input name={"pagePosition"} value={"PAGE_ABS"} checked={item.pagePosition==="PAGE_ABS"} onChange={e => setStateItem({pagePosition: e.target.value})} type={"radio"} />
							</div>
							<div>
								<label>Page relative</label>
								<input name={"pagePosition"} value={"PAGE_REL"} checked={item.pagePosition==="PAGE_REL"} onChange={e => setStateItem({pagePosition: e.target.value})} type={"radio"} />
							</div>
							<div>
								<label>Header bottom</label>
								<input name={"pagePosition"} value={"HEADER_BOT"} checked={item.pagePosition==="HEADER_BOT"} onChange={e => setStateItem({pagePosition: e.target.value})} type={"radio"} />
							</div>
						</td>
						<td><input checked={item.blockClose} onChange={e => setStateItem({blockClose: e.target.checked})} type={"checkbox"} /></td>
						<td><input checked={item.onlyLogged} onChange={e => setStateItem({onlyLogged: e.target.checked})} type={"checkbox"} /></td>
						<td><input checked={item.isActive} onChange={e => setStateItem({isActive: e.target.checked})} type={"checkbox"} /></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
			<div>
				<button className={"btn btn-primary"} onClick={()=>setState({adding: false})}>Cancel</button>
				<button className={"btn btn-primary"} onClick={()=>{
					const { item } = state;
					fetchJson("/api/user/messages/update", {
						method: "POST",
						body: JSON.stringify({ id: item.ID, data: item })
					}).then(() => {
						setState({data: null, adding: false});
					});
				}}>Confirm</button>
			</div>
		</div>
	</div>
}

export const SiteMessages = () => {
	const [state, setStateFull] = useState<any>({ key: 0, adding: false, addingIndex: -1, items: null });

	const { key, data } = state;
	const setState = (params: any) => setStateFull({...state, ...params});

	if(!data) {
		fetchJson("/api/user/messages/all").then(response => setState({data: response}))
	}

	const setStateItem = (props: any) => {
		const item = deepCopy(state.item);
		Object.assign(item, props);
		setState({item});
	}

	const removeItem = (item: any) => {
		if(window.confirm("Are you sure you want to delete "+item.ID+"?")) {
			fetchJson("/api/user/messages/remove", {
				method: "POST",
				body: JSON.stringify({ id: item.ID })
			}).then(() => setState({data: null}))
		}
	}

	const passDownProps = {
		state,
		setStateItem,
		setState,
		removeItem,
		forceUpdate: () => setState({key: state.key+1})
	}

	return <div key={key} className={"container"} style={{color: "#000000", display: "flex", flexDirection: "column"}}>
		{/* <MarkdownAsync src={markdown} /> */}
		<RenderTable {...passDownProps}/>
		<div className="row">
			{state.adding && state.addingIndex===-1 && <RenderAdditions {...passDownProps}/>}
			{!state.adding && <button
				className={"btn btn-primary"}
				onClick={() => {
					setState({item: { ID: randomGuid()}, adding: true, addingIndex: -1})
				}
			}>Add Message</button>}
		</div>
	</div>
}