import { APIDTO } from "@card4b/apidto-ts";
import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';
import { useTripsTicketsSelected } from 'api/ticketing/useTripsTicketsSelected';
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export interface TripPriceItem {
	fare_type_id: PassengerTypesEnum;
	price: number;
}

export const useTripsPrices = () : FetchOnceResult<TripPriceItem[]> => {
	const [tripsData, tripsStatus, tripsMeta] = useTripsConfirmed();
	const { fares } = tripsData || {};
	const [{ outgoing_ticket, return_ticket, outgoing_route, return_route }] = useTripsTicketsSelected();
	const idaID = outgoing_ticket && outgoing_ticket.schedule_id;
	const voltaID = return_ticket && return_ticket.schedule_id;
	const fare : APIDTO.API.FareMatrix = (() => {
		let result: any = null;
		fares && fares.forEach(f => {
			if(idaID && voltaID) {
				if(f.outgoing_schedule_id===idaID && f.return_schedule_id===voltaID) result = f;
			}
			else if(idaID) {
				if(f.outgoing_schedule_id===idaID) result = f;
			}
			else if(voltaID) {
				if(f.return_schedule_id===voltaID) result = f;
			}
		})
		return result;
	})();
	const itemsDict : { [key: string] : number } = {};
	const { outgoing_price_list, return_price_list } = fare || {};
	outgoing_price_list && outgoing_price_list.forEach(p => {
		const { price_per_type, fare_type_id } = p;
		if(typeof itemsDict[fare_type_id]!=="number") itemsDict[fare_type_id] = 0;
		price_per_type && price_per_type.forEach(ppp => {
			const { price_type_id, price } = ppp;
			if(outgoing_route && price_type_id===outgoing_route.price_type_id) {
				itemsDict[fare_type_id] +=price;
			}
		})
	})
	return_price_list && return_price_list.forEach(p => {
		const { price_per_type, fare_type_id } = p;
		if(typeof itemsDict[fare_type_id]!=="number") itemsDict[fare_type_id] = 0;
		price_per_type && price_per_type.forEach(ppp => {
			const { price_type_id, price } = ppp;
			if(return_route && price_type_id===return_route.price_type_id) {
				itemsDict[fare_type_id] +=price;
			}
		});
	});

	const itemsArr : TripPriceItem[] = [];
	Object.keys(itemsDict).forEach(key => {
		itemsArr.push({ fare_type_id: (key as PassengerTypesEnum), price: itemsDict[key] })
	})

    return [
        itemsArr.filter(c=>!!c.price),
        tripsStatus,
        tripsMeta
    ]
}