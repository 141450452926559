import { fetchJson } from 'modules/fetch';
// import { network, FetchOnceResult } from 'types';
import { useFetchOnce } from "hooks";
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

interface RNECodeItem {
  id: string;
  detailed: string;
}

type RNECodesList = {
  [key: string]: RNECodeItem;
};

export const useRNECodes = () : [FetchOnceResult<RNECodesList>, {
  setCode: (item : { guid: string; id: string; detailed: string; }) => Promise<void>,
  removeCode: (guid: string) => Promise<void>}
] => {
  const [data, status, meta] = useFetchOnce<RNECodesList>("/api/admin/codes/rne_codes/list", { method: "GET" });

  const setCode = async ({ guid, id, detailed } : { guid: string; id: string; detailed: string; }) => {
    await fetchJson("/api/admin/codes/rne_codes/set", { method: "POST", body: { guid, id, detailed }});
    await meta.refreshSilent();
  }
  const removeCode = async (guid: string) => {
    await fetchJson("/api/admin/codes/rne_codes/remove", { method: "POST", body: { guid }});
    await meta.refreshSilent();
  }

  return [[data, status, meta], { setCode, removeCode }];
}