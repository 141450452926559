import { useSalesState } from "./useSalesState";
import { useSalesRefresher } from "../../hooks/refreshers";
import { useFetchOnce } from "hooks";
import { APIDTO } from "@card4b/apidto-ts";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export interface SalesObjectBasketItem {
	ItemId : string;
	Value: number;
	Type: number;
	IsVolta: boolean;
	TicketIsDone: boolean;
	Reservation: APIDTO.Agents.AgCompanyBasketDTO;
}

export const useSalesSales = () : FetchOnceResult<SalesObjectBasketItem[]> => {
	const [{periodID}] = useSalesState();
	const [salesRefreshKey] = useSalesRefresher();
	return useFetchOnce<SalesObjectBasketItem[]>("/api/sales/sales", { lock: !periodID, refreshKey: salesRefreshKey, method: "GET", body: { periodID }});
}

// public class SalesObjectBasketItem
//     {
//         public string ItemId { get; set; }
//         public decimal Value { get; set; }
//         public bool IsVolta { get; set; }
//         public bool TicketIsDone { get; set; }
//         public APIDTO.Agents.AgCompanyBasketDTO Reservation { get; set; }
//     }