import React from "react";
import "./index.scss";
import { randomGuid } from "modules/random";
import clsx from "clsx";
interface AccordionItemOldState {
	uid?: string;
	isActive?: boolean;
	elementStyle?: any;
}
class AccordionItemOLD extends React.Component {
	state : AccordionItemOldState = {}
	props : any;

	constructor(props: any) {
		super(props);
		this.state = {
			uid: randomGuid()
		};
		this.goUp = this.goUp.bind(this);
		this.goDown = this.goDown.bind(this);
	}
	get element() {
		return this.state.uid ? document.getElementById(this.state.uid) : null;
	}
	get height() {
		if(!this.element) return 0;
		return this.element.offsetHeight;
	}
	componentDidMount() {
		const { isActive } = this.props;
		if(isActive)
			this.goDown();
	}
	goUp() {
		const { duration, isActive } = this.props;
		this.setState({
			isActive,
			elementStyle: { marginTop: "-2px" }
		});
		setTimeout(() => {
			this.setState({ elementStyle: { marginTop: `-${this.height}px`, transition: `margin ${duration}ms` }});
		}, 100);
	}
	goDown() {
		const { duration, isActive } = this.props;
		this.setState({
			isActive,
			elementStyle: { marginTop: `-${this.height}px` }
		});
		setTimeout(() => {
			this.setState({ elementStyle: { marginTop: "-2px", transition: `margin ${duration}ms` }});
		}, 100);
	}
	render() {
		const { title, children, disabled, isActive, duration, onActivate } = this.props;
		if(isActive!==this.state.isActive) {
			if(isActive) setTimeout(this.goDown);
			if(!isActive) setTimeout(this.goUp);
		}
		const activeCN = isActive ? " active " : "";
		const icon = isActive && !disabled ? "ui-icon-triangle-1-s" : "ui-icon-triangle-1-e";

		// disabled={disabled}
		return <div className="accordion-item">
			<h3
				className={`accordion-item-header ${activeCN}`}
				onClick={()=>{
					if(disabled) return;
					duration && isActive && this.goUp();
					duration && !isActive && this.goDown();
					setTimeout(onActivate, duration || 0);
				}}
			>
				<span className={`ui-icon ${icon}`}></span>
				{/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a href="#">{title}</a>
			</h3>
			<div
				id={this.state.uid}
				style={this.state.elementStyle}
				className="accordion-item-content"
			>
				{children}
			</div>
		</div>
	}
}

export interface AccordionItemProps extends React.HTMLAttributes<{}> {
	duration?: number;
	title?: string;
	disabled?: boolean;
	isActive?: boolean;
	onActivate?: () => void;
}
export const AccordionItem = (props : AccordionItemProps) => {
	return <AccordionItemOLD {...props}/>
}

interface AccordionProps extends React.HTMLAttributes<{}> {
	duration: number;
	active?: (number | boolean);
	onActivate: (idx : number) => void;
}

export const Accordion = ({ active, onActivate, duration, ...htmlAttributes } : AccordionProps) => {
	const { children, className, ...restAttributes } = htmlAttributes;
	return <div {...restAttributes} className={`accordion ${clsx(className)}`}>
		{React.Children.map(children, (child : any, index) => {
				return React.cloneElement(child, {
					duration,
					isActive: typeof active==="number" && active===index,
					onActivate: () => onActivate(index)
				});
		})}
	</div>
}