import { useFetchOnce } from "hooks";
import { APIDTO } from "@card4b/apidto-ts";
import { useAuthorised } from "api/user";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

const stringLowerCompare = (a: any, b: any) => {
	const aLow = a.text.toLowerCase();
	const bLow = b.text.toLowerCase();
	return aLow>bLow ? 1 : aLow<bLow ? -1 : 0;
}

export const useApiAgentsList = ({lock} : { lock?: boolean; }={}) : FetchOnceResult<APIDTO.API.Stop2[]> => {
	const [isAuthorised] = useAuthorised();
	const lockInner = !isAuthorised || lock;
	const [data, status, meta] = useFetchOnce<APIDTO.API.Location[]>("/api/agents/list", {method: "GET", lock: lockInner });
	const stops : APIDTO.API.Stop2[] = [];
	data && data.forEach(item => {
		item.stops.forEach(stop => {
			stops.push(stop);
		})
	})
	const newData = !stops ? stops : stops.map(ag=>({...ag, value: ag.id, text: ag.name})).sort(stringLowerCompare);
	return [newData, status, meta];
};