import React from "react";
import { Confirm } from "./Confirm";
import { useReduxFormSelector } from "forms";
import { useVoucherCreateStatus } from 'api';
import { FieldNumberEditable, FieldSelectEditable } from "forms";
import { useOrigins, useDestinations } from "api";
import clsx from "clsx";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts/keys";
import { ErrorEditable } from "controls";
import { FieldsHolder } from "pages/Home/views/SchedulesSearchSoveView/FormSchedulesSoveSearch/FieldsHolder";
import { datetimeHelpers } from "modules/datetime/datetimeHelpers";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";
import { reduxForm } from "redux-form";
// import { FieldsHolder } from "views/search/SchedulesSearchSoveView/FormSchedulesSoveSearch/FieldsHolder";

const months = datetimeHelpers.getMonthList("pt");

const nullStr : any = null;
const EMPTY_VALUE : {
  value: string;
  text: string;
} = {
  value: nullStr,
  text: ""
}

const formConnect = reduxForm({
	form: "searchFormVoucher",
	enableReinitialize: true,
	destroyOnUnmount: false
});


export const OriginHolder = ({...htmlAttributes} : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
	const [origins] = useOrigins({ offer: "national" });
  const originsShown = origins ? [EMPTY_VALUE, ...origins.map(c=>({...c, value: c.id}))] : [EMPTY_VALUE];
	return <FieldSelectEditable
    {...restAttributes}
    className={`${clsx(className)} field-origin`}
		name="origin"
		maskOption="{text} ({value})"
		items={originsShown}
		required
		filter={true}
	/>
}

const DestinationHolder = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const [formSearch] = useReduxFormSelector("searchFormVoucher");
  const { values : searchValues } : any = formSearch || {};
  const origin : string = searchValues.origin;
  const [destinations] = useDestinations({ origin, offer: "national" });
  const destinationsShown = destinations ? [EMPTY_VALUE, ...destinations.map(c=>({...c, value: c.id}))] : [EMPTY_VALUE]
	const { className, ...restAttributes } = htmlAttributes;
	
  return <FieldSelectEditable
    {...restAttributes}
    disabled={!origin}
    name="destination"
    className={`${clsx(className)} field-destination`}
    required
    filter={true}
    items={destinationsShown}
    maskOption="{text} ({value})"
  />
}

export const Form = formConnect(({form} : { form : string }) => {


  const [createStatus] = useVoucherCreateStatus();
  const [statusConverted] = useFetchStatusTimeout("useVoucherCreateStatus", createStatus, CONFIRM_ERROR_TIMEOUT_MS);
  const err : any = statusConverted && statusConverted.error;
  
  return <form className={`form ${form}`}>
    <FieldsHolder>
			<OriginHolder />
      <DestinationHolder />
      <FieldSelectEditable
        name="year"
        required
        items={[
          { value: "2021", text: "2021"},
          { value: "2022", text: "2022"},
          { value: "2023", text: "2023"},
        ]}
      />
      <div>&nbsp;</div>
      <FieldSelectEditable
        name="month"
        required
        items={months.map((monthName, monthIndex) => {
          return { value: ""+(monthIndex+1), text: monthName }
        })}
      />
			<div className="flex-end">
        <Confirm />
      </div>
      <FieldNumberEditable name="price" required/>
		</FieldsHolder>
    {err && <ErrorEditable code={err.errorDetails} />}
  </form>
})