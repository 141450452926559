export const add = (time : Date, options : any ={}) => {
	const { year=0, month=0, date=0, day=0, hour=0, minute=0, second=0, millisecond=0 } = options;
	return new Date(
		Date.UTC(
			time.getUTCFullYear()+year,
			time.getUTCMonth()+month,
			time.getUTCDate()+(date||day),
			time.getUTCHours()+hour,
			time.getUTCMinutes()+minute,
			time.getUTCSeconds()+second,
			time.getUTCMilliseconds()+millisecond
		));
}