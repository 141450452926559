export const uploadFile = async () : Promise<File> => {
	const removeInput = () => {
		let inp = document.getElementById("file");
		if(inp)document.body.removeChild(inp);
	};
	const showInput = async () : Promise<File> => {
		return new Promise((resolve) => {
			let inp = document.createElement("input");
			inp.type = "file";
			inp.id = "file";
			document.body.onfocus = () => {
				document.body.onfocus = null;
				removeInput();
			};
			inp.onchange = () => {
				const file = inp.files[0];//
				document.body.onfocus = null;
				removeInput();
				resolve(file);
			};
			document.body.appendChild(inp);
			inp.click();
		});
	};
	const result = await showInput();
	return result;
};