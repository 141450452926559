import React from "react";
import { RenderValue } from "./RenderValue";

export const ConfigItem = ({name, value, defaultValue, onChange} : { name : string, value : any, defaultValue : any, onChange: (value : any) => {} }) => {
  return <tr>
    <td>
      <label>{name}</label>
    </td>
    <td>
      <RenderValue name={name} value={value} defaultValue={defaultValue} onChange={(value) => {
        onChange(value);
      }}/>
    </td>
    <td>
      {/* <label>{name} translation</label> */}
    </td>
  </tr>
}