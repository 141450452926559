import React from "react";
import { TabsContainer, EditableText } from "controls";
import { useReservationOffer, useReservationId } from "api";
import "./index.scss";
import { API } from "types";

interface SchedulesSearchTabsProps extends React.HTMLAttributes<{}> {
  allow?: API.LISTS.RESERVATION_OFFER_MODES[];
}

export const SchedulesSearchTabs = ({ allow, ...htmlAttributes } : SchedulesSearchTabsProps) => {
  const [reservationId] = useReservationId();
  const [{offer}] = useReservationOffer();
  const allowNational = !allow || allow.indexOf("national")>=0;
  const allowInternational = !allow || allow.indexOf("international")>=0;
  const buttonNational = { name: "national", children: <EditableText scope="Search" as="TabNational"/>, active: offer==="national" };
  const buttoInternational = { name: "international", children: <EditableText scope="Search" as="TabInternational"/>, active: offer==="international" }
  const buttons = allowNational && allowInternational ? [buttonNational, buttoInternational] : allowNational ? [buttonNational] : allowInternational ? [buttoInternational] : [];

  return <div className="schedules-search-tabs">
    <TabsContainer
      {...htmlAttributes}
      disabled={!!reservationId}
      routed={{
        query: "offer",
        buttons
      }}
    />
  </div>
}