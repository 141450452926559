import React from "react";
import "./index.scss";
import { Chart } from "./Chart";
import { Users } from "./Users";
import { DialogUser } from "./DialogUser";
import { AccordionForms } from "./Accordion";
import { TitlePanel, Flex } from "controls";
import { DEFAULT_TITLE, LOADING_STR } from "consts";
import { usePage } from "hooks";
import { useConfigurationState, useConfigurationHierarchy, useConfigurationAccordion, useConfigurationCompany, useConfigurationGroup, useConfigurationAgent } from "api/configuration";

export const Configuration = () => {

	usePage({
		name: "CONFIGURATION",
		options: {
			title: DEFAULT_TITLE
		}
	})

	const [, , { save: saveAccordion }] = useConfigurationAccordion();
	const [,{ loading: agentLoading, actionLoading }] = useConfigurationState();
	const [, { loading: loadingHierarchy }, { refresh: refreshHierarchy }] = useConfigurationHierarchy();
	const loading = loadingHierarchy || agentLoading || actionLoading;
	const [agent] = useConfigurationAgent();
	const { id: agentID, parentId: agentParentID } = agent || {};
	useConfigurationCompany();
	useConfigurationGroup();
	useConfigurationState();
	useConfigurationState();


	return <TitlePanel
		type={"dark"}
		title={"Organização"}
		className="configuration-root-panel full-size"
		onRefresh={refreshHierarchy}
		loading={loading && (actionLoading ? actionLoading : LOADING_STR)}
		titleProps={{ className: "flex flex-start" }}
		contentProps={{ className: "flex flex-row full-size nooverflow" }}
	>
		<Flex className="full-size" ratio={[7, 3]} gap={5}>
			<Flex column className="full-size" style={{ overflowX: "auto", overflowY: "hidden", borderRight: "1px solid #B5D8E2" }}>
				<Chart />
			</Flex>
			<Flex className="full-size" column style={{ justifyContent: "space-between" }}>
				<AccordionForms />
				<Users />
			</Flex>
		</Flex>
		{(agentID || agentParentID) && <DialogUser onClose={() => {
			saveAccordion({ id: null, parentId: null, level: null });
		}} />}
	</TitlePanel>
}

export default Configuration;