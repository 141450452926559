import React from "react";
import "./index.scss";

import { useReservationMode } from "api";
import { TitlePanel, TabsContainer, ErrorEditable } from "controls";
import { Title } from "./Title";
import { LOADING_STR } from "consts";
import { List } from "./List";
import {
  useSchedulesExtended,
  useSchedulesSelected
} from "consts/schemas";
import { useReservationSearchFormValues } from "api";
import { useSchedulesTab } from "api/ticketing/useSchedulesTab";
import { useSchedulesWithStopsConfirmed } from "api/ticketing/useSchedulesWithStopsConfirmed";

const SelectedHeadersPreview = () => {
  const [selected] = useSchedulesSelected();
  if(!selected) return null;
  const { frequency, period } = selected;
  return <div className="flex flex-column" style={{textAlign: "center"}}>
    {frequency && frequency.length > 0 ? <span>{frequency}</span> : <span>&nbsp;</span>}
    {period && period.length > 0 ? <span>{period}</span> : <span>&nbsp;</span>}
  </div>
}

export const SchedulesView = ({style} : { style?: any }) => {
  const [extended, setExtended] = useSchedulesExtended();
  const [{ roundTrip }] = useReservationSearchFormValues();
  const [tab] = useSchedulesTab();
  const [, { error, loading }] = useSchedulesWithStopsConfirmed();
  const [mode] = useReservationMode();
  if(mode!=="schedules") return null;//

  const buttons = (() => {
    const ida = { name: "ida", children: "Horários de Ida", active: tab==="ida" };
    const volta = { name: "volta", children: "Horários de Volta", active: tab==="volta" };
    if(roundTrip) return [{...ida, active: tab==="ida"}, {...volta, active: tab==="volta"}];
    return [{...ida, children: "Lista de Horários", active: true}];
  })();
  return <div className="schedules-view" style={style}>
    <TitlePanel
      type={"dark"}
      title={<Title />}
      loading={loading && LOADING_STR}
    >
      {!error && <TabsContainer
        routed={{
          query: "schedules_tab",
          buttons,
          after: <div className="title-info-panel">
            <SelectedHeadersPreview />
            <div className="extended-schedule" onClick={() => setExtended(!extended)}>
              <label htmlFor="HorarioExtend">Mostrar Percurso Completo</label>
              <input type="checkbox" checked={extended} onChange={()=>{}} />
            </div>
          </div>
        }}
      >
        {!loading && <List />}
      </TabsContainer>}
      {error && <ErrorEditable code={error.errorDetails} />}
    </TitlePanel>
  </div>
}//