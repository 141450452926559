import React from "react";
import { ReactCalendar, ReactCalendarProps } from "../ReactCalendar";
import "./index.scss";

// function isValidDate(d : Date) {
// 	return d instanceof Date && !isNaN(+d);
// }

export const ReactCalendarValued = ({value, onChange, ...rest} : ReactCalendarProps) => {
	return <ReactCalendar
		{...rest}
		value={value}
		onChange={value => onChange && onChange(value)}
	/>
}