import { usePassengersRemote } from 'api';
import { FETCH } from 'types';
import { UsePassengersStatusResult } from 'types/passengers/UsePassengersStatusResult';
import { useInitialPassengers } from "../useInitialPassengers";

export const usePassengersStatus = () : UsePassengersStatusResult => {
	const [, { loading: initialLoading }] = useInitialPassengers();
	const [, { loading: serverLoading, saving: serverSaving }] = usePassengersRemote();
	const success = false;
	// const error : FETCH.APIError = null;
	const status : FETCH.FetchOnceStatus = {
		loading: initialLoading || serverLoading || serverSaving,
		success,
		error: false
	};
	return [
		status
	];
}