import { FETCH } from 'types';
import { useFetchOnce, useStateNamedTimeout } from 'hooks';
import { APIDTO } from "@card4b/apidto-ts";
// import { usePassengersClient } from 'api';
// import { useReduxFormSelector } from 'forms';
import { useMemo } from 'react';

/*CAL, 2020-11-20*/
import { hex_md5 } from "modules/encrypt";
import { FetchJsonParams } from "modules/fetch/types/FetchJsonParams";
/*------------*/

export interface UseFetchTripsProps {
  lock?: boolean;
  refreshKey?: string;

  rflexNr?: number;
  origin: string;
  destination: string;
  ida: Date;
  volta: Date;
  passengerList: any[];
}
export type UseFetchTripsResult = [
  APIDTO.API.ScheduleTable,
  FETCH.FetchOnceStatus,
  FETCH.FetchOnceMethods
]

let prevBody : any = null;

/*CAL, 2020-11-20 */
let lastFetchKey : string = "";
let lastRefreshKey : string = "";
/*------------*/

export const useFetchTripsGlobalState = () => useStateNamedTimeout<boolean>('useFetchTripsGlobal')

export const useFetchTrips = (props : UseFetchTripsProps) : UseFetchTripsResult => {
  const { refreshKey, lock, rflexNr, origin, destination, ida, volta, passengerList } = props;
  const activeElement = document.activeElement;
  const activeElements = useMemo(() => {
    return passengerList && passengerList.map((pass, index)=> {
      const elem : any = document.getElementById(`promocode${index}`);
      return activeElement===elem;
    })
  }, [passengerList, activeElement])

  const isAnyActive = activeElements.filter(el => !!el).length>0;
  const body = useMemo(() => {
    return {
      rflexNr,
      jsonInput: {
        idOrigin: origin,
        idDestination: destination,
        dateIda: ida,
        dateVolta: volta,
        passengerList
      }
    }
  }, [destination, ida, origin, rflexNr, volta, passengerList]);

  if(!isAnyActive) {
    prevBody = body;
  }

  /*CAL, 2020-11-20 - contornar cache data*/
  const lockAux =  (lock || !ida || !passengerList || passengerList.length===0);
  const url = "/api/ticketing/trips";
  const fetchJsonParams : FetchJsonParams = { body:prevBody, method:"POST", headers:null };
  const fetchKey = hex_md5(JSON.stringify({url, lockAux, refreshKey, fetchJsonParams}));
  const newRefreshKey = (fetchKey === lastFetchKey) ? lastRefreshKey : (refreshKey || "") + Date.now().toString();
  //console.log("refreshKey:" + refreshKey + " | Ori:" + origin + " | Dest:" + destination + " | RflexNr:" + rflexNr + " | Pass:" + JSON.stringify(passengerList));
  //console.log("NewRefreshKey:" + newRefreshKey + " | FetchKey:" + fetchKey);
  lastFetchKey = fetchKey;
  lastRefreshKey = newRefreshKey;
  /*------------*/

  //const [data, status, meta] = useFetchOnce<any>("/api/ticketing/trips", {
  const [data, status, meta] = useFetchOnce<any>(url, {
    lock: lockAux,//lock || !ida || !passengerList || passengerList.length===0,
    refreshKey: newRefreshKey,//refreshKey,
    method: "POST",
    keepData: true,
    delay: 100,
    body: prevBody
  });

  return [
    (data ? data[0] : null),
    status,
    meta
  ];
}