const allowedChars = ".!@#$%&'*+-/=?^_`{|}~".split("");
/*eslint no-useless-escape: "off"*/
export const isEmailValidLatin = (email : string) => {
	const charArr = email.split("");
	let okChars = true;
	charArr.forEach(char => {
		const c = char.charCodeAt(0);
		const idx = allowedChars.indexOf(char);
		const currCharValid1 = idx>=0;
		const currCharValid2 = (c>=65 && c<=90) || (c>=97 && c<=122) || (c>=48 && c<=57);
		if(!currCharValid1 && !currCharValid2) okChars = false;
	});
	if(!okChars) return false;
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}