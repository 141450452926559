import React, { useState } from "react";
import { connect } from "react-redux";
import { dispatchSubmit } from "forms";
import { ENV_DEV } from "consts";
const connector = connect((state : any, props : any) => ({
	formObject: state.form[props.form]
}))

export const FormConfirmBase = connector(({form, formObject, RenderButton, timeout=0, ...rest} : any) => {
	if(ENV_DEV) {
		if(!form) {
			console.error("form attribute required");
			return <span style={{color: "red"}}>ERROR Check Console</span>
		}
		if(!RenderButton) {
			console.error("RenderButton attribute required, should be a react component");
			return <span style={{color: "red"}}>ERROR Check Console</span>
		}
	}
	const { anyTouched, submitSucceeded, submitting, syncErrors, submitFailed, triggerSubmit } = formObject || {};
	let status : any = (() => {
		if(triggerSubmit || submitting) return 1;
		if(submitSucceeded) return 2;
		if((syncErrors || submitFailed) && (anyTouched || triggerSubmit)) return 3;
		return 0;
	})();
	const [tempStatus, setTempStatus] = useState<any>(null);
	const tempIsNumber = typeof tempStatus === "number";
	if(tempIsNumber) {
		if(status<=1) {
			setTempStatus(null);
		}
	}
	else {
		if(status>=2) {
			setTimeout(() => {
				setTempStatus(0);
			}, timeout);
			setTempStatus(status);
		}
	}

	const onClick= (e: any) => {
		setTimeout(() => dispatchSubmit(form));
		e && e.preventDefault();
	}
	return <RenderButton {...rest} onClick={onClick} form={form} status={tempIsNumber ? tempStatus : status} />
});