import { FETCH } from 'types';
import { useFetchOnce } from 'hooks';
import { APIDTO } from "@card4b/apidto-ts";
export interface UseFetchSTicketReprintProps {
  docId: string;
  lock?: boolean;
}


export type UseFetchTicketsReprintResult = [
  APIDTO.Ticketing.ReprintTicketsInfo[],
  FETCH.FetchOnceStatus,
  FETCH.FetchOnceMethods
]

export const useFetchTicketsList = (props : UseFetchSTicketReprintProps) : UseFetchTicketsReprintResult => {
  const { docId, lock } = props;
  const [data, status, meta] = useFetchOnce<any>("/api/ticketing/reprintList", { method: "POST", body: { docId }, lock });
  return [
    data ? data.list : null,
    status,
    meta
  ]
}