import { useRouteQueryKeys } from "hooks";

export const useConfigurationSelection = () : [any, any, any]=> {
	const [query, { addQuery }] = useRouteQueryKeys();
	const { chartType, showAllUsers } = query;
	return [
		{
			chartType: chartType || "vertical",
			showAllUsers: showAllUsers==="true"
		},
		null,
		{ save: (obj: any) => addQuery(obj) }
	]
}