import React from "react";
import { PROCESSING_STR } from "consts";
import { Flex } from "controls";
import { fetchJson } from "modules/fetch";
import { FieldSelectEditable, FieldCheckEditable, FieldRadioEditable, FormErrorsContainer, FormConfirm, dispatchChange } from "forms";

import { Loader } from "controls";
import { LOADING_STR } from "consts";
import { useReduxFormSelector } from "forms";
import { useInitial } from "api/app/useInitial";
import { reduxForm } from "redux-form";
import { useOrigins } from "api/locations/useOrigins";
import { useUserDetails } from "api/user/useUserDetails";
import { useReservationOffer } from "api/reservation/useReservationOffer";

const name = "userDefaultDepartureForm";

const connectForm = reduxForm({
	form: name,
	// validate: () => {},
	enableReinitialize: true,
});


export const Form: any = connectForm(({
	form,
	formObject,
	initialValues,
	submitSucceeded,
	pristine,
	valid,
	error,
	submitting,

	originsNational,
	originsInternational
}: any) => {
	
	if(formObject && formObject.registeredFields && (!formObject.values || Object.keys(formObject.values).length===0)) {
		Object.keys(formObject.registeredFields).forEach(key => {
			dispatchChange(name, key, initialValues[key]);
		})
	}

	const submitDisabled = (!valid && !error) || pristine || submitting;
	return <form className={form}>
		<div className="form-row">
			<label style={{fontWeight: "bold"}}>
				Configurações
			</label>
		</div>
		<Flex row between fullWidth gap={10}>
			<Flex column fullWidth>
				<FieldSelectEditable
					name="originNational"
					filter={true}
					placeholder="Origem Predefinida"
					items={originsNational && [{ value: "", text: ""},...originsNational.map((c: any)=>({...c, value: c.id}))]}
				/>
				<FieldSelectEditable
					name="originInternational"
					filter={true}
					placeholder="Origem Predefinida"
					items={originsInternational && [{ value: "", text: ""},...originsInternational.map((c: any)=>({...c, value: c.id}))]}
				/>
				<FieldCheckEditable
					name="printZebra"
				/>
				<FieldRadioEditable
					name="offerPreference"
					items={[
						{value: "national", text: "Nacional"},
						{value: "international", text: "Internacional"},
					]}
				/>
			</Flex>
			<div>
				<FormConfirm form={form} disabled={submitDisabled}>
					Gravar
				</FormConfirm>
			</div>
		</Flex>
		<div className="flex-between">
			<FormErrorsContainer form={form} />
			{valid && !error && <React.Fragment>
				{!submitting && submitSucceeded && <span>Configurações gravadas com sucesso</span>}
				{submitting && <span>{PROCESSING_STR}</span>}
			</React.Fragment>}
		</div>
	</form>
})

export const UserDefaultDepartureForm = (props: any) => {
	const [formObject] = useReduxFormSelector(name);
	const [{ defaultOffer }] = useReservationOffer();
	const [, refresh] = useInitial();
	const [details] = useUserDetails();
	const [originsNational, { loading: nationalLoading }] = useOrigins({offer: "national"});
	const [originsInternational, { loading: internationalLoading }] = useOrigins({offer: "international"});
	const { sysAttribute } = details || {};

	const PrinterState = sysAttribute && sysAttribute.filter(c=>c.Name==="PrinterState");
	const DepartureCode = sysAttribute && sysAttribute.filter(c=>c.Name==="DepartureCode");

	const printerStateVal = PrinterState && PrinterState[0] && PrinterState[0].Value;
	const departureCodeVal = DepartureCode && DepartureCode[0] && DepartureCode[0].Value;
	const departureCodeValSplit = departureCodeVal && departureCodeVal.split(";");

	const printZebra = printerStateVal==="true";
	const originNational = departureCodeValSplit && (departureCodeValSplit.length>1 ? departureCodeValSplit[0] : departureCodeValSplit[0]);
	const originInternational = departureCodeValSplit && (departureCodeValSplit.length>1 ? departureCodeValSplit[1] : departureCodeValSplit[0]);

	const initialValues = {
		printZebra,
		originNational,
		originInternational,
		offerPreference: defaultOffer
	}

	const onSubmit = async (values: any) => {
		const departure = (values.originNational||"")+";"+(values.originInternational||"")
		const request = {
			attributes: [
				{ Name: "PrinterState", Value: values.printZebra ? "true" : "false" },
				{ Name: "DepartureCode", Value: departure },
				{ Name: "OfferPreference", Value: values.offerPreference },
			]
		}
		await fetchJson("/api/user/attributes/save", {method: "POST", body: request });
		await refresh();
	};

	if(nationalLoading || internationalLoading) {
		return <Loader text={LOADING_STR}/>
	}

	return <Form
		initialValues={initialValues}
		onSubmit={onSubmit}
		originsNational={originsNational}
		originsInternational={originsInternational}
		formObject={formObject}
	/>
}