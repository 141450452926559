import { useRefresherNamed } from "hooks";

const status: any = {
	updating: false,
	creating: false,
	mask: null,
	message: null,
	error: null,
	hierarchyMask: null,
	chartError: null
}

export const useConfigurationState = () => {

	const [refresh] = useRefresherNamed("useConfigurationState");

	const { updating, creating, mask, message, error, hierarchyMask, chartError } = status;
	const setUpdating = (value: any) => { status.updating = value; refresh(); }
	const setCreating = (value: any) => { status.creating = value; refresh(); }
	const setMask = (value: any) => { status.mask = value; refresh(); }
	const setMessageInner = (value: any) => { status.message = value; refresh(); }
	const setErrorInner = (value: any) => { status.error = value; refresh(); }
	const setHierarchyMask = (value: any) => { status.hierarchyMask = value; refresh(); }
	const setChartErrorInner = (value: any) => { status.chartError = value; refresh(); }
	
	const setChartError = (chartError: any, delay: any) => {
		setChartErrorInner(chartError);
		if(delay) setTimeout(() => setChartErrorInner(null), delay)
	}

	const setMessage = (message: any, delay: any) => {
		setMessageInner(message);
		if(delay) setTimeout(() => setMessageInner(null))
	}
	const setError = (error: any, delay: any) => {
		setErrorInner(error);
		if(delay) setTimeout(() => setErrorInner(null))
	}

	return [
		{
			mask,
			message,
			error,
			chartError,
			hierarchyMask,
		},
		{
			...status,
			updating,
			creating
		},
		{
			setChartError,
			setHierarchyMask,
			setMask,
			setMessage,
			setError,
			setUpdating,
			setCreating
		}
	]
}