import {
  mergeFetchOnceMeta,
  mergeFetchOnceStatus
} from 'modules/fetch';
import { useFetchDestinations } from './useFetchDestinations';
import { API } from "types";
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

export const useDestinations = ({ offer, origin } : API.HOOKS.UseDestinationsProps) : FetchOnceResult<API.RESPONSES.DestinationsResponse> => {
  const [dataNat,statusNat,metaNat] = useFetchDestinations({
    lock: !origin || offer==="international",
    national: true,
    international: false,
    origin
  });
	const [dataInt,statusInt,metaInt] = useFetchDestinations({
    lock: !origin || offer==="national",
    national: false,
    international: true,
    origin
  });

  const data =
    offer==="both" ?
      (
        (dataNat && dataInt) ? [...dataNat, ...dataInt] :
        (dataNat || dataInt)
      ) :
    offer==="national" ? dataNat :
		offer==="international" ? dataInt : [];
		
	const status =
		offer==="both" ? mergeFetchOnceStatus(statusNat, statusInt) :
		offer==="national" ? statusNat :
		offer==="international" ? statusInt :
		statusNat;

	const meta =
		offer==="both" ? mergeFetchOnceMeta(metaNat, metaInt) :
		offer==="national" ? metaNat :
		offer==="international" ? metaInt :
		metaNat;

	return [
		data,
		status,
		meta
	];
}