import { useClientInfoRouting } from './useClientInfoRouting';
import { APIDTO } from "@card4b/apidto-ts";
import { useClientInfoCustomers } from "./useClientInfoCustomers";
import { useClientInfoCustomer } from "./useClientInfoCustomer";
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

interface UseClientInfoData {
  customerId: string;
  customers: APIDTO.User.Customer[];
  customer: APIDTO.User.Customer
}

type UseClientInfoResult = FetchOnceResult<UseClientInfoData>;

export const useClientInfo = () : UseClientInfoResult => {
  const [{ customerId }] = useClientInfoRouting();
	const [customers, status, meta] = useClientInfoCustomers();
	const [customer] = useClientInfoCustomer();
	return [
		{ customerId, customers, customer },
    status,
    meta
	]
}