import { useEffect } from 'react';

/**
 * Hooks clicks outside of element
 * @param ref reference to object is being to be listened
 * @param handler called when user clicked out of referenced object
 */
export function useOnClickOutside(ref : React.MutableRefObject<any>, handler : (e : any) => any) {
	useEffect(
		() => {
			const listener = (event: any) => {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}

				handler(event);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);

			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		},
		[ref, handler]
	);
}