import { usePassengersForm } from "../usePassengersClient";
import { useReservationSearchConfirmed } from "../../search/useReservationSearchConfirmed";
import { useMemo } from "react";
import { PassengerTrips } from "types/passengers/PassengerTrips";


export const usePassengersTripsList = () : [PassengerTrips[]]=> {
  const [{ volta, reschedule_of_ida, reschedule_of_volta }] = useReservationSearchConfirmed();
  const [pForm] = usePassengersForm();
  const { values: { passengers } } = pForm;
  const reschedule_of : string[] | null = useMemo(() => {
    if(reschedule_of_ida) {
      if(!!volta && reschedule_of_volta) {
        return [reschedule_of_ida, reschedule_of_volta]
      }
      return [reschedule_of_ida];
    }
    return null;
  }, [reschedule_of_ida, reschedule_of_volta, volta])
  const result : PassengerTrips[] = useMemo(() => {
    return passengers && passengers.map(({ promocode, fare_type_id }, index) => {
      return {
        promocode: promocode,
        fare_type_id,
        id: index+1,
        reschedule_of: reschedule_of || undefined
      }
    })
  }, [passengers, reschedule_of]);
  return [result]
}
