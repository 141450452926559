export const priceFormat = (value : number, params : any = {}) : any => {
	const { digits = 2 } = params;
	if(typeof value!=="number") {
		switch(typeof value) {
		case "string": return priceFormat(+value, params);
		default: return priceFormat(-1, params);
		}
	}
	const result = /*parseFloat*/((Math.round(value * 100) / 100)).toFixed(digits);
	return result+"€";
}