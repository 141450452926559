import React from "react";
import clsx from "clsx";
import { useInitialSearch } from "api";
import "./index.scss";
import { Form } from "./Form";

export const FormSchedulesSoveSearch = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
  const [initialValues] = useInitialSearch();
  return <div {...restAttributes} className={`schedules-search-sove-form ${clsx(className)}`}>
    <Form initialValues={initialValues}/>
  </div>
}