import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { ENV_DEV } from "consts";
import { routerMiddleware } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form';


const { hostname, port } = window.location;
const isStagingHostname = hostname==="testes.rede-expressos.pt" || (hostname==="10.92.177.20" && port==="6003");
const isDebug = ENV_DEV || isStagingHostname;
export const history = require("history").createBrowserHistory();
export const middlewareHistory = routerMiddleware(history);
const middlewares = [middlewareHistory];

const getMiddlewares = () => {
	if(!isDebug) { return applyMiddleware(...middlewares) }
	return composeWithDevTools(applyMiddleware(...middlewares));
}

export const store = createStore(
  combineReducers({
    form: formReducer
  }),
  getMiddlewares()
)

export const dispatch = store.dispatch;
export const getState = store.getState;