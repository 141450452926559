import { useFetchOnce } from "hooks";
import { useConfigurationAccordion } from "./useConfigurationAccordion";
import {
	ENABLING_STR,
	DISABLING_STR,
	ACCOUNT_DEPTH_KEY,
	COMPANY_DEPTH_KEY,
	GROUP_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
	// CONFIRM_ERROR_TIMEOUT_MS,
} from "consts";
import { useConfigurationUsers } from "./useConfiguration";
import { useConfigurationHierarchy } from "./useConfiguration";
import { useConfigurationState } from "./useConfigurationState";
import { fetchJson } from "modules/fetch";
import { useStateNamed } from "hooks";
import { useRouteQueryKey } from "hooks/routing";
import { useUserDetails } from "api/user/useUserDetails";
import { useUserLogout } from "api/user/useUserLogout";

const levelsList = [COMPANY_DEPTH_KEY, GROUP_DEPTH_KEY, POINT_OF_SALE_DEPTH_KEY, ACCOUNT_DEPTH_KEY];
const levelRoutes = ["company", "group", "pos", "agent"];
const levelDec = (level: any) => levelsList[levelsList.indexOf(level) + 1];

export const useConfigurationBlock = ({ level }: any) => {
	const [logout] = useUserLogout();
	const [levelSelected] = useRouteQueryKey("level");
	const lowerLevel = levelDec(level);
	const levelRoute = levelRoutes[levelsList.indexOf(level)];
	const [details] = useUserDetails();
	const { userType } = details || {};
	const [,,{refresh: refreshUsers}] = useConfigurationUsers({ lock: userType==="OPERATOR" });

	const [,, {refresh: refreshHierarchy}] = useConfigurationHierarchy();
	const [accordion, , {save: saveAccordion}] = useConfigurationAccordion();
	const [,, {setCreating, setUpdating, setHierarchyMask}] : any = useConfigurationState();
	const { id, parentId } = (accordion && accordion.level === level && accordion) || {};
	const [actionLoading, setLoading] = useStateNamed<any>("useConfigurationBlock.actionLoading", null);
	const lock = !id || level!==levelSelected;
	const [itemData, itemStatus, { refresh }] = useFetchOnce<any>(`/api/configuration/${levelRoute}/load`, { method: "POST", lock, body: { id } });

	const refreshComplete = async () => {
		await refreshHierarchy();
		await refreshUsers();
		await refresh();
	}

	const add = async (ID: any) => {
		await saveAccordion({ id: null, parentId: ID, level: lowerLevel });
		await refreshComplete();
	}
	const edit = async (ID: any) => {
		await saveAccordion({ id: ID, parentId: null, level });
		// await refreshComplete();
	}
	const enable = async (ID: any) => {
		setLoading(ENABLING_STR);
		try {
			await fetchJson("/api/configuration/agent/enable", { method: "POST", body: { id: ID }});
			await saveAccordion({id: null, parentId: null, level: null});
			await refreshComplete();
		}
		catch(error) {
			console.error(error);
			// setChartError('Ocorreu um erro a activar o elemento', 2000);
			setHierarchyMask(null);
		}
		setLoading(false);
	}
	const disable = async (ID: any) => {
		setLoading(DISABLING_STR);
		try {
			// setHierarchyMask("A desactivar...");
			await fetchJson(`/api/configuration/${levelRoute}/disable`, { method: "POST", body: { id: ID } });
			if (level === COMPANY_DEPTH_KEY) {
				setHierarchyMask('Efectuou a desactivação da empresa. Deixará de ter acesso à aplicação.');
				setTimeout(logout, 3000);
			}
			await saveAccordion({id: null, parentId: null, level: null});
			await refreshComplete();
		}
		catch (error) {
			console.error(error);
			setHierarchyMask(null);
			// setChartError('Ocorreu um erro a desactivar o elemento', 2000);
		}
		setLoading(false);
	}
	const save = async (values: any) => {
		const { ID } = values;
		const isCreate = !ID;
		const statusFunc = isCreate ? setCreating : setUpdating;
		statusFunc(true);
		await fetchJson(`/api/configuration/${levelRoute}/${isCreate ? "create" : "update"}`, {
			method: "POST",
			body: values
		});
		saveAccordion({ id: null, parentId: null, level: null });
		statusFunc(false);
		await refreshComplete();
	}

	return [
		{
			level,
			id,
			parentId,
			...itemData
		},
		{
			...itemStatus,
			loading: itemStatus.loading && !lock,
			actionLoading
		},
		{
			save,
			edit,
			add,
			enable,
			disable,
			refresh
		}
	]
}