import React from "react";
import { TableOld } from "controls";
import { hocDialog } from "controls/dialogs";

const dialogConnect = hocDialog({
	name: "FooterAlertsDialog",
	title: "Informação Alertas",
	resizable: true,
	draggable: true,
	minWidth: 700,
	minHeight: 200,
	outsideClose: true
});

export const FooterAlertsDialog = dialogConnect(({ alerts }: any) => {
	return <div id="Div4" className="agentInfoBox" style={{width: "100%", height: "100%"}}>
		<div id="updPanelAlertsInfo">
			<div id="alertsTableContainer">
				<TableOld
					id="alertsInfoTable"
					selectable={true}
					isDefault={true}
					>
					<thead>
						<tr>
							<th>Título</th>
							<th>Conteúdo</th>
						</tr>
					</thead>
					<tbody>
						{alerts && alerts.map((item: any, index: any) => {
							//info={JSON.stringify(item)}
							return <tr key={index} className={index%2 === 0 ? "" : "colored-old"}>
								<td>{item.Title}</td>
								<td>{item.Body}</td>
							</tr>
						})}
					</tbody>
				</TableOld>
			</div>
		</div>
	</div>
})