import React, { useState } from "react";
// import { dispatchAppendObject, getState, fetchJson } from "modules";
import { /*CONFIRM_ERROR_TIMEOUT_MS, */LOADING_STR } from "consts";
import { DialogReportSubmission } from "./DialogReportSubmission";
import { Loader, ErrorEditable } from "controls";
import { /*useLiquidationNotes, */ useLiquidationRefresher } from "hooks";
import { useFetchAction } from "hooks";
import { useRouteQueryKey } from "hooks";
import { hocDialog } from "controls/dialogs";
import { useLiquidationCurrent } from "api/liquidation";

const connector = hocDialog({
	name: "DialogConfirmSubmission",
	title: "Confirmação",
	minWidth: 500,
	minHeight: 100
})

export const DialogConfirmSubmission = connector(({setConfirmSubmissionOpen, setParentState, close}: any) => {
	const [, refreshLiquidation] = useLiquidationRefresher();
	const [, setNoteID] = useRouteQueryKey("noteid");
	const [currentLiquidation] = useLiquidationCurrent();
	const [reportSubmissionID, setReportSubmissionID] = useState<boolean>(false);

	const [, { loading, error }, { execute: executeSubmit }] = useFetchAction("/api/liquidation/approve/submit", {
		body: (() => {
			const id = currentLiquidation && currentLiquidation.liquidation.ID;
			return { ID: id };
		})()
	});

	return <div style={{display: "flex", flexDirection: "column"}}>
		{loading && <Loader text={LOADING_STR} overlay={true}/>}
		{error && <ErrorEditable code={error.error}/>}
		<p>Tem a certeza que pretende submeter a nota de liquidação?</p>
		{reportSubmissionID && <DialogReportSubmission setParentState={setParentState} reportSubmissionID={reportSubmissionID} loading={loading} onClose={()=>{
			setReportSubmissionID(null);
			setNoteID(null);
			close();
			setConfirmSubmissionOpen(null);
		}}/>}
		<div className="dialog-buttons">
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={async () => {
					try {
						const id = currentLiquidation && currentLiquidation.liquidation.ID;
						if(id) {
							await executeSubmit();
							refreshLiquidation();
							setReportSubmissionID(id);
						}
					}
					catch(error) {
						console.error(error);
					}
				}}>
				Submeter
			</button>
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={()=>{
					close();
				}}>
				Cancelar
			</button>
		</div>
	</div>
});