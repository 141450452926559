import React from "react";
import { DropTarget } from 'react-dnd'

const DroppableChartSrc = ({ className, isOver, connectDropTarget, children }: any) => {

	return connectDropTarget(<div className={`chart-root ${className} ${isOver ? "over" : ""}`}>
		{children}
	</div>)
}

export const Droppable = DropTarget(
	'box',
	{},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	}),
)(DroppableChartSrc);