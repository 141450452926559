import { fetchJson } from "../modules/fetchJson";
import { useFetchOnce } from "./useFetchOnce";
import { deepCopy } from "../../deep";
import { hex_md5 } from "../../encrypt";
import { useRefresherNamed } from "../../../hooks";
import { RemoteStateResult } from '../types/RemoteStateResult';

const loadings: any = {};
const caches: any = {};
const savings: any = {};

// let lastLog = "";

interface UseRemoteStateProps {
	url: string;
	key?: any;
	empty: any;
	lock?: boolean;
}

export const useRemoteState = (
	{url, key, empty, lock=false}: UseRemoteStateProps
) : RemoteStateResult<any> => {
	const dataKey = hex_md5("useRemoteState2"+url+key);
	const [forceUpdate] = useRefresherNamed(dataKey);
	const [
		fetchData,
		{ loading: fetchLoading, success: fetchSuccess, error: fetchError },
	] = useFetchOnce<any>(url, { method: "GET", lock });
	
	const data = caches[dataKey] || fetchData || empty || null;
	const setLoading = (value: any) => { loadings[dataKey]=value; forceUpdate(); }
	const setSaving = (value: any) => { savings[dataKey]=value; forceUpdate(); }
	const setData = (value: any) => { caches[dataKey]=value; }

	const save = async(obj : any) : Promise<any> => {
		const oldState = deepCopy(data);
		const newState = Object.assign({}, oldState, obj);
		if(JSON.stringify(newState)===JSON.stringify(caches[dataKey])) {
			return;
		}
		caches[dataKey] = newState;
		setSaving(true);
		try {
			const response = await fetchJson(url, { method: "POST", body: newState });
			setData(response);
			setSaving(false);
			return response;
		}
		catch(error) {
			setData(oldState);
			setSaving(false);
			console.error(error);
		}
	}

	const refresh = async() => {
		if(lock) return;
		setLoading(true);
		try {
			const response = await fetchJson(url, { method: "GET" });
			setData(response);
		}
		catch(error) {
			console.error(error);
		}
		setLoading(false);
	}

	const saving : boolean = savings[dataKey] || false;
	const loading = loadings[dataKey] || fetchLoading || false;

	return [
		data,
		{
			loading,
			saving,
			success: fetchSuccess,
			error: fetchError
		},
		{
			refresh, 
			save
		}
	]
}