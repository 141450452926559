import React from "react";
import "./index.scss";
import { LOADING_STR } from "consts";
import { TitlePanel } from "controls";
import { useDashboardList } from "api";
import { useSalesRefresher } from 'hooks/refreshers';
import { SalesPanelTable } from "./SalesPanelTable";
import { datetimeStringify } from "modules";

export const SalesPanel = ({ onSearch } : { onSearch : () => any }) => {
	const [,salesRefresh] = useSalesRefresher();
	const [sales, { success, loading }] = useDashboardList();

	const timeCurr = sales && sales.time;
	const timeSplit = timeCurr && timeCurr.length>0 && new Date(timeCurr);
	const timeStr = datetimeStringify(timeSplit).toFieldDateTimeString();

	return <TitlePanel
		type={"dark"}
		title={"Dashboard de Vendas"}
		loading={loading && LOADING_STR}
		lastRefresh={success && sales && timeStr}
		onRefresh={salesRefresh}
		onSearch={onSearch}
		className={`salesPanel panel-dashboard full-height`}>
		<div className="table-container flex-row">
			<SalesPanelTable />
		</div>
	</TitlePanel>
}