import React, { useState } from "react";
import { dispatchSubmit } from "forms";
import { LOADING_STR, LOGIN_SUCCESS } from "consts";
import { Loader, Flex, ButtonStatusEditable } from "controls";
import { FormErrorsContainer, FieldTextEditable, FieldPasswordEditable } from "forms";
import { Navigation } from "navi";
import { reduxForm, SubmissionError } from "redux-form";
import { UserLoginFunctionType } from "api/user";
import { getState } from "app/store";

// const ValuesDebugger = () => {
// 	const [values] = useReduxFormSelectorValues("loginForm");
// 	return <div>{values && JSON.stringify(values)}</div>
// }

const FormComponent = ({
	form,
	submitSucceeded,
	pristine,
	valid,
	error,
	submitting
}: any) => {


	// const submitDisabled = (!valid && !error) || pristine || submitting;
	const [requireSubmit, setRequireSubmit] = useState<boolean>(false);
	const { loginForm } = (getState().form) || {};
	const { values } = loginForm || {};
	const { userName, password } = values|| {};

	if(requireSubmit && userName && password) {
		setTimeout(async () => {
			await setRequireSubmit(false);
			setTimeout(() => {
				dispatchSubmit(form);
			});
		});
	}
	
	return <form className={`${form} login-form`} id="loginForm">
		{submitting && <div className="mask-holder"><div className="mask-holder-inner"><Loader text={LOADING_STR} overlay/></div></div>}
		{submitSucceeded && <div className="mask-holder"><div className="mask-holder-inner"><Loader text={LOGIN_SUCCESS} overlay/></div></div>}
		<Flex ratio={[5,1]} gap={8}>
			<div>
				<FieldTextEditable
					name="userName"
					className="testcn2"
					autoFocus={true}
					id="nameField"
					autoComplete="current-password"
					required
					// validate={[requiredValidate]}
				/>
				<FieldPasswordEditable
					name="password"
					id="passwordField"
					// autoComplete="current-password"
					required
					// validate={[requiredValidate]}
				/>
			</div>
			<div className="flex-column flex-end margin-bottom-8">
				{/* <FormConfirm form={form} disabled={submitDisabled}>Entrar</FormConfirm> */}
				<ButtonStatusEditable
					scope="loginForm"
					as="confirm"
					// disabled={submitDisabled}
					styling="primary"
					status={{ loading: submitting, error: !!error }}
					onClick={() => {
						setTimeout(() => {
							setRequireSubmit(true);
						})
					}}
				/>
			</div>
		</Flex>
		{ !error ? <div className="form-errors-panel hide-repeating-errors">
			<FormErrorsContainer form={form} />
		</div> : <FormErrorsContainer form={form} /> }
	</form>
}

// export const LoginForm = FormComponent

export const LoginForm = reduxForm<{
	userName: string;
	password: string;
},{
	navigation?: Navigation<any>,
	login?: UserLoginFunctionType
}>({
	form: "loginForm",
	onSubmit: async (values, a, { navigation, login }) => {
		try {
			await login({
				user: values.userName,
				password: values.password
			});
			setTimeout(() => {
				navigation.navigate("/")
			}, 2000);
		}
		catch(error) {
			console.error(error);
			throw new SubmissionError({_error: error.error});
		}
	}
})(FormComponent);