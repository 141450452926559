import React from "react";
import { AdminPanel } from "../AdminPanel";
import "./index.scss";
import { useAdminConfigs } from "admin/hooks";

export const AdminsList = () => {
  const [configs] = useAdminConfigs();
  if(!configs) return null;
  const userIDs = configs.admins;
  return <div className="flex-column admin-admins-list">
    <table>
      <thead>
        <th>UserID / Email</th>
        <th>Permissions</th>
        <th></th>
      </thead>
      <tbody>
        {userIDs && userIDs.map((userID : string) => <AdminPanel userID={userID}/>)}
      </tbody>
    </table>
  </div>
}