import React from "react";

interface CaretArrowIconProps extends React.HTMLAttributes<{}> {
	direction: ("up" | "down");
}

export const CaretArrowIcon = ({ direction, ...htmlAttributes } : CaretArrowIconProps) => {
	const { style, ...restAttributes } = htmlAttributes;
	return <i
		{...restAttributes}
		className={`fa fa-caret-${direction}`}
		style={{position: "absolute", right: "6px", top: "calc(50% - 7px)", ...style }}
	/>
}