import { APIDTO } from '@card4b/apidto-ts';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';
import { useTripsSelectedRoutes } from 'api/ticketing/useTripsSelectedRoutes';
import { useCallback, useEffect } from 'react';
import { RouteTicketSelectionParams } from 'types/ticketing/RouteTicketSelectionParams';

export const useTripsValidator = () : void => {
  const [{ outgoing_schedules, return_schedules }, { loading, success }] = useTripsConfirmed();
  const [idaSelection, setIda] = useTripsSelectedRoutes("ida");
  const [voltaSelection, setVolta] = useTripsSelectedRoutes("volta");

  const makeCheckDir = (
    schedules : APIDTO.API.DateSchedule[],
    selection: RouteTicketSelectionParams,
    setSelection: (data : RouteTicketSelectionParams) => Promise<void>
  ) => {
    let isFoundSelection = false;
    selection && schedules && schedules.forEach(schedule => {
      const isScheduleOK = schedule.schedule_id===selection.schedule_id;
      const isTypeOK = isScheduleOK && schedule && schedule.min_price_per_type.filter(c=>c.price_type_id===selection.price_type_id).length>0;
      if(isScheduleOK && isTypeOK) {
        isFoundSelection = true;
      }
    })
    if(!isFoundSelection && schedules && schedules.length>0) {
      const first = schedules && schedules[0];
      const { schedule_id, min_price_per_type } = first || {};
      const firstPrice = min_price_per_type && min_price_per_type[0];
      const { price_type_id } = firstPrice || {};
      if(schedule_id && price_type_id)
        setSelection({ schedule_id, price_type_id });
    }
  }
  
  const makeCheck = useCallback(() => {
    makeCheckDir(outgoing_schedules, idaSelection, setIda);
    makeCheckDir(return_schedules, voltaSelection, setVolta);
  }, [idaSelection, outgoing_schedules, setIda, return_schedules, voltaSelection, setVolta]);

  useEffect(() => {
    if(success)
      makeCheck();
  }, [makeCheck, outgoing_schedules, return_schedules, loading, success, idaSelection, voltaSelection]);
}