import React from "react";
import { FieldSelectEditable } from "forms";
// import { useReservationOffer } from "api";
import { useReservationId } from "api";
import { useDestinationsOffer, useReservationSearchFormValues } from "api";
import clsx from "clsx";

const EMPTY_VALUE : any = {//{ value: string, text: string }
  value: null,
  text: ""
}

interface FieldDestinationProps extends React.HTMLAttributes<{}> {}

export const FieldSchedulesSearchDestination = ({...htmlAttributes} : FieldDestinationProps) => {
	const [reservationId] = useReservationId();
	// const [{ offer }] = useReservationOffer();
	const [{ origin }] = useReservationSearchFormValues();
	const [destinations, { loading }] = useDestinationsOffer({ origin });
	const { className, ...restAttributes } = htmlAttributes;
	const destinationsShown = destinations ? [EMPTY_VALUE, ...destinations.map(c=>({...c, value: c.id}))] : [EMPTY_VALUE]

	return <FieldSelectEditable
		{...restAttributes}
		disabled={!!reservationId || !origin}
		loading={loading}
		name="destination"
		className={`${clsx(className)} field-destination`}
		required
		filter={true}
		items={destinationsShown}
		maskOption="{text} ({value})"
	/>
}