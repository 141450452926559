import { ItemsMergedData } from "./ItemsMergedData";
import { datetimeStringify } from "modules"//datetimeParse

export const dateOfTrip = (item : ItemsMergedData) => {
	const { ExtraInfo } = item;
	if(!ExtraInfo) return "";
	const arItem = ExtraInfo.filter(c=>c.Name==="DepartureDate")[0];
	if(!arItem) {
		return null;
	}
	const valueUTCStr = arItem.Value;
	const res = datetimeStringify(new Date(valueUTCStr)).toFieldDateTimeString();
	return res;
}