import React from "react";
import { ButtonBaseProps } from "../types";

export const ButtonBase = ({onClick, className, children, ...restProps} : ButtonBaseProps) => {
  return <button 
    {...restProps}
    className={`btn ${className}`}
    onClick={e => {
      onClick && onClick(e);
      e && e.preventDefault();
    }}
  >
    {children}
  </button>
}