import { parse, stringify } from "query-string";
import { BROWSER_LANG } from "../../../consts";
import { convertPathnameToLang } from "../../convert/convertPathnameToLang";
import { lowStorage } from "../../managers";
import { FetchDataParams } from "../types/FetchDataParams";

export const normalizeFetchRequest = (url : string, params : FetchDataParams = {}) => {
	if(!params) params = {};
  if(!params.headers) params.headers = {};
	const { auth } = lowStorage.all();
	if(auth && Object.keys(auth).length>0) {
		Object.keys(auth).forEach(key => {
			if(key && params.headers) {
				params.headers["auth-"+key] = auth[key];
			}
		})
	}
	params.headers[BROWSER_LANG] = convertPathnameToLang(window.location.pathname);

	if(!params.method) params.method = "GET";
	
	if(!params.headers["content-type"]) params.headers["content-type"] = "application/json; charset=utf-8";
	if(params.method==="GET") {
		if(params.body) {
			const qIndex = url.indexOf("?");
			if(qIndex>=0) {
				url+=stringify(params.body);
			}
			else {
				url+="?"+stringify(params.body);
			}
			params.body = undefined;
		}
	}
	else {
		const qIndex = url.indexOf("?");
		if(qIndex>=0) {
			params.body = JSON.stringify({
				...(params.body ? (typeof params.body==="string" ? JSON.parse(params.body) : params.body) : {}),
				...parse(url.substring(qIndex))
			});
			url = url.substring(0, qIndex);
		}
	}
	if(typeof params.body==="object") params.body = JSON.stringify(params.body);

	return [
		url,
		params
	]
}