import React from "react";
// import { connect } from "react-redux";
import "./index.scss";
import { useReduxFormSelector } from "forms";

const filterRepeatingErrors = (errorsList: any) => {
	const newErrorsList : any[] = [];
	const cache: any = {};
	errorsList && errorsList.map((err: any): any => {
		if(!cache[err[0]+err[1]]) {
			newErrorsList.push(err);
			cache[err[0]+err[1]] = true;
		}
		return null;
	})
	return newErrorsList;
}

export const FormErrorsBase = ({form, RenderError, ...rest}: any) => {
	const errorList : any[] = [];
	const [formObject] : any = useReduxFormSelector(form);
	const { syncErrors, fields, submitErrors, asyncErrors, error } = formObject;
	syncErrors && Object.keys(syncErrors).forEach(key => {
		const code = syncErrors[key];
		if(Array.isArray(code)) {
			code.forEach((obj, arrayIndex) => {
				obj && Object.keys(obj).forEach(fieldName => {
					if(
						fields &&
						fields[key] &&
						fields[key][arrayIndex] &&
						fields[key][arrayIndex][fieldName] &&
						fields[key][arrayIndex][fieldName].touched)
					{
						errorList.push([fieldName, obj[fieldName]])
					}
				})
			})
		}
		else {
			if(fields && fields[key]) {
				if(fields[key].touched) {
					errorList.push([key,code]);
				}
			}
		}
	});
	asyncErrors && Object.keys(asyncErrors).forEach(key => {
		errorList.push([
			key,
			(() => {
				const val = asyncErrors[key];
				if(typeof val==="string") return val;
				if(typeof val==="object") {
					if(val.errorDetails) return val.errorDetails;
					if(val.error) return val.error;
					return null;
				}
			})()
		]);
	});
	submitErrors && Object.keys(submitErrors).forEach(key => {
		errorList.push([key,submitErrors[key]]);
	});

	if(error) {
		errorList.push([null,error])
	}
	// if(!ENV_DEV && !anyTouched) {
	// 	return null;
	// }
	return <div className="form-errors">
		{errorList.length>0 && filterRepeatingErrors(errorList).map((err, index) => {
			if(!err) return null;
			const [name, code] = err;
			if(!code) return null;
			return <RenderError key={index} {...rest} form={form} formKey={name} formCode={code} />
		})}
	</div>;
}