import React from "react";
import clsx from "clsx"
import { LOADING_STR } from "consts";
import { Loader } from "controls";
import { useInitialPassengers, useReservationOffer, useClientInfo } from "api";
import { validateSovePassengers } from "./validateSovePassengers";
import "./index.scss";
import { Form } from "./Form";
import { Passenger } from "types/passengers/Passenger";
import { useFormValidator } from "./useFormValidator";

const FormHolder = () => {
  const [passengers, { loading }] = useInitialPassengers();
  const [{offer}] = useReservationOffer();
  if(loading || !passengers) return <Loader text={LOADING_STR} overlay />;
  return <Form
    style={{}}
    initialValues={{passengers}}
    validate={({passengers} : { passengers: Passenger[] }) => {
      const result = validateSovePassengers({
        passengers,
        international: offer==="international"
      });
      if(result && Object.keys(result).length>0) {
        return result;
      }
      return {};
    }}
  />
}

const Validator = () => {
  useFormValidator();
  return <></>;
}

export const FormPassengersSoveMain = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const [{ customerId, customer }] = useClientInfo();
  const loading = false;
  const [{ offer }] = useReservationOffer()
  const { className, ...restAttributes } = htmlAttributes;
  return <div {...restAttributes} className="passengers-view">
    <div className={`passengers-view-form ${clsx(className)}`}>
      {loading && <Loader text={LOADING_STR} overlay />}
      <FormHolder key={`${offer}_${customerId}_${customer && customer.Email}`}/>
      <Validator />
    </div>
  </div>
}