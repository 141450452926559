import React, { useRef, useState } from "react";
import { fetchJson } from "modules/fetch";
import { useAdminConfigs } from "admin/hooks";
import { useInitial } from "api/app/useInitial";
import { useServer } from "api/app/useServer";

const addAdminAction = async (value : any) => {
  let email = null;
  let userID = null;
  if(value.indexOf("@") >= 0) {
    email = value;
  }
  else {
    userID = value;
  }
  /*const result = */await fetchJson("/api/admin/admins/add", { method: "POST", body: { email, userID }});
}

export const AdminAdd = () => {
  const inputRef : any = useRef();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [,{ refresh: refreshPermissionsList }] = useAdminConfigs();
  const [,refreshInitial] = useInitial();
  const [{initialized}] = useServer();
  
  const onCancelClick = async () => {
    inputRef.current.value = "";
    setIsAdding(false);
  }
  const onAddClick = async () => {
    const { value } = inputRef.current;
    if(!isAdding) {
      inputRef.current.value = "";
      setIsAdding(true);
      return;
    }
    else {
      await addAdminAction(value);
      await refreshPermissionsList();
      if(!initialized) {
        window.location.reload();
      }
      if(initialized) {
        await refreshInitial();
        await onCancelClick();
      }
    }
  }
  

  return <div className="flex-row flex-between" style={{padding: 20}}>
    <div>
      <div className={isAdding ? "" : "hidden"}>
        <input placeholder="Email or user id" ref={inputRef}/>
      </div>
    </div>
    <div>
      <button className="btn-primary" onClick={onAddClick}>Add Admin</button>
      {isAdding && <button className="btn-secondary" onClick={onCancelClick}>Cancel</button>}
    </div>
  </div>
}