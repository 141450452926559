import React from "react";
import { RootGateProps } from "forms/base/gates";

export const DatePrimitiveCalendarIcon = ({ gateState } : { gateState: RootGateProps }) => {
  const { extras, props } = gateState || {};
  const { onClickLabel } = (extras || {}) as any;

  return <div onClick={onClickLabel} style={{display: "flex", justifyContent: "flex-end"}} className="calendar-icon">
    <i {...props} style={{ width: 0, fontSize: "24px", minWidth: "fit-content" }} className="icon-Calendario" />
  </div>
}