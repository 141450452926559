import { hocMergeFields } from "./hocMergeFields";

import { 
  RadioPrimitive,
  SelectPrimitiveProps,
  SelectPrimitive,
  PrimitiveFieldBase,
  PrimitiveItemedProps,
  CaptchaPrimitiveProps,
  DatePrimitiveProps,
  FileB64PrimitiveProps,
  DatePrimitive,
  FileB64Primitive,
  TextPrimitive,
  TextAreaPrimitive,
  LabelPrimitive,
  CaptchaPrimitive,
  CheckPrimitive
} from "../../input"

import { defaultsList } from "./defaultsList";

export const [FieldTextSimple, FieldTextComplex, FieldTextEditable] = hocMergeFields<PrimitiveFieldBase>(TextPrimitive, defaultsList.text);
export const [FieldTextAreaSimple, FieldTextAreaComplex, FieldTextAreaEditable] = hocMergeFields<PrimitiveFieldBase>(TextAreaPrimitive, defaultsList.textarea);
export const [FieldLabelSimple, FieldLabelComplex, FieldLabelEditable] = hocMergeFields<PrimitiveFieldBase>(LabelPrimitive, defaultsList.label);
export const [FieldNumberSimple, FieldNumberComplex, FieldNumberEditable] = hocMergeFields<PrimitiveFieldBase>(TextPrimitive, defaultsList.number);
export const [FieldEmailSimple, FieldEmailComplex, FieldEmailEditable] = hocMergeFields<PrimitiveFieldBase>(TextPrimitive, defaultsList.email);
export const [FieldHiddenSimple, FieldHiddenComplex, FieldHiddenEditable] = hocMergeFields<PrimitiveFieldBase>(TextPrimitive, defaultsList.hidden);
export const [FieldPasswordSimple, FieldPasswordComplex, FieldPasswordEditable] = hocMergeFields<PrimitiveFieldBase>(TextPrimitive, defaultsList.password);
export const [FieldCheckSimple, FieldCheckComplex, FieldCheckEditable] = hocMergeFields<PrimitiveFieldBase>(CheckPrimitive, defaultsList.check);
export const [FieldCaptchaSimple, FieldCaptchaComplex, FieldCaptchaEditable] = hocMergeFields<CaptchaPrimitiveProps>(CaptchaPrimitive, defaultsList.captcha);
export const [FieldDateSimple, FieldDateComplex, FieldDateEditable] = hocMergeFields<DatePrimitiveProps>(DatePrimitive, defaultsList.date);
export const [FieldFileB64Simple, FieldFileB64Complex, FieldFileB64Editable] = hocMergeFields<FileB64PrimitiveProps>(FileB64Primitive, defaultsList.fileB64);
export const [FieldSelectSimple, FieldSelectComplex, FieldSelectEditable] = hocMergeFields<SelectPrimitiveProps>(SelectPrimitive, defaultsList.select);
export const [FieldRadioSimple, FieldRadioComplex, FieldRadioEditable] = hocMergeFields<PrimitiveItemedProps>(RadioPrimitive, defaultsList.radio);
