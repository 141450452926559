const _hostname = window.location.hostname;
export const ENV_DEV = process.env.NODE_ENV === "development";
export const IS_TESTES = _hostname.indexOf("testes.rede-expressos.pt")>=0;
export const IS_MAIN = !IS_TESTES && _hostname.indexOf("rede-expressos.pt")>=0;
export const IS_LOCAL = Boolean(
	_hostname === "localhost" ||
	_hostname.indexOf("192.168")===0 ||
	_hostname === "[::1]" ||
	_hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
export const IS_SUPPORT_SW = "serviceWorker" in navigator;
export const IS_SECURE = window.location.protocol.indexOf("https")>=0;
export const IS_DEBUG = ENV_DEV || IS_LOCAL || IS_TESTES;