import React from "react";
import { TableOld, ContentBoxTitle, Loader, DownloadingStatusMask } from "controls";
import { datetime } from "modules";
import { useFetchOnce } from "hooks";
import { useStateAppend } from "hooks";
import { LOADING_STR } from "consts";
import { useSaleInfoId, useVoucherNavigation } from "api";
import { ENV_DEV } from "consts";

import {
	XLSImage,
	PDFImage,
	PrintImage,
	LinkDownload,
	LinkPrint
} from "controls";
import { hocDialog } from "controls/dialogs";
import { APIDTO } from "@card4b/apidto-ts";
import { useLiquidationNotes } from "api/liquidation/useLiquidationNotes";

const Row = ({name, children, value} : any) => <tr>
	<td className="text-bold">{name} </td>
	<td>
		{value && <label>{value}</label>}
		{children}
	</td>
</tr>

const connector = hocDialog({
	name: "DialogNoteDetails",
	title: "Vendas",
	minWidth: 900,
	minHeight: 500
});

export const DialogNoteDetails = connector(({ noteID } : any) => {
	const [response, noteSalesStatus] = useFetchOnce<APIDTO.Agents.AgCompanyBasketDTO[]>("/api/liquidation/note/sales/", { method: "GET", body: { liquidationId: noteID }});
	const [notesList, { loading: noteLoading }] = useLiquidationNotes();
	const selectedNote0 = notesList && notesList.notes && notesList.notes.filter((c: any)=>""+c.liquidation.ID===""+noteID);
	const selected = selectedNote0 && selectedNote0[0];
	const notes = (() => {
		if(!response) return null;
		const list : {
			sale: APIDTO.Agents.AgCompanyBasketDTO;
			item: APIDTO.Agents.AgCompanyBasketItemDTO;
		}[] = [];
		response.forEach((sale) => {
			const items = sale.Items.sort((a, b) => {
				if(a.ItemID>b.ItemID) return 1;
				if(a.ItemID<b.ItemID) return -1;
				return 0;
			}).map((item) => ({
				sale,
				item
				// Reservation: sale,
				// ItemID: item.ItemID,
				// Value: item.Value,
				// IsVolta: item.IsVolta
			})).filter((c : any)=>!!c);

			items.forEach((item) => {
				list.push(item);
			});
		});

		return list.sort((a, b) => {
			if(a.sale.TimeSale > b.sale.TimeSale) return -1;
			if(a.sale.TimeSale < b.sale.TimeSale) return 1;
			return 0;
		});
	})();

	const ERROR_MESSAGE = "De momento não é possível obter a informação pretendida";
	const { accountDetailsMessage } = { accountDetailsMessage: false };
	const { isManager } = { isManager: false };
	const [state, setState] = useStateAppend({});
	const [, setSaleInfoId] = useSaleInfoId();
	const [, setVoucherId] = useVoucherNavigation();
	const xlsUrl = "/api/liquidation/note/xls?LiquidAccPeriod="+noteID;
	const pdfUrl = "/api/liquidation/note/pdf?LiquidAccPeriod="+noteID;

	return <>
		<DownloadingStatusMask {...state}/>
		<div className={isManager ? "SalesAccDetailsForm" : "SalesAccDetailsFormOperator"}>
			{noteSalesStatus.loading && <Loader overlay={true} text={LOADING_STR}/>}
			{!noteSalesStatus.error && <div id="liquidationNoteSalesDetailsHeader">
				<ContentBoxTitle>
					Detalhes
				</ContentBoxTitle>
				{noteLoading && <Loader text={LOADING_STR}/>}
				{selected && <table>
					<tbody>
						<Row name={"Operador:"} value={selected.operatorAccount.Name} />
						<Row name={"Id:"} value={selected.liquidation.LiquidID} />
						<Row name={"Data Início:"} value={(selected.liquidation.PeriodBegin ? (datetime.formatDateHours(selected.liquidation.PeriodBegin)) : " Sem Vendas ")} />
						<Row name={"Data Submissão:"} value={(selected.liquidation.PeriodEnd ? (datetime.formatDateHours(selected.liquidation.PeriodEnd)) : " - ")} />
						<Row name={"Data Aprovação:"} value={(selected.liquidation.TimeLiquidAck ? (datetime.formatDateHours(selected.liquidation.TimeLiquidAck)) : " - ")} />
						<Row name={"Data Limite:"} value={(selected.liquidation.TimePeriodLimit ? (datetime.formatDateHours(selected.liquidation.TimePeriodLimit)) : " - ")} />
						<Row name={"Valor:"} value={`${selected.liquidation.Value && (+selected.liquidation.Value).toFixed(2)} €`} />
						<Row name={"Exportar:"}>
							<LinkDownload href={xlsUrl} onStatus={setState}><XLSImage /></LinkDownload>
							<LinkDownload href={pdfUrl} onStatus={setState}><PDFImage /></LinkDownload>
							<LinkPrint href={pdfUrl} onStatus={setState}><PrintImage /></LinkPrint>
						</Row>
					</tbody>
				</table>}
			</div>}
			<ContentBoxTitle>Vendas </ContentBoxTitle>
			<TableOld
				selectable={true}
				isDefault={true}
			>
				<thead>
					<tr>
						{["Data","Cód. Reserva","Nº Venda","Nº Bilhete / Voucher","Data da Viagem","Origem/Destino","Valor(€)"].map(item=>{
							return <th key={item}>{item}</th>
						})}
					</tr>
				</thead>
				<tbody>
					{notes && notes.map((note, index) => {
						const modulus = (index - 1) % 2;
						const classStr = (modulus === 0 ? ' colored-old' : ' ');
						const { sale, item } = note;
						const { SRPOID, OrderID, SaleID, TimeSale } = sale;
						const { Type, ItemID, Value } = item;
						// const {  } = Reservation;
						const TimeSaleStr = (TimeSale as any);
						const saleDate = TimeSaleStr && TimeSaleStr.length>0 ? datetime.formatDateHours(new Date(TimeSaleStr)) : null;
						// let travelDate;
						let DepartureDate : string;
						let origin;
						let destination;
						sale.ExtraInfo && sale.ExtraInfo.forEach((value: any) => {
							if (value.Name === "DepartureDate") {
								DepartureDate = value.Value;
								// travelDate = value.Value;
							} else if (value.Name === "Origin") {
								origin = value.Value;
							} else if (value.Name === "Destination") {
								destination = value.Value;
							}
						})
						const travelDate = DepartureDate && DepartureDate.length>0 ? datetime.formatDateHours(new Date(DepartureDate)) : null;
						return <tr
							key={index}
							className={classStr}
							onClick={async () => {
								if(Type===0) await setSaleInfoId(SRPOID);
								if(Type===1) await setVoucherId(SRPOID);
							}}
						>
							<td><label>{saleDate}</label></td>
							<td><label>{OrderID}</label></td>
							<td><label>{SaleID}</label></td>
							<td><label>{ItemID}</label></td>
							<td><label>{(travelDate ? travelDate : ' - ')}</label></td>
							<td><label>{(origin ? origin : '') + " - " + (destination ? destination : '')}</label></td>
							<td><label>{(Value && (+Value).toFixed(2))} €</label></td>
						</tr>
					})}
				</tbody>
			</TableOld>
			{noteSalesStatus.error && <p className="error">{ERROR_MESSAGE}</p>}
			{ENV_DEV && <div className={`CurrLiquidationSalesInfoPane ${isManager ? "no_display":""}`}>
				<table>
					<tbody>
						<tr>
							<td>
								Vendas (€):
								<input type="text" readOnly={true} disabled={true} value={selected && selected.liquidation.Value && (+selected.liquidation.Value).toFixed(2)} id="CurrLiquidationSalesTotal"/>
							</td>
							<td>
								<label className="error">{accountDetailsMessage}</label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>}
		</div>
	</>
});