import React from "react";
import { JsonView } from "controls";
import { useRouteQueryKeys } from "hooks";
import { DevDialogChild } from "debug/controls";
import { useEnv, useLowStorage } from "hooks";
import { useAdmin } from "api/admin";
import { useInitial } from "api/app/useInitial";
import { useConfigs } from "api/app/useConfigs";
import { useUserStorage } from "api/user/useUserStorage";
import { useUser } from "api/user/useUser";

export const HooksDebug = () => {
	const [user] = useUser();
	const [admin] = useAdmin();
	const [env] = useEnv();
	const [configs] = useConfigs();
	const [lowStorage] = useLowStorage();
	const [initial] = useInitial();
	const [userStorage] = useUserStorage();
	const [routeQueryKeys] = useRouteQueryKeys();

	return <>
		<DevDialogChild>
			<JsonView name={"useInitial"} data={initial} />
			<JsonView name={"useConfigs"} data={configs} />
			<JsonView name={"useLowStorage"} data={lowStorage} />
			<JsonView name={"useEnv"} data={env} />
			<JsonView name={"useUser"} data={user} />
			<JsonView name={"useAdmin"} data={admin} />
			<JsonView name={"useUserStorage"} data={userStorage} />
			<JsonView name={"useRouteQueryKeys"} data={routeQueryKeys} />
		</DevDialogChild>
	</>
}