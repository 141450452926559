import React from "react";
import { ViewInitialConfigs } from "admin/views";
import { useAdminConfigs } from "admin/hooks";
import { AdminAdd } from "admin/components";

const InitialSetup = () => {
  const [configs] = useAdminConfigs();
  if(!configs) return null;
  const channelOK = configs.initialList.ChannelID>0;
  const adminRequire = (() => {
    if(configs.admins && configs.admins.length>0) return false;
    if(!channelOK) return false;
    return true;
  })();
  return <div>
    <h1>Card4b project initialization</h1>
    <ViewInitialConfigs />
    {adminRequire && <AdminAdd />}
  </div>
}

export default InitialSetup;