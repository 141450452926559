import React from "react";
import "./index.scss";
import { TitlePanel, TableSchemed } from "controls";
import { LOADING_STR } from "consts";
import { useRouteQueryKey } from "hooks";
import { API } from "types";
import { APIDTO } from '@card4b/apidto-ts';
import { useFetchStops } from "api/ticketing/useFetchStops";
import { useTripsTicketsSelected } from "api/ticketing/useTripsTicketsSelected";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";

interface RoutesProps {
  direction: API.LISTS.RouteDirection;
}
export const Routes = ({ direction } : RoutesProps) => {
  const [, onSelect] = useRouteQueryKey("agentsid");
  const [, { loading: loadingTrips }] = useTripsConfirmed();
  const [{ outgoing_ticket, return_ticket }] = useTripsTicketsSelected();
  const title = direction==="ida" ? "Percurso" : "Percurso volta";
  const tripId = direction==="ida" ? (outgoing_ticket && outgoing_ticket.schedule_id) : (return_ticket && return_ticket.schedule_id);
  const date =  direction==="ida" ? (outgoing_ticket && outgoing_ticket.date) : (return_ticket && return_ticket.date);
  const [stops, { loading: loadingStops }] = useFetchStops({ tripId, date });
	const loading = loadingTrips || loadingStops;
	const { outgoing_itinerary } = stops || {};
	const { legs } = outgoing_itinerary || {};
  const legStopsExtract : APIDTO.API.StopTime[] = [];
	legs && legs.forEach(leg => {
    const { stops } = leg || {};
		stops && stops.forEach((stop : APIDTO.API.StopTime, stopIndex) => {
      legStopsExtract.push(stop);
		});
  });

  return <div className="trips-routes">
    <TitlePanel title={title} loading={loading && LOADING_STR}>
      <TableSchemed
        style={{ borderCollapse: "collapse" }}
        items={legStopsExtract}
        body={{
          schema: (item : APIDTO.API.StopTime) => {
            const { stop, arrival, departure } = item;
            const { id, name } = stop;
            return {
              onClick: () => {onSelect(id)},
              items: [
                name,
                ((arrival && arrival.time) || (departure && departure.time) || "")
              ]
            }
          }
        }}
      />
    </TitlePanel>
  </div>
}