import React from "react";
import "./index.scss";
import { ButtonEmoji } from "controls";
import { useAdminStorage } from "api/admin";

export const SwitchEditableMode = () => {
	const [{ isEditable }, { append }] = useAdminStorage();
	const onClick = async ()=>{
		try {
			await append("isEditable", !isEditable)
		}
		catch(error) {
			console.error(error);
		}
	}
	return <ButtonEmoji icon="✏️" onClick={onClick} innerStyle={isEditable ? { textShadow: "3px 3px 5px red"} : {}} />
}