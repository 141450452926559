import React from "react";
import { AgentsDialog, PwdChangeDialog, SalesDetailsDialog } from "controls/dialogs";
import { useRouteQueryKey } from "hooks";
import { useSaleInfoId } from "api";
export const DialogsList = () => {
	const [agentsId, setAgentsId] = useRouteQueryKey("agentsid");
	const [isprofile, setIsProfile] = useRouteQueryKey("isprofile");
	const [srpoId, setsrpoId] = useSaleInfoId();

	return <div id="dialogs_list">
		{agentsId && <AgentsDialog onClose={async ()=>{ await setAgentsId(null); }} />}
		{isprofile && <PwdChangeDialog onClose={async ()=> await setIsProfile(null)}/>}
		{srpoId && <SalesDetailsDialog onClose={async () => { await setsrpoId(null); }}/>}
	</div>
}