import React from "react";
import { Flex, ButtonTyped, ToolTipImage } from "controls";
import { dispatchChange } from "forms";
import { maxLengthNormalize } from "forms";
import { FieldTextEditable, FieldNumberEditable, FieldEmailEditable, FieldSelectEditable } from "forms";
import { useReservationOffer, useReservationStep, usePassengersTypes, usePassengersPrices, usePassengersActions, usePassengersClient } from "api";
import { PRICE_INFO_TEXT } from "consts";
import { useTranslationDeepValue } from "app/translations";
import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';

const isChangableFair = (fare_type_id?: PassengerTypesEnum) => {
	if(!fare_type_id) return false;
	if(fare_type_id===PassengerTypesEnum.PASSENGER_ANIMAL) return true;
	if(fare_type_id===PassengerTypesEnum.PASSENGER_BIKE) return true;
	if(fare_type_id===PassengerTypesEnum.PASSENGER_SPORTSBOARD) return true;
	return false;
}

const FieldFairtype = ({name, index, ...restProps }: React.HTMLAttributes<{}> & {name: string, index : number}) => {
  const [step] = useReservationStep();
  const isRegisteredOrFinalized = step==="REGISTERED" || step==="FINALIZED";
  const [passengersTranslations] = useTranslationDeepValue("PASSENGERS", {});
  const { passengerKeys, passengerTypes } = usePassengersTypes();
  const [passengers] = usePassengersClient();

  if(!passengersTranslations || Object.keys(passengersTranslations).length===0) {
    return null;
  }
  return <FieldSelectEditable
    {...restProps}
    disabled={isRegisteredOrFinalized}
    name={name+"fare_type_id"}
    required
    items={passengerTypes.map(value=>({value, text: passengersTranslations["PASSENGER_"+value] || value }))}
    //items={passengerKeys.map(value=>({value, text: passengersTranslations["PASSENGER_"+value] || value }))}
    onChange={(e : any) => {
      const value : PassengerTypesEnum = e.target.value;
      const passenger = passengers[index];
      const { fare_type_id } = passenger;
      const translation = passengersTranslations["PASSENGER_"+value];
      const requireUpdateName = isChangableFair(value);
      const wasUpdatable = isChangableFair(fare_type_id);
      if(requireUpdateName) {
        dispatchChange("passengersForm", "passengers["+index+"].name", translation);
      }
      else if(wasUpdatable) {
        dispatchChange("passengersForm", "passengers["+index+"].name", "");
      }
    }}
  />
}

const PassengerItemRowSummary = ({name, index}: {name: string, index : number}) => {
  const [step] = useReservationStep();
  const [{ list: priceList }] = usePassengersPrices();
  const [{ removeAtIndex }] = usePassengersActions();
  const isRegisteredOrFinalized = step==="REGISTERED" || step==="FINALIZED";
  const priceFound = priceList && priceList[index] && (+priceList[index])>0;
  return <Flex ratio gap={5}>
    {!priceFound ? <div className="field FieldLabel"></div> : <div className="field FieldLabel">
        <label className="field-label">
          <ToolTipImage
            place={"bottom"}
            src={"images/icons/info.png"}
            className={isRegisteredOrFinalized ? "" : "hidden"}
            title={PRICE_INFO_TEXT}
          />
          <span className="text-center">Preço</span>
        </label>
        <label className="field-input text-center" style={{fontWeight: "bold"}}>
          {priceList && priceList[index] && priceList[index].toFixed(2)}€
        </label>
    </div>}
    <div className="flex-end">
      {!isRegisteredOrFinalized && <div>
        <ButtonTyped
          styling="primary"
          className='remove-passenger'
          onClick={()=>{ removeAtIndex(index); }}
          style={{background: "red"}}
        >
          &nbsp;X&nbsp;
        </ButtonTyped>
      </div>}
    </div>
  </Flex>
}

// let tempValue : string = '';

export const PassengerItemSoveMain = ({ name, index } : { name: string, index : number }) => {
  const [{ offer }] = useReservationOffer();

  return <Flex column style={{border: "1px dashed gray", padding: "8px 8px 0 8px", marginTop: 0, borderRadius: 3}}>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldTextEditable
        name={name+"name"}
        autoComplete="false"
        required={offer==="international"}
      />
      <FieldEmailEditable
        name={name+"email"}
      />
    </Flex>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldTextEditable
        name={name+"doc"}
        required={offer==="international"}
      />
      <FieldTextEditable
        name={name+"promocode"}
        id={`promocode${index}`}
      />
    </Flex>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldNumberEditable
        name={name+"phone"}
        normalize={[maxLengthNormalize(9)]}
      />
      <FieldFairtype name={name} index={index} />
    </Flex>
    <PassengerItemRowSummary name={name} index={index} />
  </Flex>
}