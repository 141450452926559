import { useEffect } from 'react';
import { useRefresherNamed } from "../state/useRefresherNamed";

const cachedScripts : string[] = [];
const states: any = {};

/**
 * Downloads and executes remote script
 * @param src remote source of script to be used
 * @returns [loaded, error]
 */
export function useScript(src : string) {
	const [refresh] = useRefresherNamed(`useScript.${src}`);
	if(!states[src])
		states[src] = { loaded: false, loading: false };
	let setState: any = (newState?: any): any => {
		states[src] = newState;
		refresh();
	};
	const state = states[src];

	useEffect(
		() => {
			if (cachedScripts.includes(src)) {
				setState({ loaded: true, error: false });
			} else {
				cachedScripts.push(src);
				let script = document.createElement('script');
				script.src = src;
				script.async = true;

				const onScriptLoad = () => setState({ loaded: true, error: false });
				const onScriptError = () => {
					const index = cachedScripts.indexOf(src);
					if (index >= 0) cachedScripts.splice(index, 1);
					script.remove();
					setState({ loaded: true, error: true });
				};

				script.addEventListener('load', onScriptLoad);
				script.addEventListener('error', onScriptError);
				document.body.appendChild(script);

				return () => {
					script.removeEventListener('load', onScriptLoad);
					script.removeEventListener('error', onScriptError);
					// eslint-disable-next-line react-hooks/exhaustive-deps
					setState = () => {}
				};
			}
		},
		[src]
	);
	return [state.loaded, state.error];
}