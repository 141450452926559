import React from "react";
import { ButtonBaseProps } from "../types/ButtonBaseProps";

export interface ButtonEmojiProps extends ButtonBaseProps {
	icon: string;
	innerStyle?: React.CSSProperties;
}

export const ButtonEmoji = ({innerStyle, icon, ...htmlAttributes } : ButtonEmojiProps) => {
	const { style, children, ...restAttributes } = htmlAttributes;
	return <div {...restAttributes} style={{
		...style,
		width: "50px",
		height: "50px",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		textAlign: "center"
	}}>
		<span role="img" style={{...(innerStyle || {}), userSelect: "none", fontSize: "30px" }}>{icon}{children}</span>
	</div>
}