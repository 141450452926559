import React, { useState } from "react";
//@ts-ignore
import { Resizable } from "react-resizable";

// interface PanelResizableProps {
//   resizable : boolean;
//   width: number;
//   height: number;
//   minWidth: number;
// 	minHeight: number;
// 	children: any;
// 	onResize: any;
// }

export const PanelResizable = ({ resizable, children, width, height, minWidth, minHeight, onResize, ...rest }: any) => {
  const [sizeIt, setSize] = useState<any>({width, height});
  if(!resizable) {
    return React.cloneElement(children, rest);
  }
	const onResizeInner = (e: any, { size }: any) => {
		if(minWidth && size.width<minWidth) size.width = minWidth;
		if(minHeight && size.height<minHeight) size.height = minHeight;
		setSize(size);
		onResize && onResize();
	}
	return <Resizable
		width={sizeIt.width}
		height={sizeIt.height}
		onResize={onResizeInner}
	>
		{React.cloneElement(children, {...rest, ...sizeIt})}
	</Resizable>
}