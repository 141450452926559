import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import "../styles/index.scss";
import { AppContent } from "./AppContent";
import { store } from "./store";
import { Router } from 'react-navi';
import { history } from "./store";
import { convertRoutesToNavi } from "modules/routing/convertRoutesToNavi";
import { mount, createBrowserNavigation } from "navi";
import { pages, routes } from "pages";
import { TranslationsProvider } from "../api/translations/TranslationsProvider";
import { DndProvider } from "react-dnd";
import { AdminProvider } from "api/admin/context/AdminProvider";
import { HTML5Backend } from "react-dnd-html5-backend";
import { InitialProvider } from "api/app/InitialProvider";

const navigation = createBrowserNavigation({
	history,
	routes: mount(convertRoutesToNavi({ routes, pages }))
});

export const App = () => {
	return <ReduxProvider store={store}>
		<DndProvider backend={HTML5Backend}>
			<Router navigation={navigation}>
				<InitialProvider url={"/api/app/initial"}>
					<TranslationsProvider saveUrl="/ms/translations_update/">
						<AdminProvider>
							<AppContent />
						</AdminProvider>
					</TranslationsProvider>
				</InitialProvider>
			</Router>
		</DndProvider>
	</ReduxProvider>
}