import React, { useState } from "react";
import "./index.scss";
import { DownloadingStatusMask, ErrorEditable, Loader } from "controls";
import { LOADING_STR } from "consts";
import { StackPanel, LinkPrint, LinkDownload } from "controls";
import { FieldTextEditable, FieldDateEditable, FieldSelectEditable, FormErrorsContainer } from "forms";
import { reduxForm } from "redux-form";
import { useFetchOnce } from "hooks";
import { useReduxFormSelector } from "forms";
import { useApiSettings } from "api";


const connectForm = reduxForm({
	form: "boardingTable",
	enableReinitialize: true
});

export const FormBoardingInternational = connectForm(({ form, onConfirm, valid }: any) => {
	const [settings] = useApiSettings();
	const days = settings ? +settings["MAX_ADVANCE_DAYS_INTERNATIONAL"] : 10;
	const reservationDateLimit = days;

	const [boardingCodes, { error: errorCodes, loading: loadingCodes }]: any = useFetchOnce<any>("/api/boarding/codes/");
	const [{ values, syncErrors }] = useReduxFormSelector(form); //formStateValues || {}
	const { code, date, bus }: any = values || {};

	const href = `/api/boarding/pdf?code=${code}&date=${date}&bus=${bus}`;

	const [state, setStateInn] = useState<any>({});
	const setState = (s: any) => setStateInn({ ...state, ...s })

	return <div
	// type={"dark"}
	// title={"Folha de Embarque (international)"}
	// className="boarding-table"
	// contentProps={{ id: "BoardingTableContainer" }}
	// loading={loadingCodes && LOADING_STR}
	>
		{loadingCodes && <Loader text={LOADING_STR} overlay />}
		<DownloadingStatusMask {...state} />
		<form className={`${form}`}>
			<StackPanel style={{ maxWidth: "500px" }}>
				<FieldSelectEditable
					loading={loadingCodes}
					name="code"
					filter={true}
					id={"boarding-line"}
					items={boardingCodes && boardingCodes.map((c: any) => ({ ...c, value: c.id }))}
					required
				/>
				<FieldDateEditable
					name="date"
					id={"boarding-date"}
					// imgPos="none"
					// minDate={"-0D"}
					maxDate={"+" + reservationDateLimit + "D"}
					required
				/>
				<FieldTextEditable
					name="bus"
					className="form-value"
					id="boarding-bus"
					required
				/>
				{valid && errorCodes && <ErrorEditable code={errorCodes.error} />}
				{!valid && syncErrors && <div className="form-errors-panel hide-repeating-errors">
					<FormErrorsContainer form={form} />
				</div>}
				<div className="flex-end">
					<LinkDownload
						className="btn-primary text-center"
						href={href}
						disabled={syncErrors != null}
						onStatus={(status: any) => {
							if (status.success) onConfirm(values);
							setState(status)
						}}
						style={{ whiteSpace: "nowrap", width: "auto" }}
					>
						Descarregar
					</LinkDownload>
					<LinkPrint
						className="btn-primary text-center"
						href={href}
						disabled={syncErrors != null}
						onStatus={(status: any) => {
							if (status.success) onConfirm(values);
							setState(status);
						}}
						style={{ whiteSpace: "nowrap", width: "auto" }}
					>
						Imprimir
					</LinkPrint>
				</div>
			</StackPanel>
		</form>
	</div>
})