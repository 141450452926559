/**
 * Cuts string after {limit} characters and attach {tail} in the end
 * @param {string} value - input string
 * @param {number} limit - characters length from input string
 * @param {string} tail - what to append in the end (default is "…")
 */
export const stringTruncTail = (
  value: string,
  limit: number,
  tail: string = '…',
) => value.substr(0, limit) + (value.length >= limit ? tail : '');
