import React from "react";
import { SalesPeriodsList } from "./SalesPeriodsList";
import { SalesSearch } from "./SalesSearch";
import { SalesTable } from "./SalesTable";
import { LOADING_STR } from "consts";
import "./index.scss";
import { TitlePanel, Flex, DownloadingStatusMask, ErrorEditable } from "controls"
import { useStateAppend } from "hooks";
import { useUserDetails } from "api/user/useUserDetails";
import { useSalesState } from "api/sales/useSalesState";
import { useSalesSales } from "api/sales/useSalesSales";
import { useSalesPeriods } from "api/sales/useSalesPeriods";
import { useSalesStatuses } from "api/sales/useSalesStatuses";

export const SalesPanel = (props: any) => {
	const [{ sysAttribute }] = useUserDetails();
	const [state1, setState] = useStateAppend({})
	const [,{ loading: salesLoading, error: salesError }] = useSalesState();
	const [,{ loading: itemsLoading, error: itemsError }] = useSalesSales();
	const [,{ loading: periodsLoading, error: periodsError }] = useSalesPeriods();
	const [,{ loading: statusListLoading, error: statusListError }] = useSalesStatuses();
	const isManager = sysAttribute ? sysAttribute.filter(c => c.Name === "isManager" && c.Value === "1").length > 0 : false;
	if (!isManager)
		return null;
	const loading = salesLoading || periodsLoading || itemsLoading || statusListLoading;
	const searchError = [ statusListError ].filter((x: any) => x !== false)[0];
	const tableError = [ salesError, periodsError, itemsError ].filter((x: any) => x !== false)[0];
	return <Flex {...props} className="panel-sales-top full-size" ratio={[1]} gap={5}>
		<TitlePanel
			title="Conta Corrente"
			type="dark"
			loading={loading && LOADING_STR}
			contentProps={{ className: "flex flex-column full-size" }}
		>
			<DownloadingStatusMask {...state1} />
			<Flex column style={{ height: "100%" }}>
				<SalesSearch setParentState={setState} />
				{searchError && <ErrorEditable code={searchError.error} />}
				<hr className="separator" />
				{!tableError && <Flex ratio={[1, 3]} className="sales-tables-container">
					<SalesPeriodsList />
					<SalesTable/>
				</Flex>}
				{!searchError && tableError && <ErrorEditable code={tableError.error} />}
			</Flex>
		</TitlePanel>
	</Flex>
}