import React from "react";
import { Flex, ButtonTyped } from "controls";
import { useReservationId, useReservationOffer } from "api";
import { FormErrorsContainer } from "forms";
import { usePassengersActions, useReservationSearchFormValues } from "api";
import { usePassengersClient, usePassengersPrices } from "api";
import { PassengerItemSoveMain } from "./PassengerItemSoveMain";
import { FieldArray, WrappedFieldArrayProps, reduxForm } from "redux-form";
import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';
import { Passenger } from "types/passengers/Passenger";
import { useConfigs } from "api/app/useConfigs";

const FormPassengersReplicate = () => {
	const [reservationId] = useReservationId();
	const [{ replicateFirstToOthers }] = usePassengersActions();
	return <div className="flex" style={{marginBottom: 8 }}>
		<ButtonTyped
			styling="primary"
			disabled={!!reservationId}
			className="x-small"
			onClick={async (e)=>{
				e.preventDefault();
				if(!reservationId) {
					await replicateFirstToOthers();
				}
			}}
		>
			Replicar dados do primeiro passageiro
		</ButtonTyped>
	</div>
}

const FormPassengersAdd = () => {
	const [{ addList }] = usePassengersActions();
	const [reservationId] = useReservationId();
	const [configs] = useConfigs();
	const [passengers] = usePassengersClient();
	const [{ offer }] = useReservationOffer()
	const [{ revalidations }] = useReservationSearchFormValues();
	const { Reservation_MaxPassengers } = configs || {};
	const leftToMax = Reservation_MaxPassengers - ((passengers && passengers.length)||0);
	const allowAdd = !reservationId && leftToMax>0;
	if(!!revalidations) return <div />
	return <div>
		<ButtonTyped
			styling="primary"
			className="add-passenger"
			disabled={!allowAdd}
			onClick={()=>{
				const multElem : HTMLInputElement = (document.getElementById("addPassengerMultiplier") as any);
				if(multElem) {
					const attachedList : Passenger[] = [];

					const addingPassenger : Passenger = {
						fare_type_id: PassengerTypesEnum.PASSENGER_NORMAL,
						email: offer==="international" ? passengers[0].email : ""
					}

					for(let i=0; i<Math.min(leftToMax, +(multElem.value)); i++) {
						attachedList.push(addingPassenger)
					}
					addList(attachedList);
				}
			}}
		>
			{"+ Adicionar passageiro"}
		</ButtonTyped>
		<span>x</span>
		<input
				disabled={!allowAdd}
				id="addPassengerMultiplier"
				type="text"
				defaultValue="1"
				size={2}
				maxLength={2}
			/>
	</div>
}

const FormPassengersPriceTotal = () => {
	const [{ total }] = usePassengersPrices();
	return <div>
		<span>Total:&nbsp;{total && total.toFixed(2)}€</span>
	</div>
}

export const Form = reduxForm<{},{
	style: React.CSSProperties
}>({
	form: "passengersForm",
	enableReinitialize: true,
	destroyOnUnmount: false
})(({ style, form }) => {
	return <form
		className={`tabcontent passengers-form ${form}`}
		style={{...style}}
	>
			<FormErrorsContainer form={form} />
			<Flex column>
				<FormPassengersReplicate />
				<FieldArray
					name="passengers"
					component={({ fields, meta } : WrappedFieldArrayProps<any>) => <>
						{fields.map((name, index) => {
							return <PassengerItemSoveMain name={name} index={index} />
						})}
					</>}
				/>
			</Flex>
			<hr />
			<div style={{display: "flex", justifyContent: "space-between"}}>
				<FormPassengersAdd />
				<FormPassengersPriceTotal />
			</div>
	</form>
});