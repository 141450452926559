import React from "react";
import { EditableText } from "controls";
export const LoadingButton = ({status, scope, as, style, onClick, className} : any) => {
	let errClass = "";
	let icon : any = null;
	const state = typeof status==="number" ? status :
	typeof status==="object" ? (status.loading ? 1 : status.error ? 3 : status.success ? 2 : 0)
	: 0;
	if(state===1) { icon = <i className="fa fa-spin fa-spinner"/>; errClass = "loading"; }
	if(state===2) { icon = <i className="fa fa-check"/>; errClass = "success"; }
	if(state===3) { icon = <i className="fa fa-close"/>; errClass = "error"; }
	return (
		<EditableText
			scope={scope}
			as={as}
		>
			{
				({value}) => <button
					className={"btn btn-primary "+(errClass)+(" "+className)}
					style={style}
					onClick={onClick}>{icon}<span>{value}</span></button>
			}
		</EditableText>
	)
}