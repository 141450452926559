import React from "react";
import { RootGateProps } from "../gates";
import { Gate } from "effector-react";
import { WrappedFieldProps } from "redux-form";
import { HocFieldProps } from "../fields/types";

  
  export const FieldEditableElement = ({
    WrappedGate,
    gateState,
    Primitive
  } : {
    Primitive?: (props : HocFieldProps) => JSX.Element,
    WrappedGate: Gate<WrappedFieldProps>,
    gateState: RootGateProps
  }) => {
  const {
    input,
    meta
  } = WrappedGate.state.getState();
  const {
    defaults: {
      AfterComponent,
      elementProps
    },
    props: {
      ...restProps
    }
  } = (gateState as any);
  const { value } = input || {};
  const { form } = meta;
  return <div className="field-input">
    {Primitive && <Primitive
      autoFocus={meta.active}
      {...(elementProps || {})}
      {...(restProps || {})}
      {...(input || {})}
      value={value || ""}
      active={meta.active}
      form={form}
      style={{
        zIndex: 100000
      }}
    />}
    {AfterComponent && <AfterComponent gateState={gateState}/>}
  </div>
}