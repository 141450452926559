import React, { useState } from "react";
import ReCaptcha, { RecaptchaProps } from "react-recaptcha";
import { randomGuid } from "modules/random";
import { useRoutingLang, useScript } from "hooks";
// let alreadyLoaded = false;

const sitekey = "6LfTDjQUAAAAABUH1lwIaZJc_xdzHXXvyfnQNIGj";

// let setKey = () => {}

export { RecaptchaProps }
export const RecaptchaInput = ({ verifyCallback } : RecaptchaProps) => {
	const [lang] = useRoutingLang();
	const [key] = useState<any>(randomGuid());
	// window.recaptchaScriptLoaded = () => {
	// 	if(!alreadyLoaded) {
	// 		alreadyLoaded = true;
	// 		setKey(randomGuid());
	// 	}
	// };
	// useEffect(() => {
	// 	setKey = setKeyInner;
	// 	return () => {
	// 		setKey = () => {}
	// 	}
	// }, [])
	useScript(`https://www.google.com/recaptcha/api.js?onload=recaptchaScriptLoaded&render=explicit`);
	const callback = (e : any) => {}
	// const expiredCallback = (e) => { console.log("expiredCallback:", e); }
	return <ReCaptcha
		key={key}
		sitekey={sitekey}
		// expiredCallback={expiredCallback}
		verifyCallback={verifyCallback}
		callback={callback}
		render="explicit"
		hl={lang}
	/>
}