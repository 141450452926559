import React, { useState } from "react";
import { getRouteList } from "./getRouteList"
import "./index.scss";
import { EditableText, PictureEditable } from "controls";
import { useAdminStorage } from "api/admin";

const CB = ({title, name, state, setState, description}: any) => {
	return <tr className="cb-item">
		<td><label htmlFor={"AdminFilter"+name}>{title}</label></td>
		<td>
			<input
				id={name}
				type={"checkbox"}
				checked={state[name]}
				onChange={e=>{
					setState({...state, [name]: e.target.checked })
					// set({[name]: e.target.checked }, { admin })
				}}
			/>
		</td>
		<td>{description}</td>
	</tr>
}

const Filters = ({ state, setState }: any) => {
	return <div className="admin-pages-filters">
		<table className="table">
			<tbody>
				<CB
					state={state}
					setState={setState}
					title={"Page Title"}
					name={"PageTitle"}
					description={"title: page"}
				/>
				<CB
					state={state}
					setState={setState}
					title={"Page Description"}
					name={"PageDescription"}
					description={"description: page"}
				/>
				<CB
					state={state}
					setState={setState}
					title={"Page Image"}
					name={"PageImage"}
					description={"image: page"}
				/>
				<CB
					state={state}
					setState={setState}
					title={"Meta Title"}
					name={"MetaTitle"}
					description={"title: browser tab / google / twitter / FB"}
				/>
				<CB
					state={state}
					setState={setState}
					title={"Meta Description"}
					name={"MetaDescription"}
					description={"description: google / twitter / FB"}
				/>
				<CB
					state={state}
					setState={setState}
					title={"Meta Image"}
					name={"MetaImage"}
					description={"image: twitter / FB"}
				/>
			</tbody>
		</table>
	</div>
}

export const ViewPagesMeta = () => {
  const [state, setState] = useState<any>({});
	const [{isEditable}] = useAdminStorage();
	const list = getRouteList();
	const { PageTitle, PageDescription, MetaTitle, MetaDescription, PageImage, MetaImage } = state;
	return <div className={"admin-page-items"}>
		<Filters state={state} setState={setState} />
		<table className="table table-striped-grid table-primary-header">
			<thead>
				<tr>
					<th>Name</th>
					{PageTitle && <th>Page Title</th>}
					{PageDescription && <th>Page Description</th>}
					{PageImage && <th>Page Image</th>}
					{MetaTitle && <th>Meta Title</th>}
					{MetaDescription && <th>Meta Description</th>}
					{MetaImage && <th>Meta Image</th>}
				</tr>
			</thead>
			<tbody>
				{list.map((name, index)=>{
					return <tr key={index}>
						<td><strong>{name}</strong></td>
						{PageTitle && <td><EditableText scope={name} as={"Title"}>{({value})=><span>{value}</span>}</EditableText></td>}
						{PageDescription && <td><EditableText scope={name} as={"SubTitle"}>{({value})=><span>{value}</span>}</EditableText></td>}
						{PageImage && <td><PictureEditable force={isEditable} scope={name} as={"HeaderImage"} /></td>}
						{MetaTitle && <td><EditableText scope={name} as={"MetaTitle"}>{({value})=><span>{value}</span>}</EditableText></td>}
						{MetaDescription && <td><EditableText scope={name} as={"MetaDescription"}>{({value})=><span>{value}</span>}</EditableText></td>}
						{MetaImage && <td><PictureEditable force={isEditable} scope={name} as={"MetaImage"} /></td>}
					</tr>
				})}
			</tbody>
		</table>
	</div>
}