import React from "react";
import { useConfigurationBlock } from "api/configuration";

export const EditButton = ({level, ID, IsEditable}: any) => {
	const [,,{edit}] = useConfigurationBlock({level});
	
	if(!IsEditable) return null;

	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	return <a
		className="btn-small editNodeBto"
		onClick={async()=>{
			if(!IsEditable) return;
			await edit(ID);
			
		}}
	>
		<img alt="edit" src="images/icons/editBtn.png" width="15" height="15" />
	</a>
}