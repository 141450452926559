import React from "react";
import { useCurrentRoute, View,	useNavigation } from "react-navi";
import { Header } from "app/layout/Header";
import { Footer } from "app/layout/Footer";
import { DialogsList } from "../DialogsList";
// import HTML5Backend from 'react-dnd-html5-backend';
// import { DndProvider } from "react-dnd";
import "./index.scss";
import { useServer } from "api/app/useServer";
import { useAuthorised } from "api/user";

export const Layout = () => {
	const route = useCurrentRoute();
	const navigation = useNavigation();
	const [isAuthorised] = useAuthorised();
	const [server] = useServer();
	
	const { data } = route || {};
	const { component } = data;
	const isAnonymous = !component || component==="Login" || component==="PwdReset";
	if(server) {
		if(server.initialized) {
			if(!isAuthorised && !isAnonymous) {
				setTimeout(() => {
					window.location.pathname = "/login";
				})
			}
			
		}
		else {
			if(window.location.pathname.indexOf("initial_setup")<0) {
				setTimeout(() => {
					window.location.pathname = "/initial_setup";
				})
			}
		}
	}
	if(isAnonymous && isAuthorised) {
		setTimeout(() => {
			navigation.navigate("/");
		})
	}
	return <>
		{!isAnonymous && <Header />}
		<div className="body">
			<View />
		</div>
		{!isAnonymous && <Footer />}
		{!isAnonymous && <DialogsList />}
	</>
}

// export const Layout = () => {
// 	return <DndProvider backend={HTML5Backend}>
// 		<LayoutSrc />
// 	</DndProvider>
// }