import { PassengerTypesEnum } from 'types/enum/PassengerTypesEnum';
import { deepCopy } from 'modules';
import { usePassengersClient } from "../usePassengersClient";
import { usePassengersRemote } from "../usePassengersRemote";
import { PassengersMethodsList } from 'types/passengers/PassengersMethodsList';
import { Passenger } from 'types/passengers/Passenger';

const { PASSENGER_ANIMAL, PASSENGER_SPORTSBOARD, PASSENGER_BIKE } = PassengerTypesEnum;
export const usePassengersActions = () : [PassengersMethodsList] => {
  const [passengersClient] = usePassengersClient();
  const [, , { refresh: remoteRefresh, save: remoteSave }] = usePassengersRemote();

  const dispatchCleanClient = async () => {
    // await dispatchChange(formNameSchedulesPassengers, null, null);
  }

  const saveClient = async() => {
    await remoteSave(passengersClient);
    await dispatchCleanClient();
  }

  const saveList = async(passengers : Passenger[]) => {
    await remoteSave(passengers);
    await dispatchCleanClient();
  }

  const addList = async (passengers : Passenger[]) => {
    const newList = deepCopy(passengersClient);
    passengers.forEach((pass : any) => {
      const allowOnlyOneOfThisType = [PASSENGER_ANIMAL, PASSENGER_SPORTSBOARD, PASSENGER_BIKE].indexOf(pass.fare_type_id)>=0;
      const isAlreadyExist = passengersClient.filter(c=>c.fare_type_id===pass.fare_type_id).length>0;
      const allowAdd = !allowOnlyOneOfThisType || !isAlreadyExist;
      if(allowAdd) newList.push(pass);
    });
    if(newList.length!==passengersClient.length) {
      await saveList(newList);
    }
  }

  const removeAtIndex = async (index : number) => {
		const newList : Passenger[] = [];
		passengersClient.forEach((pass, pIndex) => {
			if(index!==pIndex) { newList.push(pass); }
		})
		await saveList(newList);
  }

  const replicateFirstToOthers = async() => {
    const newList = deepCopy(passengersClient);
    const first = newList[0];
    for(let i=1; i<newList.length; i++) {
      newList[i].name = first.name;
      newList[i].email = first.email;
      newList[i].phone = first.phone;
    }
    await saveList(newList);
  }

  const changeAtIndex = async(index : number, passenger : Passenger) => {
		const newList : Passenger[] = deepCopy(passengersClient);
		newList[index] = { ...newList[index], ...passenger };
		await saveList(newList);
  }

  const methods : PassengersMethodsList = {
    saveClient,
    saveList,
    addList,
    removeAtIndex,
    dispatchCleanClient,
    replicateFirstToOthers,
    changeAtIndex,
    remoteRefresh,
    remoteSave
  }
  return [methods]
}