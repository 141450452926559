import { useSchedulesTab } from './useSchedulesTab';
import { useReservationSearchConfirmed } from '../search/useReservationSearchConfirmed';
import { useFetchSchedulesWithStops } from "./useFetchSchedulesWithStops";
import { UseSchedulesWithStopsConfirmedResult } from 'types/ticketing/UseSchedulesWithStopsConfirmedResult';

export const useSchedulesWithStopsConfirmed = () : UseSchedulesWithStopsConfirmedResult => {
  const [direction] = useSchedulesTab();
  const [{ origin, destination }, { loading: loadingSearch }] = useReservationSearchConfirmed();
  const [data, { loading, error }] = useFetchSchedulesWithStops({
    origin: direction==="ida" ? origin : destination,
    destination: direction==="ida" ? destination : origin,
    lock: loadingSearch
  });
  return [
    data,
    {
      success: !loading && !!data,
      error,
      loading: loading || loadingSearch
    }
  ]
}