import React from "react";
import { EditableText } from "controls/EDITABLE";
import { SearchInputProps } from "./SearchInputProps";
export { SearchInputProps }
export const SearchInput = ({
	scope,
	html,
} : SearchInputProps) => { 
	if(!scope) return null;
	if(!html) return null;
	return <EditableText scope={scope} as={`${html.value}`}>
		{({ value } : { value : string })=>{
			return <input	value={value}	{...html}	/>
		}}
	</EditableText>
}