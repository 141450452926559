import React from "react";
import { PrimitiveProps } from "./types";

export const LabelPrimitive = ({ value, style, ...htmlAttributes} : PrimitiveProps) => {
  return <label
    {...htmlAttributes}
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      ...(style || {})
    }}
  >
    {value}
  </label>
}