/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export class MenuGroup extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			hover: false
		}
	}
	render() {
		const { className, children, title } = this.props;
		return <li
			onMouseEnter={() => {
				this.setState({hover: true})
			}}
			onMouseLeave={() => {
				this.setState({hover: false})
			}}
		>
			<a href="#" className={className} onClick={e=>e.preventDefault()}>{title}</a>
			<ul style={{visibility: this.state.hover ? "visible" : "hidden"}}>
				{children}
			</ul>
		</li>
	}
}