import React from "react";

export const EditUserButton = ({onClick}: any) => {
	return <div onClick={onClick} className="edit-user-button" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
		<img
			className="editManagerBto"
			style={{marginLeft: "8px", cursor: "pointer"}}
			width="15"
			height="15"
			src="images/icons/editBtn.png"
			alt="edit"
		/>
	</div>
}