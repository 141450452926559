import React from "react";
import { TableOld } from "controls";
import { datetime } from "modules";
import "./index.scss";
import { useRouteQueryKey } from "hooks";
import { useLiquidationNotes } from "api/liquidation";
import { useUserDetails } from "api/user/useUserDetails";

export const LiquidationTable = () => {
	const [, setNoteID] = useRouteQueryKey("noteid");
	const [userDetails] = useUserDetails();
	const {sysAttribute} = userDetails;
	const isManager = sysAttribute ? sysAttribute.filter(c=>c.Name==="isManager" && c.Value==="1").length>0 : false;
	const [notes, { success: notesSuccess }] = useLiquidationNotes();
	const notesList = (notesSuccess && notes.notes) || [];
	if(!notes) return null;
	return <div className="liquidation-table" id="liquidationsTableContainer">
		<TableOld
			id="liquidationsListTable"
			selectable={true}
			isDefault={true}
		>
			<thead>
				<tr>
					<th>ID</th>
					<th>Data Início</th>
					<th>Data Fecho</th>
					<th>Data Aprovação</th>
					<th>Estado</th>
					{isManager && <th>Operador</th>}
					<th>Valor (€)</th>
					<th>Entrega Pendente</th>
					{isManager && <th></th>}
				</tr>
			</thead>
			<tbody>
				{notesList && notesList.map((note: any, noteIndex: any) => {
					const { liquidation, operatorAccount, status, currentUser } = note;
					const {
						ID,
						LiquidID,
						Value,
						PeriodBegin,
						PeriodEnd,
						TimeLiquidAck,
						TimePeriodLimit,
						IsOffPeriod,
						ManagerAgCpAccount
					} = liquidation;

					const { REFName } = status;
					const approveAllowed = isManager && ManagerAgCpAccount && currentUser.ID === ManagerAgCpAccount;
					const classStr = ((noteIndex - 1) % 2 === 0 ? ' colored-old' : ' ');

					return <tr
					className={classStr}
					onClick={
						() => {
							const allowOpenDialog = !!Value || !!PeriodBegin;
							if(!allowOpenDialog) return;
							setNoteID(ID);
						}
					}
					key={noteIndex}
					>
						<td><label>{LiquidID}</label></td>
						<td><label>{(PeriodBegin ? (datetime.formatDateHours(PeriodBegin)) : " Sem vendas ")}</label></td>
						<td><label>{(PeriodEnd ? (datetime.formatDateHours(PeriodEnd)) : " - ")}</label></td>
						<td><label>{(TimeLiquidAck ? (datetime.formatDateHours(TimeLiquidAck)) : " - ")}</label></td>
						<td><label>{status.Name}</label></td>
						{isManager && <td><label>{operatorAccount.Name}</label></td>}
						<td><label className={"liquidationValueCell"}>{Value ? (+Value).toFixed(2) : "0"}</label></td>
						<td>
							<label>
								{IsOffPeriod ? <React.Fragment>
								<img src="images/icons/exclamation.png" alt="icon"/>
								<label>{TimePeriodLimit ? (datetime.formatDateHours(TimePeriodLimit)) : ''}</label>
								</React.Fragment>: ""}
							</label>
						</td>
						{isManager && <td
							onClick={function(event) { approveAllowed && event.stopPropagation(); }}>
							{
								ManagerAgCpAccount && currentUser.ID === ManagerAgCpAccount ? <React.Fragment>
									{REFName==="APR" ? <input
										value={ID}
										className="liquidationIsToApprove"
										type="checkbox"/> :
										" - "
									}
								</React.Fragment> : null
							}
						</td>}
					</tr>
				})}
			</tbody>
		</TableOld>
	</div>
}