import { compose } from 'redux'
import { validate } from "./validate";
import { reduxForm } from "redux-form";

const reduxFormDecorator = reduxForm({
	form: "salesSearchForm",
	enableReinitialize: true,
	validate,
	// onSubmit
});

export const connectForm = compose(reduxFormDecorator);