import { randomGuid } from "modules/random";
import { useRouteQueryKey } from "hooks";

interface IReservationUIDMethods {
	setKey: (key : string | null) => {};
	refreshKey: () => {};
	clearKey: () => {};
}

export const useReservationUID = () : [string, IReservationUIDMethods] => {
	const [key, setKeyInner] = useRouteQueryKey("reservationUid");

	const setKey = (newKey : (string | null)) => {
		setKeyInner(newKey);
		return new Promise(resolve => {
			setTimeout(resolve, 100);
		});
	}

	return [
		key,
		{
			setKey,
			refreshKey: () => setKey(randomGuid()),
			clearKey: () => setKey(null)
		}
	]
}