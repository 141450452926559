import { useFetchOnce } from 'hooks';
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';
import { FETCH, API } from "types";

export const useFetchOrigins = ({ national, international, lock } : API.HOOKS.UseFetchOriginsProps) : FetchOnceResult<API.RESPONSES.OriginsResponse> => {
  const id = new URLSearchParams(window.location.search).get("idsso");
  const [data,status,meta] = useFetchOnce<any>("/api/locations/origins", {
    method: "POST",
    lock,
    body: {
      national,
      international,
      id
    }
  });
  return [data, status, meta];
}