import React from "react";
import { RadioInput, RadioInputProps } from "../inputs";
import { PrimitiveFieldBase } from "./types";

export interface RadioPrimitiveProps extends RadioInputProps, PrimitiveFieldBase {}

export const RadioPrimitive = (props : RadioPrimitiveProps) => {
	const { name, form, scope } = props;
  const scopePrimitive = scope ? scope : form ? (form+"_"+name) : null;
  if(!scopePrimitive) return null;
	return <RadioInput
    {...props}
    scope={scopePrimitive}
  />
}