import React from 'react';
import { Accordion, Loader, AccordionItem } from "controls";
import "./Accordion.scss";

import {
	// COMPANY_DEPTH_KEY,
	// GROUP_DEPTH_KEY,
	// POINT_OF_SALE_DEPTH_KEY,

	CREATING_STR,
	UPDATING_STR,
	LOADING_STR
} from "consts";

import {
	FormCompany,
	FormGroup,
	FormPOS
} from "./forms";
import { useStateNamed } from "hooks";
import { useConfigurationCompany, useConfigurationGroup, useConfigurationPOS, useConfigurationState } from 'api/configuration';


export const AccordionForms = () => {
	const [company, { loading: loadingCompany }, { save: saveCompany }] = useConfigurationCompany();
	const [group, { loading: loadingGroup }, { save: saveGroup }] = useConfigurationGroup();
	const [pos, { loading: loadingPOS }, { save: savePOS }] = useConfigurationPOS();
	const loading = loadingCompany || loadingGroup || loadingPOS;
	const [, { creating, updating }] : any = useConfigurationState();
	const [collapsed, setCollapsed] = useStateNamed<any>("AccordionForms.collapsed", null);
	const isCompany = company.id || company.parentId;
	const isGroup = group.id || group.parentId;
	const isPOS = pos.id || pos.parentId;

	const activeLevel = 
		isCompany ? 0 :
		isGroup ? 1 :
		isPOS ? 2 :
		false;

	return <div style={{position: "relative"}}>
		{creating && <Loader text={CREATING_STR} overlay/>}
		{updating && <Loader text={UPDATING_STR} overlay/>}
		{loading && <Loader text={LOADING_STR} overlay/>}
		<Accordion
			duration={400}
			active={collapsed===false ? false : activeLevel}
			onActivate={()=>{
				setCollapsed(collapsed===false ? true : false)
			}}
		>
			<AccordionItem title="Empresa" disabled={!isCompany} >
				<FormCompany save={saveCompany} initialValues={company}/>
			</AccordionItem>
			<AccordionItem	title="Grupo" disabled={!isGroup}>
				<FormGroup save={saveGroup} initialValues={group} />
			</AccordionItem>
			<AccordionItem title="Posto de Venda" disabled={!isPOS} >
				<FormPOS save={savePOS} initialValues={pos} />
			</AccordionItem>
		</Accordion>
	</div>
}