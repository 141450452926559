import React, { useState } from "react";
import { Editing } from "../Editing";
import { View } from "../View";
import { EditableTextEditableProps } from "../../types/EditableTextEditableProps";
let allowSave = true;

export const Editable = ({onSave, value, viewProps, ...props} : EditableTextEditableProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  if(editing) {
    const onCancel = () => setTimeout(()=>setEditing(false), 100);
    const onSaveInner = async(value: any) => {
      if(!allowSave) return;
      allowSave = false;
      setTimeout(() => { allowSave = true }, 100);
      await onSave(value);
      await setEditing(false);
    }
    return <Editing {...{ value, onCancel, onSave: onSaveInner }} />
  }

  const onEditClick = () => setTimeout(()=>setEditing(true), 100);
  
  return <div className={"block-editable"}>
    <View {...viewProps}/>
		<i className="fa fa-edit edit-icon" onClick={onEditClick} />
	</div>
}