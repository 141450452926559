import { useContext } from 'react';
import { AdminContext } from './context/AdminContext';
import { AdminAdmin } from 'types/admin/AdminAdmin';

export const useAdmin = () : [AdminAdmin, { refresh: () => {} }] => {
	const [admin, refresh] = useContext(AdminContext) || [null, () => {}];
	return [
		admin,
		{ refresh }
	];
}