import React from "react";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts";
import { FormErrorsContainer, FormConfirm, FieldTextEditable } from "forms";
import { reduxForm, SubmissionError } from "redux-form";

export const Form = reduxForm<
	{ customerId: string },
	{
		save: ({ customerId } : { customerId : string}) => any,
		setSubmitClientMessageShown: (value : boolean) => any
	}
>({
	form: "searchClientsForm",
	onSubmit: async ({customerId},a,{ save, setSubmitClientMessageShown }) => {
		try {
			setSubmitClientMessageShown(true);
			/*const response = */await save({customerId});
			setTimeout(() => {
				setSubmitClientMessageShown(false);
			}, CONFIRM_ERROR_TIMEOUT_MS);
		}
		catch(error) {
			console.error(error);
			throw new SubmissionError({_error: error.error});
		}
	}
})(({ form }) => {
	return <form className={`${form} search-clients-form`}>
		<FieldTextEditable
			name="customerId"
			placeholder=""
		/>
		<FormConfirm
			form={form}
			className="btn-primary"
		>
			Validar Cliente
		</FormConfirm>
		<div className="form-submit-row">
			<FormErrorsContainer form={form}/>
		</div>
	</form>
})