import React from "react";
// import {PROCESSING_STR, CONFIRM_ERROR_TIMEOUT_MS } from "consts";

import { useLiquidationRefresher } from "hooks";
import { Loader, ErrorEditable } from "controls";
import { LOADING_STR } from "consts";
import { hocDialog } from "controls/dialogs";
import { useFetchAction } from "hooks";
// import { useLiquidationNotes } from "api/liquidation";

const dialogConnect = hocDialog({
	name: "DialogApprove",
	title: "Confirmação",
	minWidth: 500,
	minHeight: 100,

})

export const DialogApprove = dialogConnect(({close}: any) => {
	const [, refreshLiquidation] = useLiquidationRefresher();
	const [,{ loading, error }, { execute: executeApprove }] = useFetchAction("/api/liquidation/approve/close", {
		body: (() => {
			const notesTable = document.getElementById("liquidationsListTable");
			const approveNotes = notesTable && notesTable.getElementsByClassName("liquidationIsToApprove");
			const approveNotesSelected = [];
			if(approveNotes) {
				for(let i in approveNotes) {
					const chk: any = approveNotes[i];
					if(chk.checked) approveNotesSelected.push(chk.value);
				}
			}
			return { IDs: approveNotesSelected }
		})()
	});
	// const [,, { refresh: refreshNotes}] = useLiquidationNotes();

	return <div style={{display: "flex", flexDirection: "column"}}>
		{loading && <Loader text={LOADING_STR} overlay={true}/>}
		{error && <ErrorEditable code={error.error}/>}
		<p>Tem a certeza que pretende aprovar a(s) nota(s) de liquidação?</p>
		<div className="dialog-buttons">
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={async ()=>{
					await executeApprove();
					refreshLiquidation();
					close();
				}}>
				Aprovar
			</button>
			<button
				className="ui-button ui-corner-all ui-widget"
				onClick={()=>{
					close();
				}}>
				Cancelar
			</button>
		</div>
	</div>
});