import { FETCH } from 'types';

export const useInvoiceStatus = () : [FETCH.FetchOnceStatus] => {
	const loading = false;
	const success = false;
	const error: any = null;
	const status : FETCH.FetchOnceStatus = { loading, success, error };
	return [
		status
	];
}