import { useSchedulesTab } from './useSchedulesTab';
import { useRouteQueryKey } from "hooks";

export const useSchedulesSelectedId = () : [string, (schedule_id : string) => Promise<void>] => {
  const [tab] = useSchedulesTab();
  const [schedule_id, setScheduleId] = useRouteQueryKey(`schedule_${tab}`);
  return [
    schedule_id,
    setScheduleId
  ]
}