import React from "react";
import { FieldDateEditable } from "forms";
import { useReservationId, useMaxCalendarDaysRoute } from "api";
import { useReduxFormSelector } from "forms";
import clsx from "clsx";

interface FieldVoltaProps extends React.HTMLAttributes<{}> {}
export const FieldSchedulesSearchVolta = ({ ...htmlAttributes } : FieldVoltaProps) => {
	const [reservationId] = useReservationId();
	const [{ values: { roundTrip, ida }}] = useReduxFormSelector<any>("searchForm");
	const { className, ...restAttributes } = htmlAttributes;
	let maxDateIV = "";
	const [calendarDays] = useMaxCalendarDaysRoute();
	maxDateIV = "+" + calendarDays + "D";
	let voltaMinDate = "-0D";
	if(ida) {
		const now = new Date();
		const realDiff = (ida - (+now));
		const realDiffMult = Math.ceil((realDiff) / (1000 * 60 * 60 * 24));
		const diff = Math.max(realDiffMult, 0);
		voltaMinDate = `+${diff}D`;
	}

	if(!roundTrip) {
		return <div></div>
	}
	return <FieldDateEditable
		{...restAttributes}
		name="volta"
		className={`${clsx(className)} field-origin`}
		disabled={!!reservationId}
		minDate={voltaMinDate}
		maxDate={maxDateIV}
	/>
}