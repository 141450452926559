import React from "react";
import "./index.scss";
import { ConfigList } from "./ConfigList";
import { useAdminConfigs } from "admin/hooks";
import { ConfigsInitial } from "types/configs/ConfigsInitial";
export const ViewInitialConfigs = () => {
  const [configs, methods] = useAdminConfigs();
  if(!configs) return null;
  const save = async(data : ConfigsInitial) => {
    await methods.saveInitial(data);
  }
  return <ConfigList list={configs.initialList} defaults={configs.initialDefault} save={save}/>
}

export const ViewPublicConfigs = () => {
  const [configs, methods] = useAdminConfigs();
  if(!configs) return null;
  return <ConfigList list={configs.publicList} defaults={configs.publicDefault} save={methods.savePublic}/>
}

export const ViewPrivateConfigs = () => {
  const [configs, methods] = useAdminConfigs();
  if(!configs) return null;
  return <ConfigList list={configs.privateList} defaults={configs.privateDefault} save={methods.savePrivate}/>
}

export const ViewConfigs = () => {
  return <div>
    <h1>Initial</h1>
    <ViewInitialConfigs />
    <h1>Private</h1>
    <ViewPrivateConfigs />
    <h1>Public</h1>
    <ViewPublicConfigs />
  </div>
}