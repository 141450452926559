import { useRemoteState } from 'hooks';
import { IReservationSelectionData } from '../../types/models/interfaces/reservation/IReservationSelectionData';
import { useUserDetails } from 'api/user/useUserDetails';
import { RemoteStateResult } from 'modules/fetch/types/RemoteStateResult';

export const useReservationSelection = () : RemoteStateResult<IReservationSelectionData> => {
	const [{ printZebra, ...restState }, status, meta] = useRemoteState({ url: "/api/reservation/store/selection", empty: {} });
	const [{ sysAttribute }] = useUserDetails();
	// const OfferPreference = sysAttribute && sysAttribute.filter(c=>c.Name==="OfferPreference")[0];
	const PrinterState = sysAttribute && sysAttribute.filter(c=>c.Name==="PrinterState")[0];

	const loading = status.loading;

	return [
		{
			...restState,
			printZebra: (typeof printZebra==="boolean" ? printZebra : (PrinterState && PrinterState.Value==="true") ? true : false)
		},
		{
			...status,
			loading
		},
		meta
	];
}