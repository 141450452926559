import { fileResponseHeaderDetails } from "modules/api/fileResponseHeaderDetails";
import { apiValidateResponse } from "modules/api/apiValidateResponse";
import { fetchData } from "../../fetch/modules/fetchData";

export const remoteFileGetBuffer = async (src : string) => {
	const response = await fetchData(src);
	const { filename, mimetype, headers, gzip } = fileResponseHeaderDetails(response);
  let buffer = apiValidateResponse(response) && await response.arrayBuffer();
	const result = { buffer, filename, mimetype, headers, gzip };
	return result;
}