import { useLowStorageAuth } from 'hooks/storages/useLowStorageAuth';
import { useNavigation } from "react-navi";
import { useInitial } from 'api/app/useInitial';
import { fetchJson } from 'modules/fetch/modules/fetchJson';

interface ApiError {
  error: string;
  errorDetails: string;
}
interface LoginResponse {
  userID : string;
	sessionID : string;
	error?: ApiError
}

export type UserLoginFunctionType = ({ user, password } : { user: string, password: string }) => Promise<LoginResponse>;

export const useUserLogin = () => {// : [UserLoginFunctionType]
  const [, refresh] = useInitial();
	const navigation = useNavigation();
	
	const [, setAuth] = useLowStorageAuth();
  const login : UserLoginFunctionType = async ({user, password})  => {
		try {
			const response : LoginResponse = await fetchJson("/api/app/login", { method: "POST", body: {user, password}});
			await setAuth(response);
			const { error } = response;
			if(error) throw response;
			await refresh();
			setTimeout(() => navigation.navigate("/"));
			return response;
		}
		catch(error) {
			console.error(error);
			throw error;
		}
  }
  return [login];
}