import { useCurrentRoute } from 'react-navi';
import { ReservationSteps } from 'types/reservation/ReservationSteps';
import { useReservationSearchConfirmed } from "../search/useReservationSearchConfirmed";
import { useReservationToken } from "./helpers/useReservationToken";
export const useReservationStep = () : [ReservationSteps] => {
  const { url: { query } } = useCurrentRoute();
  const [{ origin, destination }] = useReservationSearchConfirmed();
  const [reservationToken] = useReservationToken();
  const { recovery, document, recoverid, TransID } = query;
  const isSearchOK = !!origin && !!destination;
  const isSiteRecovery = (recovery && document) || !!recoverid;
  const isSiteFinalized = !!TransID;

  const current : ReservationSteps = (() : ReservationSteps => {
    if(Object.keys(query).length===0) return "NONE";
    if(reservationToken) return "REGISTERED";
    if(isSearchOK || isSiteRecovery) return "SEARCH";
    if(isSiteFinalized) return "FINALIZED";
    return "NONE";
  })();
  return [current]
}