import React from "react";
import { LinksMenu } from "./LinksMenu";
import { SessionMenu } from "./SessionMenu";
import { Z_APP_HEADER } from "consts";

export const Header = () => {
	return <div className="header" style={{zIndex: Z_APP_HEADER}}>
			<SessionMenu />
			<LinksMenu />
		</div>
}