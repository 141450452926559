import { start } from "./start";
import { isEqual } from "./isEqual";
import { add } from "./add";
import pad from "pad";
import { datetimeDateFormat } from "modules";

export const dateDashFormat = "dd-mm-yyyy";
export const dateSlashFormat = "dd/mm/yyyy";
export const timeMinutesFormat = "HH:MM";
export const timeSecondsFormat = "HH:MM:ss";

const loadingAppTime = new Date();

class DateTime {
	//SOVE
	timeParse(time: any) {
		if(typeof time === "object") return time;
		if(typeof time === "string") {
			if(time.length>0) {
				if(time.indexOf("/Date(")>=0) {
					return this.timeParseSharp(time);
				}
				else if(time.indexOf("T")>=0) {
					return this.timeParseUTC(time);
				}
			}
		}
		return null;
	}
	timeParseSharp(strTime: any) {
		if(!strTime || strTime.length===0) return null;
		const milis = strTime.replace('/Date(', '').replace(')/', '');
		const timeResult = new Date(parseInt(milis));
		return timeResult;
	}
	timeParseUTC(strTime: any) {
		if(!strTime || strTime.length===0) return null;
		const timeResult = new Date(strTime);
		return timeResult;
	}
	fromDateString(timeStr: any) {
		if(!timeStr || timeStr.length===0) return null;
		const spl = timeStr.split("/");
		const res = new Date(spl[2], spl[1] - 1, spl[0]);
		return res;
	}
	toDateString(time: any) {
		time = this.timeParse(time);
		return !time ? "" : datetimeDateFormat(time, dateSlashFormat);
	}
	
	formatDate(time: any) {
		time = this.timeParse(time);
		return !time ? "" : datetimeDateFormat(time, dateDashFormat);
	}
	formatHours(time: any) {
		time = this.timeParse(time);
		return !time ? "" : datetimeDateFormat(time, timeMinutesFormat);
	}
	formatDateHours(time: any) {
		time = this.timeParse(time);
		return !time ? "" : datetimeDateFormat(time, dateDashFormat+" "+timeMinutesFormat);
	}

	//SITE
	get initTime() { return loadingAppTime; }
	get now() { return new Date(); }
	isEqual(time1: any, time2: any, options: any) { return isEqual(time1, time2, options); }
	add(time: any, options: any) { return add(time, options); }
	start(time: any, options: any) { return start(time, options); }
	dateToString(time: any): any {
		if(typeof time==="string") return this.dateToString(new Date(time));
		const res = pad(2, ""+(time.getUTCDate()),"0")+"/"+pad(2, ""+(time.getUTCMonth()+1),"0")+"/"+time.getUTCFullYear();
		return res;
	}
	dateToStringLocal(time: any): any {
		if(typeof time==="string") return this.dateToStringLocal(new Date(time));
		const res = pad(2, ""+(time.getDate()),"0")+"/"+pad(2, ""+(time.getMonth()+1),"0")+"/"+time.getFullYear();
		return res;
	}
	stringToDate(time: any) {
			const day = time.substring(0,2);
			const month = time.substring(3,5);
			const year = time.substring(6,11);

			const yearI = parseInt(year, 10);
			const monthI = parseInt(month, 10);
			const dayI = parseInt(day, 10);
			const yearValid = Number.isNaN(yearI) ? new Date().getUTCFullYear() : yearI;
			const monthValid = Number.isNaN(monthI) ? new Date().getUTCMonth() : monthI-1;
			const dayValid = Number.isNaN(dayI) ? new Date().getUTCDate() : dayI;
			const res2 = new Date(Date.UTC(yearValid, monthValid, dayValid));
			return res2;
	}
	
	toTime(time: any) : any{
		if(typeof time==="string") return this.toTime(new Date(time));
		return pad(2, ""+(time.getUTCHours()),"0")+":"+pad(2, ""+(time.getUTCMinutes()),"0"); //WAS h
	}
	toTimeSec(time: any): any {
		if(typeof time==="string") return this.toTimeSec(new Date(time));
		return pad(2, ""+(time.getUTCHours()),"0")+":"+pad(2, ""+(time.getUTCMinutes()),"0")+":"+pad(2, ""+(time.getUTCSeconds()),"0");
	}
	toTimeSecLocal(time: any): any {
		if(typeof time==="string") return this.toTimeSecLocal(new Date(time));
		return pad(2, ""+(time.getHours()),"0")+":"+pad(2, ""+(time.getMinutes()),"0")+":"+pad(2, ""+(time.getSeconds()),"0");
	}
}

export const datetime = new DateTime();

export * from "./datetimeParseOld";
export * from "./datetimeFromDateLegacyReverseString";
export * from "./datetimeFromDateLegacyString";
export * from "./datetimeAddDays";
export * from "./datetimeDateFormat";
export * from "./datetimeStringify";
export * from "./datetimeParse";