import React from "react";
import { FieldDateEditable } from "forms";
import { useReservationId, useMaxCalendarDaysRoute } from "api";
import clsx from "clsx";

interface FieldIdaProps extends React.HTMLAttributes<{}> {}
export const FieldSchedulesSearchIda = ({ ...htmlAttributes } : FieldIdaProps) => {
	const { className, ...restAttributes } = htmlAttributes;
	const [reservationId] = useReservationId();
	let maxDateIV;
	const [calendarDays] = useMaxCalendarDaysRoute();
	maxDateIV = "+" + calendarDays + "D";
	return <FieldDateEditable
		{...restAttributes}
		className={`${clsx(className)} field-ida`}
		name="ida"
		minDate={"-0D"}
		disabled={!!reservationId}
		maxDate={maxDateIV}
	/>
}
