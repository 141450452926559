import React from "react";
// import PropTypes from 'prop-types';
import "./index.scss";
//@ts-ignore
import $ from "jquery";
import { randomGuid } from "modules/random";

class TableOld extends React.Component {
	
	guid?: string;
	props: any;

	componentDidMount() {
		this.guid = randomGuid();
	}
	render() {
		const self : any = this;
		const { id, selectable, disabled, disabledChange, isDefault, className, style, children, ...rest } = this.props;

		const selectableCN = `${selectable ? "selectable" : ""}`;
		const defaultCN = `${isDefault ? "default_table" : ""}`;
		const classNameRoot = `table-old ${className} ${selectableCN} ${defaultCN} ${this.guid}`;

		if(selectable && !disabledChange) {
			setTimeout(() => {
				$(`.${this.guid} tbody tr`).click(function() {
					const selected = $('.selected', $(self).parent());
					if (selected.length > 0) {
						selected.removeClass('selected');
					}
					$(self).addClass('selected');
					$(self).trigger('select');
				});
			});
		}
		const key = JSON.stringify({
			disabledChange,
			disabled,
			style,
			selectable,
			id
		})

		//disabled={disabled} 
		return <table key={key} {...rest} id={id} className={classNameRoot} style={style}>
			{children}
		</table>
	}
}

// TableOld.propTypes = {
// 	id: PropTypes.string,
// 	selectable: PropTypes.bool,
// 	isDefault: PropTypes.bool
// }

export {
	TableOld
}