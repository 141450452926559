import React, { useEffect } from "react";
import { EditableText } from "../EditableText";

export const ErrorEditable = ({code, ...rest}: any) => {
	useEffect(() => {
		if(code) {
			console.error({code});
		}
	}, [code]);
	return <EditableText
		{...rest}
		scope="ERRORS"
		as={code}
	>
		{({value})=><div className="error-panel"><span className="error">{value || code}</span></div>}
	</EditableText>
}