import { useFetchOnce } from "hooks";
import { useReservationId } from "./helpers/useReservationId";
import { APIDTO } from "@card4b/apidto-ts";
import { FetchOnceResult } from 'modules/fetch/types/FetchOnceResult';

export const useReservationInfo = () : FetchOnceResult<APIDTO.API.Booking> => {
	const [reservationId, { loading: idLoading }] = useReservationId();
	const [data, { loading: infoLoading, ...restInfoStatus }, meta] = useFetchOnce<any>("/api/reservation/info", { method: "POST", lock: !reservationId, body: { reservationId } });
	const loading = idLoading || infoLoading
	return [
		data,
		{
			...restInfoStatus,
			loading
		},
		meta
	]
}