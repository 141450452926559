/**
* Function convert string to Date if string is passed. if Date is passed, returns as it is
*
* @param value String or Date value
* @returns Date parsed from value param
*
* @version 1.0.0
* @throws something throws for sure
*/

//@see https://reactjs.org/docs/hooks-reference.html#useeffect

// export const datetimeParse = (value : string | Date) : Date => {
//   throw "datetimeParse Not implemented";

//   if(value instanceof Date) return value;
//   if(!value) return null;
// }

export const datetimeParseOld = (value : string | Date) : Date => {
  if(value instanceof Date) return value;
	const y = +(value.substring(0,4));
	const m = +(value.substring(5,7))-1;
  const d = +(value.substring(8,10));
  const hh = +(value.substring(11,13));
  const mm = +(value.substring(14,16));
  const ss = +(value.substring(17,19));


	return new Date(Date.UTC(y,m,d, hh, mm, ss));
}
//"2019.01.29" => date