import React from "react";
import "./index.scss";
import { descriptions } from "./descriptions";
import { getColor } from "styleguide";
import { useInitial } from "api/app/useInitial";
import { useAdminPermissions, useAdminStorage } from "api/admin";
import { useUser } from "api/user";

const iconTrue = <i className="fa fa-check-square-o"></i>
const iconFalse = <i className="fa fa-square-o"></i>

let testCountRender = 0;

// eslint-disable-next-line no-unused-vars
export const UserSettingsDisplay = ({user, setItem, onChange} : any) => {
	
	const [, refreshCurrentUser] = useInitial();
	const [userCurrent] = useUser();
	const [ permissions ] = useAdminPermissions();
	const [storage, { append }] = useAdminStorage();
	const { email } = user || {};
	const keys = Object.keys(storage);

	const onRowClick = async (key : any) => {
		await append(key, !storage[key]);
		// await fetchJson("/api/admin/admins/save_settings", {
		// 	method: "POST",
		// 	body: {
		// 		email: user.email,
		// 		settings: {...settings, [key]: !settings[key]}
		// 	}
		// });
		if(onChange) {
			setTimeout(onChange);
		}
		if(email===userCurrent.email) {
			setTimeout(refreshCurrentUser);
		}
	}

	if(testCountRender>300) {
		return null;
	}
	return <div className={"user-settings-display"}>
		<p>{JSON.stringify({email, ...storage})}</p>
		<table className="table table-striped table-primary-header">
			<thead>
				<th>key</th>
				<th>value</th>
				<th>description</th>
			</thead>
			<tbody>
				<tr>
					<td colSpan={3}><h1>{email}</h1></td>
				</tr>
				{keys.map(key=> {
					if(!permissions) return null;
					if(key==="isEditable" && !permissions.EDITABLE_TEXT) return null;
					if(key==="isEditableImage" && !permissions.EDITABLE_IMAGE) return null;
					return <tr key={key} onClick={async () => await onRowClick(key)}>
						<td>{key}</td>
						<td
							style={{ color: /*storage[key]===storage[key]*/true ? "black" : getColor("primary") }}>
							{storage[key] ? iconTrue : iconFalse}
						</td>
						<td>
							{descriptions[key]}
						</td>
					</tr>
				})}
			</tbody>
		</table>
	</div>
}