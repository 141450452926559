import React from "react";
import { isValidDate } from "modules";
import { ButtonTyped } from "controls";
import { useUserDetails } from "api/user/useUserDetails";
import { useReservationSearchConfirmAction } from "api/search/useReservationSearchConfirmAction";
import { useReservationSearchForm } from "api/search/useReservationSearchForm";
import { useReservationId } from "api/reservation/helpers/useReservationId";
export const Confirm = () => {
	const [{ agentCompany }] = useUserDetails();
	const isAllowSale = agentCompany && agentCompany.Status===3;
	const [{ confirm }] = useReservationSearchConfirmAction();
	const [{submitting, values }] = useReservationSearchForm();
	const { origin, destination, ida, volta } = values || {};
	const [reservationId] = useReservationId();
	const isDisabledSchedules = !!(reservationId || !origin || !destination || submitting);
	const isDisabledReservation = !!(isDisabledSchedules || !ida || !isValidDate(ida) || (volta && !isValidDate(volta)));

	return <div className={`search-form-confirm-sove button-set flex-end margin-bottom-8`}>
		<ButtonTyped
				styling="primary"
				className="bigger"
				disabled={isDisabledSchedules}
				onClick={async ()=>{
					await confirm("schedules");
				}}
			>
				Horários
			</ButtonTyped>
			<ButtonTyped
				styling="primary"
				className="bigger"
				disabled={isDisabledReservation || !isAllowSale}
				onClick={async ()=>{
					await confirm("trips");
				}}
			>
				Venda
			</ButtonTyped>
	</div>
}