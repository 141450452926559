const ptMonths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto','Setembro','Outubro','Novembro','Dezembro'];
const enMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September','October','November','December'];
const frMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout','Septembre','Octobre','Novembre','Décembre'];
const esMonths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

export const getMonthList = (lang : string) : string[] => {
  if(lang==='pt') return ptMonths;
  if(lang==='en') return enMonths;
  if(lang==='fr') return frMonths;
  if(lang==='es') return esMonths;
  return [];
}