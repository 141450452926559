const w : any = window;

const easings: any = {
    linear(t: any) {
      return t;
    },
    easeInQuad(t: any) {
      return t * t;
    },
    easeOutQuad(t: any) {
      return t * (2 - t);
    },
    easeInOutQuad(t: any) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t: any) {
      return t * t * t;
    },
    easeOutCubic(t: any) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t: any) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t: any) {
      return t * t * t * t;
    },
    easeOutQuart(t: any) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t: any) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t: any) {
      return t * t * t * t * t;
    },
    easeOutQuint(t: any) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t: any) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
};

export const pageScroll = (destination: any, duration = 200, easing = 'linear') => {
	const cancel = () => {
		if(!w.animationHandle) return;
		w.cancelAnimationFrame(w.animationHandle);
		w.animationHandle = null;
	};
	const start = w.pageYOffset;
	const startTime = 'now' in w.performance ? performance.now() : new Date().getTime();
	const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
	const windowHeight = w.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
	const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
	const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
	if ('requestAnimationFrame' in w === false) {
		w.scroll(0, destinationOffsetToScroll);
		if (cancel) { cancel(); }
		return;
	}

	const scroll = () => {
		const now = 'now' in w.performance ? performance.now() : new Date().getTime();
		const time = Math.min(1, ((now - startTime) / duration));
		const timeFunction = easings[easing](time);
		w.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
		if (Math.abs(w.pageYOffset - destinationOffsetToScroll) <= 1) {
			if (cancel) cancel();
			return;
		}
		w.animationHandle = w.requestAnimationFrame(scroll);
	}
	scroll();
	setTimeout(cancel, duration * 1.5);
}