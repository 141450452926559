import React, { useState } from "react";
import { LiquidationTable } from "./LiquidationTable";
import { TitlePanel, DownloadingStatusMask, ErrorEditable } from "controls"
import { LOADING_STR } from "consts";
import "./index.scss";
import { LiquidationSearch } from "./LiquidationSearch";
import { DialogNoteDetails } from "./DialogNoteDetails";
import { DialogApprove } from "./DialogApprove";
import { DialogConfirmSubmission } from "./DialogConfirmSubmission";
import { useLiquidationRefresher  } from "hooks";
import { useRouteQueryKey } from "hooks";
import { useSaleInfoId } from "api/sales";
import { useLiquidationState, useLiquidationCurrent } from "api/liquidation";
import { useVoucherNavigation } from "api";
const w : any = window;
// eslint-disable-next-line no-unused-vars
export const LiquidationPanel = ({ style } : any) => {
	const [, refreshLiquidation] = useLiquidationRefresher();
	const [noteID, setNoteID] = useRouteQueryKey("noteid");
	const [salesrpoid] = useSaleInfoId();
	const [voucherId] = useVoucherNavigation();
	const [,{loading: stateLoading}] = useLiquidationState();
	const [notes, { success: notesSuccess, loading: notesLoading, error: notesError }] = useLiquidationState();
	const [,{ loading: currentLoading, error: currentError }] = useLiquidationCurrent();
	const lastRefresh = notesSuccess && notes && notes.lastRefresh;
	const loading = notesLoading || stateLoading || currentLoading;
	const searchError = [currentError].filter((x: any) => x !== false)[0];
	const tableError = [notesError].filter((x: any) => x !== false)[0];
	const [stateV, setStateInner] = useState<any>({});
	const setState = (value: any) => setStateInner({ ...stateV, ...value });

	const [confirmSubmissionOpen, setConfirmSubmissionOpen] = useState<boolean>(false);
	const [dialogNotesApproveOpen, setDialogNotesApproveOpen] = useState<boolean>(false);
	w.setConfirmSubmissionOpen = setConfirmSubmissionOpen;
	w.setDialogNotesApproveOpen = setDialogNotesApproveOpen;

	return <TitlePanel
		type={"dark"}
		title={"Notas de Liquidação"}
		className="panel-sales-bottom salesPanel"
		lastRefresh={lastRefresh}
		onRefresh={() => {
			refreshLiquidation();
		}}
		loading={loading && LOADING_STR}
		contentProps={{
			className: "flex flex-column full-size",
			style: {
				overflowX: "hidden",
				overflowY: "auto"
			}
		}}
		style={{
			...style,
			overflow: "hidden"
		}}
	>
		<>
			<DownloadingStatusMask {...stateV} />
			<LiquidationSearch
				setParentState={(...st : any) => setState(st)}
			/>
			{searchError && <ErrorEditable code={searchError.error} />}
			<hr className="separator" />
			{!tableError && <LiquidationTable />}
			{!searchError && tableError && <ErrorEditable code={tableError.error} />}
			{noteID && <DialogNoteDetails notesList={notes} noteID={noteID} onClose={() => {
				if(salesrpoid)return;
				if(voucherId)return;
				setNoteID(null);
			}} />}
			{dialogNotesApproveOpen && <DialogApprove onClose={() => setDialogNotesApproveOpen(false)} />}
			{confirmSubmissionOpen && <DialogConfirmSubmission setConfirmSubmissionOpen={setConfirmSubmissionOpen} onClose={() => {
				setConfirmSubmissionOpen(false)
			}} />}
		</>
	</TitlePanel>
}