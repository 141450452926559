import React, { useEffect, useMemo } from "react";
import { POINTOFSALE_MANAGER, GROUP_MANAGER, CREATING_STR, UPDATING_STR, LOADING_STR } from "consts";
import { Loader, Flex, ButtonStatusEditable } from "controls";
import { FieldLabelEditable, FieldEmailEditable, FieldPasswordEditable, FieldTextEditable, FieldHiddenSimple, FieldCheckEditable, FieldSelectEditable,
	FormErrorsContainer, FormConfirm, dispatchChange } from "forms";
	import { reduxForm, SubmissionError } from "redux-form";
	import { useReduxFormSelector } from "forms";
import { useConfigurationSalesPeriods, useConfigurationHierarchy, useConfigurationState, useConfigurationAgent } from "api/configuration";
import { getState } from "app/store";
import "./index.scss";
import { maxLengthNormalize } from "forms";

const FORM_NAME = "userForm";
const Row = (props: any) => <Flex row {...props} />
const Column = (props: any) => <Flex column {...props} />
const weekDays = [
	"Segunda-Feira",
	"Terça-Feira",
	"Quarta-Feira",
	"Quinta-Feira",
	"Sexta-Feira",
	"Sábado",
	"Domingo"
];

const connectForm = reduxForm({
	form: FORM_NAME,
	onSubmit: async (values, a, { save } : any) => { // agent, setAgentID, refreshHierarchy
		try {
			await save(values);
		}
		catch(error) {
			throw new SubmissionError({_error: error.error});
		}
	},
	enableReinitialize: true,
	destroyOnUnmount: false
})

const hrs : {value:string, text: string}[] = [];
for (let i = 0; i < 24; ++i) {
	hrs.push({
		value: ""+i,
		text: ((i.toString().length === 1) ? ("0" + i) : i) + " H"
	});
}

const LiquidationNotePart = () => {
	const [{values: { Day, AccLiqPeriodType }}] = useReduxFormSelector(FORM_NAME); //Name, parentId
	const [salesPeriods] = useConfigurationSalesPeriods();
	const REFName = useMemo(() => {
		const filteredSales = salesPeriods && salesPeriods.filter((c: any) => (+c.ID===AccLiqPeriodType));
		const { REFName : result } = (filteredSales && filteredSales.length> 0 && filteredSales[0]) || {};
		return result;
	}, [AccLiqPeriodType, salesPeriods]);

	

	const [periodTypes, days, hours] = useMemo(() => {
		const periodTypes = salesPeriods && [
			{value: 0, text: " - "},
			...salesPeriods.map((item: any) => ({...item, value: item.ID, text: item.Name}))
		]
		const days = weekDays && [
			{value: "", text: " "},
			...weekDays.map((item, index) => ({value: ""+(index+1), text: item}))
		]
		const hours = hrs && [
			{value: "", text: " "},
			...hrs
		]

		return [periodTypes,days,hours]
	}, [salesPeriods]);

	

	useEffect(() => {
		if(REFName=== "DAILY" && Day!=="") {
			dispatchChange(FORM_NAME ,"Day", "");
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [REFName, Day, FORM_NAME]);


	return <div>
		<span>Nota Liquidação</span>
		<Row className="note-bordered">
			<FieldSelectEditable
				name="AccLiqPeriodType"
				required
				items={periodTypes}
			/>
			<FieldSelectEditable
				name="Day"
				className="agentTypeConf"
				disabled={REFName === "DAILY" || REFName === "MONTLY" || REFName === "YEARLY"}
				items={days}
			/>
			<FieldSelectEditable
				name="Hour"
				className="agentTypeConf"
				disabled={REFName === "MONTLY" || REFName === "YEARLY"}
				items={hours}
			/>
		</Row>
	</div>
}

export const FormUser = connectForm(({ form, submitting, submitSucceeded, valid, error } : any) => {
	const values = getState().form[FORM_NAME].values;
	const { ID } = values || {};
	const [hierarchy,{loading: hierarchyLoading}] = useConfigurationHierarchy();
	const [,{loading: stateLoading, creating, updating}]: any = useConfigurationState();
	const [, {loading: periodsLoading}] = useConfigurationSalesPeriods();
	const [agent, { loading: agentLoading, error: agentLoadingError }] = useConfigurationAgent();
	const { isPosManager, isGroupManager } = agent;
	const { UserType, Company } = hierarchy || {};
	const { Manager } = Company || {};
	const loading = agentLoading || hierarchyLoading || stateLoading || periodsLoading;
	const gestorDisabled = (
		(UserType === POINTOFSALE_MANAGER && isPosManager) ||
		(UserType === GROUP_MANAGER && isGroupManager)
	);

	return <form className={`${form} configuration-user-form`}>
		{submitting && creating && <Loader text={CREATING_STR} overlay/>}
		{submitting && updating && <Loader text={UPDATING_STR} overlay/>}
		{!submitting && loading && <Loader text={LOADING_STR} overlay />}
		<Column>
			<FieldHiddenSimple
				name="ID"
			/>
			<FieldHiddenSimple
				name="parentId"
			/>
			<Row className="ratio-single">
				<FieldTextEditable
					name={"Name"}
					required
				/>
			</Row>
			<Row ratio={[1,1]} className="ratio-double">
				<FieldTextEditable
					name={"Abrv"}
					required
					normalize={[maxLengthNormalize(20)]}
				/>
				<FieldCheckEditable
					name={"IsManagerAcc"}
					disabled={gestorDisabled}
					onChange={()=>{
						const IsManagerAcc = true;
						if (!!ID && Manager === ID && IsManagerAcc) {
							alert('Atenção! Se alterar o gestor deixará de ter acesso à gestão da empresa.');
						}
					}}
				/>
			</Row>
			<Row className="ratio-single">
				{!!ID && <FieldLabelEditable
					name={"UserName"}
				/>}
			</Row>
			<Row className="ratio-single">
				<FieldEmailEditable
					name="Email"
					required
				/>
			</Row>
			<Row className="ratio-double" ratio={[1,1]}>
				<FieldPasswordEditable
					name="pwd"
					disabled={!!ID}
					required={!ID}
					onFocus={()=>{
						dispatchChange(form ,"pwd", "");
					}}
				/>
				<FieldPasswordEditable
					name="pwdConfirm"
					disabled={!!ID}
					required={!ID}
					onFocus={()=>{
						dispatchChange(form ,"pwdCofirm", "");
					}}
				/>
			</Row>
			<LiquidationNotePart />
		</Column>
		<Flex className="margin-top-8">
			<div className={"flex-between flex-column full-width"}>
				{agentLoadingError && <span className="error">{"Erro a obter dados do utilizador"}</span>}
				{valid && !error && !submitting && submitSucceeded && <span>Utilizador criado com sucesso</span>}
				<FormErrorsContainer form={form}/>
			</div>
			<div className="button-set flex-end">
				<ButtonStatusEditable status={{ }} scope={FORM_NAME} as="clean" onClick={() => {
					dispatchChange(FORM_NAME ,"Name", "");
					dispatchChange(FORM_NAME ,"Abrv", "");
					dispatchChange(FORM_NAME ,"IsManagerAcc", false);
					dispatchChange(FORM_NAME ,"Email", "");
					dispatchChange(FORM_NAME ,"pwd", "");
					dispatchChange(FORM_NAME ,"pwdConfirm", "");
					dispatchChange(FORM_NAME ,"AccLiqPeriodType", 0);
					dispatchChange(FORM_NAME ,"Day", "");
					dispatchChange(FORM_NAME ,"Hour", "");
				}}/>
				<FormConfirm form={form} />
			</div>
		</Flex>
	</form>
});