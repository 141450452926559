/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { createHandlerList } from "../../modules/creators/createHandlerList";
import { HandlerList } from './../../types/models/complex/HandlerList';

function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick: any) => tick + 1);
  }, [])
  return update;
}

// let temporaryIndexer = 0;
const subscribers: any = {};
/**
 * Only refreshes it's listeners
 * @param name name for refresher
 * @returns [ function refresh() {} ]
 */
export const useRefresherNamedDirect = (name : string) : [() => Promise<void>] => {
  if(!subscribers[name]) subscribers[name] = createHandlerList(name);
  const subscriber : HandlerList = subscribers[name];
  // const [, forceUpdate] = useState<any>(0);
  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);
  const forceUpdate = useForceUpdate();

  useEffect( () => {
    subscriber.subscribe(forceUpdate);

    return () => {
      subscriber.unsubscribe(forceUpdate);
    }
  }, [name]);

  return [
    async () => {
      subscriber.process()
    }
  ]
}

export const useRefresherNamedTimeout = (name : string) : [() => Promise<void>] => {
  if(!subscribers[name]) subscribers[name] = createHandlerList(name);
  const subscriber : HandlerList = subscribers[name];
  const forceUpdate = useForceUpdate();

  useEffect( () => {
    subscriber.subscribe(forceUpdate);
    return () => {
      subscriber.unsubscribe(forceUpdate);
    }
  }, [name]);

  return [
    async () => {
      // await subscriber.process()
      subscriber.processTimeout()
    }
  ]
}

export const useRefresherNamed = useRefresherNamedDirect;
