import React, { useRef } from "react";
import { EditableTextEditingProps } from "../../types/EditableTextEditingProps";

export const Editing = ({onSave, onCancel, value, ...props} : EditableTextEditingProps) => {
	const ref = useRef<any>();
	const onSaveClick = async () => {
		if(onSave && typeof ref!=="undefined" && typeof ref.current!=="undefined") {
			await onSave(ref.current.value)
		}
	}
	return <div className={"block-editing"}>
			<textarea defaultValue={value} ref={ref}/>
			<i className="fa fa-check edit-icon" onClick={onSaveClick} />
			<i className="fa fa-times edit-icon" onClick={onCancel} />
	</div>
}