import { ERROR_FORM_FIELD_WRONG_MBWAY } from "../statuses/errors";
import { FormFieldValidatorFunc } from './base/FormFieldValidatorFunc';

export const mbWayValidate : FormFieldValidatorFunc = (value : any) : any => {
	if(!value) return undefined;
	const firstOK = value[0] === "9";
	const secondOK = value[1] === "1" || value[1] === "2" || value[1] === "3" || value[1] === "6";
	const lengthOK = value.length===9;
	if(!firstOK || !secondOK || !lengthOK) {
		return ERROR_FORM_FIELD_WRONG_MBWAY;
	}
	return undefined;
}