import React, { useState, useEffect, useMemo } from "react";
import "./index.scss";
import { EditableText, Place } from "controls";
import { CONFIRM_ERROR_TIMEOUT_MS, ERROR_SELECT_RESERVED_SEAT } from "consts";
import { CHANGE_SEATS as SCOPE } from "consts";
import { BusLabels } from "./BusLabels";
import { convertLegIntoBusSeats } from "./convertLegIntoBusSeats";
// import { seatsInMap } from "./seatsInMap";
import { deepCopy, fetchJson } from "modules";
import { useInitial } from "api/app/useInitial";
import { useSeatsReservationInfo } from "api/seats/useSeatsReservationInfo";
import { useSeatsReservationTotalLegIndexed } from "api/seats/useSeatsReservationTotalLegIndexed";
import { useReservationInfo } from "api/reservation/useReservationInfo";
import { APIDTO } from "@card4b/apidto-ts";
import { BookingSeatsLegGrouped } from "types/reservation/BookingSeatsLegGrouped";
let lastIsMobile: any = null;


export const getSeatsSorter = ({ rows, vertical }: any) => {
	let rowsRes = deepCopy(rows);
	if (vertical && rowsRes) {
		for (let i = 0; i < rowsRes.length; i++) {
			rowsRes[i].reverse();
		}
		return rowsRes;
	}
	return rowsRes;
};


export const useSeatsRender = ({ vertical, leg }: { vertical: boolean; leg: BookingSeatsLegGrouped }) => {
	const { seat_map } = leg || {};
	const [initial] = useInitial();
	const { buses } = initial || { buses: {} };
	const rows = seat_map ? (buses[seat_map] || buses[seat_map.toLowerCase()]) : [];
	const sorted = getSeatsSorter({ vertical, rows })
	return [sorted];
}

interface BusSharedProps {
	reservationId?: string;
	index?: number;
	postChange?: any;
}

export const BusShared = ({ reservationId, index, postChange }: BusSharedProps) => {

	console.clear();

	const [_error, setError] = useState<any>(null);
	const [, , { refresh: refreshInfo }] = useReservationInfo();

	const [, , { setData: setSeatsData }] = useSeatsReservationInfo();

	const changeSeat = async (request: any) => {
		try {
			const result = await fetchJson("/api/reservation/seats/change", { method: "POST", body: request });
			await setSeatsData(result)
			await refreshInfo();
		}
		catch (error) {
			setError(error);
		}
	}


	const error: any = _error;
	const [leg] = useSeatsReservationTotalLegIndexed(index);
	const { legIndex, type } = leg || {};
	const [seatError, setSeatError] = useState<boolean>(false);
	const [vertical, setVertical] = useState<boolean>(false);

	//const orientationStr = !!vertical || window.innerWidth < 700 || window.innerHeight < 700 ? "vertical" : "horizontal"; //EM TESTE
	const orientationStr = !!vertical ? "vertical" : "horizontal"; 
	const hor_driver = /*orientationStr=="horizontal" ? */<div className="bus-condutor "><Place conductor={true} /></div>/* : <div></div>*/
	const ver_driver = /*orientationStr!="horizontal" ? <div className="bus-condutor "><Place conductor={true} /></div> :*/ <div></div>

	const handleResize = () => {
		const isMobile = window.innerWidth < 569;
		if (isMobile !== lastIsMobile) {
			setVertical(isMobile);
			lastIsMobile = isMobile;
		}
	}

	const handleOrientation = () => {
		const isMobile = window.innerWidth < 569;
		if (isMobile) {
			const angle: number = /* window.orientation || (deprecated)*/ (window.screen && window.screen.orientation && window.screen.orientation.angle) || 0;
			const hor = Math.abs(angle || 0) !== 0;
			setVertical(!hor);
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		window.addEventListener("orientationchange", handleOrientation);

		handleResize();
		handleOrientation();

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("orientationchange", handleOrientation);
		}
	}, []);

	const [rows] = useSeatsRender({ vertical: !!vertical, leg });

	const [seatsState, setSeatsState] = useState<any>({ selectedSeat: null, selectedPassenger: null });
	const updateSeatsState = ({ ...vals }) => setSeatsState({ ...seatsState, ...vals });
	
	const seatsConstruct = useMemo(() => convertLegIntoBusSeats(leg), [leg]);
	
	const { seats } : any = leg || {}
	const seatsDict: { [key: string]: APIDTO.API.BookingSeat;	} = useMemo(() => {
		const res: any = {}
		seats && seats.forEach((seat: APIDTO.API.BookingSeat) => {
			res[seat.seat_no] = seat;
		})
		return res;
	}, [seats])



	const seatClick = async (seatNumber: number) => {
		//console.log("seatClick "+seatNumber);
		//console.log("seats_selectable "+leg.trip.seats_selectable);
		if (!leg.trip.seats_selectable) return;
		const { selectedSeat, selectedPassenger } = seatsState;
		const seat = seatsDict[seatNumber];
		const seatConstruct = seatsConstruct && seatsConstruct[seatNumber];
		const passenger_id = seat ? seat.passenger_id : seatConstruct.passenger;
		// const { passenger_id } = seat || {}
		if (selectedSeat === seatNumber) {
			updateSeatsState({
				selectedSeat: null,
				selectedPassenger: null
			});
			return;
		}

		if (!selectedSeat) {
			if (passenger_id) {
				updateSeatsState({
					selectedSeat: seatNumber,
					selectedPassenger: passenger_id
				});
			}
			else {
				updateSeatsState({
					selectedSeat: null,
					selectedPassenger: null
				});
				setSeatError(true);
				setTimeout(() => {
					setSeatError(false);
				}, CONFIRM_ERROR_TIMEOUT_MS)

			}
			return;
		}

		if (!selectedSeat) {
			return;
		}
		updateSeatsState({
			newSeat: seatNumber
		});
		await changeSeat({
			reservationNr: reservationId,
			jsonInput: [{
				outgoing_leg_no: type === "outgoing_itinerary" ? legIndex : undefined,
				return_leg_no: type === "return_itinerary" ? legIndex : undefined,
				current_seat_no: selectedSeat || undefined,
				new_seat_no: seatNumber,
				passenger_id: "" + selectedPassenger
			}]
		});
		if (postChange) {
			await postChange();
		}
		updateSeatsState({
			newSeat: null,
			selectedSeat: null,
			selectedPassenger: null
		});
	}
	//
	if (leg && leg.trip && !leg.trip.seat_assignment) {
		return <EditableText scope={SCOPE} as="no-seats" tag={"h3"} />
	}

	console.log({
		leg,
		seats,
		seatsDict,
		rows
	})


	return <div className={`bus-container ${leg.trip.seats_selectable ? "seats_selectable" : "seats_not_selectable"}`}>
		<div className={"bus-main " + index}>
			<div className={"bus-border " + orientationStr + " " + (vertical ? " css-rotating " : "")}>
				{hor_driver}
				<div className="bus-sits">
					{rows && <div className={"bus-seats " + orientationStr}>
						{rows.map((row: any, rowIndex: any) => <div key={rowIndex} className="seats-wrapper">
							{row.map((line: any, indexLine: any) => {
								if (line <= 0)
									return <div key={indexLine} className={"bus-seat "}></div>

								const seat = seatsDict[line];
								const construct = seatsConstruct && seatsConstruct[line];
								const occupied = seat?.status==="O" || !construct;
								const blocked = seat?.status==="B" || seat?.status==="C";
								const reserved = seat?.status==="R" || (construct?.reserved);
								const flex = seat?.seat_class ==="F";
								const promo = !flex && seat?.seat_class !="S";
								return <div key={indexLine} className={"bus-seat "}>
									<Place
										{...seatsState}
										blocked={blocked}
										reserved={reserved}
										flex={flex}
										promo={promo}
										occupied={occupied}
										number={line}
										onClick={() => seatClick(line)}
									/>
								</div>;
							})}
						</div>)}
					</div>}
				</div>
				{ver_driver}
			</div>
		</div>
		<div className="flex-column errors">
			{error && <EditableText scope="ERRORS" as={error.errorDetails} tag="h6" style={{ color: "#e05656" }} />}
			{seatError && <EditableText scope="ERRORS" as={ERROR_SELECT_RESERVED_SEAT} tag="h6" style={{ color: "#e05656" }} />}
			<BusLabels
				leg={leg}
			/>
		</div>
	</div>;
}