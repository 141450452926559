import React from "react";

export const ViewUsers = () => {
  return <h1>ViewUsers</h1>
}

// import "./index.scss"
// import { Loader } from "controls";
// import { datetime } from "modules";
// import { useFetchOnce } from "hooks";

// export const AuthorisationsOLD = () => {
// 	const [authorisationsData, { loading }] = useFetchOncez("/api/admin/authorizations/list");
// 	if(loading) return <Loader />
// 	if(!authorisationsData) return null;
// 	const keys = Object.keys(authorisationsData);

// 	const itemsArray = keys.map(key => authorisationsData[key]);


// 	const itemsSorted = itemsArray
// 		.map(item=>({
// 			...item,
// 			lastLoginStr: item.login_time && `${datetime.dateToStringLocal(item.login_time)} ${datetime.toTimeSecLocal(item.login_time)}`,
// 			lastUsageStr: item.usage_time && `${datetime.dateToStringLocal(item.usage_time)} ${datetime.toTimeSecLocal(item.usage_time)}`
// 		}))
// 		.sort((a,b) => a.lastUsageStr > b.lastUsageStr ? -1 : a.lastLoginStr < b.lastLoginStr ? 1 : 0);

// 	return <div className="admin-users-authorizations">
// 		<table className="table table-striped table-primary-header">
// 			<thead>
// 				<th>email</th>
// 				{/* <th>browserToken</th> */}
// 				<th>login_time</th>
// 				<th>usage_time</th>
// 			</thead>
// 			<tbody>
// 				{itemsSorted && itemsSorted.map((item, index)=>{
// 					return <tr key={index}>
// 						<td>{item.email}</td>
// 						{/* <td>{item.browserToken}</td> */}
// 						<td>{item.lastLoginStr}</td>
// 						<td>{item.lastUsageStr}</td>
// 					</tr>
// 				})}
// 			</tbody>
// 		</table>
// 	</div>
// }