const w : any = window;
let initialized = false;
let lastPage: any, lastTitle: any;
// import { userConfig } from "userConfig";
// const userConfig : any = {};

// const oldGaKey = "UA-12813895-1";
const newGaKey = "UA-12813895-5";

const { gaKey, gaTimeout } = {gaKey: newGaKey, gaTimeout: 5000}
	// // (userConfig && userConfig.analytics) ||
	// (window.location.origin.indexOf("rede-expressos")>0 && ({gaKey: newGaKey, gaTimeout: 5000})) ||
	// {};

console.log({ gaKey, gaTimeout })

let init = () => {
	/* eslint-disable */
	(function(i: any, s: any, o: any, g: any, r: any, a?: any, m?: any) {
		i['GoogleAnalyticsObject']=r;
		i[r]=i[r] || function() {
			(i[r].q=i[r].q||[]).push(arguments)
		}, i[r].l = 1*(+(new Date()));

	a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];
	a.async=1;
	a.src=g;
	m.parentNode.insertBefore(a,m)

	})(
		window,
		document,
		'script',
		'https://www.google-analytics.com/analytics.js',
		'ga'
	);
	w.ga('create', gaKey, 'auto');
	init = null;
}

const ga = (...args: any) => {
	if(!initialized) {
		setTimeout(() => {
			ga(...args);
			initialized = true;
		}, gaTimeout);
		return;
	}
	if(init!==null) {
		init();
		setTimeout(() => {
			ga(...args);
		});
		return;
	}
	console.log("%c Google analytics","color: #05F410; font-size: 10px;", ...args);~
	console.log({ ww: w.ga, args })
	w.ga(...args);
}

function setPage({page, title}: any) {
	console.log({ page, title })
	if(page===lastPage && title===lastTitle)
		return;
	lastPage = page;
	lastTitle = title;
	ga("send", { hitType: "pageview", page, title });
}

const eRequire = () => ga("require", "ecommerce")
const eClear = () => ga('ecommerce:clear')
const eAddTransaction = ({id, affiliation, revenue, shipping, tax, currency="EUR" }: any) => ga("ecommerce:addTransaction", {id, affiliation, revenue, shipping, tax, currency })
const eAddItem = ({id, name, sku, category, price, quantity, currency="EUR"}: any) => ga("ecommerce:addItem", {id, name, sku, category, price, quantity, currency })
const eSend = () => ga('ecommerce:send')

class Analytics {
	setPage = setPage;
	eRequire = eRequire;
	eClear = eClear;
	eAddTransaction = eAddTransaction;
	eAddItem = eAddItem;
	eSend = eSend;
	// constructor() {
	// 	const self : any = this;
	// 	self.eRequire = eRequire;
	// 	self.eClear = eClear;
	// 	self.eAddTransaction = eAddTransaction;
	// 	self.eAddItem = eAddItem;
	// 	self.eSend = eSend;
	// }
	
}

export const analytics = new Analytics();