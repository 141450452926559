import React from "react";
import "./index.scss"
import { MenuGroup } from "./MenuGroup";
import { MenuItem } from "./MenuItem";
import { useRouteQueryKey } from "hooks";
import { useReservationId } from "api/reservation";
import { useAdminPermissions } from "api/admin";
import { useUserDetails } from "api/user/useUserDetails";

export const LinksMenu = () => {
	const [details] = useUserDetails();
	const { sysAttribute } = details;
	const isManager = sysAttribute && sysAttribute.filter(c=>c.Name==="isManager" && c.Value==="1").length>0;
	const [reservationId] = useReservationId();
	const linkCN = reservationId ? "reservation_registered" : "";
	const [, setAgentsId] = useRouteQueryKey("agentsid");
	const [{ADMIN_PAGE, DEVELOPER_FEATURES}] = useAdminPermissions();

	return <div className="links-dd-root">
		<ul className="links-dd-menu">
			<MenuItem to="/dashboard">Dashboard</MenuItem>
			<MenuGroup title={"Bilheteira"} className={linkCN}>
				<MenuItem to="/">Horários / Vendas</MenuItem>
				{/* <MenuItem onClick={reservationShowDialog}>Consulta de Vendas</MenuItem> */}
				<MenuItem to="/dashboard">Pesquisar / Anular Reservas</MenuItem>
				<MenuItem to="/boarding">Folha de Embarque</MenuItem>
				{DEVELOPER_FEATURES && <MenuItem to="/voucher">Voucher</MenuItem>}
				<MenuItem to="/rePrint">Imprimir Bilhetes</MenuItem>
			</MenuGroup>
			<MenuGroup title={"Informações"}>
				<MenuItem onClick={()=>setAgentsId(true)}>Terminais/Postos</MenuItem>
				<MenuItem to="/faq">FAQ</MenuItem>
				<MenuItem href="http://www.rede-expressos.pt" target="_newtab">Rede-Expressos</MenuItem>
				<MenuItem to="/conditions">Condições</MenuItem>
				<MenuItem to="/internationalconditions">Condições - Internacionais</MenuItem>
			</MenuGroup>
			<MenuGroup title={"Gestão"}>
				<MenuItem to="/salesliquidation">Vendas</MenuItem>
				{isManager && <MenuItem to="/configuration">Organização</MenuItem>}
			</MenuGroup>
			{ADMIN_PAGE && <MenuItem to={"/admin"}>Admin</MenuItem>}
		</ul>
	</div>
}