import React, { useState } from "react";
import "./index.scss";
import { DownloadingStatusMask } from "controls";
import { StackPanel, LinkPrint, LinkDownload } from "controls";
import { FieldTextEditable, FormConfirm } from "forms";
import { reduxForm } from "redux-form";
import { useReduxFormSelector ,FieldNumberEditable} from "forms";
import { useUserDetails } from "api/user/useUserDetails";
import { TicketsTable } from "./TicketsList";

import qs from "querystring"



const connectForm = reduxForm({
	form: "reprintTable",
	enableReinitialize: true,
});



const FormConfirms = () => {
	const [hideLightbox, setHideLightbox] = useState(true);
	const [{ values, syncErrors }] = useReduxFormSelector("reprintTable");
	const { docId, ticketId,bookingId }: any = values || {};
	const [state, setStateInn] = useState<any>({});
	const setState = (s: any) => setStateInn({ ...state, ...s })
	const [details] = useUserDetails();
	const { userID } = details || {};
	

	const body = {
		bookingId,
		ticketId,
		docId,
		userID: userID,
		printerType: "PDF"
	}

	const href = "/api/ticketing/ticketRePrint?"+qs.stringify(body);
	
	/*function refreshPage() {
		window.location.reload();
	  }*/

	return <div className="flex-column" style={{width: "100%"}} >
		<DownloadingStatusMask {...state} />
		<div className="flex-end">
			<LinkDownload
				className="btn-primary text-center"
				href={href}
				disabled={syncErrors != null}
				onStatus={(status: any) => {
					setState(status)
				}}
				style={{ whiteSpace: "nowrap", width: "auto" }}
			>
				Descarregar
			</LinkDownload>
			<LinkPrint
				className="btn-primary text-center"
				href={href}
				disabled={syncErrors != null}
				onStatus={(status: any) => {
					setState(status);
				}}
				style={{ whiteSpace: "nowrap", width: "auto" }}
			>
				Imprimir
			</LinkPrint>
			{/* <button className="btn-primary text-center" onClick={() => alert(docId)} > Mais</button>  */}
		</div>
	</div>
}

const GetID= () => {
	const [{ values, syncErrors }] = useReduxFormSelector("reprintTable");
	const { docId}: any = values || {};
	return {
		docId
	}
}


export const FormReprintTicket = connectForm(({ form, onConfirm, valid }: any) => {

	return <div>
		<form className={`${form}`}>
			<StackPanel style={{ maxWidth: "500px" }}>
			<FieldTextEditable
					name="bookingId"
					className="form-value"
			/>
			<FieldNumberEditable
					name="ticketId"
					className="form-value"
			/>
			<FieldTextEditable
					name="docId"
					className="form-value"
			/>
			<FormConfirms />
			</StackPanel>
			<StackPanel >
			<div><TicketsTable /> </div>
			</StackPanel>

			
		</form>
	</div>
})

