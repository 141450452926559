import React from "react";
import { datetime } from "modules";
import "./index.scss";
import { Form } from "./Form";
import { DelimiterPanel, Flex } from "controls";

import {
	XLSImage,
	PDFImage,
	PrintImage,
	LinkDownload,
	LinkPrint
} from "controls";
import { useSalesState, useSalesStatuses, useSalesPeriods } from "api/sales";

const ERROR_LABEL_ID = "lbl_salesAccountError";

export const SalesSearch = ({setParentState}: any) => {
	const [statusList] = useSalesStatuses();
	const [{year, month, periodID}] = useSalesState();
	const [periods] = useSalesPeriods();
	const period = periods && periods.filter((c: any)=>c.ID===periodID)[0];
	const periodStatus = period && statusList && statusList.filter((c: any)=>c.ID===period.Status)[0];
	const periodBegin = period && period.PeriodBegin ? datetime.toDateString(new Date(period.PeriodBegin)) : "-";
	const periodEnd = period && period.PeriodEnd ? datetime.toDateString(new Date(period.PeriodEnd)) : "-";
	const initialValues = {
		year: ""+year,
		month: ""+month
	}
	const pdfUrl = `/api/sales/report/pdf?periodID=${periodID}`;
	const xlsUrl = `/api/sales/report/xls?periodID=${periodID}`;

	return <div className="sales-search-container">
		<div className="form-root">
			<Form initialValues={initialValues} />
		</div>
		<Flex className="salesText">
			<DelimiterPanel style={{marginLeft: "10px"}}>
				<label>{`${periodBegin} / ${periodEnd}`}</label>
				<label>{(period && period.Value ? (+period.Value).toFixed(2) : "0")+ " €"}</label>
				<label>{periodStatus && periodStatus.Name}</label>
			</DelimiterPanel>
			<label className="text-center" style={{marginLeft: "20px"}}>Extracto</label>
			<div className="download-buttons">
				<LinkDownload href={xlsUrl} onStatus={setParentState}><XLSImage /></LinkDownload>
				<LinkDownload href={pdfUrl} onStatus={setParentState}><PDFImage /></LinkDownload>
				<LinkPrint href={pdfUrl} onStatus={setParentState}><PrintImage /></LinkPrint>
			</div>
			<label id={ERROR_LABEL_ID} className="error"></label>
		</Flex>
	</div>
}