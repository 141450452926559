import { fetchData } from "./fetchData";
import { FetchJsonParams } from "../types/FetchJsonParams"

export const fetchJson = async<TResponse extends any>(url : string, params : FetchJsonParams = {}) : Promise<TResponse> => {
	try {
		const result = await fetchData(url, params);
		return await result.json();
	}
	catch(error) {
		if(error.json) {
			throw await error.json();
		}
		throw error;
	}
}