import React, { useEffect, useState } from "react";
//@ts-ignore
import JSONView from "json-view";
import "./index.scss";
import { hex_md5 } from "../../../modules";
import { JsonViewProps } from "../types/JsonViewProps";

export const JsonView = ({expand = false, name = "root", data} : JsonViewProps) => {
  const id = hex_md5(JSON.stringify({name, data}));
  const [view, setView]: any = useState<any>(null);
  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById(id);
      if(!view && elem) {
        var view2 = new JSONView(name, data);
        if(expand) {
          view2.expand(true);
        }
        if(elem) {
          elem.appendChild(view2.dom);
        }
        setView(view2);
      }
    })
  }, [id, view, data, expand, name]);
  return <div id={id} className="json-view" />
}