import React from "react";
import "./index.scss";
import { Logo } from "./Logo"
import { useRouteQueryKey } from "hooks";
import { useUserLogout } from "api";
import { useUserDetailsSOVE } from "api/user/useUserDetails";
// import $ from "jquery";

// const addClick = (value: string) => {
// 	const clicks : string[] = JSON.parse(localStorage.getItem("clicks") || "[]");
// 	localStorage.setItem("clicks", JSON.stringify([value, ...clicks]));
// }

// let onLogout = () => {}

// $(window).on('mouseover', (function () {
//     window.onbeforeunload = null;
// }));
// $(window).on('mouseout', (function () {
//     window.onbeforeunload = ConfirmLeave;
// }));
// function ConfirmLeave(e : any) {
// 	onLogout();
//     return true;
// }
// window.onbeforeunload = ConfirmLeave;
// var prevKey="";
// $(document).keydown(function (e) {    
// 	const now = new Date();
// 	const keyTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
// 	addClick(e.key);
// 	localStorage.setItem(keyTime, JSON.stringify({ key: e.key, keyCode: e.keyCode, result: e.result}));
// 	if(!e.key) return;        
//     if (e.key==="F5") {
//         window.onbeforeunload = ConfirmLeave;
// 	}
//     else if (e.key.toUpperCase() === "W" && prevKey === "CONTROL") {                
// 		window.onbeforeunload = ConfirmLeave;
//     }
//     else if (e.key.toUpperCase() === "R" && prevKey === "CONTROL") {
//         window.onbeforeunload = ConfirmLeave;
//     }
//     else if (e.key.toUpperCase() === "F4" && (prevKey === "ALT" || prevKey === "CONTROL")) {
//         window.onbeforeunload = ConfirmLeave;
//     }
//     prevKey = e.key.toUpperCase();
// });

export const SessionMenu = () => {
	const [logout] = useUserLogout();
	const [{ agentCompany, userAccountData }] = useUserDetailsSOVE();
	const { Name: companyName } = agentCompany || {};
	const { Name: userAccountName } = userAccountData || {};

	const [, setIsProfile] = useRouteQueryKey("isprofile");

	// useEffect(() => {
	// 	onLogout = logout;
	// 	return () => {
	// 		onLogout = () => {}
	// 	}
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return <div className="session-menu">
		<Logo />
		<span className="session-company-name">{companyName}</span>
		<div className="session-menu-right">
			<span className="session-user-name" onClick={() =>setIsProfile(true)}>{userAccountName}</span>
			<span className="session-logout">
				<img alt="" src="/images/page/logout_over.png" onClick={()=>{
					logout();
				}} />
			</span>
		</div>
	</div>
}