import React from "react";
// import { useSchedulesData } from "../useSchedulesData";
import "./index.scss";
import { useSchedulesSelected } from "consts/schemas";

export const Title = () => {
  const [selected] = useSchedulesSelected();
  const [fromName, toName] = !selected ? [null, null] : [selected.from.name, selected.to.name];
  const { distance, max_price } = selected || {};
  return <span className="schedules-view-title">
    <span>Horários </span>
    {selected && <>
      de <span className="highlight">{fromName}</span> para <span className="highlight">{toName}</span>
      &nbsp;({distance}Kms) - €{max_price && max_price.toFixed(2)}
    </>}
  </span>
}