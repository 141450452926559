import React from "react";
import {
	COMPANY_DEPTH_KEY,
	GROUP_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
	ACCOUNT_DEPTH_KEY
} from "consts";
import { useConfigurationBlock } from "api/configuration";

export const AddButton = ({ID, Name, level, IsEditable, IsAvailable}: any) => {
	const [,, { add }] = useConfigurationBlock({level});

	if(level===ACCOUNT_DEPTH_KEY)
		return null;
	if(!IsEditable)
		return null;

	const onAddClick = async () => {
		if(!IsEditable || !IsAvailable) return;
		await add(ID);
		
	}
	
	let icon = (()=>{
		if (level === COMPANY_DEPTH_KEY) return "groupButton.png";
		if (level === GROUP_DEPTH_KEY) return "pvButton.png"
		if (level === POINT_OF_SALE_DEPTH_KEY) return "adduserButton.png";
	})();
	
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	return <a
		onClick={onAddClick}
		className="btn-small addNodeBto">
		<img alt="add" src={"images/icons/"+icon} width="15" height="15" />
	</a>
}