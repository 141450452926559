

import React, { useState, useRef, ChangeEventHandler } from "react";
import "./index.scss";
import { Drop } from "../../LAYOUT_CONTAINERS/Drop";
import { ReactCalendarValued } from "../ReactCalendarValued";
import { useOnClickOutside } from "hooks";
import clsx from "clsx";
import { datetimeParse, datetimeStringify, datetimeAddDays } from "modules/datetime";

function isValidDate(d : Date) {
	return d instanceof Date && !isNaN(+d);
}

const timeAdder = (change : string) => {
	const pos = change.indexOf("+")>=0;
	let clean = change;
	clean = clean.replace("+", "");
	clean = clean.replace("-", "");
	clean = clean.replace("D", "");
	return datetimeAddDays(new Date(), (pos ? +clean : -clean));
}

let locked = false;
//React.InputHTMLAttributes<{}>
export interface ReactCalendarPickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
	
	active?: boolean;
	minDate?: string;
	maxDate?: string;
	// disabled?: boolean;

	//legacy >>
	fieldName?: string;
	scope?: string;
	//<< legacy
}

export const ReactCalendarPicker = ({
	active,
	fieldName,
	disabled,
	minDate,
	maxDate,
	className,
	onChange,
	onFocus,
	onBlur,
	style,
	value,
	...rest
} : ReactCalendarPickerProps) => {
	// const [uid, setUID] = useState<any>(null);
	// useEffect(()=>{
	// 	setUID(randomGuid());
	// },[]);
	const containerRef = useRef<any>();
	const hiddenRef : any = useRef<HTMLInputElement>();
	const [__isDrop, setDropInner] = useState<any>(active);
	const dropOpened = active===true ? true : active===false ? false : __isDrop;
	const setDrop = (value : boolean) => {
		if(locked) return;
		locked = true;
		setTimeout(() => { locked = false }, 50);
		setDropInner(value);
	}
	const minMaxDate : any = {};
	if(minDate) {
		minMaxDate.minDate = timeAdder(minDate);
	}
	if(maxDate) {
		minMaxDate.maxDate = timeAdder(maxDate);
	}
	
	const onInput : ChangeEventHandler<HTMLInputElement> = (e : any) => {
		onChange && onChange(e);
		onBlur && onBlur(e);
	}
	useOnClickOutside(containerRef, (event : any) => {//React.MouseEvent<any>
		if(active) {
			onBlur && onBlur(event);
		}
	})

	const dateValue = value && isValidDate(datetimeParse(""+value).fromFieldDateUtcString()) && datetimeParse(""+value).fromFieldDateUtcString();

	return <div ref={containerRef} onClick={()=>setTimeout(() => {
		setDrop(true);
	})} className={`${className} calendar-picker`}>
		<input type={"hidden"} defaultValue={value} ref={hiddenRef} onInput={onInput}/>
		<input
			{...rest}
			// id={uid}

			className={`calendar-picker ${clsx(className)}`}
			disabled={disabled}
			style={{...style, zIndex: dropOpened ? 100 : 0}}
			value={value || ""}
			onClick={onFocus as any}
			// onFocus={onFocus}
			onChange={(e)=>{
				hiddenRef.current.value = e.target.value;
				hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
			}}
			autoComplete="off"
		/>
		{dropOpened && <Drop dropped={dropOpened} onLeave={()=>setDrop(false)}>
			<ReactCalendarValued
					{...minMaxDate}
					value={dateValue}
					onChange={val=>{
						const valFirst = Array.isArray(val) ? val[0] : val;
						hiddenRef.current.value = ""+datetimeStringify(valFirst).toFieldDateString();
						hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
					}}
				/>
		</Drop>}
	</div>
}