import React from "react";
import { Flex } from "controls";
import { FormErrorsContainer,  } from "forms";
import { PassengerItemVoucher } from "./PassengerItemVoucher";
import { FieldArray, reduxForm, WrappedFieldArrayProps } from "redux-form";

export const Form = reduxForm<{},{ style?: React.CSSProperties; submit?: any }>({
	form: "passengersFormVoucher",
	enableReinitialize: true,
	forceUnregisterOnUnmount: true,
	destroyOnUnmount: false,
	onSubmit: () => {}
})(({ style, form, submit } : any) => {
  return <form className={`tabcontent passengers-form ${form}`} style={{...style}}>
			<FormErrorsContainer form={"passengersFormVoucher"} />
			<Flex column>
				<FieldArray
					name="passengers"
					component={({ fields, meta } : WrappedFieldArrayProps<any>) : any => fields.map((name, index) => {
						return <PassengerItemVoucher key={index} name={name} index={index} />
					})}
					submit={submit}
				/>
			</Flex>
	</form>
});