import React from "react";
import { ACCOUNT_DEPTH_KEY } from "consts";


export const InactiveManagerButton = ({level, isInactiveManager, onClick}: any) => {
	if(level !== ACCOUNT_DEPTH_KEY) return null;
	if(isInactiveManager) return null;
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	return <a className="inactiveManagerNodeButton" onClick={onClick}>
		<img src="images/icons/flag.png"
		className="inactiveManagerNodeIcon"
		alt='inactive'
		width="15" height="15" />
	</a>
}