import React, { Suspense } from "react";
import { AppLoader } from "./AppLoader";
import { Overlay } from "./Overlay";
import { Page } from "./Page";
import { Layout } from "app/layout/Layout";
import { useInitial } from "api/app/useInitial";
import { useKeepAlive } from "api/user";

export const AppContent = () => {
	const initialCtx = useInitial()
	useKeepAlive();

	if (!initialCtx) return <AppLoader />
	if (!initialCtx[0]) return <AppLoader />

	return <>
		<Overlay />
		<Page>
			<Suspense fallback={<AppLoader />}>
				<Layout />
			</Suspense>
		</Page>
	</>
}