import React, { useState } from "react";
import "./index.scss";
import { Paths } from "./Paths";
import { useFetchOnce } from "hooks";
import { Loader } from "controls"
import { fetchJson } from "modules/fetch";

export const ViewServer = () => {
	const [paths, pathsStatus] = useFetchOnce<any>("/api/admin/maintaining/paths");
	const [state, setState] = useState<any>({});
	const actionsList = [
		{
			title: "Unload",
			description: "Unload current server instance, after refresh you will lose authorization and all cache values will be cleaned",
			action: async ()=> await fetchJson("/api/dev/unload")
		},
		{
			title: "Me",
			description: "Get data about yourself (IP address)",
			action: async ()=> await fetchJson("/api/dev/me")
		},
		{
			title: "Refresh runtime",
			description: "Refresh instance GUID, so users will be forced to refresh service worker with next page refresh",
			action:  async ()=> await fetchJson("/api/admin/maintaining/server_runtime")
		},
		{
			title: "Reload API",
			description: "Call API reload method",
			action: async ()=> await fetchJson("/api/admin/maintaining/reload_api")
		},
	]
	if(pathsStatus.loading) {
		return <Loader />
	}
	return <div className="admin-maintaining-server">
		<Paths paths={paths}/>
		<table className="table table-striped table-primary-header">
			<thead>
				<th>Name</th>
				<th>Result</th>
				<th>Actions</th>
			</thead>
			<tbody>
				{actionsList.map((item, index) => <tr key={index}>
					<td><strong>{item.title}</strong></td>
					{!state[index] && <td><span>{item.description}</span></td>}
					{state[index] && <td>{JSON.stringify(state[index])}</td>}
					<td><button onClick={async() => {
						const response = await item.action();
						setState({[index]: response})
					}} className="btn btn-primary">Proceed</button></td>
				</tr>)}
			</tbody>
		</table>
	</div>
}
