import React, { useState } from "react";
import "./index.scss";
import "../RadioAccordion/radioAccordionAndVisibilityCheck.scss";
import clsx from "clsx";
import { randomGuid } from "modules/random";

export const VisibilityCheck = ({id, title, children, className} : {id? : string, title? : string, children : any, className? : string}) => {
	const currID = id || randomGuid();
	const [ checked, setChecked ] = useState<boolean>(false);
	return <div className={`visibility-container ${clsx(className)} ${clsx(checked && "checked")}`} key={currID}>
		<input
			type="checkbox"
			id={currID}
			checked={checked}
			onChange={() => setChecked(!checked)}
			className="visibility-check"
		/>
		<label
			className="visibility-title"
			htmlFor={currID}
		>
			{title}
		</label>
		<div className="visibility-content">
			{children}
		</div>
	</div>
}