export const start = (time : any, options : any={}) => {
	if(!time) return null;
	const { year, month, date, day, dow, hour, minute, second, millisecond } = options;
	if(year)
		return new Date(Date.UTC( time.getUTCFullYear(), 0 ));
	if(month)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth()
		));
	if(dow)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate()-time.getDay()
		));
	if(date||day)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate()
		));
	if(hour)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate(),
			time.getUTCHours()
		));
	if(minute)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate(),
			time.getUTCHours(),
			time.getUTCMinutes()
		));
	if(second)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate(),
			time.getUTCHours(),
			time.getUTCMinutes(),
			time.getUTCSeconds()
		));
	if(millisecond)
		return new Date(Date.UTC(
			time.getUTCFullYear(),
			time.getUTCMonth(),
			time.getUTCDate(),
			time.getUTCHours(),
			time.getUTCMinutes(),
			time.getUTCSeconds(),
			time.getUTCMilliseconds()
		));
	return time;
}