import React from "react";
import { TableCell } from "../TableCell";
import clsx from "clsx";
import { _TableRowProps } from "../../types/_TableRowProps";
// import { _TableCellProps } from "../../types/_TableCellProps";

export const TableRow = ({ items, selected, ...htmlAttributes } : _TableRowProps) => {
  const { children, className, ...restAttributes } = htmlAttributes;
  return <tr {...restAttributes} className={`${clsx(className)} ${clsx(selected && "selected")}`}>
    {items && items.map((item, index) => {
      const itemProps : any /*_TableCellProps*/ = (() => {
        if(typeof item==="string") return { children: item };
        else return item;
      })();
      return <TableCell {...itemProps} key={index}/>
    })}
    {children}
  </tr>
}