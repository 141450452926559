import React from "react";
import { PanelTitleClose } from "../.."

// interface DialogContentProps {
// 	name?: string;
// 	style?: Object;
// 	width?: number;
// 	height?: number;
// 	windowRef?: any;
// 	className?: string;
// 	title?: string;
// 	children?: any;
// 	onCloseWrap?: () => {};
// 	rest?: any;
// 	Element?: any;
// }
// eslint-disable-next-line no-unused-vars
export const DialogContent = ({name, style, width, height, windowRef, className, title, children, onCloseWrap, rest: {dispatch, ...rest}, Element, ...restProps}: any) => {
	return <div
		{...restProps}
		style={{...style, width, height}}
		ref={windowRef}
		className={`window-${name} ${className} dialog-window`}
	>
		<PanelTitleClose
			title={title}
			onClose={onCloseWrap}
			className={`dialog-title title-${name}`}
		/>
		<div className="dialog-content">
			<div className="dialog-content-inner">
				<Element {...rest} rest={onCloseWrap} close={onCloseWrap}/>
			</div>
		</div>
		{children}
	</div>
}