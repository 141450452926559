import React from "react";
import { LOADING_STR, RESET_PWD_SUCCESS } from "consts";
import { Loader,Flex} from "controls";
import { onSubmit } from "./onSubmit";
import { validate } from "./validate";
import { FormErrorsContainer, FieldPasswordEditable, FormConfirm } from "forms";
import { reduxForm } from "redux-form";

export const PwdResetForm = reduxForm<any>({
	form: "pwdResetForm",
	validate,
	onSubmit
})(({
	form,
	submitSucceeded,
	pristine,
	valid,
	error,
	submitting,
}) => {
	const submitDisabled = (!valid && !error) || pristine || submitting;
	return <form className={`${form} login-form`}>
		{submitting && <div className="mask-holder"><div className="mask-holder-inner"><Loader text={LOADING_STR} overlay/></div></div>}
		{submitSucceeded && <div className="mask-holder"><div className="mask-holder-inner"><Loader text={RESET_PWD_SUCCESS} overlay/></div></div>}
		<Flex ratio={[5,1]} gap={8}>
			<div className="flex-column flex-end">
				<FieldPasswordEditable
					name="newPwd"
					required
					// placeholder="Utilizador"
					// validate={[requiredValidate]}
				/>
				<FieldPasswordEditable
					name="confirmNewPwd"
					required
					// placeholder="Senha"
					// validate={[requiredValidate]}
				/>
			</div>
			<div className="flex-column flex-end margin-bottom-8">
				<FormErrorsContainer form={form}/>
				<FormConfirm form={form} disabled={submitDisabled} />
			</div>
		</Flex>
		<FormErrorsContainer form={form} />
	</form>
});
