import { SubmissionError } from "redux-form";
import { fetchJson } from "modules/fetch";
import { parse } from "query-string";

export const onSubmit = async ({newPwd} : {newPwd : string}) => {//confirmNewPwd
	try {
		const token = parse(window.location.search.replace("?", "")).requestid;
		const request = {
			token: token,
			pass:newPwd// encodeURIComponent(newPwd),
		};
		await fetchJson("/api/user/password/confirm", { method: "POST", body: request});
	}
	catch(error) {
		console.error(error);
		throw new SubmissionError({_error: error.error});
	}
}