import React from "react";
import { TableHead } from "../TableHead";
import clsx from "clsx";
import { _TableHeaderProps } from "../../types/_TableHeaderProps";
// import { _TableHeadProps } from "../../types/_TableHeadProps";

export const TableHeader = ({ items, settings, ...htmlAttributes } : _TableHeaderProps) => {
  const { children, className, ...restAttributes } = htmlAttributes;
  const { primary_header } = settings || {};
  return <thead {...restAttributes} className={`${clsx(primary_header && "primary")} ${clsx(className)}`}>
    {items && items.map((item, index) => {
      const itemProps : any/*_TableHeadProps*/ = (() => {
        if(typeof item==="string" || typeof item==="number") {
          return { children: item };
        }
        if(typeof item==="object") {
          if((item as React.HTMLAttributes<{}>).children) return item;
          return { children: item };
        }
        return item;
      })();
      return <TableHead {...itemProps} key={index}/>
    })}
    {children}
  </thead>
}