import React, { useState } from "react";
import { VisibilityCheck } from "controls/primitive";
import { useAdminStorage } from "api/admin";

export const AdminStorage = () => {
  const [loadingKey, setLoadingKey] = useState<any>(null);
  const [storage, { append: appendStorage }] = useAdminStorage();
  const storageKeys = Object.keys(storage);
  const selectedStorage = storageKeys.map(key=>!!storage[key]).filter(value => !!value);

  const onStorageLineClick = async (key : string) => {
    setLoadingKey(key);
    await appendStorage(key, !storage[key]);
    setLoadingKey(null);
  }

  return <VisibilityCheck title={`Storage (${selectedStorage.length} / ${storageKeys.length})`} className="arrow-right justify-between">
    <table>
        <tbody>
          {storageKeys.map(key => {
            const value = storage[key];
            return <tr key={key} style={{fontWeight: value ? "bold" : "normal"}}>
              <td>{key}</td>
              <td> 
                {loadingKey!==key && <input type="checkbox" checked={value} onClick={() => {
                  onStorageLineClick(key);
                }} />}
                {loadingKey===key && <i className="fa fa-spin fa-spinner"/>}
              </td>
            </tr>
          })}
        </tbody>
      </table>
  </VisibilityCheck>
}