import React from "react";
import "./index.scss";
import { ButtonTyped, StackPanel, ButtonStatus } from "controls";
import { FieldTextEditable, FieldDateEditable, FieldSelectEditable, FormErrorsContainer, dispatchAppendObject, dispatchSubmit, dispatchClear } from "forms";
import { useDashboardAgents, useDashboardRoute, useDashboardList } from "api";
import { hocDialog } from "controls/dialogs";
import { useOrigins, useDestinations } from "api";
import { reduxForm } from "redux-form";

const useLoadingStateInner = () : [boolean] => {
	const [,{ loading }] = useDashboardList();
	return [loading];
}

const Form = reduxForm<{},{ onClean: () => any, loading: boolean }>({
	enableReinitialize: true,
	destroyOnUnmount: false,
})(({ form, onClean, initialValues }) => {
	const [loading] = useLoadingStateInner();
	const [agents, agentsStatus] = useDashboardAgents();
	const [origins, originsStatus] = useOrigins({ offer: "both" });
	const [destinations, destinationsStatus] = useDestinations({origin: "*", offer: "both" });
	const originsItems: any = !originsStatus.success || !origins ? [] : [{value: null, text: ""}, ...origins];
	const destinationsItems: any = !destinationsStatus.success || !destinations ? [] : [{value: null, text: ""}, ...destinations];
	const companyAgentsItems = !agentsStatus.success || !agents ? [] : agents.map((ag: any) => ({ text: ag.Name, value: "" + ag.ID }));
	return <form className={`${form} agentInfoBox`}>
		<StackPanel count={2} gap={8}>
			<FieldTextEditable name="reservationNumber" />
			<FieldTextEditable name="ticketNumber" />
			<FieldSelectEditable
				loading={originsStatus.loading}
				disabled={!originsStatus.success}
				name="origin"
				filter={true}
				items={originsItems && originsItems.map((c: any)=>({...c, value: c.id || c.value}))}
				onChange={value=>{
					if(!value) dispatchAppendObject(form, { origin: null })
				}}
			/>
			<FieldSelectEditable
				loading={destinationsStatus.loading}
				disabled={!destinationsStatus.success}
				name="destination"
				filter={true}
				items={destinationsItems && destinationsItems.map((c: any)=>({...c, value: c.id || c.value}))}
				onChange={value=>{
					if(!value) dispatchAppendObject(form, { destination: null })
				}}
			/>
			<FieldDateEditable name="reservationDate" />
			<FieldDateEditable name="travelDate" />
			<FieldSelectEditable
				loading={agentsStatus.loading}
				name="operat"
				filter={true}
				disabled={!agentsStatus.success}
				items={companyAgentsItems}
			/>
		</StackPanel>
		<div className="button-set flex-end">
			<FormErrorsContainer form={form} />
			<ButtonTyped styling="primary" onClick={onClean}>Limpar</ButtonTyped>
			<ButtonStatus styling="primary" status={{loading}} onClick={()=>{ dispatchSubmit(form) }}>Pesquisar</ButtonStatus>
		</div>
	</form>
});

export const SearchDialog = hocDialog({
	name: "salesSearchDialog",
	title: "PESQUISA DE VENDAS",
	height: 270,
	width: 640
})((props : any) => {
	const [initialValues, { clear, save }] = useDashboardRoute();
	return <Form
		{...props}
		initialValues={initialValues}
		onSubmit={save}
		onClean={async() => {
			await clear();
			await dispatchClear("salesSearchDialogForm");
		}}
	/>
});