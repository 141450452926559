import { useUser } from "./useUser";
import { APIDTO } from "@card4b/apidto-ts";
import { UserDetails } from "types/user/UserDetails";

interface USERDetailsResult extends UserDetails, APIDTO.User.UserLoginChannelResponse { }

export const useUserDetails = () : [USERDetailsResult]=> {
    const [user] = useUser();
    const { details } = user || {};
    const sove : any = (details && details.sove) || {};//APIDTO.User.UserLoginChannelResponse
    const summary : USERDetailsResult = {
      ...details,
      ...sove
    }
    return [
      summary
    ];
}
export const useUserDetailsSOVE = () : [APIDTO.User.UserLoginChannelResponse] => {
  const [user]: any = useUser();
  const res : USERDetailsResult = (user && user.details && user.details.sove) || {};
  return [res]
  // return [
  //   (user && user.details && user.details.sove) || {}
  // ]
}