import React from "react";
import { TitlePanel } from "controls"
import { DEFAULT_TITLE } from "consts";
import { usePage } from "hooks";
import { Flex } from 'controls';
import { FormPassengersSoveMain } from "./FormPassengersSoveMain";
import { ReservationSchedulesView } from "./views/ReservationSchedulesView";
import { ReservationInfoView } from "./views/ReservationInfoView";
import { SchedulesSearchSoveView } from "./views/SchedulesSearchSoveView";
import "./index.scss";
import { useFormValidator } from "./FormPassengersSoveMain/useFormValidator";

export const Home = () => {
	usePage({
		name:"MAIN",
		options: {
			title: DEFAULT_TITLE
		}
	});

	return <Flex column gap={5} className="full-size" ratio={[1,1]}>
		<TitlePanel
			type={"dark"}
			title={"Origem e Destino"}
			className="panel-main-search"
		>
			<Flex gap={5} className="panel-main-search-flex" ratio={[1,1,1]}>
				<SchedulesSearchSoveView />
				<FormPassengersSoveMain />
				<ReservationInfoView />
			</Flex>
		</TitlePanel>
		<ReservationSchedulesView />
	</Flex>
}

export default Home;