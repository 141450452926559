let envShown = false;

/**
 * Wrapper around process.env
 * 
 * @returns [enviromentVariables]
 */

type UseEnvResult = {
	ENV_SOVE: boolean;
	NODE_ENV: string;
	ENV_DEV: boolean;
	ENV_PROD: boolean;
	TIMESTAMP: number;
	PUBLIC_URL: string;
}

export const useEnv = () : [UseEnvResult] => {
	const result : any = process.env;
	if(!envShown) {
		envShown = true;
	}
	return [result];
}