import React from "react";
import { Form } from "./Form"
import { SubmissionError } from "redux-form";
import { hocDialog } from "controls/dialogs";
import { useUserResetPassword } from "api/user";

const FORM_NAME = "PwdResetDialog";

const connectDialog = hocDialog({
	name: FORM_NAME,
	title: "Recuperação Password",
	width: 305,
	height: 110,
	minWidth: 305,
	minHeight: 130
})

export const LoginRecoverPasswordDialog = connectDialog(() => {
	const [resetPassword] = useUserResetPassword();
	return <>
		<Form
			onSubmit={async ({userName}) => {
				try {
					await resetPassword({userName});
				}
				catch(error) {
					console.error(error);
					throw new SubmissionError({_error: error.error});
				}
			}}
		/>
	</>
})