import { useTripsSelectedRoutes } from '../../ticketing/useTripsSelectedRoutes';
import { useReservationSearchConfirmed } from 'api';
import { usePassengersForm, usePassengersClient } from "../usePassengersClient";
import { Passenger } from 'types/passengers/Passenger';
import { useReservationSearchFormValues } from 'api';

export const usePassengersRequest = () : [Passenger[] | null] => {
	const [searchFormValues] = useReservationSearchFormValues();

	const [clientPassengers] = usePassengersClient();
	const [{syncErrors}] = usePassengersForm();
	const [idaRoute] = useTripsSelectedRoutes("ida");
	const { price_type_id } = idaRoute || {};
	const [{ reschedule_of_ida, reschedule_of_volta, volta }] = useReservationSearchConfirmed();
	if(!clientPassengers) return [null];
	if(clientPassengers.length===0) return [null];
	if(syncErrors) return [null];

	const {   revalidations } = searchFormValues;

	const reschedule_of = (() => {
		
		if(reschedule_of_ida && revalidations) {
			if(!!volta && reschedule_of_volta) {
				return [reschedule_of_ida, reschedule_of_volta]
			}
			return [reschedule_of_ida];
		}
		return null;
	})();

	const result = clientPassengers.map((p, index) => {
		
		return {
			...p,
			id: ""+(index+1),
			price_type_id,
			reschedule_of
		}
	})

	return [
		!reschedule_of ? result : result.filter((item, index) => index===0)
	]
}
