const splitMapTrim = (data: any) => {
	return (data||"").split(";").map((c: any)=>c.trim());
}

const findLine = (arr: any, content: any) => {
	let idx = -1;
	arr.forEach((line: any, index: any) => {
		if(line.indexOf(content)>=0)
			idx = index;
	})
	return idx;
}

export const fileResponseHeaderDetails = ({headers}: any) => {
	const contentDispositionSplit = splitMapTrim(headers.get("content-disposition"));
	const contentTypeSplit = splitMapTrim(headers.get("content-type"));
	const contentEncodingSplit = splitMapTrim(headers.get("content-encoding"));

	let filename = null;
	let mimetype = null;
	const filenameIdx = findLine(contentDispositionSplit, "filename");
	if(filenameIdx>=0) {
		const line = contentDispositionSplit[filenameIdx];
		filename = line.substring(line.indexOf("=")+1);
		while(filename.indexOf("\"") >= 0) {
			filename = filename.replace("\"", "");
		}
	}
	if(findLine(contentTypeSplit, "pdf")>=0) {
		mimetype = "application/pdf";
	}
	if(findLine(contentTypeSplit, "excel")>=0) {
		mimetype = "application/vnd.ms-excel";//;charset=ANSI
	}
	const gzip = findLine(contentEncodingSplit, "gzip")>=0;
	const result = { filename, mimetype, headers, gzip }
	return result;
}