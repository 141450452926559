import { useState, useEffect } from "react";
import { useSchedulesExtended } from './useSchedulesExtended';
import { createScheduleTable } from './createScheduleTable';
import { TransformedScheduleResult } from "types/ticketing/TransformedScheduleResult";
import { ScheduleTableItemed } from "types/ticketing/ScheduleTableItemed";

const useResultState = () : [TransformedScheduleResult, (data : TransformedScheduleResult) => void] => {
  return useState<any>();
}
export const useSchedulesTransformer = (schedules : ScheduleTableItemed) : [TransformedScheduleResult] => {
  const [extended] = useSchedulesExtended();
  const [result, setResult] = useResultState();
  useEffect(() => {
    setResult(createScheduleTable({ schedules, extended }));
  }, [schedules, extended, setResult]);
  return [result];
}