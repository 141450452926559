import { fetchJson } from "modules/fetch/modules";
import { useUserDetails } from "./useUserDetails";
import { useInitial } from "api/app/useInitial";

interface UseUserChangePasswordFuncProps {
	oldKey: string;
	newKey: string;
}
export type UseUserChangePasswordFunc = (props : UseUserChangePasswordFuncProps) => Promise<void>

export const useUserChangePassword = () => {
  const [, refresh] = useInitial();
  const [details] = useUserDetails();
  const changePassword : UseUserChangePasswordFunc = async ({oldKey, newKey}) => {
		const userId = details && (
			details.userID ||
			(details.userAccountData &&details.userAccountData.UserID)
		);
		await fetchJson("/api/user/password/change", { method: "POST", body: {userId,oldKey,newKey}})
		await refresh();
  }
  return [changePassword];
}