import React, { useState, useRef, useEffect } from "react";
import { Z_APP_FOOTER } from "consts";
import { FooterAlertsDialog } from 'controls/dialogs/FooterAlertsDialog/index';
import { useFetchOnce } from "hooks";
import { useAnimationFrame } from "hooks";
import { useAuthorised } from "api/user";

let timePrev : any = null;
let lastX = 0;
let current : any = null;

export const Footer = ()=> {
	const [isAuthorised] = useAuthorised();
	const [alerts, { success }] = useFetchOnce<any>("/api/content/footerAlerts", { method: "GET", lock: !isAuthorised });
	const animatedRef = useRef();
	const [alertsShow, setAlertsShow] = useState<boolean>(false);
	const pxPerSecond = 200;

	const [isMoving, setIsMoving] = useState<boolean>(true);
	const [dragging, setDragging] = useState<boolean>(false);
	const setX = (x: any) => {
		if(!current) {
			timePrev = new Date();
			return;
		}
		lastX = x;
		current.style.transform = `translateX(${x}px)`
	}

	const animationHandler : any = () => {
		if(!isMoving || !current) {
			timePrev = new Date();
			return;
		}
		const time : any = new Date();
		const timeDiffMS = !timePrev ? 0 : time - timePrev;
		const shiftX = timeDiffMS * pxPerSecond / 1000.0;
		timePrev = time;
		setX(lastX <= -140 ? current.offsetWidth : lastX - shiftX);
	}

	useEffect(() => {
		current = animatedRef.current;
		return () => {
			current = null;
		}
	})

	useAnimationFrame(animationHandler);

	return <>
		{alertsShow && <FooterAlertsDialog
			alerts={alerts}
			onClose={()=>{ setAlertsShow(false)	}}
		/>}
		<div
			className="footer"
			id="footer"
			onMouseOver={()=>setIsMoving(false)}
			onMouseOut={()=>setIsMoving(true)}
			onClick={()=>{
				if(!dragging)
					setAlertsShow(true);
			}}
			style={{zIndex: Z_APP_FOOTER}}
		>
			<div className="footer_loading">
				<div className="footer_loading_text">
					A Carregar Alertas...
				</div>
			</div>
			<div
				style={{display: "flex"}}
				onMouseMove={(e)=>dragging && setX(e.clientX)}
				onMouseDown={()=>setDragging(true)}
				onMouseUp={()=>setDragging(false)}
			>
				{success && <div
					ref={animatedRef}
					className="div_alerts"
					id="div_alerts"
					style={{userSelect: "none"}}
					data-duration='50000'
				>
					{alerts && alerts.map((a: any, index: any) => <React.Fragment key={index}>
						<span className="alert_title">{a.Title}&nbsp;</span>
						<span className="alert_msg">{a.Resume}</span>
					</React.Fragment>)}
				</div>}
			</div>
		</div>
	</>
}