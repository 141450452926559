import React, { useState } from "react";
//@ts-ignore
import ReactTooltip from 'react-tooltip';
import { randomGuid } from "modules/random";

const tooltipStyle : any = {
	maxWidth: "200px",
	whiteSpace: "pre-wrap",
	lineHeight: "initial",
	fontWeight: "normal",
	textAlign: "center"
}

const iconStyle = {
	width: 16,
	height: 16
};

export const ToolTipImage = ({ title, className, place="bottom", src }: any) => {
	const [id] = useState<any>(randomGuid);
	return <React.Fragment>
			<ReactTooltip
				id={`for-${id}`}
				place={place}
				type="dark"
				effect="solid"
			>
				<p style={tooltipStyle}>
					{title}
				</p>
			</ReactTooltip>
			<img
				alt="tooltip"
				data-tip={true}
				data-for={`for-${id}`}
				className={className}
				id={id}
				src={src}
				style={iconStyle}
			/>
		</React.Fragment>
}