import { useState } from "react";
import { FETCH } from "types";

export interface DataSalesState {
	activeIda?: number;
	activeVolta?: number;

	loading?: boolean;
  success?: boolean;
  error?: FETCH.APIError;
}
export type SetDataSalesStateFunc = (newData : DataSalesState) => void;

export const useSalesDetailsState = () : [DataSalesState, SetDataSalesStateFunc] => {
	const [data, setData] = useState<any>({});
	const appendData = (newData : DataSalesState) => {
		setData({...data, ...newData});
	}
	return [data, appendData];
}