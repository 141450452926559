import React from "react";
import { StackPanel, Flex, ButtonTyped, ErrorEditable } from "controls";
import { dispatchChange, FormErrorsContainer, FieldTextEditable,FieldTextAreaEditable, FieldLabelEditable, FieldSelectEditable } from "forms";
import { asyncValidate } from "./asyncValidate";
import { reduxForm } from "redux-form";
import {
	numbersNormalize,
	maxLengthNormalize,
	combineNormalize,
} from "forms";
import { useReduxFormSelector } from "forms";
import { FieldNumberEditable } from "forms";
import { useInitialInvoice } from "api/invoice/useInitialInvoice";
import { useInvoiceStatus } from "api/invoice/useInvoiceStatus";
import { usePassengersStatus } from "api/passengers/helpers/usePassengersStatus";
import { useUserDetails } from "api/user/useUserDetails";

const InvoiceFormErrors = ({ form } : { form: string }) => {
	const [formState] = useReduxFormSelector(form);
	const invoiceErrors : any = formState && formState.asyncErrors;
	return <>
		{invoiceErrors && <ErrorEditable code={invoiceErrors.invoicePostalCode1} />}
	</>
}

const FormInvoiceControls = ({form } : { form: string }) => {
	const [{ values: invoiceValues }] : any = useReduxFormSelector(form);
	const [{ loading: loadingPassengers }] = usePassengersStatus();
	const [{ loading: loadingInvoice }] = useInvoiceStatus();

	return <div className="full-width">
		<ButtonTyped
			styling="primary"
			disabled={loadingPassengers || loadingInvoice}
			style={{ float: "right", marginTop: "5px" }}
			onClick={() => {
				Object.keys(invoiceValues).forEach(key => {
					const allowClear = key!=="invoiceCountry";
					if(allowClear && invoiceValues[key]) {
						dispatchChange(form, key, "");
					}
				})
			}}
		>Limpar</ButtonTyped>
	</div>
}


export const formConnect = reduxForm({
  form: "invoiceForm",
  asyncValidate,
  asyncBlurFields: ["invoicePostalCode1", "invoicePostalCode2", "invoiceNif"],
  enableReinitialize: true,
	// shouldValidate: () => true => site only
})

const Form = formConnect(({form}) => {

	const [{ agentCompany }] = useUserDetails();
	const { AllowsInvoice } = agentCompany || {};
  return <form className={`tabcontent invoice-form ${form}`} style={{ margin: "8px" }}>
		<FormErrorsContainer form={"invoiceForm"} />
		<hr />
		<div className="margin-bottom-8" style={{ margin: "12px 0px 10px 0px" }}>
			<label className="font-bold">Dados de Cliente</label>
		</div>
		 <StackPanel className="stack-client-data" style={{ maxWidth: "50%" }}>  
		{/*<StackPanel className="stack-client-data" count={2}>*/}
			<FieldTextEditable
				name="clientName"
			/>
			<FieldTextEditable
				name="clientDoc"
			/>
	
		</StackPanel>
		<StackPanel>
		<FieldTextAreaEditable
				name="clientObs"
				normalize={maxLengthNormalize(50)}
			/> 
		</StackPanel>
		<hr />
		<div className="margin-bottom-8" style={{ margin: "12px 0px 10px 0px" }}>
			<label className="font-bold">Dados de Fatura</label>
		</div>
		{AllowsInvoice && <>
			<StackPanel count={2}>
				<FieldTextEditable
					name="invoiceName"
				/>
				<FieldTextEditable
					name="invoiceNif"
					normalize={combineNormalize([numbersNormalize, maxLengthNormalize(9)])}
				/>
				<FieldTextEditable
					name="invoiceAddress"
				/>
			</StackPanel>
			<Flex count={2} gap={8}>
				<div>
					<FieldSelectEditable
						name="invoiceCountry"
						items={[ { value: "PT", text: "Portugal" } ]}
					/>
				</div>
				<div className="flex flex-row full-width">
					<FieldNumberEditable
						name="invoicePostalCode1"
						style={{ maxWidth: 180, minWidth: 180 }}
						className="invoicePostalCode1"
						// normalize={combineNormalize([numbersNormalize, maxLengthNormalize(4)])}
						normalize={maxLengthNormalize(4)}
					/>
					<FieldNumberEditable
						className="invoicePostalCode2"
						style={{ maxWidth: 80, minWidth: 80 }}
						name="invoicePostalCode2"
						// normalize={combineNormalize([numbersNormalize, maxLengthNormalize(3)])}
						normalize={maxLengthNormalize(3)}
					/>
					<FieldLabelEditable
						className="invoiceCity flex-end"
						style={{ maxWidth: 60, minWidth: 60 }}
						name="invoiceCity"
					/>
					<FormInvoiceControls form={form}/>
				</div>
			</Flex>
		</>}
		<InvoiceFormErrors form={form}/>
	</form>
  
})

export const InvoiceForm = () => {
  const [initialValues] = useInitialInvoice();
  return <Form initialValues={initialValues} />
}