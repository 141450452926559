import { requiredValidate } from '../../validators/requiredValidate';
import { FormFieldValidatorFunc } from '../../validators/base/FormFieldValidatorFunc';
import { FormFieldValidator } from '../../validators/base/FormFieldValidator';
export const mixValidate = (
  required: boolean,
  validate : FormFieldValidator,
  defaultValidate : FormFieldValidatorFunc[],
  _debug?: any
) => {
  const validatorsResult : any[] = [];
  if(typeof validate==="function") { validatorsResult.push(validate); }
  if(typeof validate==="object" && Array.isArray(validate)) {
    validate.forEach(val => validatorsResult.push(val));
  }
  if(typeof defaultValidate==="object" && Array.isArray(defaultValidate)) {
    defaultValidate.forEach(val => validatorsResult.push(val));
  }
  if(required) validatorsResult.push(requiredValidate);
  if(Object.keys(validatorsResult).length===0) return [];
  return validatorsResult.map(validator => {
    return (value : string) => {
      const res = validator ? validator(value) : undefined;
      return res;
    }
  });
}
