import React, { ReactNode } from "react";
import { RootGateProps } from "../gates";
import clsx from "clsx";
import { Z_FIELD_ACTIVE } from "consts";
// import { PrimitivePropsBase } from "../primitives";
import { Gate } from "effector-react";
import { WrappedFieldProps } from "redux-form";

export const FieldContainer = ({ WrappedGate, gateState, children } : {  WrappedGate: Gate<WrappedFieldProps>, gateState: RootGateProps, children: ReactNode }) => {
  const { input, meta } = WrappedGate.state.getState();
  const {
    defaults: {
      containerProps: {
        style: containerStyle,
        className: containerClassName,
        ...restContainerProps
      }
    },
    props: {
      style,
      required,
      className,
      disabled
    }
  } = (gateState || {}) as any;
  const { active, touched, error, warning } = meta || {};
  const { name } = input || {};
  
  return <div
    {...(restContainerProps || {})}
    className={`field field-${name} ${clsx(containerClassName)} ${clsx(className)} ${clsx(active && "active")} ${clsx(touched && (error || warning) && "error")} ${clsx(disabled && "disabled")} ${clsx(required && "required")}`}
    style={{
      ...(containerStyle || {}),
      ...(style || {}),
      zIndex: active ? Z_FIELD_ACTIVE : 0
    }}
  >
    {children}
  </div>
}