import React from "react";
import ReactCalendarBase from "react-calendar";
import { ReactCalendarProps } from "./ReactCalendarProps";
import clsx from "clsx";

const monthList = ['Janeiro','Fevereiro','Março ','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
const dowList = ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'];

export const ReactCalendar = ({ className, ...rest } : ReactCalendarProps) => {
  return <ReactCalendarBase
    locale="pt-PT"
    calendarType="US"
		className={`react-calendar-overrides ${clsx(className)}`}
    formatShortWeekday={(locale, date)=> dowList[date.getDay()]}
    formatMonthYear={(locale, date) => monthList[date.getMonth()]+" "+date.getFullYear()}
    minDetail="month"
    maxDetail="month"
    {...rest}
  />
}

export * from "./ReactCalendarProps";