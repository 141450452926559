import { useTripsRefresher } from 'hooks';
import { useRouteQueryKeys } from "hooks";
import { useReservationSearchFormValues, useReservationSearchConfirmed } from 'api';
import { useReservationMode } from "../reservation/useReservationMode"
import { API } from "types";
import { datetimeStringify, datetimeParse } from 'modules/datetime';
import { RestartNewClick } from "consts/schemas/ticketing/tickets/useTripsSelected";


export type UseReservationSearchConfirmActionResult = [
  {
    confirm: (mode?: API.LISTS.SCHEDULE_TYPE_MODE) => Promise<void>
  }
]
let saleClick = 1; //CAL, 2020-12-11 => controlar se é uma nova confirmação/venda


export const useReservationSearchConfirmAction = () : UseReservationSearchConfirmActionResult => {
  const [,{ addQuery }] = useRouteQueryKeys();
  const [, refreshTrips] = useTripsRefresher();
  const [searchFormValues] = useReservationSearchFormValues();
  const [confirmedValues]: any = useReservationSearchConfirmed();
  const [mode] = useReservationMode();
  const confirm = async(confirmMode?: API.LISTS.SCHEDULE_TYPE_MODE) : Promise<void> => {
    const clearRouteKeys: any[] = [];
    const clearRouteKeysDir = ["schedule_", "trip_", "price_type_"];
    const clearRoute : any = {};
    clearRouteKeysDir.forEach(key => {
      clearRoute[key+"ida"] = null;
      clearRoute[key+"volta"] = null;
    });
    clearRouteKeys.forEach(key => {
      clearRoute[key] = null;
    });

    
    saleClick = saleClick * -1;  //CAL, 2020-12-11
		const { origin, destination, ida, volta, roundTrip, revalidations, reschedule_of_ida, reschedule_of_volta } = searchFormValues;

		const newRoute = {
      ...clearRoute,
			origin,
			destination,
			mode: confirmMode && confirmMode.length>0 ? confirmMode : mode,
			ida: ida && datetimeStringify(datetimeParse(ida).fromFieldDateUtcString()).toSearchDateUtcString(),
			volta: volta && datetimeStringify(datetimeParse(volta).fromFieldDateUtcString()).toSearchDateUtcString(),
			reschedule_of_ida: revalidations ? reschedule_of_ida : null,
      reschedule_of_volta: revalidations ? reschedule_of_volta : null,
      saleClick: saleClick,
    };
		if(!roundTrip && newRoute.volta) {
			newRoute.volta = null;
		}


		const isChangedQuery = (() => {
      let isChanged = false;
      ["mode","ida", "volta", "origin", "destination", "reschedule_of_ida", "reschedule_of_volta"].forEach(key => {
        if(""+confirmedValues[key]!==""+newRoute[key]) isChanged = true;
      });
      return isChanged;
		})();

    RestartNewClick();

		if(isChangedQuery) {
      addQuery(newRoute);
    }
    else {
      await refreshTrips();
    }
	}

  return [
    {
      confirm
    }
  ]
}