import { APIDTO } from '@card4b/apidto-ts';
import { useSchedulesSelectedId } from 'api/ticketing/useSchedulesSelectedId';
import { useSchedulesWithStopsConfirmed } from 'api/ticketing/useSchedulesWithStopsConfirmed';

type UseScheduleSelectedResult = [
  APIDTO.API.DateSchedule | null,
  (schedule : (string | APIDTO.API.DateSchedule)) => Promise<void>
]

export const useSchedulesSelected = () : UseScheduleSelectedResult => {
  const [schedule_id, setID] = useSchedulesSelectedId();
  const [schedules] = useSchedulesWithStopsConfirmed();
  const selected : APIDTO.API.DateSchedule | null = (() => {
    if(!schedules) return null;
    const { outgoing_schedules } = schedules;
    if(!outgoing_schedules) return null;
    if(schedule_id) {
      for(let i=0; i<outgoing_schedules.length; i++) {
        if(outgoing_schedules[i].schedule_id===schedule_id) {
          return outgoing_schedules[i];
        }
      }
    }
    return outgoing_schedules[0];
  })();

  const setSelected = async (data : (string | APIDTO.API.DateSchedule)) => {
    if(typeof data==="string") {
      await setID(data);
    }
    if(typeof data==="object" && !!data) {
      await setID(data.schedule_id);
    }
  }
  return [
    selected,
    setSelected
  ]
}