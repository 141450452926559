import { useLowStorage } from "./useLowStorage";

export const useLowStorageAuth = () => {
  const [state, low] = useLowStorage();
  const { auth } = state || {};
  const setAuth = (params: any) => {
    if(!params) {
      low.remove("auth");
      return;
    }
    const {userID, sessionID} = params;
    low.set("auth", { userID, sessionID });
  }
  return [
    auth,
    setAuth
  ]
}
