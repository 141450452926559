import React from 'react';
import { datetime } from "modules";
import { ContentBoxTitle } from "controls";
import { stringify } from "query-string";
import { SetDataSalesStateFunc } from "./useSalesDetailsState";
import {
	PDFImage,
	PrintImage,
	LinkDownload,
	LinkPrint
} from "controls";
import { useSaleInfo } from 'api/sales/useSaleInfo';
import { useUserDetails } from 'api/user/useUserDetails';

const Row = ({name, value}: any) => <tr>
	<td className="text-bold">{name} </td>
	<td><label>{value}</label></td>
</tr>

interface DetailsProps {
	setParentState: SetDataSalesStateFunc;
	isPaidState: boolean;
}

export const Details = ({ setParentState, isPaidState } : DetailsProps) => {
	const [info, { loading/*, error*/ }, { refresh: saleInfoRefresh }] = useSaleInfo();
	const [{agentCompany}] = useUserDetails();
	const { AllowsInvoice } = agentCompany || {};
	const { Invoices, Details,HasInvoice, saleID } = info || {};
	if(!Details) return null;
	const {
		ReservationID,
		SaleID,
		Operator,
		State,
		SaleDate,
		Value,
		Origin,
		Destination,
		DepartureDate,
		ReturnDate,
	} = Details;

	const SaleDateStr = datetime.formatDateHours(SaleDate);
	const departure = datetime.formatDateHours(DepartureDate);
	const _return = ReturnDate ? (datetime.formatDateHours(ReturnDate)) : "-";
	const OriginDestination = Origin + " - " + Destination + " (" + departure + " / " + _return + ")"

	const ft = (Invoices || []).filter(item=>item.documentType.indexOf("FT")>=0).map((item, index)=>{
		const { documentNr/*, documentType, invoiceCompanyID*/ } = item;
		const href = "/api/sales/salePDF?"+stringify({invoiceId: documentNr, saleID});
		return <React.Fragment key={index}>
			<LinkDownload href={href} onStatus={setParentState}><PDFImage /></LinkDownload>
			<LinkPrint href={href} onStatus={setParentState}><PrintImage /></LinkPrint>
		</React.Fragment>;
	});
	const nc = (Invoices || []).filter(item=>item.documentType.indexOf("NC")>=0).map((item, index)=>{
		const { documentNr, documentType/*, invoiceCompanyID*/ } = item;
		const href = "/api/sales/salePDF?"+stringify({invoiceId: documentNr, saleID});
		return <React.Fragment key={index}>
			<LinkDownload href={href} onStatus={setParentState}><PDFImage icon={"pdf"+documentType} /></LinkDownload>
			<LinkPrint href={href} onStatus={setParentState}><PrintImage /></LinkPrint>
		</React.Fragment>;
	});

	const allTicketsHref = "/api/sales/allTicketsPDF?"+stringify({reservationNr: ReservationID});

	return <React.Fragment>
		<ContentBoxTitle>
			Detalhes
		</ContentBoxTitle>
		<table>
			<tbody>
				<Row name={"Cód. Reserva:"} value={ReservationID} />
				<Row name={"Nº Venda:"} value={SaleID} />
				<Row name={"Operador:"} value={Operator} />
				<Row name={"Data/Hora:"} value={SaleDateStr} />
				<Row name={"Estado:"} value={State} />
				<Row name={"Valor:"} value={`${Value && (+Value).toFixed(2)} €`} />
				<Row name={"O/D:"} value={OriginDestination} />
				{isPaidState && <tr>
						<td className="text-bold">Bilhetes:</td>
						<td>
							<LinkDownload href={allTicketsHref} onStatus={setParentState}><PDFImage /></LinkDownload>
							<LinkPrint href={allTicketsHref} onStatus={setParentState}><PrintImage /></LinkPrint>
						</td>
					</tr>}
				{AllowsInvoice && (!HasInvoice || ft.length>0) && <tr>
					<td className="text-bold">Fatura:</td>
					{(!ft || ft.length===0) && <td>
						<img
							alt="refresh"
							id="updateInvoiceInfoBto"
							src="/images/icons/refreshBlue.png"
							className="pointer"
							onClick={e => {
								saleInfoRefresh();
								e && e.preventDefault();
							}}
						/>
						{loading && <span className="error no_old_display">A atualizar...</span>}
					</td>}
					{ft && ft.length>0 && <td>
						<span id="saleDetailsExtractDownloadBto" className="no_old_display">
							{ft}
						</span>
					</td>}
				</tr>}
				{/* {ENV_DEV && <button onClick={() => {
					saleInfoRefresh();
				}}>REFRESH</button>} */}
				{AllowsInvoice && nc.length>0 &&
					<tr>
						<td id="saleDetailsExtractNC" className="text-bold">Nota Crédito:</td>
						<td>
							<span id="saleDetailsExtractDownloadBtoNC" className="no_old_display">
								{nc}
							</span>
						</td>
					</tr>
				}
			</tbody>
		</table>
	</React.Fragment>
}