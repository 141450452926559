import { useRouteQueryKeys } from "./useRouteQueryKeys";
import { isFalsy } from "modules";
import { UseRouteQueryKeyResult } from "./types/UseRouteQueryKeyResult";
/**
 * Function updates routing query string value
 * 
 * @param key name for search query parameter
 * 
 * @returns value in search by key, if isFalsy - then null
 * 
 * @version 1.0.0
 */
export const useRouteQueryKey = (key : string) : UseRouteQueryKeyResult => {
  const [{ [key] : value }, { addQuery }] = useRouteQueryKeys();
  return [
    isFalsy(value) ? null : value,
    async (value : any) => {
      await addQuery({[key]: value })
    }
  ]
}
export { useRouteQueryKey as useRouteQuery }