import pad from 'pad';
import { getMonthName } from '../datetimeHelpers/getMonthName';
import { DatetimeStringifyFunctions } from './DatetimeStringifyFunctions';

export interface DatetimeStringifyParams {
  format: string;
}


const pad2NBegin = (value : number) => pad(2, ''+(value), '0');
const pad4NBegin = (value : number) => pad(4, ''+(value), '0');

const serializeDateAsc = (date : Date, utc: boolean, delimiter: string) => { // DD_MM_YYYY where "_" is delimiter
  const [dayIdx, monthIdx, yearIdx] = utc ? [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()] : [date.getDate(), date.getMonth(), date.getFullYear()];
  return `${pad2NBegin(dayIdx)}${delimiter}${pad2NBegin(monthIdx+1)}${delimiter}${pad4NBegin(yearIdx)}`;
}

const serializeDateDsc = (date : Date, utc: boolean, delimiter: string) => { // YYYY_MM_DD where "_" is delimiter
  const [dayIdx, monthIdx, yearIdx] = utc ? [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()] : [date.getDate(), date.getMonth(), date.getFullYear()];
  return `${pad4NBegin(yearIdx)}${delimiter}${pad2NBegin(monthIdx+1)}${delimiter}${pad2NBegin(dayIdx)}`;
}

const serializeShortTime = (date : Date, utc : boolean, delimiter: string) => { // HH_MM where "_" is delimiter
  const [hours, minutes] = utc ? [date.getUTCHours(), date.getUTCMinutes()] : [date.getHours(), date.getMinutes()];
  return `${pad2NBegin(hours)}${delimiter}${pad2NBegin(minutes)}`;
}

const serializeLongDateNoYear = (date : Date, utc: boolean, lang: string) => {
  const [dayIdx, monthIdx] = utc ? [date.getUTCDate(), date.getUTCMonth()] : [date.getDate(), date.getMonth()];
  return `${dayIdx} ${getMonthName(monthIdx, lang)}`
}

const serializeLongDateWithYear = (date : Date, utc: boolean, lang: string) => {
  const [dayIdx, monthIdx, yearIdx] = utc ? [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()] : [date.getDate(), date.getMonth(), date.getFullYear()];
  return `${dayIdx} ${getMonthName(monthIdx, lang)} ${yearIdx}`
}

/**
 * Function stringify Date object and returns empty string if date was empty
 * @param {Date} date value of datetime to be serialized
 */
export const datetimeStringify = (date?: Date) : DatetimeStringifyFunctions => {
  return {
    toFieldDateUtcString: () => !date ? '' : serializeDateAsc(date, true, '/'),
    toFieldDateString: () => !date ? '' : serializeDateAsc(date, false, '/'),

    toFieldDateTimeUtcString: () => !date ? '' : `${serializeDateAsc(date, true, '/')} ${serializeShortTime(date, true, ':')}`,
    toFieldDateTimeString: () => !date ? '' : `${serializeDateAsc(date, false, '/')} ${serializeShortTime(date, false, ':')}`,

    toSearchDateUtcString: () => !date ? '' : serializeDateDsc(date, true, '.'),
    toSearchDateString: () => !date ? '' : serializeDateDsc(date, false, '.'),

    toLegacyDateUtcString: () => !date ? '' : serializeDateDsc(date, true, '-'),
    toLegacyDateString: () => !date ? '' : serializeDateDsc(date, true, '-'),

    toTimeUtcString: () => !date ? '' : serializeShortTime(date, true, ':'),
    toTimeString: () => !date ? '' : serializeShortTime(date, false, ':'),

    toLongDateUtcNoYear: (lang : string) => !date ? '' : serializeLongDateNoYear(date, true, lang),
    toLongDateNoYear: (lang : string) => !date ? '' : serializeLongDateNoYear(date, false, lang),

    toLongDateUtcWithYear: (lang : string) => !date ? '' : serializeLongDateWithYear(date, true, lang),
    toLongDateWithYear: (lang : string) => !date ? '' : serializeLongDateWithYear(date, false, lang),
  };
}