import React from "react";
import {
	COMPANY_DEPTH_KEY,
	GROUP_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
	POINTOFSALE_MANAGER,
	GROUP_MANAGER,
	ACCOUNT_DEPTH_KEY
} from "consts";
import { useConfigurationBlock } from "api/configuration";

const ACCOUNT_DESACTIVATE_MESSAGE = "Tem a certeza que pretende desactivar o utilizador?";
const OTHER_DESACTIVATE_MESSAGE = "Tem a certeza? Todos os utilizadores associados serão desactivados.";


export const RemoveButton = ({level, ID, UserType}: any) => {
	const [,,{ disable: disableAgent }] = useConfigurationBlock({level});
	if(level===COMPANY_DEPTH_KEY) return null;
	if(level===GROUP_DEPTH_KEY && UserType===GROUP_MANAGER) return null;
	if(level===POINT_OF_SALE_DEPTH_KEY && UserType===POINTOFSALE_MANAGER) return null;
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	return <a
		className="btn-small deleteNodeBto"
		href="#"
		onClick={async ()=> {
			if (!window.confirm(level === ACCOUNT_DEPTH_KEY ? ACCOUNT_DESACTIVATE_MESSAGE : OTHER_DESACTIVATE_MESSAGE)) return;
			await disableAgent(ID);
			
		}}
	>
		<img alt="delete" src="images/icons/deleteBtn.png" width="15" height="15" onClick={(e)=>{
			e.preventDefault();
		}} />
	</a>
}