import React from "react";
import { useVoucherCurrent, useClientInfoCustomer } from "api";
import { LOADING_STR } from "consts";
import { Loader } from "controls";
import { Form } from "./Form";

export const FormVoucherPassengers = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const [, { loading }] = useVoucherCurrent();
  const [customer] = useClientInfoCustomer();
  const { Name, DocumentNr, RFLEXCard } = customer || {};
  const { CardNumber } = RFLEXCard || {};
  const { ...restAttributes } = htmlAttributes;
  return <div {...restAttributes}>
    {loading && <Loader text={LOADING_STR} overlay />}
    <div>
      <Form
        initialValues={{passengers: [{
            name: Name,
            doc: DocumentNr,
            rflex: CardNumber
        }]}}
      />
    </div>
  </div>
}