export function isValidDate(str: any) {
	if(!str) return false;
	let result = true;
    const separator = str.indexOf('-') > -1 ? '-' : '/';

    const bits = str.split(separator);

    if (bits.length !== 3) {
        result = false;
    } else {
        const day = parseFloat(bits[0]);
        const month = parseFloat(bits[1]) - 1;
        const year = parseFloat(bits[2]);

        const date = new Date(year, month, day, 0, 0, 0);

        if (year !== date.getFullYear() ||
            month !== date.getMonth() ||
            day !== date.getDate()) {

            result = false;
        }
    }
    return result;
}