import { useReservationPriceTypeValidation, useReservationTimeValidation } from 'api';
import { useReservationRegisterRequest } from "./requests/useReservationRegisterRequest";
import { useFetchAction } from "hooks";
import { useReservationId } from "./helpers/useReservationId";
import { useReservationUID } from "./helpers/useReservationUID";
import { usePassengersForm } from "../passengers/usePassengersClient/usePassengersForm";
import { useRouteQueryKeys } from "hooks";
import { useStepsProceed } from "hooks";
import { UseReservationRegisterResult } from 'types/reservation/UseReservationRegisterResult';
import { useTripsConfirmed } from 'api/ticketing/useTripsConfirmed';

export const useReservationRegister = () : UseReservationRegisterResult => {
	const [reservationUid] = useReservationUID();
	const [, { addQuery }] = useRouteQueryKeys();
	const [{isValidPriceTypes}] = useReservationPriceTypeValidation();
	const [isValidTime] = useReservationTimeValidation();
	const [reservationId, { loading: idLoading }] = useReservationId();
	const [request] = useReservationRegisterRequest();
	const [, { loading: tripsLoading }] = useTripsConfirmed();
	const [{ syncErrors: passengersSyncErrors }] = usePassengersForm();
	const [response, { success, error, loading: registerLoading }, { execute }] = useFetchAction("/api/reservation/register", {
		method: "POST",
		body: {
			...request,
			reservationUid
		}
	});
	const loading = idLoading || registerLoading || tripsLoading;
	const visible = !reservationId;
	const enabled = !!visible && !!isValidTime && !passengersSyncErrors && !loading && !!isValidPriceTypes;//!invoiceSyncErrors &&
	const [doIt, { loading: loadingDo }] = useStepsProceed({
		name: "register",
		loading,
		enabled,
		steps: [
			async() => {
				try {
					const response = await execute();
					return response;
				}
				catch(error) {
					return false;
				}
			},
			async(res) => {
				const { reservationUid, reservationToken, isRecover } = res;
				addQuery({reservationToken, reservationUid, isRecover})
			}
		]
	});

	return [
		{ request, response, visible, enabled },
		{ loading: loading || loadingDo, success, error },
		{ register: doIt }
	];
}
