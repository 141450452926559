

import React from "react";
import "./index.scss";
import { TableOld, Loader } from "controls";
import { useReservationId } from "api";
import { useSeatsReservationLegs } from "api";
import { useReservationInfo } from "api";
import { useSeatsReservationInfo } from "api";
import { useSeatsReservationTotalLegIndexed } from "api";
import { BusShared as Bus } from "controls";
import { useStateNamed } from "hooks";
import { BusRowsProps } from "types/models";

const TableHeader = () => <thead>
	<tr>
		<th></th>
		<th>Origem</th>
		<th>Destino</th>
		<th>Viatura</th>
		<th>Lugares Marcados</th>
		<th>Lotação</th>
		<th>Vendidos</th>
		<th>Reservados</th>
	</tr>
</thead>

const BusRows = ({onSelect, selected, className, direction, index, text, leg} : BusRowsProps) => {
	if(!leg) return null;
	let seatsReserved = leg.trip.seating_capacity - leg.assigned_seats.length;
	leg.available_seats && leg.available_seats.forEach(av => {
		const { first, last } = av;
		const diff = last-first+1;
		seatsReserved -= diff;
	})
	return <tr
		data-direction={direction}
		data-index={index}
		className={`${className} ${index % 2 !== 0 ? "colored-old" : ""} ${selected ? "selected" : ""}`}
		onClick={() => onSelect()}
	>
		<td className={text ? "text-big tableIdentifier" : ""} style={{minWidth: 30}}>
			{text}
		</td>
		<td>{leg.stops[0].stop.name}</td>
		<td>{leg.stops[leg.stops.length-1].stop.name}</td>
		<td>{leg.trip.bus_no}</td>
		<td>{leg.trip.seat_assignment && !!leg.trip.seating_capacity ? "Sim" : "Não"}</td>
		<td>{leg.trip.seating_capacity}</td>
		<td>{seatsReserved}</td>
		<td>{leg.assigned_seats.length}</td>
	</tr>
}

export const ReservationSeatsView = ({ style } : any) => {
	const [selectedBus, setSelectedBus] = useStateNamed("seats.selectedBus", 0);
	const [reservationId] = useReservationId();
	const [reservationInfo] = useReservationInfo();
	const [,{ loading }] = useSeatsReservationInfo();
	const [{ totalLegs }] = useSeatsReservationLegs();
	const [leg] = useSeatsReservationTotalLegIndexed(selectedBus);
	const { outgoing_itinerary } = reservationInfo || {};
	const { origin, destination, trip } = leg || {};
	const operator = trip && trip.carrier && trip.carrier.id;
	const operatorIcon = `/images/operators/${operator}.gif`;

	return <div style={style} id={"tabSeats"} className="tabcontent seats-container">
		{loading && <Loader fullscreen overlay />}
		{!loading && <>
			<TableOld
				id="busListTable"
				className="bus-choise-table"
				selectable={true}
				isDefault={true}
			>
				<TableHeader />
				<tbody>
					{totalLegs && totalLegs.map((leg : any, index : any)=> {
						const direction = index<outgoing_itinerary.legs.length ? "outbound" : "return";
						const text = index===0 ? "Ida" : index===outgoing_itinerary.legs.length ? "Volta" : null;
						return <BusRows
							key={index}
							direction={direction}
							text={text}
							leg={leg}
							index={index}
							selected={selectedBus === index}
							onSelect={() => {
								setSelectedBus(index);
							}}
						/>
					})}
				</tbody>
			</TableOld>
			<div className="bus-seats-manager">
				<div id="operatorIconPane" >
					{operator && operatorIcon && <img  style={{height: "25px"}} id="operatorIcon" src={operatorIcon} alt="operator"/>}
				</div>
				<div>
					<label className="text-big">{origin}</label>
					<img src="images/icons/seta_destino.gif" alt="para" />
					<label className="busTripDestination">{destination}</label>
				</div>
			</div>
			{totalLegs && totalLegs.map((leg, index) => {
				if(index !== selectedBus) return null;
				return <Bus
					reservationId={reservationId}
					index={index}
					postChange={() => {
						// refreshPurchase();
					}}
				/>
			})}
		</>}
	</div>
}