import React, { useLayoutEffect, useRef } from "react";
import "./index.scss";
import { ENV_DEV, Z_LOADER } from "consts";

export const LoaderBase = ({className, fullscreen, overlay, RenderLoader, containerStyle, ...rest} : any) => {
	
	const ref = useRef<any>();
	useLayoutEffect(() : any => {
		if(overlay) {
			const parentNode = ref.current.parentNode;
			const originalStyle = window.getComputedStyle(parentNode).position;
			parentNode.style.position = 'relative';
			return () => parentNode.style.position = originalStyle;
		}
	}, [overlay]);
	if(ENV_DEV) {
		if(!RenderLoader) {
			console.error("RenderLoader is not provided in Loader");
			return <h1 style={{color: "red"}}>Error, check console</h1>
		}
	}
	return <div
		ref={ref}
		className={`loader-base ${fullscreen ? "fullscreen" : ""} ${overlay ? "overlay" : ""} ${className}`}
		style={{...containerStyle, zIndex: Z_LOADER}}
	>
		<RenderLoader {...rest} />
	</div>
}