/**
 * Return function, which freeze when called and return result if previous froze was unfrozen
 * @param timeout delay before unlock in milliseconds
 * @returns Function of locker
 */
export const createLocker = (timeout : number) => {
  let locker = false;

  return () => {
    // const current = locker;
    locker = true;
    setTimeout(() => locker = false, timeout);
    return locker;
  }
}