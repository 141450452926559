/* eslint-disable no-throw-literal */
import { fetchJson } from 'modules/fetch';

export const validatePostal = async ({ invoicePostalCode1, invoicePostalCode2 }: any) => {
	const it1 = invoicePostalCode1 && invoicePostalCode1.length===4;
	const it2 = invoicePostalCode2 && invoicePostalCode2.length===3;
	if(!invoicePostalCode1 && !invoicePostalCode2) {
		return;
	}
	if(!(it1 && it2)) {
		throw "Dados postais inválidos. Deve preencher o código postal e o sufixo.";
	}
	if(it1 && it2) {
		let response: any;
		try {
			try {
				response = await fetchJson("/api/user/postalCode", { method: "POST", body: {
					postalCode: invoicePostalCode1,
					subPostalCode: invoicePostalCode2
				}})
			}
			catch (error) {
				throw error.error;
			}
			if(response == null || response.length === 0 || response === '')
			{
				throw 'Dados postais inválidos. Contacte apoio ao cliente.';
			}
			else {
				return response;
			}
		}
		catch(error) {
			throw error;
		}
	}
	return;	
}