import { useFetchOnce } from "hooks";
import { useSaleInfoId } from "./useSaleInfoId";
import { APIDTO } from "@card4b/apidto-ts";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export type UseSaleInfoResult = FetchOnceResult<APIDTO.Payment.SaleInfoResponse>

export const useSaleInfo = () : UseSaleInfoResult => {
  const [salesrpoid] = useSaleInfoId();
  const [data, status, meta] = useFetchOnce<any>("/api/sales/info/", {
    method: "POST",
    lock: !salesrpoid,
    body: { id: salesrpoid }
  });
  return [data, status, meta];
}