import { useFetchAction } from "hooks";
import { useReservationId } from "./helpers/useReservationId";
import { useReservationClear } from "api/reservation";
import { useStepsProceed } from "hooks";
import { useReservationCancelRequest } from "./requests/useReservationCancelRequest";
import { UseReservationCancelResult } from "types/reservation/UseReservationCancelResult";

export const useReservationCancel = () : UseReservationCancelResult => {
	const [clearReservation] = useReservationClear();
	const [reservationId, { loading: idLoading }] = useReservationId();
	const [request] = useReservationCancelRequest();
	const [response,  { success, error, loading: cancelLoading }, { execute }] = useFetchAction("/api/reservation/cancel", {
		method: "POST",
		body: request
	});
	const visible = !!reservationId;
	const loading = idLoading || cancelLoading;
	const enabled = visible && !loading;

	const [doIt, { loading: loadingDo }] = useStepsProceed({
		name: "cancel",
		loading,
		enabled,
		steps: [
			async() => {
				return window.confirm("Tem a certeza que pretende continuar?");
			},
			async () => {
				try {
					await execute();
					return true;
				}
				catch(error) {
					return false;
				}
			},
			async() => {
				await clearReservation();
				return true;
			}
		]
	});

	return [
		{ request, response, visible, enabled },
		{ loading: loading || loadingDo, success, error },
		{ cancel: doIt }
	]
}