export const deepEqual = (obj1 : any, obj2 : any) => {
	if(!obj1 && !obj2) return true;
	if(typeof obj1!==typeof obj2) return false;
	if(typeof obj1==="object") {
		if(Array.isArray(obj1) !== Array.isArray(obj2)) return false;
		if(Array.isArray(obj1)) {
			if(obj1.length!==obj2.length) return false;
			let isEqual = true;
			for(let i=0; i<obj1.length; i++) {
				if(!deepEqual(obj1[i], obj2[i]))
					isEqual = false;
			}
			return isEqual;
		}
		else {
			if(Object.keys(obj1).length!==Object.keys(obj2).length) return false;
			if(obj1 instanceof Date) return (+obj1 === +obj2);
			
			let isEqual = true;
			Object.keys(obj1).forEach(key => {
				if(!deepEqual(obj1[key], obj2[key]))
					isEqual = false;
			})
			return isEqual;
		}
	}
	return ""+obj1===""+obj2;
}