import {
	useReservationId,
	useReservationSearchConfirmed,
} from 'api';
import { useTripsTicketsSelected } from 'api/ticketing/useTripsTicketsSelected';
const INVALID_TIME_MESSAGE_STR = "Hora de início da viagem de volta deve ser posterior à data de chegada da viagem de ida";

export const useReservationTimeValidation = () => {
	const [{ ida, volta }] = useReservationSearchConfirmed();
	const [reservationId] = useReservationId();
	const [{ outgoing_ticket, return_ticket }] = useTripsTicketsSelected();
	const idaStart = ida && outgoing_ticket ? outgoing_ticket.arrival_time : "";
	const voltaStop = volta && return_ticket ? return_ticket.departure_time : "";
	const idaD = ida && Date.parse('20 Aug 2000 ' + idaStart);
	const voltaD = volta && Date.parse('20 Aug 2000 ' + voltaStop);

	const isErrorOrder = (
		(reservationId || outgoing_ticket || return_ticket) &&
		(ida && volta) &&
		(idaStart && voltaStop && volta.getTime() === ida.getTime()) &&
		(idaD > voltaD)
		);
		

    const isValid = !isErrorOrder;
    return [
        isValid,
        (isValid ? null : INVALID_TIME_MESSAGE_STR)
    ]
}