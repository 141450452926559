
// import { AllowedLanguages } from "./AllowedLanguages";
const ptWeek = [ 'D', 'S', 'T','Q','Q','S','S'];
const enWeek = [ 'Su', 'Mo', 'Tu','We','Th','Fr','Sa'];
const frWeek = [ 'Di', 'Li', 'Ma','Me','Je','Ve','Sa'];
const esWeek = [ 'D', 'L', 'M','M','J','V','S'];

export const getShortWeekDayList = (lang : string) : string[]=> {
  if(lang==='pt') return ptWeek;
  if(lang==='en') return enWeek;
  if(lang==='es') return esWeek;
  if(lang==='fr') return frWeek;
  return null;
}