import { useConfigurationBlock } from "./useConfigurationBlock";
import { useFetchOnce } from "hooks";

import {
	COMPANY_DEPTH_KEY,
	GROUP_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
	ACCOUNT_DEPTH_KEY
} from "consts";

export const useConfigurationCompany = () => useConfigurationBlock({ level: COMPANY_DEPTH_KEY });
export const useConfigurationGroup = () => useConfigurationBlock({ level: GROUP_DEPTH_KEY });
export const useConfigurationPOS = () => useConfigurationBlock({ level: POINT_OF_SALE_DEPTH_KEY });
export const useConfigurationAgent = () => useConfigurationBlock({ level: ACCOUNT_DEPTH_KEY });

export const useConfigurationSalesPeriods = () => useFetchOnce<any>("/api/configuration/salesPeriods");
export const useConfigurationManagers = () => useFetchOnce<any>("/api/configuration/managers");
export const useConfigurationUsers = ({ lock }: { lock?: boolean }) => useFetchOnce<any>("/api/configuration/users", { method: "GET", lock });
export const useConfigurationHierarchy = () => useFetchOnce<any>("/api/configuration/hierarchy", { keepData: true });