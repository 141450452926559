import React from "react";
import { usePage } from "hooks";
import { DEFAULT_TITLE } from "consts";
import { Flex, TitlePanel } from "controls";
import { VoucherInfoView } from "./VoucherInfoView";
import { VoucherSearchView } from "./VoucherSearchView";
import { FormVoucherPassengers } from "./FormVoucherPassengers";
import "./index.scss";

export const Voucher = () => {
	usePage({
		name: "VOUCHER",
		options: {
			title: DEFAULT_TITLE
		}
	});
	return <Flex column ratio={[45, 55]} gap={5} className="full-size page-voucher">
		<TitlePanel
			type={"dark"}
			title={"Voucher"}
			className="panel-main-search"
		>
			<Flex gap={5} className="panel-main-search-flex" ratio={[1,1,1]}>
				<VoucherSearchView />
				<FormVoucherPassengers />
				<VoucherInfoView />
			</Flex>
		</TitlePanel>
		<div></div>
	</Flex>
}

export default Voucher;