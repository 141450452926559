import { useReservationToken } from "./useReservationToken";
import { useFetchOnce } from "hooks";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

export const useReservationId = () : FetchOnceResult<string> => {
	const [reservationToken] = useReservationToken();
	const [data, status, meta] = useFetchOnce<any>("/api/reservation/token/toid", {
		method: "POST",
		lock: !reservationToken,
		body: { reservationToken }
  });
  const { reservationId } = data || {};
	return [
    reservationId,
    status,
    meta
	]
}