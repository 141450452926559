import React, { useRef } from "react";
import { ItemsListInputItem } from "../../items";
import { ItemsListInputProps } from "./ItemsListInputProps";
// export { ItemsListInputProps }

export const ItemsListInput = ({ scope, items,	...htmlAttributes} : ItemsListInputProps) => {
	const hiddenRef : any = useRef<HTMLInputElement>();
	const { value, className, onChange, ...restHtmlAttributes } = htmlAttributes;
	return <div {...restHtmlAttributes} className={`${className} list`}>
		<input type={"hidden"} defaultValue={value} ref={hiddenRef} onInput={onChange} />
		{items && items.map((item, index) => {
			return <ItemsListInputItem
				scope={scope}
				item={item}
				onClick={(e) => {
					hiddenRef.current.value = item.value;
					hiddenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
				}}
			/>
		})}
	</div>
}