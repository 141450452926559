import React from "react";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts";
import { LoadingButton } from "controls";
import { FormConfirmBase } from "forms";

const RenderButton = ({form, ...rest}: any) => {
	return <LoadingButton
		{...rest}
		scope={form}
		className={"btn-confirm btn-fix-height btn-fix-width"}
		as="confirm"
	/>
}

export const FormConfirm = ({form, ...rest}: any) => <FormConfirmBase {...rest} form={form} RenderButton={RenderButton} timeout={CONFIRM_ERROR_TIMEOUT_MS} />