import { useSelector } from "react-redux";
import { UseReduxFormSelectorResult } from "./types";

export const useReduxFormSelectorBase = <TValues extends any>(form : string) : [UseReduxFormSelectorResult<TValues>] => {
  const selector = useSelector((state : { form: any }) => state.form[form]);
  const { values, ...rest } = selector || {};
  return [{
    ...(rest || {}),
    values: values || {},
    form
  }];
}
