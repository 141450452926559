import React from "react"

class AdminAsync extends React.Component {
	state : any = {}
	componentDidMount() {
		this.load();
	}
	load = async () => {
		const component = (await import("./index")).default;
		this.setState({component});
	}
	render() {
		if(!this.state.component) return null;
		return React.createElement(this.state.component);
	}
}

export default AdminAsync;