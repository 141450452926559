import React from "react";
import { TableSchemed, PDFImage, PrintImage, Loader, ButtonStatusEditable, ErrorEditable } from "controls";
import clsx from "clsx";
import "./index.scss";
import { LOADING_STR } from "consts";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts/keys";
import { APIError } from "types";
import { datetimeParseOld } from "modules/datetime/datetimeParseOld";
import { datetimeStringify } from "modules/datetime/datetimeStringify";
import { priceFormat } from "modules/formatters/priceFormat";
import { base64Print } from "modules/files/print/base64Print";
import { base64Download } from "modules/files/downoad/base64Download";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";
import { useVoucherStatus } from "api/voucher/useVoucherStatus";
import { useVoucherCurrent } from "api/voucher/useVoucherCurrent";
import { useVoucherDelete } from "api/voucher/useVoucherDelete";
import { useUserDetails } from "api/user/useUserDetails";

interface VoucherItem {
  name: string;
  value?: string;
  Component?: React.FunctionComponent<any>;
}

export const VoucherInfoView = (htmlAttributes : React.HTMLAttributes<{}>) => {
  const { className, ...restAttributes } = htmlAttributes;
  const [voucher] = useVoucherCurrent();
  const [{ loading: loadingVoucher }] = useVoucherStatus();
  const [deleteStatus,deleteVoucher] = useVoucherDelete();
  const [statusConverted] = useFetchStatusTimeout("useVoucherDeleteStatus", deleteStatus, CONFIRM_ERROR_TIMEOUT_MS);
  const loading = loadingVoucher || (deleteStatus && deleteStatus.loading);
  const [details] = useUserDetails();
  const { userID } = details || {};

  const onCancel = async () => {
    try {
      await deleteVoucher({
        id: voucher && voucher.id,
        userId: userID,
      });
    }
    catch(error) {}
  }

  const VoucherButtons = () => {
    return <div className="flex flex-row">
      <div onClick={async () => {
        await base64Download("data:application/pdf;base64,"+voucher.pdf, "application/pdf", "voucher_"+voucher.id+".pdf");
      }}><PDFImage /></div>
      <div onClick={async () => {
        await base64Print("data:application/pdf;base64,"+voucher.pdf, "application/pdf");
      }}><PrintImage /></div>
    </div>
    
  }
  
  const items : VoucherItem[] = (!voucher) ? [] : [
    { name: "Nº Voucher:", value: voucher.id },
    { name: "Nº Venda:", value: voucher.saleId },
    { name: "Operador:", value: voucher.operatorName },
    { name: "Validade:", value: voucher.validity },
    { name: "Data/Hora:", value: datetimeStringify(datetimeParseOld(voucher.timeCreate)).toFieldDateTimeUtcString() },
    { name: "Estado:", value: voucher.operation },
    { name: "Valor:", value: ""+priceFormat(voucher.price) },
    { name: "O/D:", value: `${voucher.from.name} - ${voucher.to.name}` },
    { name: "Voucher:", Component: VoucherButtons }
  ];
  const isPaid = voucher && voucher.status===3; /*APIDTO.Enum.ReservationStates.Payed*/
  return <div { ...restAttributes } className={`voucher-info-view ${clsx(className)}`}>
    {loading && <Loader text={LOADING_STR} overlay/>}
    {voucher && <TableSchemed
      style={{width: "100%"}}
      items={items}
      body={{
        schema: (item : VoucherItem) => {
          return [{
            children: <strong>{item.name}</strong>
          }, {
            children: item.Component ? React.createElement(item.Component) : <span>{item.value}</span>
          }];
        }
      }}
    />}
    {voucher && isPaid && <div className="flex flex-end">
      <ButtonStatusEditable
        scope="VOUCHER"
        as="CANCEL_VOUCHER"
        status={statusConverted}
        styling="primary"
        onClick={onCancel}
      />
    </div>}
    {statusConverted && statusConverted.error && <ErrorEditable code={(statusConverted.error as APIError).error} />}
  </div>
}