import React, { useRef, useState } from "react";
//@ts-ignore
import { colors } from "styleguide";

const ColorCell = ({colorName, color} : any) => {
	const colorRef = useRef<any>();
	const computedStyle = colorRef && colorRef.current && colorRef.current.style;
	const borderStyle = `1px inset ${color}`;

	const { color: rgbColor } = computedStyle || {};
	const [r, g, b] : [number,number,number] = !rgbColor ? [0,0,0] : rgbColor.replace("rgb(", "").replace(")", "").replace(" ", "").replace(" ", "").replace(" ", "").split(",");
	const brightness = (((r) + (g) + (b)) / 3);
	const fontColor = brightness > 180 ? "black" : "white";
	const [updateCount,forceUpdate] = useState<number>(0);
	if(r+g+b === 0 && updateCount<5) {
		setTimeout(()=>forceUpdate(updateCount+1),100);
	}

	return <tr className="color-cell" style={{color, border: borderStyle}} ref={colorRef}>
		{true && <>
			<td style={{backgroundColor: color, color: fontColor}}>{colorName}</td>
			<td style={{borderRight: borderStyle, color}}>{color}</td>
			<td style={{borderRight: borderStyle, color}}>{rgbColor}</td>
			<td>brightness: {brightness}</td>
		</>}
	</tr>
}

export const StyleGuideContent = () => {
	const colorsNormalized : any[] = [];
	Object.keys(colors).forEach(key => {
		//@ts-ignore
		colorsNormalized.push({name: key, color: colors[key]})
	})
	return <div className="styleguide-content" style={{display: "flex", flexDirection: "column"}}>
		<table>
			<tbody>
				{colorsNormalized.map(({name, color}) => {
					if(name.indexOf("delimiter")>=0) {
						return <tr>
							<td colSpan={4}>
								<label style={{fontWeight: "bold", width: "100%", textAlign: "center"}}>{color}</label>
							</td>
						</tr>
					}
					return <ColorCell key={name} colorName={name} color={color} />
				})}
			</tbody>
		</table>
	</div>
}