import React from "react";
import { useFetchOnce } from "hooks";
import { AdminProvider } from "api/admin/context";
import { useAdminPreview, useAdminConfigs } from "admin/hooks";
import { AdminPermissions } from "../AdminPermissions";
import { AdminStorage } from "../AdminStorage";
import { AppLoader } from "app/AppLoader";
import { fetchJson } from "modules/fetch";
import { useAdmin } from "api/admin";
import { AdminAdmin } from "types/admin/AdminAdmin";

const AdminPanelBase = () => {
  const [,setPreviewAdmin] = useAdminPreview();
  const [admin] = useAdmin();
  const [,{ refresh }] = useAdminConfigs();
  const removeAdmin = async (admin : AdminAdmin) => {
    const yes = window.confirm("You sure you want to remove admin "+admin.email+"?");
    if(!yes) return;
    await fetchJson("/api/admin/admins/remove", {
      method: "POST",
      body: {
        userID: admin.userID
      }
    });
    await refresh();
  }
  return <tr>
    <td>
      <div className="flex-column">
        <strong style={{margin: 8}}>{admin.email}</strong>
        <span style={{margin: 8}}>{admin.userID}</span>
      </div>
    </td>
    <td>
        <div className="flex-column">
          <div style={{margin: 8}}>
            <AdminPermissions />
          </div>
          <div style={{margin: 8}}>
            <AdminStorage />
          </div>
        </div>
    </td>
    <td style={{paddingLeft: 20}}>
      <button className="btn-primary" onClick={() => {
        setPreviewAdmin(admin);
      }}>Preview</button>
      <button className="btn-warning" onClick={() => {
        removeAdmin(admin);
      }}>Remove</button>
    </td>
  </tr>
}

export const AdminPanel = ({userID} : {userID:string}) => {
  const [adminServer] = useAdmin();

  const allowFetch = userID && adminServer.userID!==userID;

  const [admin,, { refreshSilent }] = useFetchOnce<any>(`/api/admin/admin`, {
    body: { userID },
    lock: !allowFetch
  });

  if(allowFetch) {
    return <AdminProvider admin={admin} onRefresh={refreshSilent}>
      {admin ? <AdminPanelBase /> : <AppLoader />}
    </AdminProvider>
  }
  return <AdminPanelBase />
}