let isInitialized = false;

const initIt = () => {
    const funct = (f: any, b: any,e: any,v: any,n?: any,t?: any,s?: any) : any => {
        if(f.fbq)
            return;
        
        n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
        };
        if(!f._fbq)
            f._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue=[];
        t=b.createElement(e);
        t.async=!0;
        t.src=v;
        s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
    };

    //!
    funct(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
}

const init = () => {
    initIt();
    //@ts-ignore
    window.fbq('init', '555911811441929');
    // !function(f,b,e,v,n,t,s) {
    //     // alert("OK 1");
    //     // if(f.fbq)return;
    //     fbq=function(){
    //         n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
    //     };
    //     n = fbq;
    //     if(!f._fbq) f._fbq=n;
    //     n.push=n;
    //     n.loaded=!0;
    //     n.version='2.0';
    //     n.queue=[];t=b.createElement(e);t.async=!0;
    //     t.src=v;s=b.getElementsByTagName(e)[0];
    //     s.parentNode.insertBefore(t,s);
    // }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
    // fbq('track', 'PageView');

    isInitialized = true;
}

class FBPixel {
    // constructor() {
    // }
    // init() {
    //     this.fbq('init', '555911811441929');
    // }
    fbq(...params: any) {
        if(!isInitialized) {
            init();
            // this.init();
        }
        //@ts-ignore
        window.fbq(...params);
    }
}

export const fbpixel = new FBPixel();