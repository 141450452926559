/**
 * Recursively replaces text in a string, using an object that supports replacement within a string.
 * @param {string} searchValue A object can search for and replace matches within a string.
 * @param {string} replaceValue A string containing the text to which will be insert
 */

export const stringReplaceAll = (
  value: string,
  searchValue: string,
  replaceValue: string,
): string => {
  if (typeof searchValue === 'string' && value.indexOf(searchValue) > 0) {
    return stringReplaceAll(value.replace(searchValue, replaceValue), searchValue, replaceValue);
  }
  return value.replace(searchValue, replaceValue);
};
