import { useState, useEffect, useCallback } from "react";

export const useMedia = (queries: any, values: any, defaultValue: any): any => {
	const mediaQueryLists = queries.map((q: any) => window.matchMedia(q));
	const getValue = useCallback(() => {
		const index = mediaQueryLists.findIndex((mql: any) => mql.matches);
		return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
	}, [values, defaultValue, mediaQueryLists]);
	const [value, setValue] = useState<any>(getValue);
	useEffect(
		() => {
			const handler = () => setValue(getValue);
			mediaQueryLists.forEach((mql: any) => mql.addListener(handler));
			return () => mediaQueryLists.forEach((mql: any) => mql.removeListener(handler));
		},
		[mediaQueryLists,getValue]
	);
	return value;
}
