import { ItemTextType } from "../primitives/types/ItemTextType";
// import React from "react";

import { ItemValueType } from "../primitives/types/ItemValueType";

export interface ReplaceMaskProps {
  mask: string;
  value: ItemValueType;
  text: ItemTextType;
}

export const replaceMask = ({ mask, value, text } : ReplaceMaskProps) : string => {
	if(!text && (!value || value==="")) return "";
	const res : string = mask.replace("{text}", ""+text).replace("{value}", ""+value);
	if(res.length===0) {
		return "";
	}
	return res;
}