import React, { useState } from "react";
import { PictureView } from "../PictureView";
import { PictureUploader } from "../PictureUploader";
import { EditableText } from "../../EditableText";
import { IMAGE_ALTS } from "consts";
import "./index.scss";
import { useInitial } from "api/app/useInitial";
import { useAdminStorage } from "api/admin";
import { useTranslation } from "api/translations/useTranslation";

interface PictureEditableProps extends React.HTMLAttributes<{}> {
	scope: string;
	as: string;
	force?: boolean;
}

/*eslint-disable*/
export const PictureEditable = ({/*dispatch, */scope, as, ...rest} : PictureEditableProps) => {
/*eslint-enable*/
	const [{images}] = useInitial();
	const [translation] = useTranslation();
	const alt = translation && translation[IMAGE_ALTS] && translation[IMAGE_ALTS][scope+"_"+as];
	const [{ isEditable, isEditableImage }] = useAdminStorage();
	const filename = images && images[scope] && images[scope][as];
	const [ file, setFile ] = useState<any>(null);
	const [ base64, setBase64 ] = useState<any>(null);
	return <PictureView
		{...rest}
		base64={base64}
		filename={filename}
		alt={alt}
		before={isEditable && <div style={{display: "flex", justifyContent: "space-between"}}>
			{isEditableImage && <PictureUploader
				scope={scope}
				as={as}
				file={file}
				base64={base64}
				onPreview={(props: any)=> {
					const { file, base64 } = props || {};
					setFile(file);
					setBase64(base64);
				}}
			/>}
			{isEditable && <div style={{position: "absolute", right: "0", border: "2px solid orange", zIndex: 1}}>
				<EditableText scope={IMAGE_ALTS} as={scope+"_"+as}>
					{({value}) => {
						if(!value) {
							return <span style={{color: "red"}}>ALT</span>
						}
						return <span>{value}</span>
					}}
				</EditableText>
			</div>}
		</div>}
	/>
}