import { DatetimeParseFunctions } from './DatetimeParseFunctions';

export const datetimeParse = (dateStr : string) : DatetimeParseFunctions => {
  return {
    fromFieldDateUtcString: () => {
      const d = +(dateStr.substring(0,2));
      const m = +(dateStr.substring(3,5))-1;
      const y = +(dateStr.substring(6,10));
      return new Date(Date.UTC(y,m,d))
    },
    fromFieldDateString: () => {
      const d = +(dateStr.substring(0,2));
      const m = +(dateStr.substring(3,5))-1;
      const y = +(dateStr.substring(6,10));
      return new Date(y,m,d)
    },
    fromSearchDateUtcString: () => {
      const y = +(dateStr.substring(0,4));
      const m = +(dateStr.substring(5,7))-1;
      const d = +(dateStr.substring(8,10));
      return new Date(Date.UTC(y,m,d))
    },
    fromSearchDateString: () => {
      const y = +(dateStr.substring(0,4));
      const m = +(dateStr.substring(5,7))-1;
      const d = +(dateStr.substring(8,10));
      return new Date(y,m,d)
    }
  }
}