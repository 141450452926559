import React from "react";
import { Flex } from "controls";
import { FieldTextEditable } from "forms";
import { combineNormalize, maxLengthNormalize, numbersNormalize } from "forms";

export const PassengerItemVoucher = ({ name, index } : { name: string, index : number }) => {
  return <Flex column style={{border: "1px dashed gray", padding: "8px 8px 0 8px", marginTop: 0, borderRadius: 3}} key={index}>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldTextEditable
        name={name+"name"}
        type="text"
        autoComplete="false"
        disabled={true}
      />
    </Flex>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldTextEditable
        name={name+"doc"}
        type="text"
        disabled={true}
      />
    </Flex>
    <Flex ratio gap={5} className={"column-fields"}>
      <FieldTextEditable
        name={name+"rflex"}
        type="text"
        normalize={combineNormalize([numbersNormalize, maxLengthNormalize(9)])}
        disabled={true}
      />
    </Flex>
  </Flex>
}