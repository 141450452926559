import { useCallback, useEffect } from "react";
import { useRouteQueryKeys } from "hooks";
import { useStateNamed } from "hooks";

export const useReservationClear = () => {
	const [, { addQuery }] = useRouteQueryKeys();
	const [requireClear, setRequireClear] = useStateNamed("useReservationClear.requireClear", false);
	const proceedClear = useCallback(() => {
		addQuery({reservationUid: null, reservationToken: null, isRecover: null });
		setRequireClear(false);
	}, [addQuery, setRequireClear])
	useEffect(() => {
		if(requireClear) {
			proceedClear();
		}
	}, [proceedClear, requireClear])
	return [
		() => setRequireClear(true)
	]
}