import React from "react";
import { emailValidate } from './../validators/emailValidate';
import { CheckPrimitivePlaceholder } from './../primitives/extras/CheckPrimitivePlaceholder';
import { HocFieldsMergedDefaults } from './../fields/types';
import { numbersNormalize, dateNormalize } from "../normalize";
import { DatePrimitiveCalendarIcon } from "../primitives";
import { captchaValidate } from "../validators";

interface DefaultsListStruct {
  check: HocFieldsMergedDefaults;
  label: HocFieldsMergedDefaults;
  email: HocFieldsMergedDefaults;
  hidden: HocFieldsMergedDefaults;
  password: HocFieldsMergedDefaults;
  number: HocFieldsMergedDefaults;
  date: HocFieldsMergedDefaults;
  radio: HocFieldsMergedDefaults;
  captcha: HocFieldsMergedDefaults;
  fileB64: HocFieldsMergedDefaults;
  textarea: HocFieldsMergedDefaults;
  text: HocFieldsMergedDefaults;
  select: HocFieldsMergedDefaults;
}

const defaultsList : DefaultsListStruct = {

  text: { type: "text" },
  textarea: { },
  label: {
    containerProps: { className: "FieldLabel" }
  },
  select: { },
  fileB64: { },
  radio: { },

  check: {
    containerProps: { className: "FieldCheck" },
    elementProps: { type: "checkbox" },
    AfterComponent:  ({ gateState }) => <CheckPrimitivePlaceholder gateState={gateState}/>,
  },

  email: {
    defaultValidate: [emailValidate]
  },

  hidden: {
    elementProps: { type: "hidden", className: "hidden"}
  },

  password: {
    elementProps: { type: "password" }
  },

  number: {
    type: "text",
    defaultNormalize: [numbersNormalize]
  },

  date: {
    defaultNormalize: [dateNormalize],
    containerProps: { className: "FieldDate" },
    AfterComponent: ({ gateState }) => <DatePrimitiveCalendarIcon gateState={gateState}/>,
  },

  captcha: {
    containerProps: { className: "FieldCaptcha" },
    defaultValidate: [captchaValidate]
  },

}

Object.keys(defaultsList).forEach(defaultKey => {
  const defAsAny : any = defaultsList;
  if(!defAsAny[defaultKey].elementProps) defAsAny[defaultKey].elementProps = {};
  if(!defAsAny[defaultKey].containerProps) defAsAny[defaultKey].containerProps = {};
})

export { defaultsList }