import { useFetchAction } from "hooks";
import { useReservationId } from "./helpers/useReservationId";
import { useReservationClear } from "./useReservationClear";
import { useSalesRefresher } from "hooks";
import { useReservationTimeValidation } from "./useReservationTimeValidation";
import { useReservationPriceTypeValidation } from "./useReservationPriceTypeValidation";
import { useRouteQueryKeys } from "hooks";
import { useStepsProceed } from "hooks";
import { useInvoiceForm } from "api";
import { useReservationConfirmRequest } from "./requests/useReservationConfirmRequest";
import { usePassengersForm } from '../passengers';
import { UseReservationConfirmResult } from "types/reservation/UseReservationConfirmResult";
import { useTripsConfirmed } from "api/ticketing/useTripsConfirmed";

export const useReservationConfirm = () : UseReservationConfirmResult => {
	const [{isValidPriceTypes}] = useReservationPriceTypeValidation();
	const [isValidTime] = useReservationTimeValidation();
	const [,salesRefresh] = useSalesRefresher();
	const [, { addQuery}] = useRouteQueryKeys();
	const [clearReservation] = useReservationClear();
	const [reservationId, { loading: idLoading }] = useReservationId();
	const [{ syncErrors: invoiceSyncErrors, asyncErrors: invoiceAsyncErrors }] = useInvoiceForm();
	const [tripsData] = useTripsConfirmed();
	const { outgoing_schedules } = tripsData || {};
	const [request] = useReservationConfirmRequest();
	const [p] = usePassengersForm();
	const passengersHaveError = () => {
		if(p.syncErrors) return true;
		if(p.asyncErrors) return true;
		return false;
	}
	const [response, { success, error, loading: confirmLoading }, { execute }] = useFetchAction("/api/reservation/confirm", {
		method: "POST",
		body: {
			jsonInput: request
		}
	});
	const visible = !!reservationId && !!outgoing_schedules;
	const loading = idLoading || confirmLoading;
	const enabled = !!visible && !!isValidTime && !invoiceSyncErrors&& !invoiceAsyncErrors  && !loading && !!isValidPriceTypes && !passengersHaveError();

	const [doIt, { loading: loadingDo }] = useStepsProceed({
		name: "confirm",
		loading,
		enabled,
		steps: [
			async() => {
				return window.confirm("Tem a certeza que pretende confirmar?");
			},
			async(res) => {
				try {
					const salessrpoid = await execute();
					return salessrpoid;
				}
				catch(error) {
					return false;
				}
			},
			async(salesrpoid) => {
				addQuery({salesrpoid});
				return true;
			},
			async() => {
				salesRefresh();
				return true;
			},
			async() => {
				clearReservation();
				return true;
			}
		]
	});
	return [
		{ request, response, visible, enabled },
		{ loading: loading || loadingDo, success, error },
		{ confirm: doIt }
	];
}