import React from "react";
import { DEFAULT_TITLE } from "consts";
import { RenderConditionsData } from "./Panels";
import { usePage } from "hooks";
import "./index.scss";
import { useFetchOnce } from "hooks";

export const Conditions = () => {
	usePage({
		name: "CONDITIONS",
		options: {
			title: DEFAULT_TITLE
		}
	})
	const [conditions, status] = useFetchOnce<any>("/api/conditions/national");
	return <RenderConditionsData
		title="Regulamento do Cartão do Cliente RFLEX"
		conditions={conditions}
		status={status}
	/>
}

export default Conditions;