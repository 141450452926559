import { useFetchOnce } from 'hooks';
import { FETCH } from 'types';
import { ScheduleTableItemed } from 'types/ticketing/ScheduleTableItemed';

export interface UseFetchSchedulesWithStopsProps {
  origin: string;
  destination: string;
  lock?: boolean;
}

export type UseFetchSchedulesWithStopsResult = [
  ScheduleTableItemed,
  FETCH.FetchOnceStatus,
  FETCH.FetchOnceMethods
]

export const useFetchSchedulesWithStops = (props : UseFetchSchedulesWithStopsProps) : UseFetchSchedulesWithStopsResult => {
  const { origin, destination, lock } = props;
  const [data,status,meta] = useFetchOnce<any>("/api/ticketing/schedules_with_stops", {
    method: "POST",
    body: { origin, destination },
    lock: lock || !origin || !destination
  });
  return [
    data,
    status,
    meta
  ]
}