import { useFetchOnce } from "hooks";
import { FetchOnceResult } from "modules/fetch/types/FetchOnceResult";

type ServerCodesResponse = {
  [key: string]: string;
};

export const useServerCodes = () : FetchOnceResult<ServerCodesResponse> => {
  const [data, status, meta] = useFetchOnce<ServerCodesResponse>("/api/admin/codes/server_codes");
  return [data, status, meta];
}