import React from "react";
import { EditableText } from "controls"

export const CheckPrimitivePlaceholder = ({ gateState } : { gateState: any }) => {//RootGateProps
	const { extras, props } = gateState || {};
	const { required, form, placeholder, name } = props || {};
  const { onClickLabel } = extras || {};

	return <EditableText scope={form} as={name+"-placeholder"}>
		{({value})=>{
			const valueToRender = value || placeholder || "";
			return <span
				className="placeholder"
				onClick={onClickLabel}
				style={{}}
				dangerouslySetInnerHTML={{__html: (required ? " * " : "")+(valueToRender)}}
			/>
		}}
	</EditableText>
}