import React from "react";
import "./index.scss";
import { Loader, ErrorEditable, TableSchemed } from "controls";
import { useSchedulesSchema, useSchedulesTransformer } from "consts/schemas";
import { LOADING_STR } from "consts";
import { useSchedulesWithStopsConfirmed } from "api/ticketing/useSchedulesWithStopsConfirmed";

export const List = () => {
  const [schedules, { loading, error }] = useSchedulesWithStopsConfirmed();
  const [schedulesTransformed] = useSchedulesTransformer(schedules);
  const [{ headSchema, itemsSchema }] = useSchedulesSchema();
  if(!schedulesTransformed) return null;
  const { outgoing_schedules } = schedules || {};

  const items = outgoing_schedules ? [...outgoing_schedules,...outgoing_schedules,...outgoing_schedules] : []
  return <div className="schedules-list">
    {loading && <Loader text={LOADING_STR}/>}
    {true && <TableSchemed
      items={items}
      settings={{
        colored: "2x",
        primary_header: true
      }}
      head={{ schema: headSchema }}
      body={{ schema: itemsSchema }}
    />}
    {error && <ErrorEditable code={error.errorDetails} />}
  </div>
}