import React from "react";
import { useVoucherCreate } from "api";
import { ButtonStatusEditable } from "controls";
import pad from "pad";
import "./index.scss";
import { useReduxFormSelector } from "forms";
import { CreateVoucherRequest } from "api/voucher/types";
import { CONFIRM_ERROR_TIMEOUT_MS } from "consts/keys";
import { useFetchStatusTimeout } from "modules/fetch/hooks/useFetchStatusTimeout";
import { useUserDetails } from "api/user/useUserDetails";

interface OriginFormValues {
  origin: string;
  destination: string;
  year: string;
  month: string;
  price: string;
}
interface PassengerFormValues {
  name: string;
  doc: string;
  rflex: string;
}
export const Confirm = () => {
  const [createStatus,createVoucher] = useVoucherCreate();
  const [statusConverted] = useFetchStatusTimeout("useVoucherCreateStatus", createStatus, CONFIRM_ERROR_TIMEOUT_MS);
  const onCreate = async () => {
    try {
      await createVoucher(createRequest);
    }
    catch(error) {}
  }


  const [formSearch] = useReduxFormSelector("searchFormVoucher");
  const [formPassengers] = useReduxFormSelector("passengersFormVoucher");
  const { values : searchValues } = formSearch || {};
  const { values : passengersValues } : any = formPassengers || {};
  const { origin, destination, year, month, price } : any = searchValues;// : OriginFormValues 
  const passenger = passengersValues && passengersValues.passengers && passengersValues.passengers[0];
  const { rflex } = passenger || {};//name, doc, 
  const validity : any = (year && month && `${year}-${month ? pad(2, month, "0") : "00"}`) || null;
  const isDisabled = (statusConverted && statusConverted.loading) || !origin || !destination;
  const [details] = useUserDetails();
  const { userID } = details || {};
  const createRequest : CreateVoucherRequest = {
    idOrigin: origin,
    idDestination: destination,
    validity,
    rflex,
    userId: userID,
    price: price ? +price : 0
  }

  return <div className={`voucher-form-confirm`}>
    <ButtonStatusEditable
      className="bigger"
      styling="primary"
      scope="VOUCHER"
			// Emitir Voucher
      as="CREATE_VOUCHER"
      disabled={isDisabled}
      status={statusConverted}
			onClick={onCreate}
		/>
  </div>
}