
export const DEFAULT_TITLE = "Sistema Online de Venda Para Empresas";

export const SELECT_DESTINATION = "Seleccione um destino"; //this 4x variables from DropDown.js
export const SELECT_ORIGIN = "Seleccione uma origem";
export const ERROR_STR = "Ocorreu um erro";

export const COMPANY_MANAGER = "COMPANY_MANAGER";
export const GROUP_MANAGER = "GROUP_MANAGER";
export const POINTOFSALE_MANAGER = "POINTOFSALE_MANAGER";
export const OPERATOR = "OPERATOR";

export const STATUS_EMPTY = "EMPTY";
export const STATUS_INITIALIZED = "STATE_INITIALIZED";
export const STATUS_INITIALIZING = "INITIALIZING";
export const STATUS_READY = "READY";
export const STATUS_ERROR = "ERROR";

export const STATUS_CLOSED = "CLOSED";


export const LOADING_RESERV_CREATE = "A iniciar venda...";
export const LOADING_RESERV_REGISTER = "A registar venda...";
export const LOADING_RESERV_CONFIRM = "A confirmar venda...";
export const LOADING_RESERV_CANCEL = "A cancelar venda...";
export const LOADING_RESERV_CHANGING_SEAT = "A alterar lugar...";
export const RESERVERROR_CHANGING_SEAT = 3;

export const LOADING_ORIGINS_STR = "A carregar origens...";
export const LOADING_DESTINATIONS_STR = "A carregar destinos...";

export const PRICE_INFO_TEXT = "Os preços indicados têm carácter de consulta, podendo para alguns tipos de bilhete serem diferentes os seus valores após o registo da reserva";

export const LOGIN_SUCCESS = "Sucesso de login, redirecionando...";
export const RESET_PWD_SUCCESS = "Senha recuperada com sucesso";
export const EMITIR_STR = "A emitir...";

export const DOWNLOADING_FILE_STR = "A gerar ficheiro...";
export const DOWNLOADING_FILE_ERROR_STR = "De momento não é possível apresentar a informação";
export const LOADING_LOCATIONS_ERROR = "Vendas internacionais indisponíveis";
