// import { createBodyElement } from "modules/dom";
import { urlDownload } from "./urlDownload";

export const blobDownload = (blob: Blob, filename : string) => {
  if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
  }
  else {
      const url = URL.createObjectURL(blob);
      urlDownload(url, filename);
  }
};