import { useEffect } from "react";
import { useRefresherNamed } from "hooks";
import { analytics } from 'modules';

let prevName : string | null = null;
const useRefresher = () => useRefresherNamed("usePageRefresher")[0];

interface GAProps {
  page: string;
  title: string;
}

interface UsePagePropsOptions {
  title?: string;
  ga?: GAProps;
}

interface UsePageProps {
  name: string;
  options?: UsePagePropsOptions;
}

export const usePage = ({ name, options } : UsePageProps) => {
  const { title, ga } = options || {};
	const refresh = useRefresher();
	useEffect(() => {
		if(typeof title==="string" && title.length>0)
			document.title = title;
		if(name!==prevName) {
			prevName = name;
			refresh();
		}
		analytics.setPage({
			page: (ga && ga.page) || window.location.pathname+window.location.hash,
			title: (ga && ga.title) || title || name
		});
	}, [ga, title, name, options, refresh]);
}