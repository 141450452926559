import { useStateNamed } from "hooks";
import { FETCH } from 'types';
import { useVoucherCurrent } from "./useVoucherCurrent";

export const useVoucherStatus = () : [FETCH.FetchOnceStatus, (loading : boolean) => Promise<void>] => {
  const [,{ loading, success, error}] = useVoucherCurrent();
  const [loadingInner, setLoadingInner] = useStateNamed("useVoucherStatus");
  return [{
    loading: loading || !!loadingInner,
    success,
    error
  }, setLoadingInner]
}