import React, { useState } from "react";
import { LoginRecoverPasswordDialog } from "controls/dialogs";
import { LoginForm } from "./LoginForm"
import { ButtonTyped } from "controls";
import { LoginLayout } from "../_partials/LoginLayout";
import { usePage } from "hooks";
import { useNavigation } from "react-navi"
import { useUserLogin } from "api/user";

let shouldWait = false;

export const Login = () => {
	const [login] = useUserLogin();
	usePage({
		name: "LOGIN",
		options: {
			title: "Autenticação Consola Sistema Online de Vendas para Empresas"
		}
	})
	const navigation = useNavigation();
	const [isRecovery, setIsRecoveryInner] = useState<boolean>(false);
	
	const setIsRecovery = (val : boolean) => {
		if(shouldWait) return;
		shouldWait = true;
		setTimeout(() => { shouldWait = false }, 500);
		setIsRecoveryInner(val);
	}

	return <LoginLayout>
		{isRecovery && <LoginRecoverPasswordDialog onClose={()=>setIsRecovery(false)} />}
		<LoginForm
			login={login}
			navigation={navigation}
		/>
		<div className="login-recovery">
			<ButtonTyped
				styling="link"
				onClick={()=>setIsRecovery(true)}
			>
				{"Recuperar password >>"}
			</ButtonTyped>
		</div>
	</LoginLayout>
}

export default Login;