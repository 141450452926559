import React from "react";
import clsx from "clsx";
import { TabsContainerProps } from "../types/TabsContainerProps";
import "./index.scss";
import { TabsButtonsStackRouted } from "../TabsButtonsStackRouted";
import { TabsButtonsStackStated } from "../TabsButtonsStackStated";

export const TabsContainer = ({routed, stated, orientation="column", disabled,...htmlAttributes} : TabsContainerProps) => {
  const { className, children, ...restAttributes } = htmlAttributes;
  if(routed && stated) {
    console.error("TabsContainer: routed & stated cannnot be used in same time");
  }
  return <div
    className={`tabs-container ${clsx(className)} ${clsx(orientation)} ${clsx(disabled && "disabled")}`}
    {...restAttributes}
  >
    {routed && <TabsButtonsStackRouted disabled={disabled} {...routed}/>}
    {stated && <TabsButtonsStackStated disabled={disabled} {...stated}/>}
    <div className="tabs-view">
      {children}
    </div>
  </div>
}