export function deepGet(obj: any, path: any) {
	if (typeof path === 'string') path = path.split('.');
	let current = obj;
	for (let i in path) {
		const key = path[i];
		if (+i === path.length - 1) {
			return (current||{})[key];
		}
		else {
			current = current ? current[key] : null;
		}
	}
}
