import React from "react";
import "./index.scss";
import { Block } from "./Block";
import { Droppable } from "./Droppable";
import { ErrorEditable } from "controls";
import {
	ACCOUNT_DEPTH_KEY,
	COMPANY_DEPTH_KEY,
	GROUP_DEPTH_KEY,
	POINT_OF_SALE_DEPTH_KEY,
} from "consts";
import { useConfigurationSelection, useConfigurationHierarchy } from "api/configuration";

const itemProps = ({ level, value: valueProps, context }: any) => {
	const value = valueProps || {}
	const { UserType, CurrentUserID, InactiveManagers } = context;
	let IsEditable : any = null,
		IsAvailable : any = null,
		ID : any = null,
		Name : any = null,
		Manager : any = null,
		isInactiveManager : any = false,
		userIsManager : any = null;

	switch(level) {
		case COMPANY_DEPTH_KEY: {
				const { Company } = value;
				IsEditable = value.IsEditable;
				IsAvailable = value.IsAvailable;
				Manager = value.Manager;
				ID = Company && Company.ID;
				Name = Company && Company.Name;
				userIsManager = Company && Company.Manager === CurrentUserID;
				break;
			}
		case GROUP_DEPTH_KEY: {
				const { Group } = value;
				IsEditable = value.IsEditable;
				IsAvailable = Group.IsAvailable;
				Manager = value.Manager;
				ID = Group.ID;
				Name = Group.Name;
				userIsManager = Group && Group.AgCpAccountManager === CurrentUserID;
				break;
			}
		case POINT_OF_SALE_DEPTH_KEY: {
				const { PointOfSale } = value;
				IsEditable = value.IsEditable;
				IsAvailable = PointOfSale && PointOfSale.IsAvailable;
				Manager = value.Manager;
				ID = PointOfSale && PointOfSale.ID;
				Name = PointOfSale && PointOfSale.Name;
				userIsManager = PointOfSale && PointOfSale.Manager === CurrentUserID;
				break;
			} 
		case ACCOUNT_DEPTH_KEY: {
			
				IsEditable = true;
				IsAvailable = value.IsAvailable;
				ID = value.ID;
				Name = value.Name;
				isInactiveManager = InactiveManagers.filter((c: any)=>c===ID).length===0;
				userIsManager = ID === CurrentUserID;
				break;
			} 
		default: break;
	}
	return {
		level,
		UserType,
		IsEditable,
		IsAvailable,
		ID,
		Name,
		Manager,
		isInactiveManager,
		userIsManager
	};
}

const renderUl = ({level, values, context={}} : any) => {
	if(!values) return null;
	return values.length>0 && values.map((value: any, idx2: any) => <Block key={level+idx2} {...itemProps({ level, value, context })}>
			{
				level===COMPANY_DEPTH_KEY ? renderUl({ level: GROUP_DEPTH_KEY, values: value && value.Groups, context }) :
				level===GROUP_DEPTH_KEY ? renderUl({ level: POINT_OF_SALE_DEPTH_KEY, values: value && value.PointsOfSale, context }) :
				level===POINT_OF_SALE_DEPTH_KEY ? renderUl({ level: ACCOUNT_DEPTH_KEY, values: value && value.Accounts, context }) :
				null
			}
	</Block>)
}

export const ChartRender = () => {
	const [{ chartType }] = useConfigurationSelection();
	const [hierarchy, { error: errorHierarchy }] : any = useConfigurationHierarchy(); //success: successHierarchy,
	const { UserType, CurrentUserID, InactiveManagers } = hierarchy || {};
	return hierarchy ? <Droppable className={chartType}>
		{renderUl({
			level: COMPANY_DEPTH_KEY,
			values: [hierarchy],
			ulProps: { id: "org", style: { display: "none"} },
			context: { UserType, CurrentUserID, InactiveManagers }
		})}
	</Droppable> : <ErrorEditable code={errorHierarchy && errorHierarchy.error} />
}