import React from "react";
import "./index.scss";
import { LOADING_STR } from "consts";
import { connectForm } from "./connectForm";
import { Loader, ButtonTyped } from "controls";
import { FieldSelectEditable } from "forms";
import { useReduxFormSelector } from "forms";
import { useSalesState } from "api";

const { months, years } = (() => {
	const months = [], years = [];
	const now = new Date();
	const nowMonth = now.getUTCMonth();
	const nowYear = now.getUTCFullYear();
	
	for(let i=1; i<=12; i++) {
		const item = {
			value: ""+(i),
			text: "MONTH_"+i,
			className: ""
		}
		if(i===(nowMonth+1)) {
			item.className = "listItemCurrentOption";
		}
		months.push(item);
	}
	for(let i=-2; i<2; i++) {
		const year = (nowYear+i);
		const item = {
			value: ""+year,
			text: ""+year,
			className: ""
		}
		if(year===nowYear) {
			item.className = "listItemCurrentOption";
		}
		years.push(item);
	}
	return { months, years }
})()

export const Form = connectForm(({form, submitting}) => {
	const [,,{save: saveState}] = useSalesState();
	const [{
		initial: { year, month },
		values: { year: newYear, month: newMonth }
	}]: any = useReduxFormSelector(form);
	const isNewSearch = newYear !== year || newMonth !== month;
	isNewSearch && saveState({year: parseInt(newYear), month: parseInt(newMonth)});
	return <form className={form}>
		{submitting && <div className="mask-holder">
			<div className="mask-holder-inner">
				<Loader text={LOADING_STR} overlay />
			</div>
		</div>}
		{years && <FieldSelectEditable
			name="year"
			items={years}
		/>}
		{months && <FieldSelectEditable
			name="month"
			items={months}
		/>}
		<ButtonTyped
			styling="primary"
			className="margin-left-8"
			onClick={() => {
				saveState({year: parseInt(newYear), month: parseInt(newMonth)});
			}}
		>
			Listar
		</ButtonTyped>
	</form>
})