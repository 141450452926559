import { useRouteQueryKey } from "hooks";

export const useVoucherNavigation = () : [string, (id : string) => Promise<void>]=> {
  const [voucherId, setVoucherIdInner] = useRouteQueryKey("voucherId");
  const setVoucherId = async(id : string) => {
    if(id!==voucherId) {
      await setVoucherIdInner(id);
    }
  }
  return [voucherId, setVoucherId]
}