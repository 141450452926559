import { useLowStorageAuth } from 'hooks/storages/useLowStorageAuth';
import { useEnv } from "hooks";
import { useCurrentRoute } from "react-navi";
import { fetchJson } from "modules/fetch/modules";

export const useUserLogout = () => {
  const [{ ENV_SOVE }] = useEnv();
  const route = useCurrentRoute();
  const [auth] = useLowStorageAuth();
  const { pathname } = route.url;
  const logout = async () => {
    if(auth) {
      try {
        await fetchJson("/api/app/logout", { method: "GET" });
        const oldStorageStr = localStorage.getItem('lowdb');
        const { auth, ...rest } : any = oldStorageStr ? JSON.parse(oldStorageStr) : {};
        localStorage.setItem("lowdb", JSON.stringify({ ...rest }));
        window.location.reload();
      }
      catch(error) {
        console.error(error);
        /*const oldStorageStr = localStorage.getItem('lowdb');
        const { auth, ...rest } : any = oldStorageStr ? JSON.parse(oldStorageStr) : {};
        localStorage.setItem("lowdb", JSON.stringify({ ...rest }));
        window.location.reload();*/
      }
    }
    setTimeout(() => {
      if(ENV_SOVE) {
        if(pathname.indexOf("login") < 0) {
          window.location.pathname = "/login";
        }
      }
      else {
        window.location.reload();
      }
    });
  }
  return [logout];
}
