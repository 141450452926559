export const routes = {
	"/": "Home",
	"/main": "Home",
	"/login": "Login",
	"/boarding": "Boarding",
	"/conditions": "Conditions",
	"/faq": "FAQ",
	"/dashboard": "Dashboard",
	"/dashboardsearch": "Dashboard",
	"/salesliquidation": "SalesLiquidation",
	"/configuration": "Configuration",
	"/internationalconditions": "InternationalConditions",
	"/pwdreset": "PwdReset",
	"/PwdReset.aspx": "PwdReset",
	"/admin": "Admin",
	"/voucher": "Voucher",
	"/rePrint" : "RePrint",
	"/initial_setup": "InitialSetup"
};