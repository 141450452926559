import { useStateNamed } from 'hooks';
import { useEffect } from "react";
import { useReduxFormSelectorBase } from "./useReduxFormSelectorBase";
import { UseReduxFormSelectorResult } from "./types";

export const useReduxFormSelector = <TValues extends any>(form : string) : [UseReduxFormSelectorResult<TValues>] => {
  const [data] = useReduxFormSelectorBase<TValues>(form);
  const [dataState, setDataState] = useStateNamed<any /*UseReduxFormSelectorResult<TValues>*/>(`useReduxFormSelector.${form}`, {
    values: {},
    initial: {},
    form: null,
    syncErrors: null,
    asyncErrors: null,
    registeredFields: null,
    submitting: null,
    fields: null
  });
  useEffect(() => {
    setDataState(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify((data) || {})]);

  return [dataState];
}