import React from "react";
import "./index.scss";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { UserDefaultDepartureForm } from "./UserDefaultDepartureForm";
import { hocDialog } from "controls/dialogs";
import { SubmissionError } from "redux-form";
import { useUserChangePassword } from "api/user";

const dialogConnect = hocDialog({
	name: "PwdChangeDialog",
	title: "Configuração Utilizador",
	minWidth: 550,
	minHeight: 350
})

export const PwdChangeDialog = dialogConnect(() => {
	const [changePassword] = useUserChangePassword();
	return <div style={{display: "flex", flexDirection: "column"}}>
		<ChangePasswordForm
			validate={({ oldPwd, newPwd, confirmNewPwd }) => {
				if(confirmNewPwd!==newPwd && confirmNewPwd && newPwd) {
					const message = "Nova password e confirmação não coincidem";
					return {
						newPwd: message,
						confirmNewPwd: message
					}
				}
				return {};
			}}
			onSubmit={async ({oldPwd, newPwd}) => {
				try {
					await changePassword({ oldKey: oldPwd, newKey: newPwd })
				}
				catch(error) {
					console.error(error);
					throw new SubmissionError({_error: error.error});
				}
			}}
		/>
		<hr className="thin" />
		<UserDefaultDepartureForm />
	</div>
})