import { useMedia } from "./useMedia";

interface UseBootstrapResult {
	mobile?: boolean;
	tablet?: boolean;
	desktop?: boolean;

	xl?: boolean;
	lg?: boolean;
	md?: boolean;
	sm?: boolean;
	xs?: boolean;
}

export const useBootstrap = () : UseBootstrapResult => {
	const value = useMedia(
		['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)', '(min-width: 576px)', '(max-width: 575px)'],
		["xl", "lg", "md", "sm", "xs"],
		"xs"
	);
	const result : UseBootstrapResult = {[value]: true};
	if(value==="xs" || value==="sm"|| value==="md") result.mobile = true;
	if(value==="md") result.tablet = true;
	if(value==="lg" || value==="xl") result.desktop = true;
	return result;
}