import React, { useState } from "react";
import { DevDialog } from "../DevDialog";
import "./index.scss";
import { ButtonEmoji } from "controls";

export const DevConsole = () => {
	const [shown, setShown] = useState<boolean>(false);
	return <>
		<ButtonEmoji icon="👨‍💻" onClick={() => setShown(true)}/>
		{shown && <DevDialog onClose={() => setShown(false)}/>}
	</>
}