import React from "react";
import { EditableText } from "controls";
import { useServiceApiCodesDetails } from "api/admin/codes";
export const ErrorCodesServiceApiDetailed = () => {
  const [codes] = useServiceApiCodesDetails();
  if(!codes) return null;
  return <div>
    <table>
      <thead>
        <tr>
          <th>code</th>
          <th>id</th>
          <th>description</th>
          <th>text</th>
        </tr>
      </thead>
      <tbody>
        {codes && codes.map((code: any) => {
          const asLabel = `ERROR_API_DETAILS_${code.id}`
          return <tr key={code.id}>
            <td>{asLabel}</td>
            <td>{code.id}</td>
            <td>{code.description}</td>
            <td><EditableText scope="ERRORS" as={asLabel} /></td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}