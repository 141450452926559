import React from "react";
import { StyleGuide, SwitchEditableMode, ErrorCodesTranslations, TestingFeatures } from "../admin/components";//
import { HooksDebug } from "./HooksDebug";
import { FixedCorner } from "controls";
import { DevConsole } from "debug/controls";
import { useAdmin } from "api/admin";
export const Overlay = () => {
	const [{IsAdmin, permissions}] = useAdmin();
	return <>
		<HooksDebug />
		{IsAdmin && permissions.DEVELOPER_FEATURES && <FixedCorner top left>
			<DevConsole />
			<StyleGuide />
			<ErrorCodesTranslations/>
			<TestingFeatures />
		</FixedCorner>}
		{IsAdmin && permissions.EDITABLE_TEXT && <FixedCorner left bottom>
			<SwitchEditableMode />
		</FixedCorner>}
	</>
}