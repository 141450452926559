import React, { useState } from "react";

import { RenderBoolean } from "./RenderBoolean";
import { RenderNumber } from "./RenderNumber";
import { RenderString } from "./RenderString";
import { RenderSpecific, isSpecificValue } from "./RenderSpecific";

export const RenderValue = ({ name, value, defaultValue, onChange } : { name: string, value: any, defaultValue: any, onChange: (value : any) => any }) => {
  let Element : any = ({value} : { value : any }) => <span>{value}</span>

  if(typeof value==="boolean") Element = RenderBoolean;
  if(typeof value==="number") Element = RenderNumber;
  if(typeof value==="string") Element = RenderString;
  if(isSpecificValue(name)) Element = RenderSpecific;

  const [tempValue, setTempValue] = useState<any>(value);
  const requireSave = tempValue!==value;
  const requireReset = value!==defaultValue;

  return <div className="flex-row flex-between">
    <Element name={name} value={tempValue} onChange={setTempValue}/>
    <div className="flex-row flex-end">
      {requireSave && <button className="btn-primary" onClick={() => { onChange(tempValue) }}>SAVE</button>}
      {requireSave && <button className="btn-secondary" onClick={() => { setTempValue(value) }}>CANCEL</button>}
      {requireReset && !requireSave && <button className="btn-secondary" onClick={() => { onChange(defaultValue); setTempValue(defaultValue);}}>RESET</button>}
    </div>
  </div>
}