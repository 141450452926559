import React from "react";
import "./index.scss";
import { dispatch } from "app/store";

const confirmUpdate = ({id}: any) => {
	const request = {
		pos: {
			id,
			name: getValue("POS_name"),
			GPS: {
				lat: getValue("POS_lat"),
				lon: getValue("POS_lon")
			},
			GPSBoarding: {
				lat: getValue("POS_blat"),
				lon: getValue("POS_blon")
			},
			address: {
				detail: getValue("POS_detail"),
				zip_code: getValue("POS_zip_code")
			},
			addressBoarding: {
				detail: getValue("POS_bdetail"),
				zip_code: getValue("POS_bzip_code")
			},
			phone: getValue("POS_phone")
		}
	}
	dispatch({
		type: "ADMIN_CONTENT_POS_UPDATE",
		...request
	})
}

export const RenderPlace = ({pos, isEditing} : any) => {
	const { id, name, GPS, GPSBoarding, address, addressBoarding, phone } = pos;
	return <tr className={`place-row ${isEditing ? "editing" : ""}`}>
		<td>{id}</td>
		<td>
			{!isEditing && name}
			{isEditing && <input type="text" placeholder="" defaultValue={name} id="POS_name" />}
		</td>
		<td>
			<div className="gps">
				<div className="gps-normal">
					<label>GPS:</label>
					{!isEditing && <div>
						<span>{GPS && GPS.lat}</span>
						<span>{GPS && GPS.lon}</span>
					</div>}
					{isEditing && <div>
						<input type="text" placeholder="Latitude" defaultValue={GPS.lat} id="POS_lat" />
						<input type="text" placeholder="Longitude" defaultValue={GPS.lon} id="POS_lon" />
					</div>}
				</div>
				<div className="gps-boarding">
					<label>GPSBoarding:</label>
					{!isEditing && <div>
						<span>{GPSBoarding && GPSBoarding.lat}</span>
						<span>{GPSBoarding && GPSBoarding.lon}</span>
					</div>}
					{isEditing && <div>
						<input type="text" placeholder="Boarding latitude" defaultValue={GPSBoarding && GPSBoarding.lat} id="POS_blat" />
						<input type="text" placeholder="Boarding longitude" defaultValue={GPSBoarding && GPSBoarding.lon} id="POS_blon" />
					</div>}
				</div>
			</div>
		</td>
		<td>
			<div className="address">
				<div className="address-normal">
					<label>address:</label>
					{!isEditing && <div>
						<span>{address && address.detail}</span>
						<span>{address && address.zip_code}</span>
					</div>}
					{isEditing && <div>
						<input type="text" placeholder="Address" defaultValue={address && address.detail} id="POS_detail" />
						<input type="text" placeholder="Zip Code" defaultValue={address && address.zip_code} id="POS_zip_code" />
					</div>}
				</div>
				<div className="address-boarding">
					<label>addressBoarding:</label>
					{!isEditing && <div>
						<span>{addressBoarding && addressBoarding.detail}</span>
						<span>{addressBoarding && addressBoarding.zip_code}</span>
					</div>}
					{isEditing && <div>
						<input type="text" placeholder="Boarding Address" defaultValue={addressBoarding && addressBoarding.detail} id="POS_bdetail" />
						<input type="text" placeholder="Boarding Zip Code" defaultValue={addressBoarding && addressBoarding.zip_code} id="POS_bzip_code" />
					</div>}
				</div>
			</div>
		</td>
		<td>
			{!isEditing && phone}
			{isEditing && <input type="text" placeholder="Phone" defaultValue={phone} id="POS_phone" />}
		</td>
		<td>
			{!isEditing && <button onClick={()=>{
				setTimeout(() =>{
					dispatch({
						type: "ADMIN_SET_DEEP",
						path: ["content", "pointOfSales"],
						editingCode: id
					})
				})
			}} className="btn btn-primary">
			Edit
			</button>}
			{isEditing && <React.Fragment>
				<button onClick={()=>{
					confirmUpdate({id});
				}} className="btn btn-primary">
				Confirm
				</button>
				<button onClick={()=>{
					dispatch({
						type: "ADMIN_SET_DEEP",
						path: ["content", "pointOfSales"],
						editingCode: null
					})
				}} className="btn btn-warning">
				Cancel
				</button>
			</React.Fragment>}
		</td>
	</tr>
}

const getValue = (id : any) => {
	const elem : any = document.getElementById(id);
	return elem.value
}
// const RenderItem = ({editingCode, item}: any) => {
// 	const { POS } = item;
// 	// const { places } = item;
// 	return <React.Fragment>
// 		{POS && POS.map((pos: any, index: any)=>{
// 			return <RenderPlace isEditing={editingCode===pos.id} key={index} item={item} pos={pos} />
// 		})}
// 	</React.Fragment>
// }

// @connect(state=>({
// 	pointOfSales: state.admin && state.admin.content && state.admin.content.pointOfSales
// }))
// export class PointOfSales extends React.Component {
// 	componentDidMount() {
// 		dispatch({type: "ADMIN_CONTENT_POS"})
// 	}
// 	render() {
// 		const { pointOfSales } = this.props;
// 		if(!pointOfSales) return null;
// 		//if(!adminPOS) return null;
// 		const { items, editingCode } = pointOfSales;
// 		//const { editingCode } = adminPOS;
// 		return <div className="admin-content-point-of-sales">
// 			<table className="table table-stripped table-primary-header">
// 				<thead>
// 					<th>id</th>
// 					<th>name</th>
// 					<th>GPS</th>
// 					<th>address</th>
// 					<th>phone</th>
// 					<th>Actions</th>
// 				</thead>
// 				<tbody>
// 					{items && items.map((item, index) => {
// 						return <RenderItem editingCode={editingCode} key={index} item={item}/>
// 					})}
// 				</tbody>
// 			</table>
// 		</div>
// 	}
// }

export const PointOfSales = () => <h1>PointOfSales N/A</h1>