import React from "react";
import "./index.scss";
import { FormUser } from "../forms";
import { hocDialog } from "controls/dialogs";
import { useConfigurationAgent } from "api/configuration";

const connectDialog = hocDialog({
	name: "CreateUserDialog",
	title: "Gestão Utilizadores",
	minWidth: 520,
	width: 660,
	minHeight: 330
});

export const DialogUser = connectDialog(() => {
	const [agent,,{save}] = useConfigurationAgent();
	const { SalePeriodTypeConfiguration } = agent || {};
	const [Hour, Day] = SalePeriodTypeConfiguration ? SalePeriodTypeConfiguration.split(";") : [null,null];
	const initialValues = {
		...agent,
		Day,
		Hour
	};
	return <FormUser
		save={save}
		initialValues={initialValues}
	/>
});