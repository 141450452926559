import { useState } from "react";

/**
* Wrapper over useState which append object state instead of just setter
* Returns a stateful value, and a function to update it.
*
* @version 1.0.0
* @see https://reactjs.org/docs/hooks-reference.html#usestate
*/

export const useStateAppend = (initialValue : any) => {
	const [state, setState] = useState<any>(initialValue);
	return [
		state,
		(newState: any) => setState({...state, ...newState})
	];
}