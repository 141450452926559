import { parse } from "query-string";
export const validate = ({ newPwd, confirmNewPwd }: any) => {
	const token = parse(window.location.search.replace("?", "")).requestid;
	if (token === "" || !token) {
		const message = "De momento não é possível efectuar o pedido";
		return {
			newPwd: message
		};
	}
	if(confirmNewPwd!==newPwd && confirmNewPwd && newPwd) {
		const message = "Nova password e confirmação não coincidem";
		return {
			newPwd: message,
			confirmNewPwd: message
		}
	}
	return {};
}