/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { urlTo } from "../../../modules";
import "./index.scss";
import { useCurrentRoute } from "react-navi";
import { useNavigation } from "react-navi";
import { hex_md5 } from "modules";
import { ENV_DEV } from "consts";

const TabButton = ({query, item, onChange, tabIndex, index, setState}: any) => {
	const navigation = useNavigation();
	const { disabled, tabs, tab, button } = item || {};
	return <React.Fragment>
		{button && (
			<div
				key={index+"BB"}
				className={"tabs-item " + (tabIndex === index ? " active " : "") + (disabled ? " disabled " : "")}
				onClick={async () => {
					if(disabled) return;
					if(onChange) {
						const res = await onChange(item.tab||index);
						if(res===false) {
							return;
						}
					}
					if (!!item.tab) {
						if(query.tab!==item.tab) {
							const paramsAdd: any = { tab, subtab: null }
							if(item.tabs) {
								paramsAdd.subtab = tabs.filter((t: any)=>t.show)[0].tab;
							}
							navigation.navigate(urlTo({ paramsAdd }));
						}
					} else {
						await setState({ tabIndex: index });
					}
				}}
			>
				{typeof item.button === "string" && item.button}
				{typeof item.button === "object" && item.button}
			</div>
		)}
		{item && item.tabs && item.tabs.length>0 && <div key={index+"SB"} className="tabs-childs">
			{item.tabs.map((sub: any, tIndex: any)=>{
				return sub.show && <div
					key={tIndex+"TT"}
					className={"tabs-item child-tabs-item " + (sub.tab === query.subtab ? "active" : "")}
					onClick={() => {
						const paramsAdd = { tab: item.tab, subtab: sub.tab }
						navigation.navigate(urlTo({ paramsAdd }));
					}}
				>
					{typeof sub.button === "string" && sub.button}
					{typeof sub.button === "object" && sub.button}
				</div>
			})}
		</div>}
	</React.Fragment>
}

export const Tabs = ({ after, dispatch, children, className, onChange, navClassName, asChild, defaultTab } : any) => {
	const { url: { query }} = useCurrentRoute();
	const [{ tabIndex, errorTab, error, errorInfo }, setStateInner] = useState<any>({});
	const setState = (newState: any): any => {
		setStateInner({tabIndex, errorTab, error, errorInfo, ...newState});
	}
	const { layout, tabs } = children[tabIndex] || {};
	const indexNameF = (name: any) => {
		if(!name) return null;
		for (let i in children) {
			if(children[+i] && children[+i].tab === name) {
				return +i;
			}
		}
		return null;
	}
	const indexName = asChild ? indexNameF(query.subtab) : indexNameF(query.tab);
	const indexDefault = (() => {
		switch (typeof defaultTab) {
			case "number": return defaultTab;
			case "string": return indexNameF(defaultTab);
			default: case "undefined": return null;
		}
	})();

	useEffect(() => {
		setState({
			tabIndex: (typeof indexName!=="undefined" && indexName!==null) ? indexName : indexDefault !== null ? indexDefault : 0
		});
	}, [defaultTab, indexDefault, indexName])

	const key = hex_md5(JSON.stringify({ defaultTab, query, asChild, tabIndex}));

	return <div className={"tabs tabs-shared " + className} key={key}>
		{!asChild && <div className={"tabs-nav " + navClassName}>
			{children && children.map && children.map((item: any, index: any) => <TabButton
				key={key+index}
				onChange={onChange}
				item={item}
				tabIndex={tabIndex}
				index={index}
				query={query}
				setState={setState}
			/>)}
			{after && <div className="after-headers">{after}</div>}
		</div>}
		<div className={"tabs-layout"}>
			{tabIndex>=0 ? (
				(tabIndex===errorTab && error) ? (!ENV_DEV ? null : <>
					<h1>{error.name}</h1>
					<p style={{color: "red"}}><strong>componentStack: </strong>{errorInfo.componentStack}</p>
					<p style={{color: "red"}}><strong>message: </strong>{error.message}</p>
					<p style={{color: "red"}}><strong>stack: </strong>{error.stack}</p>
				</>) :
				(layout) ? layout() :
				(tabs) ? <Tabs
					asChild={children[tabIndex].tab}
					navClassName="container"
					className="tabs-flex tabs-left tabs-primary"
				>
					{tabs.filter((t: any)=>t.show)}
				</Tabs> :
				null
			) : ""}
		</div>
	</div>
};