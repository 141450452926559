import React from "react";
import { DEFAULT_TITLE } from "consts";
import { FormReprintTicket } from "./FormReprintTicket";
import { usePage, useRouteQueryKey } from "hooks";
import "./index.scss";
import { useBoardingSearch } from "api/boarding";
import { TabsContainer, EditableText, TitlePanel } from "controls";

export const ReprintTicketTable = () => {

	 const [reprintOffer] = useRouteQueryKey("reprintOffer");

	usePage({
		name: "REPRINT",
		options: {
			title: DEFAULT_TITLE
		}
	});

	const [data,,{save}] = useBoardingSearch();


	const getInternationalFormProps = () => {
		return {
			initialValues: data,
			onConfirm: save
		}
	}


	return <TitlePanel
		type={"dark"}
		title={"Imprimir Bilhetes"}
		className="reprint-table"
		contentProps={{ id: "reprintTableContainer" }}
	>
		<TabsContainer
			className="reprint-tabs"
			routed={{
				query: "reprintOffer",
				buttons: [
					{ name: "national", children: <EditableText scope="Search" as="TabNational"/>, active: reprintOffer!=="international" },
				]
			}}
		>
			{reprintOffer!=="international" && <FormReprintTicket {...getInternationalFormProps()}/>}
		</TabsContainer>
	</TitlePanel>
}

export default ReprintTicketTable;