import React from "react";
import { FieldSelectEditable } from "forms";
import clsx from "clsx";
import { useOriginsOffer } from "api";
import { useReservationId } from "api";

const EMPTY_VALUE : any = {///.. : { value: string, text: string }
  value: null,
  text: ""
}

interface FieldOriginProps extends React.HTMLAttributes<{}> {}

export const FieldSchedulesSearchOrigin = ({...htmlAttributes} : FieldOriginProps) => {
  const { className, ...restAttributes } = htmlAttributes;
	const [origins, { loading }] = useOriginsOffer();
	const [reservationId] = useReservationId();
  const originsShown = origins ?
    [EMPTY_VALUE, ...origins.map(c=>({...c, value: c.id}))] :
    [EMPTY_VALUE];

	return <FieldSelectEditable
    {...restAttributes}
    className={`${clsx(className)} field-origin`}
		name="origin"
		disabled={!!reservationId}
		loading={loading}
		maskOption="{text} ({value})"
		items={originsShown}
		required
		filter={true}
	/>
}