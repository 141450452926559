import React, { useState } from "react";
import { ButtonEmoji, JsonView } from "controls";
import { hocDialog } from "controls/dialogs";
import { useInitialPassengers, usePassengersForm } from "api";
import { usePassengersClient, usePassengersRemote, usePassengersMS, usePassengersTypes, usePassengersPrices } from "api";

const dialogConnect = hocDialog({name: "TestingFeatures", fullscreen: true, title: "TestingFeatures"});

export const TestingFeaturesDialog = dialogConnect(() => {
  const [passengersForm] = usePassengersForm();
  const [passengersClient] = usePassengersClient();
  const [passengersMS] = usePassengersMS();
  const { passengerKeys, passengerTypes } = usePassengersTypes();
  const [passengersPrices] = usePassengersPrices();
  const [initialPassengers] = useInitialPassengers();
  const [passengersRemote] = usePassengersRemote();

	return <div>
    <h1>This is testing window (only for Max)</h1>
    <JsonView name="passengerKeys" data={passengerKeys}/>
    <JsonView name="passengerTypes" data={passengerTypes}/>
    <JsonView name="usePassengersPrices" data={passengersPrices}/>

    <JsonView name="useInitialPassengers" data={initialPassengers}/>
    <JsonView name="usePassengersForm" data={passengersForm}/>
    <JsonView name="usePassengersClient" data={passengersClient}/>
    <JsonView name="usePassengersMS" data={passengersMS}/>
    <JsonView name="usePassengersRemote" data={passengersRemote}/>
  </div>
});

export const TestingFeatures = () => {
	const [shown, setShown] = useState<boolean>(false);
  return <>
    <ButtonEmoji icon="👷" onClick={() => setShown(true)} />
	  {shown && <TestingFeaturesDialog onClose={() => setShown(false)}/>}
  </>
}