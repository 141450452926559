import { useSeatsReservationInfo } from "./useSeatsReservationInfo";
import { useReservationInfo } from "../reservation/useReservationInfo";
import { useSeatsLegsGrouper } from "./useSeatsLegsGrouper";
import { useSeatsReservationItineraries } from "./useSeatsReservationItineraries";
import { BookingSeatsLegGrouped } from "types/reservation/BookingSeatsLegGrouped";

type UseSeatsReservationLegsResult = [{
		totalLegs: BookingSeatsLegGrouped[],
		outgoingLegs: BookingSeatsLegGrouped[];
		incomingLegs: BookingSeatsLegGrouped[];
}];

export const useSeatsReservationLegs = () : UseSeatsReservationLegsResult => {
  
  const [seatsInfo] = useSeatsReservationInfo();
	const [reservationInfo] = useReservationInfo();

	const outgoing = useSeatsReservationItineraries("outgoing_itinerary");
	const incoming = useSeatsReservationItineraries("return_itinerary");

	const outgoingLegs = useSeatsLegsGrouper("outgoing_itinerary", outgoing);
	const incomingLegs = useSeatsLegsGrouper("return_itinerary", incoming);

	const totalLegs : BookingSeatsLegGrouped[] = (() => {
		if(!seatsInfo) return [];
		if(!reservationInfo) return [];
		if(outgoingLegs && incomingLegs) return [...outgoingLegs, ...incomingLegs];
		if(outgoingLegs) return outgoingLegs;
		if(incomingLegs) return incomingLegs;
		return [];
	})();

  return [
		{
			totalLegs,
			outgoingLegs,
			incomingLegs
		}
	]
}
