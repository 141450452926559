import React from "react";
import { EditableText } from "controls";

const renderSpan = (value : string, cn : string) => {
	if(!value) {
		return null;
	}
	return <span
		className={cn}
		dangerouslySetInnerHTML={{__html: value+"&nbsp;"}}
	/>
}

export interface RenderErrorItemProps {
	scope: string;
	form: string;
	formKey: string;
	formCode: string;
}
export const RenderErrorItem = ({scope, form, formKey, formCode} : RenderErrorItemProps) => {
	return <div>
		{formKey && <EditableText
			scope={scope||form}
			as={formKey+"-label"}
		>
			{({value}) => renderSpan(value, "form-error-title") }
		</EditableText>}
		<EditableText
			scope="ERRORS"
			as={formCode}
		>
			{({value})=>renderSpan(value||formCode, "form-error-message")}
		</EditableText>
	</div>
}