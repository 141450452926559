import { FetchOnceStatus } from 'modules/fetch/types/FetchOnceStatus';
import { RemoteStateStatus } from './../../modules/fetch/types/RemoteStateStatus';
import { RemoteStateMethods } from './../../modules/fetch/types/RemoteStateMethods';
import { useRemoteState } from "hooks";
import { useReservationUID } from "api";
import { fetchJson } from "modules/fetch";
import { randomGuid } from "modules/random";
import { InvoiceFormValues } from 'types/reservation/InvoiceFormValues';
import { RemoteStateResult } from "modules/fetch/types/RemoteStateResult";

export interface IInvoiceServerRequest extends RemoteStateMethods<InvoiceFormValues> {
}

const baseUrl = "/api/reservation/invoice/";

const useRemoteStatePass = () : RemoteStateResult<any> => {
	const [id] = useReservationUID();
	const [data, status, meta] = useRemoteState({
		url: `${baseUrl}${id}`,
		lock: !id,
		empty: { invoice: {} }
	});
	return [data, status, meta]
}

const useInvoiceMS = () : [
	InvoiceFormValues,
	RemoteStateStatus,
	IInvoiceServerRequest
] => {

	const [id, { setKey }] = useReservationUID();
	const [invoice, { loading, success, error, saving }, { save: saveOrig, refresh }] : any = useRemoteStatePass();

	const saveNew = async (invoice: any) => {
		const newID = randomGuid();
		await fetchJson(`${baseUrl}${newID}`, {
			method: "POST",
			body: { invoice }
		});
		await setKey(newID);
	}
	const saveOld = async (invoice: any) => {
		await saveOrig({invoice});
	}

	const save = async (invoice: any) => {
		if(!invoice) {
			return;
		}

		if(id) await saveOld(invoice);
		else await saveNew(invoice);
	}
	return [
		invoice,
		{ loading, success, error, saving },
		{ refresh, save }
	]
}

export const useInvoiceServer = () : [
		InvoiceFormValues,
		FetchOnceStatus,
		IInvoiceServerRequest
] => {
	const [msInvoice, msStatus, msMeta] = useInvoiceMS();
	return [
		msInvoice,
		msStatus,
		msMeta
	];
}