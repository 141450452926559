import React from "react";
import "./index.scss";
import clsx from "clsx";
import { TabsButtonItem } from "../TabsButtonItem";
import { TabsButtonsStackProps } from "../types/TabsButtonsStackProps";

export const TabsButtonsStack = ({ buttons, disabled, orientation = "horizontal", after, ...htmlAttributes } : TabsButtonsStackProps) => {
  const { className, ...restAttributes } = htmlAttributes;
  return <div
    {...restAttributes}
    className={`tabs-buttons-stack ${clsx(className)} ${clsx(orientation)}`}
  >
    <div className="buttons-list">
      {buttons && buttons.map((b, index) => <TabsButtonItem disabled={disabled} {...b} key={index}/>)}
    </div>
    {after && <div className="after">
      {after}
    </div>}
  </div>
}