
/**
 * Function copy object values
 * 
 * @param obj source object
 * 
 * @returns copy of source object
 * 
 * @version 1.0.0
 */

export function deepCopy<T>(obj : T) : T {
	if (typeof obj !== "object") return obj;
	if (typeof obj === "undefined") return obj;
	//@ts-ignore
	if (obj instanceof Array) return [ ...obj.map((el) => deepCopy(el)) ];
	//@ts-ignore
	if (obj instanceof Date) return new Date(obj);
	if (obj === null) return null;
	const keys = Object.keys(obj);
	if(keys.length===0) return obj;
	//@ts-ignore
	return Object.assign(...keys.map((k) => ({ [k]: deepCopy(obj[k]) })));
}