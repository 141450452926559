import React, { useRef } from "react";
import "./index.scss";
import { useOnClickOutside } from "hooks";
export const Drop = ({dropped, onLeave, children}: any) => {
	const ref = useRef<any>();
	useOnClickOutside(ref, () => {
		if(dropped && onLeave) {
			onLeave();
		}
	});
	if(!dropped) return null;
	return <div className="drop" ref={ref}>
		<div className="drop-inner">
			{children}
		</div>
	</div>
}