import React from "react";
import "./index.scss";
import { TabsContainer, Loader } from "controls";
import { Panel } from "./Panel";
import { useReservationSearchFormValues, useReservationSearchConfirmed } from "api";
import { useTripsValidator } from "./useTripsValidator";
import { LOADING_STR } from "consts";
import { useFetchTripsGlobalState } from "api/ticketing/useFetchTrips";
import { useTripsTab } from "api/ticketing/useTripsTab";

export const TripsView = () => {
  const [tab] = useTripsTab();
  const [{ roundTrip }] = useReservationSearchFormValues();
  const [{ volta }] = useReservationSearchConfirmed();
  const onlyIda = !roundTrip || !volta;
  useTripsValidator();
  const [loading] = useFetchTripsGlobalState();

  return <div className="trips-view">
    {loading && <Loader text={LOADING_STR}/>}
    {!onlyIda && <TabsContainer 
      routed={{
        query: "trips_tab",
        buttons: [
          { name: "ida", children: "Horários de Ida", active: tab==="ida" },
          { name: "volta", children: "Horários de Volta", active: tab==="volta" },
        ]
      }}
    >
      <Panel direction={tab}/>
    </TabsContainer>}
    {onlyIda && <Panel direction={"ida"}/>}
  </div>
}